import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const marketService = {
  getMarkets: async (projectId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/markets`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching markets:", error);
      throw handleApiError(error);
    }
  },

  addMarket: async (projectId, marketName) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/markets`,
        {
          name: marketName,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error adding market:", error);
      throw handleApiError(error);
    }
  },

  deleteMarket: async (projectId, marketId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/markets/${marketId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting market:", error);
      throw handleApiError(error);
    }
  },
};
