import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Informaiton({ changePage, backPage }) {
  return (
    <div>
      <div className="w-full text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-full  ">
        <div className="w-full ">
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Müşteri ve Problem Analizi bölümünün son aşamasında uygulamaya
            geçirmek istediğiniz yenilikçi fikirlere karar vermiş durumdasınız.
            Ama henüz fikir aşamasındasınız. Bu noktada birer girişimci gibi
            düşünerek iş fikrini hayata geçirip geçirmemeye karar vermeli ve iş
            fikrinizi bir iş modeline dönüştürmelisiniz. <br /> <br />
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi başlayalım! <br />
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            <br />
            Eğer iş fikirleriniz iyileştirilmiş veya yeni bir ürün üzerine ise
            pazardaki rakiplerinizden farklılaşmaya gidiyorsunuz demektir. Yani
            bir değer önerisi sunmaya hazırlık yapıyorsunuz demektir! Bunun için
            Kim & Mauborgne tarafından ortaya konmuş Mavi Okyanus Stratejisi'nin
            Alıcı Değer Haritası'na odaklanarak çözümünüzün mevcut pazarınızın
            sunmadığı hangi değer önerilerine odaklandığını haritalamaya
            çalışınız. Böylece iş fikrinizin mevcut çözümlerden fark yarattığı
            noktaları çıkarabilirsiniz.{" "}
          </span>
        </div>
        <br />
        <div className="w-full   text-[#797979] text-lg font-normal font-['Outfit']">
          Bir alıcının/müşterinin bir ürün deneyimi genellikle satın alma
          aşamasından elden çıkarmaya kadar az çok ardışık olarak ilerleyen altı
          aşamalı bir döngüye bölünebilir. Tabloda bu altı aşamalı döngüyü
          görebilirsiniz. Firmalar ürünlerini ticarileştirebilmek için bu
          döngünün bazı aşamalarına çeşitli fayda kaldıraçları yerleştirmeleri
          gerekir. Bu fayda kaldıraçlarını 6 bileşen halinde inceleyebiliriz:{" "}
        </div>
        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            1
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Verimlilik:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Ürünün verimliliğinin, etkinliğinin iyileştirilmesidir. Daha az
                süre, daha az efor, daha çok kapasite, daha iyi performans gibi
                göstergeler dahil edilebilir.{" "}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            2
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Sadelik:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Üründe karmaşıklığın veya belirsizliğin azaltılması ile ilgili
                iyileştirmelerdir. Üründen bazı şeyler çıkarılarak veya
                azaltılarak gerçekleştirilebilir.{" "}
              </span>
            </div>
          </div>
        </div>

        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            3
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Kolaylık:
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                {" "}
                Ürüne erişim, kullanım gibi safhalarda ürün ile olan etkileşimin
                kolaylaştırılmasıdır. Ürüne 7/24 erişim veya ürün ile ilgili
                kullanıcı dostu tasarıma gidilmesi örnek gösterilebilir.{" "}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            4
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <div className="w-full">
                <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                  Risk azaltma
                </span>
                <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                  : Finansal, fiziksel veya duygusal maruz kalınabilecek
                  etmenlerin azaltılmasıdır. Ürünün bakım masraflarının
                  azaltılması, kullanım esnasındaki iş kazalarının önüne
                  geçilecek düzenlemeler yapılması veya eğitimler verilmesi,
                  müşteri hizmetlerinin iyileştirilmesi, ürünün satın almasını
                  kolaylaştırıcı garanti sürelerinde değişikliğe gidilmesi gibi
                  çeşitli örnekler verilebilir.{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            5
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Eğlence&Görüntü:
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                {" "}
                Estetik, görünüm, his ve davranışa ilişkin iyileştirmelerdir.
                Fiziki bir ürünün VR/AR ortamlarında deneyimlenmesi,
                oyunlaştırma elementlerinden faydalanılması, sosyal medya
                kampanyalarının düzenlenmesi, müşteri ilişkilerinin
                iyileştirilmesi gibi çeşitli örnekler verilebilir.{" "}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div className="flex">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            6
          </div>
          <div className="w-[1046px] ml-4">
            <div className="w-full">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Sosyal Fayda:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Toplumsal değer sunacak veya çevre dostu çözümler sunulmasıdır.
                Ürüne karbon ayak izi hesaplayıcısı eklenmesi, ürün ömrünü
                tamamlayınca başka bir ürüne dönüştürülebilir olması, bağış ve
                sosyal sorumluluk kampanyalarının düzenlenmesi örnek olarak
                verilebilir.
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="text-[#797979] text-lg font-normal font-['Outfit']">
        Siz de çözümünüze bu altı alandan en az biri ile değer kattığınızı
        ortaya koyunuz. Eğer çözümünüzde bu değer unsurlarından biri yoksa o
        zaman tekrar Müşteri ve Problem Analizi bölümüne geri dönün, problem ve
        çözümünüz üzerinde tekrar düşünün.{" "}
      </div>

      <br />
      <br />
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
