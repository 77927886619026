import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import { loginService } from "../../services/LoginService";
import Logo from "../../images/GP-Logo.png";
import RightIcon from "../../icons/arrow-right.svg";
import RegisterIcon from "../../icons/register.svg";
import { errorService } from "../../services/error";
import { toast } from "react-toastify";
import { MENU_ITEMS } from "../../components/Sidebar/constants";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useDispatch } from "react-redux";
import { setSpinner } from "../../redux/slices/spinnerSlice";

export default function Login() {
  // Initializations
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [userLogin, setUserLogin] = useState({
    mail: "",
    password: "",
  });

  const [rememberLogin, setRememberLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Form validation
  const [formErrors, setFormErrors] = useState({
    mail: "",
    password: "",
  });

  const validateForm = () => {
    let valid = true;
    const errors = {
      mail: "",
      password: "",
    };

    // Email validation
    if (!userLogin.mail) {
      errors.mail = "E-posta adresi gereklidir";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(userLogin.mail)) {
      errors.mail = "Geçerli bir e-posta adresi giriniz";
      valid = false;
    }

    // Password validation
    if (!userLogin.password) {
      errors.password = "Şifre gereklidir";
      valid = false;
    } else if (userLogin.password.length < 6) {
      errors.password = "Şifre en az 6 karakter olmalıdır";
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  async function loginUser() {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    // Handle remember login storage
    localStorage.setItem("mail", rememberLogin ? userLogin.mail : "");
    localStorage.setItem("password", rememberLogin ? userLogin.password : "");

    try {
      const response = await loginService.login(
        userLogin.mail,
        userLogin.password
      );

      if (response.isConfirmed) {
        localStorage.setItem("menu", JSON.stringify(MENU_ITEMS));
        localStorage.setItem("userEmail", userLogin.mail);
        dispatch(setSpinner(true));
        navigate("/anasayfa");
      } else {
        toast.info(
          <div>
            Lütfen e-mail adresinizi onaylayın. Eğer onaylama maili
            almadıysanız.
            <div
              onClick={() => confirmAccountReset()}
              className="inline underline cursor-pointer"
            >
              &nbsp;Tekrar mail göndermek için tıklayın.
            </div>
          </div>
        );
      }
    } catch (err) {
      // Handle different error responses
    } finally {
      setIsLoading(false);
    }
  }

  async function confirmAccountReset() {
    try {
      await loginService.resendConfirmationEmail(userLogin.mail);
      toast.success("Mail gönderimi başarıyla tamamlanmıştır.");
    } catch (err) {
      toast.error(err.message || "Mail gönderilemedi.");
      errorService(err, navigate);
    }
  }

  function rememberLoginState(remember) {
    localStorage.setItem("remember", remember);
    setRememberLogin(remember);
  }

  useEffect(() => {
    const rememberState = localStorage.getItem("remember");
    setRememberLogin(rememberState === "false" ? false : true);
    if (localStorage.getItem("remember")) {
      setUserLogin({
        mail: localStorage.getItem("mail"),
        password: localStorage.getItem("password"),
      });
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      loginUser();
    }
  };

  return (
    <LoadingSpinner state={isLoading}>
      <div className="bg-[#F6F7FA] flex flex-col justify-center items-center w-full h-screen">
        <div className="-mt-[8%]">
          <div className="flex items-center justify-center">
            <div className="flex-1">
              <img
                className="h-[77px] w-[198px] mb-[44px]"
                src={Logo}
                alt="Logo"
              ></img>
            </div>
            <div className="h-[60px] mb-10">
              <Button
                iconLeft={RegisterIcon}
                className={"py-[16px] px-[35px]"}
                text={"Kayıt Ol"}
                onClick={() => navigate("/kayit")}
              />
            </div>
            <div></div>
          </div>
          <div className="bg-white w-[800px] h-[382px] p-[40px] rounded-[8px]">
            <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
              <div className="text-black text-5xl font-medium font-['Outfit']">
                Giriş Yap
              </div>
              <div className="w-[720px] text-gray-500 text-base font-normal font-['Outfit']">
                Girişim Pusulası ile becerilerini değerlendir
              </div>

              <div className="flex">
                <div className="flex-1 mr-[8px]">
                  <Input
                    name={"e-Posta"}
                    placeholder={"e-Posta adresiniz giriniz"}
                    labelName={"e-Posta"}
                    type={"email"}
                    autocomplete="email"
                    required={true}
                    value={userLogin.mail}
                    onChange={(e) =>
                      setUserLogin({ ...userLogin, mail: e.target.value })
                    }
                    onKeyPress={handleKeyPress}
                    error={formErrors.mail}
                  />
                </div>

                <div className="flex-1 ml-[8px]">
                  <Input
                    name={"Şifre"}
                    placeholder={"**************"}
                    labelName={"Şifre"}
                    type={"password"}
                    autocomplete="current-password"
                    required={true}
                    value={userLogin.password}
                    onChange={(e) =>
                      setUserLogin({ ...userLogin, password: e.target.value })
                    }
                    onKeyPress={handleKeyPress}
                    error={formErrors.password}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="flex w-full items-start ml-0.5">
                  <div className="flex-1 flex items-center">
                    <div className="flex-1">
                      {/* <Checkbox
                        text={" Giriş bilgilerini hatırla"}
                        value={rememberLogin}
                        onChange={(e) => rememberLoginState(e.target.checked)}
                      /> */}
                    </div>

                    <div
                      onClick={() => navigate("/sifremi-unuttum")}
                      className="flex mr-[92px] text-gray-500 text-base font-normal font-['Outfit'] cursor-pointer hover:text-gray-400"
                    >
                      Şifremi Unuttum
                    </div>
                  </div>
                  <div className="flex justify-end items-end">
                    <Button
                      className={"py-[16px] px-[35px]"}
                      text={"Onayla"}
                      iconRight={RightIcon}
                      onClick={() => loginUser()}
                      type="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LoadingSpinner>
  );
}
