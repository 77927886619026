import React, { useState, useEffect } from "react";
import RadioSurvey from "../Modules/RadioSurvey";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { exportPotentialSurveyService } from "../../../../../services/exportPotentialSurveyService";
import { toast } from "react-toastify";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function Information({
  nextChange,
  backChange,
  productsId,
  projectName,
}) {
  const [surveyData, setSurveyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!productsId) return;

      setIsLoading(true);
      try {
        const response = await exportPotentialSurveyService.getAnswers(
          productsId
        );
        setSurveyData(response);
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnswers();
  }, [productsId]);

  const handleAnswerChange = async (containerId, value) => {
    try {
      await exportPotentialSurveyService.submitAnswer(
        productsId,
        containerId,
        parseInt(value)
      );
      setSurveyData((prev) =>
        prev.map((category) => ({
          ...category,
          answers: category.answers.map((q) =>
            q.containerId === containerId
              ? { ...q, answer: parseInt(value) }
              : q
          ),
        }))
      );
    } catch (error) {
      toast.error("Cevap kaydedilirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("page-content-container");

    // Store original styles
    const originalStyle = container.style.cssText;

    // Set temporary container styles for PDF
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Handle radio inputs
    const radioInputs = container.getElementsByTagName("input");
    const originalElements = [];

    Array.from(radioInputs).forEach((input) => {
      if (input.type === "radio" && input.checked) {
        const div = document.createElement("div");
        div.textContent = "✓"; // Checkmark for selected radio
        div.style.cssText = `
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Outfit;
          font-size: 14px;
          color: #439dc6;
        `;

        originalElements.push({
          element: input,
          parent: input.parentNode,
        });

        input.parentNode.replaceChild(div, input);
      }
    });

    try {
      await downloadPageAsPDF(
        "page-content-container",
        "İhracat Potansiyel Anketi",
        projectName || "Project"
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Restore original elements
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <LoadingFullPage state={isLoading || isPdfLoading}>
      <div className="w-full flex flex-col">
        <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
          Belirlediğinin ürün/hizmet grubunuz için ihracat niyetiniz iyi bir
          durumda ise sıra geldi ihracat potansiyelinizi belirlemeye! Bunun için
          hazırladığımız anketi doldurarak ihracat yapabilmeye ne kadar hazır
          hale geldiğinizi kendiniz değerlendirebilirsiniz. Her sektöre ve ürün
          grubuna göre bu kriterlerin önemi değişebileceği ve negatif yönlü
          soruların varlığı dolayısıyla bu modülün değerlendirmesini sizlere
          bırakıyoruz.
          <br />{" "}
        </div>
        <br />
        <br />
        <div className="w-full text-center text-[#439dc6] text-[40px] font-semibold font-['Outfit']">
          İhracat Potansiyel Anketi
        </div>
        <br />
        <div id="page-content-container" className="space-y-4">
          {surveyData.map((category) => (
            <div key={category.title} className="my-2">
              <div className="w-[242px] h-[26px] text-[#439dc6] text-base font-semibold font-['Outfit']">
                <div className="flex-1">{category.title}</div>
              </div>
              <div>
                {category.answers.map((question, index) => (
                  <RadioSurvey
                    key={question.containerId}
                    question={question.title}
                    value={question.answer}
                    id={question.containerId}
                    index={index}
                    defaultQuestion={true}
                    onChange={(value) =>
                      handleAnswerChange(question.containerId, value)
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <div className="bg-[#439dc6] w-[400px] flex items-center  p-4">
          <div className="w-[61px] h-[26px] text-white text-base font-semibold font-['Outfit']">
            NOT :
          </div>
          <div className="w-[353px] h-8 text-white text-sm font-semibold font-['Outfit']">
            * (-) yönlü soru yok/hayır'dan var/evet'e doğru gittikçe kötüleşen
            bir durumu ifade eder.{" "}
          </div>
        </div>

        <div className="mt-8">
          <Stepper
            nextChange={nextChange}
            backChange={backChange}
            activeStep={2}
            stepLimit={2}
            onPdfDownload={handlePdfDownload}
          />
        </div>
      </div>
    </LoadingFullPage>
  );
}
