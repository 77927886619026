import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { rcaService } from "../../../../../services/rcaService";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import InfoIcon from "../../../../../icons/info.svg";
import UserIcon from "../../../../../icons/hc-user.svg";
import Close from "../../../../../icons/close.svg";
import BigAdd from "../../../../../icons/big-add.svg";
import SmallAdd from "../../../../../icons/small-add.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import ReasonContainerModal from "../../../../../components/Modals/ReasonContainerModal";
import AddReasonModal from "../../../../../components/Modals/AddReasonModal";
import Modal from "../../../../../components/Modal/Modal";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import ReasonIcon from "../../../../../icons/reason.svg";
import ProblemIcon from "../../../../../icons/problem.svg";
import EffectsResultsIcon from "../../../../../icons/effects-results.svg";
import DeleteModal from "../../../../../components/Modals/DeleteModal";
import { handleApiError } from "../../../../../utils/errorHandler";
import InfoTooltip from "../../../../../components/Tooltip/InfoTooltip";
import tooltipTexts from "../../../../../constants/tooltipTexts";

export default function RootCause({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [problemAnswer, setProblemAnswer] = useState("");
  const [consequencesAnswer, setConsequencesAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [reasonContainers, setReasonContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [isAddReasonModalOpen, setIsAddReasonModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [isDeleteContainerConfirmOpen, setIsDeleteContainerConfirmOpen] =
    useState(false);

  // Fetch answers when component mounts
  useEffect(() => {
    const fetchAnswers = async () => {
      setIsLoading(true);
      try {
        const [problemRes, consequencesRes] = await Promise.all([
          rcaService.getProblemAnswer(projectId),
          rcaService.getConsequencesAnswer(projectId),
        ]);

        setProblemAnswer(problemRes.answer || "");
        setConsequencesAnswer(consequencesRes.answer || "");
      } catch (error) {
        handleApiError(error, "Cevaplar yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) {
      fetchAnswers();
    }
  }, [projectId]);

  // Fetch reason containers when component mounts
  useEffect(() => {
    const fetchReasonContainers = async () => {
      try {
        const containers = await rcaService.getReasonContainers(projectId);
        setReasonContainers(containers);
      } catch (error) {
        handleApiError(error, "Sebepler yüklenirken bir hata oluştu");
      }
    };

    if (projectId) {
      fetchReasonContainers();
    }
  }, [projectId]);

  // Handle next button click
  const handleNext = async () => {
    if (!problemAnswer.trim() || !consequencesAnswer.trim()) {
      handleApiError(null, "Lütfen tüm alanları doldurunuz");
      return;
    }

    try {
      await Promise.all([
        rcaService.saveProblemAnswer(projectId, problemAnswer),
        rcaService.saveConsequencesAnswer(projectId, consequencesAnswer),
      ]);
      changePage();
    } catch (error) {
      handleApiError(error, "Cevaplar kaydedilirken bir hata oluştu");
    }
  };

  // Handle reason submit
  const handleReasonSubmit = async (title) => {
    try {
      await rcaService.createReasonContainer(projectId, title);
      const updatedContainers = await rcaService.getReasonContainers(projectId);
      setReasonContainers(updatedContainers);
    } catch (error) {
      handleApiError(error, "Sebep eklenirken bir hata oluştu");
    }
  };

  const handleAddReason = async (content) => {
    if (!selectedContainer) return;

    try {
      await rcaService.addReason(selectedContainer.id, content);
      const updatedContainers = await rcaService.getReasonContainers(projectId);
      setReasonContainers(updatedContainers);
    } catch (error) {
      handleApiError(error, "Madde eklenirken bir hata oluştu");
    }
  };

  const handleDeleteReason = async () => {
    if (!selectedReason) return;

    try {
      await rcaService.deleteReason(selectedReason.id);
      const updatedContainers = await rcaService.getReasonContainers(projectId);
      setReasonContainers(updatedContainers);
      setIsDeleteConfirmOpen(false);
    } catch (error) {
      handleApiError(error, "Madde silinirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      const container = document.getElementById("page-content-container");

      // Geçici stil değişiklikleri
      const originalStyle = container.style.cssText;

      // Container'ı geçici olarak düzenleyelim
      container.style.cssText = `
        width: 1000px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Textarea'ları geçici olarak görünür div'lere çevirelim
      const textareas = container.getElementsByTagName("textarea");
      const originalElements = [];

      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder;
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      try {
        await downloadPageAsPDF(
          "page-content-container",
          "Kök Neden Analizi",
          projectName
        );
      } finally {
        // Orijinal duruma geri dönelim
        container.style.cssText = originalStyle;

        // Orijinal textarea'ları geri yükleyelim
        originalElements.forEach(({ element, parent }) => {
          const currentElement = parent.firstChild;
          parent.replaceChild(element, currentElement);
        });
        setIsPdfLoading(false);
      }
    } catch (error) {
      handleApiError(error, "PDF oluşturulurken bir hata oluştu");
      setIsPdfLoading(false);
    }
  };

  const handleDeleteContainer = async () => {
    if (!selectedContainer) return;

    try {
      await rcaService.deleteReasonContainer(selectedContainer.id);
      const updatedContainers = await rcaService.getReasonContainers(projectId);
      setReasonContainers(updatedContainers);
      setIsDeleteContainerConfirmOpen(false);
    } catch (error) {
      handleApiError(error, "Sebep silinirken bir hata oluştu");
    }
  };

  return (
    <>
      <LoadingFullPage state={isLoading || isPdfLoading}>
        <div className="w-full flex items-center flex-col">
          <div
            id="page-content-container"
            className="w-full flex-1 items-center flex flex-col justify-center"
          >
            <div className="text-black text-lg font-bold font-['Outfit']">
              Kök Neden Analizi
            </div>

            <div className="w-full h-[82px] relative border rounded-2xl border-[#c8c8c8] mt-[45px]">
              <div className="w-64 h-[82px]  left-0 top-0 absolute bg-[#169888] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <InfoTooltip title={tooltipTexts.rootCauseAnalysis.impacts}>
                  <img className="absolute  right-2 top-2" src={InfoIcon} />
                </InfoTooltip>
                <img src={EffectsResultsIcon} />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Etkiler/Sonuçlar
                    <br />
                  </span>
                </div>
              </div>
              <div className="ml-[260px] p-2">
                <textarea
                  value={consequencesAnswer}
                  onChange={(e) => setConsequencesAnswer(e.target.value)}
                  className="block w-full text-lg border-none overflow-auto outline-none h-[60px] font-['Outfit'] resize-none"
                  placeholder="Bu alana 'Etkiler/Sonuçlar' ile ilgili açıklamanızı yazınız."
                  maxLength={250}
                />
              </div>
            </div>

            <div className="w-full h-[82px] relative border rounded-2xl border-[#c8c8c8] mt-[26px]">
              <div className="w-64 h-[82px]  left-0 top-0 absolute bg-[#98166C] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <InfoTooltip title={tooltipTexts.rootCauseAnalysis.problem}>
                  <img className="absolute  right-2 top-2" src={InfoIcon} />
                </InfoTooltip>
                <img src={ProblemIcon} />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Problem
                    <br />
                  </span>
                </div>
              </div>
              <div className="ml-[260px] p-2">
                <textarea
                  value={problemAnswer}
                  onChange={(e) => setProblemAnswer(e.target.value)}
                  className="block w-full text-lg border-none overflow-auto outline-none h-[50px] font-['Outfit'] resize-none"
                  placeholder="Bu alana 'Problem' ile ilgili açıklamanızı yazınız."
                  maxLength={250}
                />
              </div>
            </div>
            <div className="w-full  bg-white rounded-[20px] border mt-[26px] border-[#c8c8c8]">
              {" "}
              <div className="w-full h-[81px] bg-[#5f6fa8] rounded-[20px]  relative">
                <InfoTooltip title={tooltipTexts.rootCauseAnalysis.causes}>
                  <img className="absolute  right-7 top-7" src={InfoIcon} />
                </InfoTooltip>
                <div className="w-64 h-[82px]  left-0 top-0 absolute  rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={ReasonIcon} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Sebepler
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid p-4 grid-cols-2 gap-4">
                {reasonContainers.map((container, index) => (
                  <div
                    key={container.id}
                    className={`rounded-lg min-h-[400px] ${
                      index % 2 === 0 ? "bg-[#995FA81A]" : "bg-[#f6eff0]"
                    }`}
                  >
                    <div className="p-2">
                      <div className="mt-2 mb-2 flex justify-center">
                        <div className="ml-2 flex-1 w-[109px] h-3 text-[#797979] text-sm font-semibold font-['Outfit'] mb-4">
                          {container.title}
                        </div>
                        <div className="flex items-center">
                          {container.reasons?.length < 5 && (
                            <img
                              src={SmallAdd}
                              className="cursor-pointer hover:opacity-70 mr-2"
                              onClick={() => {
                                setSelectedContainer(container);
                                setIsAddReasonModalOpen(true);
                              }}
                            />
                          )}
                          <button
                            className="p-1.5 rounded-lg bg-[#995FA81A] transition-colors duration-200 group"
                            onClick={() => {
                              setSelectedContainer(container);
                              setIsDeleteContainerConfirmOpen(true);
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              className="text-gray-400 group-hover:text-[#995FA8] transition-colors duration-200"
                            >
                              <path
                                d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {container.reasons?.map((reason) => (
                        <div
                          key={reason.id}
                          className="rounded-lg bg-white p-2 flex flex-col mt-2 min-h-fit"
                        >
                          <div className="flex items-start">
                            <img
                              src={Close}
                              className="min-w-[14px] h-[14px] mt-1 cursor-pointer hover:opacity-70"
                              onClick={() => {
                                setSelectedReason(reason);
                                setIsDeleteConfirmOpen(true);
                              }}
                            />
                            <div className="flex-1 ml-2">
                              <div className="text-[#797979] text-xs font-normal font-['Outfit'] whitespace-pre-wrap break-words   w-full">
                                {reason.content}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                <div
                  className="rounded-lg h-[400px] bg-[#f4f4f4] flex items-center justify-center border-2 border-dashed cursor-pointer hover:opacity-70"
                  onClick={() => setIsReasonModalOpen(true)}
                >
                  <img src={BigAdd} alt="Add reason" />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center ">
            <div className="w-[600px] flex-1">
              <div className="w-full pr-10 ">
                <Stepper
                  nextChange={handleNext}
                  backChange={backPage}
                  activeStep={4}
                  stepLimit={9}
                  onPdfDownload={handlePdfDownload}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingFullPage>

      <ReasonContainerModal
        isOpen={isReasonModalOpen}
        onClose={() => setIsReasonModalOpen(false)}
        onSubmit={handleReasonSubmit}
      />

      <AddReasonModal
        isOpen={isAddReasonModalOpen}
        onClose={() => setIsAddReasonModalOpen(false)}
        onSubmit={handleAddReason}
        containerTitle={selectedContainer?.title}
      />

      <DeleteModal
        open={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
        onDelete={handleDeleteReason}
        title="Silme Onayı"
        message="Bu maddeyi silmek istediğinizden emin misiniz?"
      />

      <DeleteModal
        open={isDeleteContainerConfirmOpen}
        onClose={() => setIsDeleteContainerConfirmOpen(false)}
        onDelete={handleDeleteContainer}
        title="Silme Onayı"
        message="Bu sebebi ve içindeki tüm maddeleri silmek istediğinizden emin misiniz?"
      />
    </>
  );
}
