import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const strategyMapService = {
  async getProcessLabels() {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/process-labels`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getStrategicGoals(projectId, labelId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/labels/${labelId}/answers`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getGoalsAndConsequences(projectId, answerId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/stratetic-guidance-answers/${answerId}/goals-and-fundamental-consequences`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async addGoal(projectId, answerId, name) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/strategic-guidance-answers/${answerId}/goals`,
        { name },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteGoal(projectId, goalId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/goals/${goalId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async addFundamentalConsequence(projectId, goalId, name) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/goals/${goalId}/fundamental-consequences`,
        { name },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteFundamentalConsequence(projectId, consequenceId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/strategic-plan/strategy-and-okr-map/${projectId}/fundamental-consequences/${consequenceId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
