import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function ClueExploratoryInterview({ changePage, backPage }) {
  return (
    <div>
      <div className="w-[805px] h-6 text-[#797979] text-xl font-bold font-['Outfit']">
        Etkili Keşif Görüşmeleri Yapmak İçin İpuçlar{" "}
      </div>

      <br />
      <br />
      <div className="w-[1046px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Aktif Bir Şekilde Dinleyin:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Tamamen doğal olun ve konuşmaktan daha çok dinleyin. İlgilendiğinizi
          göstermek için sözel olmayan ipuçları (baş sallamak gibi) kullanın.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1046px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Yargılayıcı Olmayın:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Kullanıcılara tarafsız bir ton koruyarak ve yanıtları hakkında
          yargılayıcı olmadan açıkça paylaşmaya teşvik edin.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1046px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Açık Uçlu Sorular Sorun:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Daha ayrıntılı ve düşünceli yanıtlar almak için açık uçlu sorular
          kullanın.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1046px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Açıklama İçin Takip:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Açıklamaya ihtiyacınız varsa veya bir konuya daha derinlemesine dalmak
          istiyorsanız takip soruları sormaktan çekinmeyin.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1046px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Belgeleyin ve Kaydedin:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Ayrıntılı notlar alın ve karşı taraf izin verirse, daha sonra
          görüşmeleri analiz etmek üzere görüşmeyi kaydedin.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1046px] text-[#797979] text-lg font-normal font-['Outfit']">
        Görüşmeden sonra aşağıdaki şablona izlenimlerinizi kaydedin.{" "}
      </div>

      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={8}
              stepLimit={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
