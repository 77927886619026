import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MarketTabs from "../../../../../components/MarketTabs";
import { marketAnswerService } from "../../../../../services/marketAnswerService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function SizeMarket({
  changePage,
  backPage,
  projectId,
  projectName,
  selectedMarketId,
  setSelectedMarketId,
}) {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [marketData, setMarketData] = useState({
    sizeAnswer: "",
    growthAnswer: "",
  });

  useEffect(() => {
    if (selectedMarketId) {
      fetchMarketAnswers();
    }
  }, [selectedMarketId]);

  const fetchMarketAnswers = async () => {
    try {
      const response = await marketAnswerService.getMarketAnswers(
        projectId,
        selectedMarketId
      );
      setMarketData({
        sizeAnswer: response[2]?.answers[0]?.value || "",
        growthAnswer: response[3]?.answers[0]?.value || "",
      });
    } catch (error) {
      console.error("Error fetching market answers:", error);
    }
  };

  const handleMarketSelect = (marketId) => {
    setSelectedMarketId(marketId);
  };

  const handleAnswerChange = async (titleId, value) => {
    try {
      await marketAnswerService.submitAnswer(
        projectId,
        selectedMarketId,
        titleId,
        parseInt(value)
      );

      setMarketData((prev) => ({
        ...prev,
        [titleId === 20 ? "sizeAnswer" : "growthAnswer"]: value,
      }));

      await fetchMarketAnswers();
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("size-market-container");

    // Temporary style changes
    const originalStyle = container.style.cssText;

    // Temporarily adjust container
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    try {
      await downloadPageAsPDF(
        "size-market-container",
        "Pazar Büyüklüğü ve Büyüme Hızı",
        projectName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage isLoading={isPdfLoading}>
      <MarketTabs
        projectId={projectId}
        onMarketSelect={handleMarketSelect}
        selectedMarketId={selectedMarketId}
      />
      <div id="size-market-container" className="w-full">
        {!selectedMarketId ? (
          <div className="w-full h-[400px] border border-[#e0e0e0] rounded-[10px] flex items-center justify-center bg-white">
            <div className="text-[#797979] text-lg font-normal font-['Outfit']">
              Lütfen yukarıdaki kısımdan dolduracağınız pazarı seçiniz.
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="flex items-center">
              <div className="w-[641px] text-black text-lg font-semibold font-['Outfit']">
                3. Pazar Büyüklüğü:{" "}
              </div>
              <div className="text-[#439dc6] text-lg font-semibold font-['Outfit']">
                Puan Giriniz:
              </div>
              <FormControl sx={{ m: 1, width: 347, height: 40 }} size="small">
                <InputLabel id="size-select-label">
                  Lütfen seçim yapınız
                </InputLabel>
                <Select
                  labelId="size-select-label"
                  value={marketData.sizeAnswer}
                  onChange={(e) => handleAnswerChange(20, e.target.value)}
                  label="Lütfen seçim yapınız"
                  sx={{
                    height: "40px",
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#e0e0e0",
                    },
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="mt-2 text-[#797979] text-lg font-normal font-['Outfit']">
              Pazar büyüklüğünün yeterince büyük olup olmadığı iş hedefleriniz,
              sektörünüz, müşteri potansiyeliniz ve büyüme beklentileriniz gibi
              çeşitli faktörlere bağlıdır. Örneğin pazarda yoğun bir rekabet
              varsa bu durum büyük bir pazarın varlığına işaret edebilir. Bu
              durumda pazar halihazırda rakiplere doymuş ise bu pazara girmek
              olağanüstü farklılaşmayı gerektirebilir. Değer önerinizi iyi
              kurgulamanız gerekir. O yüzden bir pazarın büyük olması her zaman
              bir avantajı beraberinde getirmez. Ayrıca pazarın uzun vadeli
              uygulanabilirliğini değerlendirin. Var olmaya ve büyümeye devam
              edecek mi, yoksa kısa vadeli bir heves pazar mı? O yüzden bir
              pazarın büyüklüğünü hesaplarken dikkatli olun. Şimdi 1-5 arasında
              bir puanlama yapın (Örneğin 1 çok düşük büyüklükte bir pazar; 5
              çok yüksek büyüklükte bir pazar) ama negatif faktörleri de hesaba
              katın
            </div>

            <div className="ml-4 mr-4 h-[0px] border-[1px] mt-8 mb-8 border-[#e0e0e0]"></div>

            <div className="flex items-center">
              <div className="w-[641px] text-black text-lg font-semibold font-['Outfit']">
                4. Pazar Büyüme Hızı (%):
              </div>
              <div className="text-[#439dc6] text-lg font-semibold font-['Outfit']">
                Puan Giriniz:
              </div>
              <FormControl sx={{ m: 1, width: 347, height: 40 }} size="small">
                <InputLabel id="growth-select-label">
                  Lütfen seçim yapınız
                </InputLabel>
                <Select
                  labelId="growth-select-label"
                  value={marketData.growthAnswer}
                  onChange={(e) => handleAnswerChange(21, e.target.value)}
                  label="Lütfen seçim yapınız"
                  sx={{
                    height: "40px",
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#e0e0e0",
                    },
                  }}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="mt-2 text-[#797979] text-lg font-normal font-['Outfit']">
              Bu hız: (Sektörün cari yıl satışları – Geçmiş yıl satışları) /
              Geçmiş yılın satışları x 100 olarak hesaplanır. Enflasyon oranı
              düşük olan gelişmiş ülkelerde bu oran genellikle %10 olarak
              alınmaktadır. %10 ve üzeri bir büyüme o pazar için iyi bir büyüme
              olarak hesaplanabilir. Ancak ülke ekonomisinin içinde bulunduğu
              duruma göre bu oran daha düşük veya yüksek olarak belirlenebilir.
              Normal büyüme hızı olarak kullanılan popüler ölçülerden biri de
              ülke ekonomisindeki genel büyüme oranıdır. Bazı sektörlerde ise o
              sektörün bir üst sektöründeki büyüme hızı normal büyüme hızı
              olarak kabul edilmektedir. Bu değerlendirmelere göre içinde
              bulunduğunuz pazarın büyüme hızını 1-5 arasında
              derecelendirebilirsiniz. Örneğin 1 büyüme hızı çok yavaş; 5 büyüme
              hızı çok yüksek bir pazarı temsil edebilir
            </div>
          </div>
        )}

        <div className="w-full flex-1">
          <div className="w-full pr-10">
            <Stepper
              backChange={backPage}
              nextChange={changePage}
              activeStep={4}
              stepLimit={5}
              onPdfDownload={handlePdfDownload}
            />
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
