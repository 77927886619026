import Header from "../../components/Header/Header";
import Business from "../../images/business.svg";
import { useAccountInformationQuery } from "../../services/AccountInformation";
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Users from "./Users/Users";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/profileSlice";
import Modules from "./Modules/Modules";
import { accountInformationService } from "../../services/AccountInformation";
import { useEffect, useState } from "react";
import { errorService } from "../../services/error";
import { useNavigate } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Add these icons
const UserIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UsersIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ModulesIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LockIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
    <path
      d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function Profile() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.profile.activeTab);
  const [accountInfo, setAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountInfo = async () => {
      setIsLoading(true);
      try {
        const data = await accountInformationService.getAccountInformation();
        setAccountInfo(data);
      } catch (err) {
        setError(err);
        errorService(err, navigate);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountInfo();
  }, [navigate]);

  // Replace data with accountInfo in your component
  const isOrganizationUser = accountInfo?.accountType === "OrganizationUser";

  const handleChange = (event, newValue) => {
    dispatch(setActiveTab(newValue));
  };

  return (
    <div className="bg-[#F6F7FA] w-screen h-screen p-10 flex items-center flex-col">
      <Header />
      <div className="w-[1640px]  bg-white rounded-[10px] border border-neutral-200 mt-4 px-[126px] py-[56px] flex">
        <div className="flex flex-col items-center ">
          <div className="w-[216px] h-[216px] bg-[#439DC6] rounded-[20px] flex p-4">
            <img src={Business} />
          </div>
          <div className="mt-5">
            <div>
              <div className="text-zinc-800 text-3xl font-bold font-['Outfit']">
                {accountInfo?.name || "Loading..."}
              </div>
              <div className="text-zinc-800 text-xl font-light font-['Outfit']">
                {accountInfo?.corporation || "Loading..."}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-8 w-full">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#439DC6",
                    color: "#439DC6",
                    height: "3px",
                    borderRadius: "3px",
                  },
                }}
                sx={{
                  "& .MuiTab-root": {
                    minHeight: "48px",
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#64748B",
                    "&.Mui-selected": {
                      color: "#439DC6",
                    },
                  },
                }}
              >
                <Tab
                  icon={<UserIcon />}
                  iconPosition="start"
                  label="İletişim Bilgilerim"
                  {...a11yProps(0)}
                />
                {!isOrganizationUser && (
                  <Tab
                    icon={<UsersIcon />}
                    iconPosition="start"
                    label="Kullanıcılar"
                    {...a11yProps(1)}
                  />
                )}
                <Tab
                  icon={<ModulesIcon />}
                  iconPosition="start"
                  label="Modüller"
                  {...a11yProps(2)}
                />
                {!isOrganizationUser && (
                  <Tab
                    icon={<LockIcon />}
                    iconPosition="start"
                    label="Şifremi Güncelle"
                    {...a11yProps(3)}
                  />
                )}
              </Tabs>
            </Box>
            <CustomTabPanel value={activeTab} index={0}>
              <PersonalInformation />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={2}>
              <Modules />
            </CustomTabPanel>
            {!isOrganizationUser && (
              <CustomTabPanel value={activeTab} index={1}>
                <Users />
              </CustomTabPanel>
            )}

            {!isOrganizationUser && (
              <CustomTabPanel value={activeTab} index={3}>
                <ChangePassword />
              </CustomTabPanel>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}
