import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../../../components/Header/Header";
import TestCardInformation from "./Informations/TestCardInformation";
import TestCard from "./Modules/TestCard";
import Project from "../Project";

export default function MarketCompatibilityTests() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Ürün-Pazar Uyumu",
      subTexts: ["Bilgilendirme"],
    },
    {
      title: "Ürün-Pazar Uyumu",
      subTexts: ["Bilgilendirme", "Bilgilendirme"],
    },
    {
      title: "Ürün-Pazar Uyumu",
      subTexts: ["Bilgilendirme", "Bilgilendirme", "Test Kartı"],
    },
    {
      title: "Ürün-Pazar Uyumu",
      subTexts: ["Bilgilendirme", "Bilgilendirme", "Test Kartı"],
    },
  ]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const changePage = (newPage) => {
    if (newPage === 0) {
      resetSearchParams();
    }
    setPageState(newPage);
  };

  const changeProject = (id) => {
    setProjectId(id);
  };

  const changeProjectName = (name) => {
    setProjectName(name);
  };

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Project
              changePage={changePage}
              chageProject={changeProject}
              changeProjectName={changeProjectName}
            />
          )}
          {pageState === 1 && (
            <TestCardInformation
              changePage={() => setPageState(2)}
              backPage={() => setPageState(0)}
            />
          )}
          {pageState === 2 && (
            <TestCard
              backPage={() => setPageState(1)}
              changePage={() => setPageState(0)}
              projectId={projectId}
              projectName={projectName}
            />
          )}
        </div>
      </div>
    </>
  );
}
