import React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//import { Popover } from "antd";
import { ClickAwayListener, Tooltip } from "@mui/material";
import Check from "../../../../../icons/approval.svg";
import DeleteIcon from "../../../../../icons/delete-row.svg";
import DeletePopover from "../../../../../components/Popover/DeletePopover";

export default function RadioSurvey({
  bgColor,
  question,
  index,
  value,
  id,
  defaultQuestion,
  onChange,
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );

  return (
    <div
      style={{ backgroundColor: bgColor }}
      className={"flex   text-left justify-center items-center"}
    >
      <div className="flex-1 w-[764px] text-neutral-500 text-base font-normal font-['Outfit']  ml-1">
        {index + 1}. &nbsp;{question}
      </div>

      <div>
        <FormControl sx={{ m: 1, width: 347 }} size="small">
          <InputLabel style={{ font: "Outfit" }} id="demo-select-small-label">
            Evet / Hayır
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={value || ""}
            label="Lütfen seçim yapınız"
            onChange={handleChange}
          >
            <MenuItem value={0}>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-gray-400 mr-2 rounded-full"></div>
                <div>Seçimi Kaldır</div>
              </div>
            </MenuItem>
            <MenuItem value={1}>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-green-400 mr-2 rounded-full"></div>
                <div>Evet</div>
              </div>
            </MenuItem>
            <MenuItem value={2}>
              <div className="flex items-center">
                <div className="w-4 h-4 bg-red-400 mr-2 rounded-full"></div>
                <div>Hayır</div>
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
