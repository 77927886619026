import axios from "axios";

export const adminService = {
  getUsers: async () => {
    const response = await axios.get("/api/admin/users");
    return response.data;
  },

  getModules: async () => {
    const response = await axios.get("/api/admin/modules");
    return response.data;
  },

  updateUserRole: async (userId, role) => {
    const response = await axios.put(`/api/admin/users/${userId}/role`, { role });
    return response.data;
  }
}; 