import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const rcaService = {
  async getProblemAnswer(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/problem/${projectId}/answer`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async saveProblemAnswer(projectId, answer) {
    try {
      const data = {
        answer,
        projectId,
      };

      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/problem/answer`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async getConsequencesAnswer(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/consequences/${projectId}/answer`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async saveConsequencesAnswer(projectId, answer) {
    try {
      const data = {
        answer,
        projectId,
      };

      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/consequences/answer`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async createReasonContainer(projectId, title) {
    try {
      const data = {
        title,
        projectId,
      };

      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/reason-containers`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async getReasonContainers(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/reason-containers/${projectId}/with-reasons`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async addReason(containerId, content) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/reason-containers/${containerId}/reasons`,
        { content },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async deleteReason(reasonId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/reasons/${reasonId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  async deleteReasonContainer(containerId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/customer-problem-analysis/empathy/rca/reason-containers/${containerId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
