import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InfoIcon from "../../../../../icons/add-item.svg";
import AddWhite from "../../../../../icons/add-white.svg";
import Close from "../../../../../icons/close.svg";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { swotAnalysisService } from "../../../../../services/swotAnalysisService";

export default function SwotAnalysisMatrix({
  subId,
  backPage,
  changePage,
  pageState,
  projectName,
}) {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [columns, setColumns] = useState({
    strengths: {
      tag: "S",
      id: "strengths",
      title: "Güçlü Yönler",
      items: [],
      color: "#169888",
    },
    weaknesses: {
      tag: "W",
      id: "weaknesses",
      title: "Zayıf Yönler",
      items: [],
      color: "#A85F68",
    },
    opportunities: {
      tag: "O",
      id: "opportunities",
      title: "Fırsatlar",
      items: [],
      color: "#5F6FA8",
    },
    threats: {
      tag: "T",
      id: "threats",
      title: "Tehditler",
      items: [],
      color: "#A95F97",
    },
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [newItemText, setNewItemText] = useState("");

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!subId) return;

      try {
        const response = await swotAnalysisService.getAnswers(subId);
        const newColumns = { ...columns };

        response.forEach((container) => {
          const columnKey = getColumnKeyFromContainerId(container.containerId);
          if (columnKey && container.answers) {
            newColumns[columnKey].items = container.answers.map((answer) => ({
              id: answer.id,
              content: answer.answer,
            }));
          }
        });

        setColumns(newColumns);
      } catch (error) {
        toast.error("SWOT analizi verileri yüklenirken bir hata oluştu");
      }
    };

    fetchAnswers();
  }, [subId]);

  const getColumnKeyFromContainerId = (containerId) => {
    const mapping = {
      1: "strengths",
      2: "weaknesses",
      3: "opportunities",
      4: "threats",
    };
    return mapping[containerId];
  };

  const getContainerIdFromColumnKey = (columnKey) => {
    const mapping = {
      strengths: 1,
      weaknesses: 2,
      opportunities: 3,
      threats: 4,
    };
    return mapping[columnKey];
  };

  const handleAddItem = (columnId) => {
    setSelectedColumn(columnId);
    setShowAddModal(true);
  };

  const handleSaveItem = async () => {
    if (!newItemText.trim()) {
      toast.error("Lütfen bir metin giriniz");
      return;
    }

    try {
      const containerId = getContainerIdFromColumnKey(selectedColumn);
      const response = await swotAnalysisService.addAnswer(
        subId,
        containerId,
        newItemText
      );

      const newColumns = { ...columns };
      newColumns[selectedColumn].items.push({
        id: response.id,
        content: newItemText,
      });

      setColumns(newColumns);
      setShowAddModal(false);
      setNewItemText("");
    } catch (error) {
      toast.error("Madde eklenirken bir hata oluştu");
    }
  };

  const handleDeleteItem = async (columnId, itemIndex) => {
    try {
      const item = columns[columnId].items[itemIndex];
      await swotAnalysisService.deleteAnswer(subId, item.id);

      const newColumns = { ...columns };
      newColumns[columnId].items.splice(itemIndex, 1);
      setColumns(newColumns);
    } catch (error) {
      toast.error("Madde silinirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("swot-matrix-container");

    if (!container) {
      toast.error("PDF oluşturmak için gerekli alan bulunamadı");
      setIsPdfLoading(false);
      return;
    }

    // Orijinal durumları sakla
    const originalStyle = container.style.cssText;
    const interactiveElements = [
      ...container.getElementsByTagName("textarea"),
      ...container.getElementsByTagName("input"),
      ...container.getElementsByClassName("group-hover:opacity-100"),
    ];
    const originalElements = [];

    try {
      // PDF için geçici stil ayarları
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
        overflow: visible !important;
      `;

      // Tüm etkileşimli elementleri dönüştür
      interactiveElements.forEach((element) => {
        if (!element.parentNode) return;

        const div = document.createElement("div");
        let content = "";

        if (element.tagName === "TEXTAREA" || element.tagName === "INPUT") {
          content = element.value || element.placeholder || "";
        } else if (element.tagName === "IMG") {
          content = element.alt || "";
        }

        // Metin içeriğini temizle ve formatla
        div.textContent = content.toString().trim().replace(/\n\s+/g, "\n");

        div.style.cssText = `
          width: ${element.offsetWidth}px !important;
          min-height: 32px !important;
          padding: 8px !important;
          margin: 0 !important;
          font-family: Outfit !important;
          font-size: 14px !important;
          line-height: 1.5 !important;
          white-space: pre-wrap !important;
          word-break: break-word !important;
          background: transparent !important;
          opacity: 1 !important;
        `;

        originalElements.push({
          element,
          parent: element.parentNode,
          originalStyle: element.style.cssText,
        });

        element.parentNode.replaceChild(div, element);
      });

      // PDF oluşturma parametreleri
      await downloadPageAsPDF(
        "swot-matrix-container",
        "SWOT Analizi Matrisi",
        projectName || "Proje", // Varsayılan proje adı
        {
          margin: [10, 10, 10, 10],
          scale: 0.85,
          printBackground: true,
          html2canvas: {
            logging: false,
            useCORS: true,
            scale: 2, // Daha yüksek çözünürlük
          },
        }
      );
    } catch (error) {
      console.error("PDF Hatası:", error);
      toast.error(
        error.message.includes("Container") ||
          error.message.includes("Invalid arguments")
          ? "PDF oluşturma ayarlarında hata"
          : "Beklenmeyen bir hata oluştu"
      );
    } finally {
      // Orijinal durumu geri yükle
      if (container) {
        container.style.cssText = originalStyle;
        originalElements.forEach(({ element, parent, originalStyle }) => {
          if (parent && parent.firstChild) {
            element.style.cssText = originalStyle;
            parent.replaceChild(element, parent.firstChild);
          }
        });
      }
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div id="swot-matrix-container">
        <div className="text-center text-2xl font-semibold mb-8">
          SWOT Analizi Matrisi
        </div>

        <div className="grid grid-cols-2 gap-12 mt-12">
          {Object.entries(columns).map(([columnId, column]) => (
            <div
              key={columnId}
              className="rounded-[20px] bg-white min-h-[400px] border-2"
            >
              <div
                className="w-full h-[81px] rounded-[20px] relative"
                style={{ backgroundColor: column.color }}
              >
                <div
                  className="w-[164.33px] h-[164.33px]  rounded-full absolute -top-10 -left-2"
                  style={{ backgroundColor: column.color }}
                />
                <div
                  style={{ color: column.color }}
                  className="w-[107.45px] h-[107.45px] text-[#169888] text-[32px] font-semibold font-['Outfit'] bg-white rounded-full absolute -top-3 left-5 flex items-center justify-center"
                >
                  {column.tag}
                </div>

                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddItem(columnId)}
                  alt="add"
                />
                <div className="h-[82px] flex items-center px-6">
                  <span className="text-white text-lg font-semibold">
                    {column.title}
                  </span>
                </div>
              </div>

              <div className="p-4 space-y-3 mt-10">
                {column.items.map((item, index) => (
                  <div
                    key={item.id}
                    className="p-3 rounded-lg relative group"
                    style={{ backgroundColor: `${column.color}33` }}
                  >
                    <img
                      src={Close}
                      className="w-4 h-4 absolute top-2 right-2 cursor-pointer opacity-100"
                      onClick={() => handleDeleteItem(columnId, index)}
                      alt="delete"
                    />
                    <div className="text-[#797979] text-sm">{item.content}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="w-full mt-12">
          <Stepper
            onPdfDownload={handlePdfDownload}
            backChange={backPage}
            nextChange={changePage}
            activeStep={2}
            stepLimit={2}
          />
        </div>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-[400px]">
            <h3 className="text-lg font-semibold mb-4">Yeni Madde Ekle</h3>
            <textarea
              placeholder="Maddeyi giriniz"
              className="w-full p-2 border rounded-lg mb-4 min-h-[100px]"
              value={newItemText}
              onChange={(e) => setNewItemText(e.target.value)}
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowAddModal(false)}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                İptal
              </button>
              <button
                onClick={handleSaveItem}
                className="px-4 py-2 bg-[#169888] text-white rounded-lg hover:bg-[#138677]"
              >
                Ekle
              </button>
            </div>
          </div>
        </div>
      )}
    </LoadingFullPage>
  );
}
