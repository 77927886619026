import React from "react";
import InfoIcon from "../../../../../icons/info.svg";
import UserIcon from "../../../../../icons/hc-user.svg";
import QuestionIcon from "../../../../../icons/hc-question.svg";
import CheckIcon from "../../../../../icons/hc-check.svg";
import { TextareaAutosize } from "@mui/base";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { howCanService } from "../../../../../services/howCanService";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import InfoTooltip from "../../../../../components/Tooltip/InfoTooltip";
import tooltipTexts from "../../../../../constants/tooltipTexts";

export default function HowCan({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [answers, setAnswers] = useState(["", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  // Sayfa yüklendiğinde cevapları getir
  useEffect(() => {
    const getAnswers = async () => {
      setIsLoading(true);
      try {
        const response = await howCanService.getHowCanAnswers(projectId);
        console.log("response: ", response);

        if (response && Array.isArray(response)) {
          setAnswers(response);
        }
      } catch (error) {
        toast.error("Cevaplar yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    getAnswers();
  }, [projectId]); // Added projectId as a dependency to prevent continuous requests

  // İleri butonuna basıldığında
  const handleNext = async () => {
    try {
      await howCanService.saveHowCanAnswers(projectId, answers);
      changePage();
    } catch (error) {
      toast.error("Cevaplar kaydedilirken bir hata oluştu");
    }
  };

  // Textarea değişikliklerini handle et
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("page-content-container");

    // Geçici stil değişiklikleri
    const originalStyle = container.style.cssText;

    // Container'ı geçici olarak düzenleyelim
    container.style.cssText = `
      width: 1000px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Textarea'ları geçici olarak görünür div'lere çevirelim
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "page-content-container",
        "Nasıl Yapabiliriz",
        projectName
      );
    } finally {
      // Orijinal duruma geri dönelim
      container.style.cssText = originalStyle;

      // Orijinal textarea'ları geri yükleyelim
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <>
      <LoadingFullPage state={isLoading || isPdfLoading}>
        <div className="w-full flex items-center flex-col">
          <div
            id="page-content-container"
            className="w-full flex-1 items-center flex flex-col justify-center"
          >
            <div className="text-black text-lg font-bold font-['Outfit']">
              Nasıl Yapabiliriz?
            </div>

            <div className="w-full h-36 relative border rounded-2xl border-[#c8c8c8] mt-[45px]">
              <div className="w-64 h-36 left-0 top-0 absolute bg-[#a95f97] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <InfoTooltip title={tooltipTexts.howCan.userBase}>
                  <img className="absolute right-2 top-2" src={InfoIcon} />
                </InfoTooltip>
                <img src={UserIcon} />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Kim İçin Yapıyoruz?
                    <br />
                  </span>
                  <span className="text-white text-lg font-normal font-['Outfit']">
                    (Kullanıcı Kitlesi)
                  </span>
                </div>
              </div>
              <div className="ml-[260px] p-2 h-36">
                <textarea
                  value={answers[0]}
                  onChange={(e) => handleChange(0, e.target.value)}
                  className="block w-full text-lg border-none outline-none h-32 font-['Outfit'] resize-none"
                  placeholder="Buraya yazınız"
                  maxLength={300}
                />
              </div>
            </div>

            <div className="w-full h-36 relative border rounded-2xl border-[#c8c8c8] mt-[15px]">
              <div className="w-64 h-36 left-0 top-0 absolute bg-[#A85F68] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <InfoTooltip title={tooltipTexts.howCan.aim}>
                  <img className="absolute right-2 top-2" src={InfoIcon} />
                </InfoTooltip>
                <img src={QuestionIcon} />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Nasıl Yapabiliriz?
                    <br />
                  </span>
                  <span className="text-white text-lg font-normal font-['Outfit']">
                    (Amaç)
                  </span>
                </div>
              </div>
              <div className="ml-[260px] p-2 h-36">
                <textarea
                  value={answers[1]}
                  onChange={(e) => handleChange(1, e.target.value)}
                  className="block w-full text-lg border-none overflow-auto outline-none h-32 font-['Outfit'] resize-none"
                  placeholder="Buraya yazınız"
                  maxLength={500}
                />
              </div>
            </div>

            <div className="w-full h-36 relative border rounded-2xl border-[#c8c8c8] mt-[15px]">
              <div className="w-64 h-36 left-0 top-0 absolute bg-[#5F6FA8] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <InfoTooltip title={tooltipTexts.howCan.solution}>
                  <img className="absolute right-2 top-2" src={InfoIcon} />
                </InfoTooltip>
                <img src={CheckIcon} />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Böylelikle
                    <br />
                  </span>
                  <span className="text-white text-lg font-normal font-['Outfit']">
                    (Sonuç)
                  </span>
                </div>
              </div>
              <div className="ml-[260px] p-2 h-36">
                <textarea
                  value={answers[2]}
                  onChange={(e) => handleChange(2, e.target.value)}
                  className="block w-full text-lg border-none overflow-auto outline-none h-32 font-['Outfit'] resize-none"
                  placeholder="Buraya yazınız"
                  maxLength={500}
                />
              </div>
            </div>
          </div>

          <div className="w-full flex items-center ">
            <div className="w-[600px] flex-1">
              <div className="w-full pr-10 ">
                <Stepper
                  nextChange={handleNext}
                  backChange={backPage}
                  activeStep={2}
                  stepLimit={9}
                  onPdfDownload={handlePdfDownload}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingFullPage>
    </>
  );
}
