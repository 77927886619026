import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../components/LoadingFullPage/LoadingFullPage";
import UserIcon from "../../icons/hc-user.svg";
import { adminService } from "../../services/adminService";

export default function AdminDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const [selectedTab, setSelectedTab] = useState("dashboard");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [usersData, modulesData] = await Promise.all([
        adminService.getUsers(),
        adminService.getModules(),
      ]);
      setUsers(usersData);
      setModules(modulesData);
    } catch (error) {
      toast.error("Veriler yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isLoading}>
      <div className="min-h-screen bg-gray-50 p-6">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-[#EF8024] mb-6">
            Admin Panel
          </h1>
          <div className="flex space-x-4 bg-white rounded-xl p-2 shadow-sm w-fit">
            {["dashboard", "users", "modules"].map((tab) => (
              <button
                key={tab}
                onClick={() => setSelectedTab(tab)}
                className={`px-6 py-2 rounded-lg transition-all ${
                  selectedTab === tab
                    ? "bg-[#EF8024] text-white"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Content */}
        <div className="space-y-6">
          {selectedTab === "dashboard" && (
            <>
              {/* Stats Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {[
                  {
                    title: "Toplam Kullanıcı",
                    value: users.length,
                    color: "#EF8024",
                  },
                  {
                    title: "Aktif Modüller",
                    value: modules.length,
                    color: "#439DC6",
                  },
                  {
                    title: "Tamamlanan Görevler",
                    value: "85%",
                    color: "#EF8024",
                  },
                ].map((stat) => (
                  <div
                    key={stat.title}
                    className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow"
                  >
                    <h3 className="text-gray-500 text-sm">{stat.title}</h3>
                    <p
                      className="text-3xl font-bold mt-2"
                      style={{ color: stat.color }}
                    >
                      {stat.value}
                    </p>
                  </div>
                ))}
              </div>

              {/* Activity Feed */}
              <div className="bg-white rounded-2xl p-6 shadow-sm">
                <h3 className="text-lg font-semibold mb-4 text-[#EF8024]">
                  Son Aktiviteler
                </h3>
                <div className="space-y-4">
                  {[1, 2, 3].map((item) => (
                    <div
                      key={item}
                      className="flex items-center justify-between p-4 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors"
                    >
                      <div className="flex items-center space-x-3">
                        <div className="w-10 h-10 rounded-full bg-[#EF802433] flex items-center justify-center">
                          <img src={UserIcon} alt="" className="w-6 h-6" />
                        </div>
                        <div>
                          <p className="font-medium">Yeni Kullanıcı Kaydı</p>
                          <p className="text-sm text-gray-500">2 saat önce</p>
                        </div>
                      </div>
                      <span className="px-3 py-1 rounded-full text-sm bg-[#439DC633] text-[#439DC6]">
                        Aktif
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {selectedTab === "users" && (
            <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
              <div className="p-6 border-b">
                <h2 className="text-xl font-semibold text-[#EF8024]">
                  Kullanıcı Yönetimi
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-[#439DC633]">
                    <tr>
                      <th className="px-6 py-3 text-left text-sm font-medium text-[#439DC6]">
                        Kullanıcı
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-[#439DC6]">
                        Email
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-[#439DC6]">
                        Yetki
                      </th>
                      <th className="px-6 py-3 text-left text-sm font-medium text-[#439DC6]">
                        İşlemler
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y">
                    {users.map((user) => (
                      <tr key={user.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-[#EF802433] flex items-center justify-center mr-3">
                              <img src={UserIcon} alt="" className="w-5 h-5" />
                            </div>
                            <span>{user.name}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">{user.email}</td>
                        <td className="px-6 py-4">
                          <span className="px-3 py-1 rounded-full text-sm bg-[#439DC633] text-[#439DC6]">
                            {user.role}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <select className="border rounded-lg px-3 py-1 text-sm focus:outline-none focus:ring-2 focus:ring-[#EF8024]">
                            <option>Kullanıcı</option>
                            <option>Admin</option>
                            <option>Moderatör</option>
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {selectedTab === "modules" && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {modules.map((module) => (
                <div
                  key={module.id}
                  className="bg-white rounded-2xl p-6 shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full bg-[#EF802433] flex items-center justify-center">
                        <img src={UserIcon} alt="" className="w-6 h-6" />
                      </div>
                      <h3 className="font-semibold">{module.title}</h3>
                    </div>
                    <span className="px-3 py-1 rounded-full text-sm bg-[#439DC633] text-[#439DC6]">
                      {module.status}
                    </span>
                  </div>
                  <div className="text-sm text-gray-600 space-y-2">
                    <p>Oluşturan: {module.createdBy}</p>
                    <p>
                      Tarih: {new Date(module.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </LoadingFullPage>
  );
}
