import React, { useEffect, useState } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../../../../components/Button/Button";
import Select from "../../../../../components/Select/Select";
import {
  BLUE_TYPE,
  GREEN_TYPE,
  GREY_TYPE,
} from "../../../../../components/Button/constants";

import { toast } from "react-toastify";
import { stakeholderMatrixService } from "../../../../../services/stakeholderMatrixService";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { stakeholderMatrixPeriodService } from "../../../../../services/stakeholderMatrixPeriodService";

export default function StakeholderAnalysisMatrix({
  subId,
  backPage,
  changePage,
  projectName,
}) {
  const [stakeholders, setStakeholders] = useState([
    {
      productGroup: "",
      productDocument: "",
      stakeholderName: "",
      importanceLevel: "medium",
      impactLevel: "medium",
      priority: "primary",
      evaluation: "",
      feedbackMethod: "",
      responsiblePersons: "",
      collectionPeriod: "",
      reportingDetails: "",
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [open, setOpen] = useState(false);
  const [newPeriod, setNewPeriod] = useState({
    startYear: new Date().getFullYear(),
    startMonth: 1,
    endYear: new Date().getFullYear(),
    endMonth: 12,
  });

  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const months = [
    { value: 1, label: "Ocak" },
    { value: 2, label: "Şubat" },
    { value: 3, label: "Mart" },
    { value: 4, label: "Nisan" },
    { value: 5, label: "Mayıs" },
    { value: 6, label: "Haziran" },
    { value: 7, label: "Temmuz" },
    { value: 8, label: "Ağustos" },
    { value: 9, label: "Eylül" },
    { value: 10, label: "Ekim" },
    { value: 11, label: "Kasım" },
    { value: 12, label: "Aralık" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = months[date.getMonth()].label;
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const handleAddRow = () => {
    setStakeholders([
      ...stakeholders,
      {
        productGroup: "",
        productDocument: "",
        stakeholderName: "",
        importanceLevel: "medium",
        impactLevel: "medium",
        priority: "primary",
        evaluation: "",
        feedbackMethod: "",
        responsiblePersons: "",
        collectionPeriod: "",
        reportingDetails: "",
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newStakeholders = stakeholders.filter((_, i) => i !== index);
    setStakeholders(newStakeholders);
  };

  const handleChange = (index, field, value) => {
    const newStakeholders = [...stakeholders];
    newStakeholders[index][field] = value;
    setStakeholders(newStakeholders);
  };

  const handlePeriodSelect = async (selectedPeriodId) => {
    setSelectedPeriodId(selectedPeriodId);
    if (selectedPeriodId) {
      try {
        setIsLoading(true);
        const response = await stakeholderMatrixService.getAnswers(
          selectedPeriodId
        );

        if (response && Array.isArray(response)) {
          const formattedStakeholders = response.map((row) => {
            const stakeholder = {
              productGroup: "",
              productDocument: "",
              stakeholderName: "",
              importanceLevel: "medium",
              impactLevel: "medium",
              priority: "primary",
              evaluation: "",
              feedbackMethod: "",
              responsiblePersons: "",
              collectionPeriod: "",
              reportingDetails: "",
            };

            // Her bir cevabı ilgili alana yerleştir
            row.answers.forEach((answer) => {
              const fieldName = getFieldNameFromLabelId(answer.labelId);
              if (fieldName) {
                stakeholder[fieldName] = answer.answer;
              }
            });

            return stakeholder;
          });

          setStakeholders(formattedStakeholders);
        } else {
          setStakeholders([
            {
              productGroup: "",
              productDocument: "",
              stakeholderName: "",
              importanceLevel: "medium",
              impactLevel: "medium",
              priority: "primary",
              evaluation: "",
              feedbackMethod: "",
              responsiblePersons: "",
              collectionPeriod: "",
              reportingDetails: "",
            },
          ]);
        }
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
        setStakeholders([
          {
            productGroup: "",
            productDocument: "",
            stakeholderName: "",
            importanceLevel: "medium",
            impactLevel: "medium",
            priority: "primary",
            evaluation: "",
            feedbackMethod: "",
            responsiblePersons: "",
            collectionPeriod: "",
            reportingDetails: "",
          },
        ]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!selectedPeriodId) {
      toast.error("Geçerli bir dönem seçilmedi");
      return;
    }

    setIsSaving(true);
    try {
      const formattedData = stakeholders.map((stakeholder) => ({
        answers: Object.entries(stakeholder)
          .filter(([_, value]) => value !== "") // Boş değerleri filtrele
          .map(([fieldName, value]) => ({
            labelId: getLabelId(fieldName),
            answer: value,
          })),
      }));

      await stakeholderMatrixService.saveAnswers(
        selectedPeriodId,
        formattedData
      );
      changePage();
    } catch (error) {
      console.error("Error saving stakeholder data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const getLabelId = (fieldName) => {
    const labelMap = {
      productGroup: 1,
      productDocument: 2,
      stakeholderName: 3,
      importanceLevel: 4,
      impactLevel: 5,
      priority: 6,
      evaluation: 7,
      feedbackMethod: 8,
      responsiblePersons: 9,
      collectionPeriod: 10,
      reportingDetails: 11,
    };
    return labelMap[fieldName];
  };

  const getFieldNameFromLabelId = (labelId) => {
    const fieldMap = {
      1: "productGroup",
      2: "productDocument",
      3: "stakeholderName",
      4: "importanceLevel",
      5: "impactLevel",
      6: "priority",
      7: "evaluation",
      8: "feedbackMethod",
      9: "responsiblePersons",
      10: "collectionPeriod",
      11: "reportingDetails",
    };
    return fieldMap[labelId];
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    let container = null;
    const originalElements = [];
    let originalContainerStyle = "";

    try {
      container = document.getElementById("stakeholder-matrix-container");

      if (!container) {
        throw new Error("Container element not found");
      }

      // Save original container style
      originalContainerStyle = container.style.cssText;

      // Set temporary styles for PDF with adjusted width and scale
      container.style.cssText = `
        width: 1500px !important;
        position: relative !important;
        padding: 10px !important;
        background: white !important;
        transform-origin: top left !important;
        transform: scale(0.8) !important;
      `;

      // Process form elements
      const formElements = [
        ...container.getElementsByTagName("textarea"),
        ...container.getElementsByTagName("select"),
        ...container.getElementsByTagName("input"),
      ];

      formElements.forEach((element) => {
        const div = document.createElement("div");
        const computedStyle = window.getComputedStyle(element);

        if (element.tagName.toLowerCase() === "select") {
          const selectedOption = element.options[element.selectedIndex];
          div.textContent = selectedOption ? selectedOption.text : "";
        } else {
          div.textContent = (
            element.value ||
            element.placeholder ||
            ""
          ).toString();
        }

        // Optimize styles for better PDF fit
        div.style.cssText = `
          width: ${computedStyle.width} !important;
          min-height: 24px !important;
          padding: 4px !important;
          margin: ${computedStyle.margin} !important;
          font-family: ${computedStyle.fontFamily} !important;
          font-size: 11px !important;
          line-height: 1.2 !important;
          white-space: pre-wrap !important;
          word-wrap: break-word !important;
          overflow-wrap: break-word !important;
          word-break: break-word !important;
          box-sizing: border-box !important;
          display: ${computedStyle.display} !important;
          border: ${computedStyle.border} !important;
          background: white !important;
        `;

        originalElements.push({
          element,
          parent: element.parentNode,
          originalStyle: {
            cssText: element.style.cssText,
            computedStyle: { ...computedStyle },
          },
        });

        element.parentNode.replaceChild(div, element);
      });

      await downloadPageAsPDF(
        "stakeholder-matrix-container",
        "Paydaş Matrisini",
        projectName || "Project",
        {
          margin: 10,
          scale: 0.8,
          maxWidth: 800,
        }
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error(
        error.message === "Container element not found"
          ? "PDF oluşturmak için gerekli alan bulunamadı"
          : "PDF oluşturulurken bir hata oluştu"
      );
    } finally {
      try {
        if (container) {
          // Restore original container style
          container.style.cssText = originalContainerStyle;

          // Restore original elements
          originalElements.forEach(({ element, parent, originalStyle }) => {
            if (parent?.firstChild) {
              element.style.cssText = originalStyle.cssText;
              parent.replaceChild(element, parent.firstChild);
            }
          });
        }
      } catch (cleanupError) {
        console.error("Cleanup error:", cleanupError);
      }
      setIsPdfLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewPeriod({
      startYear: new Date().getFullYear(),
      startMonth: 1,
      endYear: new Date().getFullYear(),
      endMonth: 12,
    });
  };

  const handleAddPeriod = async () => {
    try {
      setIsLoading(true);
      const formattedStartDate = `${newPeriod.startYear}-${String(
        newPeriod.startMonth
      ).padStart(2, "0")}-15`;
      const formattedEndDate = `${newPeriod.endYear}-${String(
        newPeriod.endMonth
      ).padStart(2, "0")}-01`;

      await stakeholderMatrixPeriodService.createPeriod({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });

      const response = await stakeholderMatrixPeriodService.getPeriods();
      setPeriods(response);
      toast.success("Yeni dönem başarıyla eklendi");
      handleClose();
    } catch (error) {
      toast.error("Dönem eklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePeriod = async (selectedPeriodId) => {
    try {
      setIsLoading(true);
      await stakeholderMatrixPeriodService.deletePeriod(selectedPeriodId);
      const response = await stakeholderMatrixPeriodService.getPeriods();
      setPeriods(response);
      toast.success("Dönem başarıyla silindi");
    } catch (error) {
      toast.error("Dönem silinirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchPeriods = async () => {
      try {
        setIsLoading(true);
        const response = await stakeholderMatrixPeriodService.getPeriods();
        setPeriods(response);
      } catch (error) {
        toast.error("Dönemler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPeriods();
  }, []);

  useEffect(() => {
    const fetchStakeholderData = async () => {
      if (!subId) return;

      setIsLoading(true);
      try {
        const response = await stakeholderMatrixService.getAnswers(subId);

        if (response && Array.isArray(response)) {
          const formattedStakeholders = response.map((row) => {
            const stakeholder = {
              productGroup: "",
              productDocument: "",
              stakeholderName: "",
              importanceLevel: "medium",
              impactLevel: "medium",
              priority: "primary",
              evaluation: "",
              feedbackMethod: "",
              responsiblePersons: "",
              collectionPeriod: "",
              reportingDetails: "",
            };

            row.answers.forEach((answer) => {
              const fieldName = getFieldNameFromLabelId(answer.labelId);
              if (fieldName && stakeholder.hasOwnProperty(fieldName)) {
                stakeholder[fieldName] = answer.answer;
              }
            });

            return stakeholder;
          });

          setStakeholders(formattedStakeholders);
        }
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStakeholderData();
  }, [subId]);

  return (
    <LoadingFullPage isLoading={isPdfLoading || isLoading || isSaving}>
      <div className="flex flex-col gap-6">
        <div className="w-96 text-[#797979] text-lg font-semibold font-['Outfit']">
          Haydi gelin şimdi Paydaş Matrisini doldurmaya!
        </div>
        <div className="w-[1136px]">
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Paydaş Matrisi ve Stratejik Planda yer alan diğer modüller için
            kritik departmanlarınızın temsilcileri ve yöneticilerinden oluşan
            5-7 kişilik bir stratejik plan ekibi oluşturmanızı ve düzenli
            aralıklarla ekiple beyin fırtınası yaparak matrise son halini
            vermenizi tavsiye ederiz.
            <br />
            <br />
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Unutmayın Paydaş matrisinde buradan sonraki doldurulacak sütunlar
            paydaşlarla iletişim ve veri toplama sürecini ifade etmektedir.
            Buradan alınan görüşler sizlere yeni inovasyon ve fırsat alanlarını
            açacaktır. Bu bölümü Müşteri ve Problem Analizi modülü ile eş
            zamanlı yürütebilirsiniz. Sadece ürününüze bedel ödeyen müşteriler
            için inovasyon yapmazsınız. Farklı kritik öneme sahip bir paydaş
            grubunuz için de Müşteri ve Problem Analizi'nde belirtilen adımları
            yürütebilirsiniz.{" "}
          </span>
        </div>
        <div className="text-[#242731] text-lg font-semibold font-['Outfit'] w-full text-center">
          Paydaş Matrisi
        </div>

        {/* Period Management Section */}
        <div className="w-full bg-white rounded-lg  p-6 mb-4">
          <div className="flex justify-between items-center">
            <div className="flex-1 mr-4">
              <Select
                className="w-full"
                value={selectedPeriodId || ""}
                onChange={(e) => handlePeriodSelect(e.target.value)}
                options={[
                  { value: "", label: "Dönem Seçiniz" },
                  ...periods.map((period) => ({
                    value: period.id,
                    label: `${formatDate(period.startDate)} - ${formatDate(
                      period.endDate
                    )}`,
                  })),
                ]}
              />
            </div>
            <button
              onClick={handleClickOpen}
              className="px-3 py-1.5 text-sm bg-[#439DC6] hover:bg-[#67aecf] focus:ring-4 focus:ring-blue-300 text-white font-medium font-['Outfit'] rounded-[10px] focus:outline-none"
            >
              Yeni Dönem Ekle
            </button>
          </div>
        </div>

        {/* Period Add Dialog */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Yeni Dönem Ekle</DialogTitle>
          <DialogContent>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <div className="space-y-4">
                <TextField
                  select
                  fullWidth
                  label="Başlangıç Ayı"
                  value={newPeriod.startMonth}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, startMonth: e.target.value })
                  }
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="number"
                  fullWidth
                  label="Başlangıç Yılı"
                  value={newPeriod.startYear}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, startYear: e.target.value })
                  }
                />
              </div>
              <div className="space-y-4">
                <TextField
                  select
                  fullWidth
                  label="Bitiş Ayı"
                  value={newPeriod.endMonth}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, endMonth: e.target.value })
                  }
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="number"
                  fullWidth
                  label="Bitiş Yılı"
                  value={newPeriod.endYear}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, endYear: e.target.value })
                  }
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="flex justify-end gap-2">
              <button
                onClick={handleClose}
                className="px-3 py-1.5 text-sm border-[1px] bg-white hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 text-[#BBBBBB] font-medium font-['Outfit'] rounded-[10px] focus:outline-none"
              >
                İptal
              </button>
              <button
                onClick={handleAddPeriod}
                className="px-3 py-1.5 text-sm bg-[#439DC6] hover:bg-[#67aecf] focus:ring-4 focus:ring-blue-300 text-white font-medium font-['Outfit'] rounded-[10px] focus:outline-none"
              >
                Ekle
              </button>
            </div>
          </DialogActions>
        </Dialog>

        {selectedPeriodId && (
          <>
            <form
              id="stakeholder-matrix-container"
              onSubmit={handleSubmit}
              className="w-full"
            >
              <div className="w-full overflow-x-auto shadow-md rounded-lg">
                <table className="w-full text-sm text-left">
                  <thead className="text-xs text-gray-700 bg-gray-50">
                    <tr>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Ürün/Hizmet Grubu
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Ürün/Hizmet Belgesi
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Paydaş Adı
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Önem Derecesi
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Etki Derecesi
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Önceliği
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Değerlendirme
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Görüş ve önerileri alınması gerekiyorsa hangi yöntemle
                        alınacak?
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Görüşlerin alınması aşamasından kimler sorumlu olacak?
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Görüş ve önerilerin alınması ne zaman ve ne kadar sürede
                        gerçekleştirilecek? Veri Toplama Süresi
                      </th>
                      <th className="px-2 py-2 text-[#242731] text-center font-['Poppins'] text-[10px] font-semibold">
                        Alınan görüş ve öneriler ne zaman, nasıl ve kimler
                        tarafından raporlanacak ve değerlendirilecek?
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {stakeholders.map((stakeholder, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.productGroup}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "productGroup",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.productDocument}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "productDocument",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.stakeholderName}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "stakeholderName",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <Select
                            value={stakeholder.importanceLevel}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "importanceLevel",
                                e.target.value
                              )
                            }
                            className="w-full text-[10px]"
                            options={[
                              { value: "high", label: "Yüksek" },
                              { value: "medium", label: "Orta" },
                              { value: "low", label: "Düşük" },
                            ]}
                            size="small"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <Select
                            value={stakeholder.impactLevel}
                            onChange={(e) =>
                              handleChange(index, "impactLevel", e.target.value)
                            }
                            className="w-full text-[10px]"
                            options={[
                              { value: "high", label: "Yüksek" },
                              { value: "medium", label: "Orta" },
                              { value: "low", label: "Düşük" },
                            ]}
                            size="small"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <Select
                            value={stakeholder.priority}
                            onChange={(e) =>
                              handleChange(index, "priority", e.target.value)
                            }
                            className="w-full text-[10px]"
                            options={[
                              { value: "high", label: "Yüksek" },
                              { value: "medium", label: "Orta" },
                              { value: "low", label: "Düşük" },
                            ]}
                            size="small"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.evaluation}
                            onChange={(e) =>
                              handleChange(index, "evaluation", e.target.value)
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.feedbackMethod}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "feedbackMethod",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.responsiblePersons}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "responsiblePersons",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.collectionPeriod}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "collectionPeriod",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                        <td className="px-1 py-1">
                          <textarea
                            value={stakeholder.reportingDetails}
                            onChange={(e) =>
                              handleChange(
                                index,
                                "reportingDetails",
                                e.target.value
                              )
                            }
                            className="w-full px-1 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-[10px] min-h-[24px]"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
            <div className="flex justify-center gap-4 mt-4">
              <button
                onClick={handleAddRow}
                className="px-3 py-1.5 text-sm bg-[#439DC6] hover:bg-[#67aecf] focus:ring-4 focus:ring-blue-300 text-white font-medium font-['Outfit'] rounded-[10px] focus:outline-none"
              >
                Yeni Satır Ekle
              </button>
              <button
                onClick={handleSubmit}
                disabled={isSaving}
                className="px-3 py-1.5 text-sm bg-green-500 hover:bg-green-600 focus:ring-4 focus:ring-green-300 text-white font-medium font-['Outfit'] rounded-[10px] focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSaving ? "Kaydediliyor..." : "Kaydet"}
              </button>
            </div>
          </>
        )}

        <div className="w-full flex items-center ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                nextChange={handleSubmit}
                backChange={backPage}
                activeStep={2}
                stepLimit={2}
                onPdfDownload={handlePdfDownload}
                nextDisable={!selectedPeriodId}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
