import React, { useState, useEffect } from "react";
import { FormControl, RadioGroup, Radio } from "@mui/material";
import MarketTabs from "../../../../../components/MarketTabs";
import { marketAnswerService } from "../../../../../services/marketAnswerService";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function DifficultyMarket({
  changePage,
  backPage,
  projectId,
  projectName,
  selectedMarketId,
  setSelectedMarketId,
}) {
  const [marketData, setMarketData] = useState({
    title: "",
    average: 0,
    answers: [],
  });
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    if (selectedMarketId) {
      fetchMarketAnswers();
    }
  }, [selectedMarketId]);

  const fetchMarketAnswers = async () => {
    try {
      const response = await marketAnswerService.getMarketAnswers(
        projectId,
        selectedMarketId
      );
      setMarketData(response[0]); // İlk kısmı alıyoruz (Pazara Giriş Zorluk Derecesi)
    } catch (error) {
      console.error("Error fetching market answers:", error);
    }
  };

  const handleMarketSelect = (marketId) => {
    setSelectedMarketId(marketId);
  };

  const handleAnswerChange = async (titleId, value) => {
    try {
      await marketAnswerService.submitAnswer(
        projectId,
        selectedMarketId,
        titleId,
        parseInt(value)
      );

      setMarketData((prev) => ({
        ...prev,
        answers: prev.answers.map((answer) =>
          answer.titleId === titleId
            ? { ...answer, value: parseInt(value) }
            : answer
        ),
      }));

      // Refresh answers to get updated average
      await fetchMarketAnswers();
    } catch (error) {
      console.error("Error submitting answer:", error);
      // You might want to add toast notification here for error handling
    }
  };
  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("difficulty-market-container");

    // Temporary style changes
    const originalStyle = container.style.cssText;

    // Temporarily adjust container
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Convert textareas to visible divs temporarily
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "difficulty-market-container",
        "Pazara Giriş Zorluk Derecesi",
        projectName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Restore original textareas
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <>
      <MarketTabs
        projectId={projectId}
        onMarketSelect={handleMarketSelect}
        selectedMarketId={selectedMarketId}
      />
      <div className="w-full">
        {!selectedMarketId ? (
          <div className="w-full h-[400px] border border-[#e0e0e0] rounded-[10px] flex items-center justify-center bg-white">
            <div className="text-[#797979] text-lg font-normal font-['Outfit']">
              Lütfen yukarıdaki kısımdan dolduracağınız pazarı seçiniz.
            </div>
          </div>
        ) : (
          <div id="difficulty-market-container">
            <div className="w-[642px] items-center flex h-[50px] bg-white border-b-0 rounded-tl-[10px] rounded-tr-[10px] border border-[#e0e0e0]">
              <div className="ml-4">
                <span className="text-[#242731] text-sm font-semibold font-['Outfit']">
                  {marketData.title}
                </span>
              </div>
            </div>

            <div className="min-h-[551px] flex flex-col bg-white border border-[#e0e0e0]">
              {marketData.answers.map((answer) => (
                <div
                  key={answer.titleId}
                  className="min-h-[50px] border-b border-[#e0e0e0] flex"
                >
                  <div className="min-w-[641px] border-r border-[#e0e0e0] pl-4 py-3 flex items-center">
                    <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                      {answer.title}
                    </div>
                  </div>
                  <div className="flex-1 flex">
                    <div className="flex-1 border-r border-[#e0e0e0] py-3 flex items-center justify-center">
                      <div className="text-[#242731] text-xs w-[150px] flex justify-center font-normal font-['Outfit']">
                        1 = Giriş Çok Zor; 5 = Giriş Çok Kolay
                      </div>
                    </div>
                    <div className="flex-[2] flex items-center justify-center">
                      <FormControl>
                        <RadioGroup
                          row
                          value={answer.value || ""}
                          onChange={(e) =>
                            handleAnswerChange(answer.titleId, e.target.value)
                          }
                          className="flex justify-center"
                        >
                          {[1, 2, 3, 4, 5].map((value) => (
                            <div
                              key={value}
                              className="flex items-center justify-center flex-col mx-2"
                            >
                              <Radio value={value.toString()} size="small" />
                              <div className="text-[#242731] text-xs font-semibold font-['Outfit']">
                                <div className="-mt-2">({value})</div>
                              </div>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <div className="w-[493px] h-[50px] bg-white rounded-bl-[10px] border-t-0 rounded-br-[10px] border border-[#e0e0e0] flex">
                <div className="flex-1 border-r border-[#e0e0e0] flex items-center">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit'] ml-4">
                    Ortalama
                  </div>
                </div>
                <div className="flex-1 flex items-center justify-center">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                    {marketData.average.toFixed(1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="w-full flex-1">
        <div className="w-full pr-10 ">
          <Stepper
            backChange={backPage}
            nextChange={changePage}
            activeStep={2}
            stepLimit={5}
            onPdfDownload={handlePdfDownload}
          />
        </div>
      </div>
    </>
  );
}
