import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Close from "../../../../../icons/close.svg";
import UserIcon from "../../../../../icons/hc-user.svg";
import InfoIcon from "../../../../../icons/info.svg";
import AddWhite from "../../../../../icons/add-white.svg";
import { empathyMapService } from "../../../../../services/empathyMapService";
import AddAnswerModal from "../../../../../components/Modal/AddAnswerModal";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { handleApiError } from "../../../../../utils/errorHandler";

import AttiudeNegative from "../../../../../icons/attitude-negative.svg";
import EarningFinance from "../../../../../icons/earning-finance.svg";
import EmotionalFeel from "../../../../../icons/emotional-feel.svg";
import MindPlanning from "../../../../../icons/mind-planning.svg";
import NottingToSay from "../../../../../icons/nothing-to.svg";
import LostChart from "../../../../../icons/loss-chart.svg";

export default function EmpathyMap({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const [newAnswer, setNewAnswer] = useState("");
  const [data, setData] = useState([]);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await empathyMapService.getAnswers(projectId);
        setData(response);
      } catch (error) {
        toast.error("Cevaplar yüklenirken bir hata oluştu");
      }
    };

    if (projectId) {
      fetchAnswers();
    }
  }, [projectId]);

  const handleAddClick = (sectionName, titleId) => {
    setSelectedSection({
      name: sectionName,
      id: titleId,
    });
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setSelectedSection(null);
    setNewAnswer("");
  };

  const handleAddAnswer = async () => {
    if (!newAnswer.trim()) {
      toast.error("Lütfen bir cevap giriniz");
      return;
    }

    try {
      await empathyMapService.addAnswer(
        projectId,
        selectedSection.id,
        newAnswer
      );
      toast.success("Cevap başarıyla eklendi");
      handleCloseModal();
      const response = await empathyMapService.getAnswers(projectId);
      setData(response);
    } catch (error) {
      handleApiError(error, "Cevap eklenirken bir hata oluştu");
    }
  };

  const handleDeleteAnswer = async (answerId) => {
    try {
      await empathyMapService.deleteAnswer(projectId, answerId);
      const response = await empathyMapService.getAnswers(projectId);
      setData(response);
      toast.success("Cevap başarıyla silindi");
    } catch (error) {
      handleApiError(error, "Cevap silinirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      const container = document.getElementById("page-content-container");

      // Geçici stil değişiklikleri
      const originalStyle = container.style.cssText;

      // Container'ı geçici olarak düzenleyelim
      container.style.cssText = `
        width: 1000px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Textarea'ları geçici olarak görünür div'lere çevirelim
      const textareas = container.getElementsByTagName("textarea");
      const originalElements = [];

      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder;
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      try {
        await downloadPageAsPDF(
          "page-content-container",
          "Empati Haritası",
          projectName
        );
      } finally {
        // Orijinal duruma geri dönelim
        container.style.cssText = originalStyle;

        // Orijinal textarea'ları geri yükleyelim
        originalElements.forEach(({ element, parent }) => {
          const currentElement = parent.firstChild;
          parent.replaceChild(element, currentElement);
        });
      }
    } catch (error) {
      console.error("PDF oluşturma hatası:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <div>
      <LoadingFullPage state={isPdfLoading}>
        <div id="page-content-container">
          <div className="text-[#0a1f38] text-base font-bold font-['Outfit'] text-center">
            Empati Haritası
          </div>
          <br />
          <div className="grid p-4 grid-cols-2 gap-4">
            {/* Hissetti */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#169888] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Hissetti", 1)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={EmotionalFeel} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Hissetti
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 1)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#16988833]"
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 mt-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Düşündü */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#A85F68] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Düşündü", 2)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={MindPlanning} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Düşündü
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 2)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#A85F6833]"
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Söyledi */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#A95F97] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Söyledi", 3)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={NottingToSay} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Söyledi
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 3)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#A95F9733]"
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 mt-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Davrandı */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#7EA760] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Davrandı", 4)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={AttiudeNegative} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Davrandı
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 4)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#7EA76033]"
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 mt-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Kazançlar */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Kazançlar", 5)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={EarningFinance} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Kazançlar
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 5)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#5F6FA833] "
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 mt-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Kayıplar */}
            <div className="rounded-[20px] bg-white min-h-[400px] border-2">
              <div className="w-full h-[81px] bg-[#f73155] rounded-[20px] relative">
                <img className="absolute right-[70px] top-7" src={InfoIcon} />
                <img
                  className="absolute right-7 top-6 cursor-pointer"
                  src={AddWhite}
                  onClick={() => handleAddClick("Kayıplar", 6)}
                />
                <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                  <img src={LostChart} />
                  <div className="w-40 ml-2">
                    <span className="text-white text-lg font-semibold font-['Outfit']">
                      Kayıplar
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-2">
                {data
                  .find((item) => item.titleId === 6)
                  ?.answers?.map((answer) => (
                    <div
                      key={answer.id}
                      className="rounded-lg p-2 flex items-center mt-2 bg-[#f7315533] "
                    >
                      <img
                        src={Close}
                        className="w-[14px] mt-1 cursor-pointer"
                        onClick={() => handleDeleteAnswer(answer.id)}
                      />
                      <div className="ml-1 mt-1 text-[#797979] text-xs font-normal font-['Outfit']">
                        {answer.content}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <AddAnswerModal
            isOpen={showAddModal}
            onClose={handleCloseModal}
            onAdd={handleAddAnswer}
            value={newAnswer}
            onChange={setNewAnswer}
            title={`${selectedSection?.name || ""} - Yeni Cevap Ekle`}
          />
        </div>

        <div className="w-full flex items-center">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10">
              <Stepper
                backChange={backPage}
                nextChange={changePage}
                activeStep={4}
                stepLimit={4}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </LoadingFullPage>
    </div>
  );
}
