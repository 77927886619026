import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import Button from "../Button/Button";
import RightIcon from "../../icons/arrow-right.svg";
import ArrowLeftIcon from "../../icons/arrow-left-gray.svg";
import { GREY_TYPE } from "../Button/constants";
import { toast } from "react-toastify";

export default function Stepper({
  activeStep,
  stepLimit,
  backChange,
  nextChange,
  backTitle,
  nextTitle,
  backDisable,
  nextDisable,
  nextTooltip,
  rightIcon,
  onPdfDownload,
  navigate,
  navigatePath,
}) {
  const handleNextChange = () => {
    if (activeStep === stepLimit) {
      nextChange();
      // Redirect to the homepage or perform the desired action
      toast.success("Tebrikler, modül tamamlandı!");
    } else {
      nextChange();
    }
  };

  return (
    <div className="flex items-center justify-between mt-10">
      <div className="flex mr-[35px]">
        <div className="text-[#439DC6] text-lg font-semibold font-['Outfit']">
          {activeStep}
        </div>
        <div className="text-zinc-400 text-lg font-light font-['Outfit']">
          /
        </div>
        <div className="text-zinc-400 text-lg font-light font-['Outfit']">
          {stepLimit}
        </div>
      </div>

      <div className=" mr-8  w-48 flex">
        <Button
          className=" w-[140px]"
          onClick={backChange}
          text={backTitle ? backTitle : "Geri"}
          iconLeft={ArrowLeftIcon}
          iconType={GREY_TYPE}
          disabled={backDisable}
        />
      </div>

      <ol className="flex items-center flex-1">
        {[...Array(activeStep)].map((x, i) => (
          <li
            key={i}
            className="flex w-full items-center text-blue-600 dark:text-blue-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-[#439DC6] after:border-4 after:inline-block after:transition-all after:duration-500 after:ease-in-out"
          />
        ))}
        {[...Array(stepLimit - activeStep)].map((x, i) => (
          <li
            key={i}
            className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-100 after:border-4 after:inline-block after:transition-all after:duration-500 after:ease-in-out"
          />
        ))}
      </ol>
      <div className="ml-8 flex gap-4">
        {onPdfDownload && (
          <Button
            className="w-[140px]"
            onClick={onPdfDownload}
            text="PDF İndir"
          />
        )}
        <Button
          tooltip={nextTooltip}
          className="w-[140px]"
          onClick={handleNextChange}
          text={
            activeStep === stepLimit
              ? "Tamamla & Kaydet"
              : nextTitle
              ? nextTitle
              : "Devam & Kaydet"
          }
          iconRight={rightIcon}
          disabled={nextDisable}
        />
      </div>
    </div>
  );
}
