import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import StrategicDirectionImage from "../../../../../images/strategic-direction.svg";

export default function InformationArticle({ changePage, backPage }) {
  return (
    <div className="w-full h-full max-w-[1136px] flex flex-col justify-center items-center ">
      <div className="h-full flex flex-col md:flex-row  justify-center items-center w-full ">
        <div className="h-8 w-8  items-center justify-center">
          <div className="w-[11px] h-[11px] bg-[#f08025] rounded-full" />
        </div>
        <div className="flex-1">
          <span class="text-[#797979] text-lg font-semibold font-['Outfit']">
            Finansal Amaçlar:{" "}
          </span>
          <span class="text-[#797979] text-lg font-normal font-['Outfit']">
            Firmanızı etkileyecek hangi finansal amaçlar var? Örnek: Net kâr
            marjının artırılması, nakit akışının optimize edilmesi, ihracatın
            artırılması vb.
            <br />
            <br />
          </span>
        </div>
      </div>

      <div className="h-full flex flex-col md:flex-row  justify-center items-center w-full ">
        <div className="h-8 w-8  items-center justify-center">
          <div className="w-[11px] h-[11px] bg-[#f08025] rounded-full" />
        </div>
        <div className="flex-1">
          <span class="text-[#797979] text-lg font-semibold font-['Outfit']">
            Müşteri Amaçları:{" "}
          </span>
          <span class="text-[#797979] text-lg font-normal font-['Outfit']">
            Müşterilerimiz için neler önemlidir, müşteri memnuniyeti nasıl
            artırılabilir? Bunun için Paydaş Analizi ve Müşteri ve Problem
            Analizi modülleri önemli girdiler sağlayabilir. Örnek: Servis
            faaliyetlerinin iyileştirilmesi, müşteri sadakatinin artırılması
            <br /> <br />
          </span>
        </div>
      </div>

      <div className="h-full flex flex-col md:flex-row  justify-center items-center w-full ">
        <div className="h-8 w-8  items-center justify-center">
          <div className="w-[11px] h-[11px] bg-[#f08025] rounded-full" />
        </div>
        <div className="flex-1">
          <span class="text-[#797979] text-lg font-semibold font-['Outfit']">
            Süreç Amaçları:{" "}
          </span>
          <span class="text-[#797979] text-lg font-normal font-['Outfit']">
            Amaçlarınıza ulaşmak için firma içinde yapmamız gereken,
            iyileştirmeniz gereken alanlar nelerdir? Örnek: Üretim sürelerinin
            düşürülmesi, kalite iyileştirme çalışmalarının yapılması <br />{" "}
            <br />
          </span>
        </div>
      </div>

      <div className="h-full flex flex-col md:flex-row  justify-center items-center w-full ">
        <div className="h-8 w-8  items-center justify-center">
          <div className="w-[11px] h-[11px] bg-[#f08025] rounded-full" />
        </div>
        <div className="flex-1">
          <span class="text-[#797979] text-lg font-semibold font-['Outfit']">
            Öğrenme ve Büyüme Amaçları:{" "}
          </span>
          <span class="text-[#797979] text-lg font-normal font-['Outfit']">
            Paydaşlarınızı ve özellikle çalışanlarınızı mutlu edecek ve sonuçta
            finansal durumumuzu da etkileyecek süreci yönetmek için firmanızda
            hangi becerilere ve yeteneklere ve nasıl bir kültüre ihtiyaç var?
            Örnek: Yenilikçi fikirlerin teşvik edilmesi, öğrenen organizasyon
            olunması, personelin bilgi ve becerilerinin artırılması
            <br />
          </span>
        </div>
      </div>

      <div className="w-full flex items-center">
        <div className="w-full lg:w-[600px] flex-1">
          <div className="w-full pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
