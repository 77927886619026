import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="max-w-[1136px] w-full mx-auto">
      <div className="text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="text-[#797979] text-lg font-normal font-['Outfit']">
        Stratejik Planın Stratejik Analiz safhası, firmanızın mevcut güçlü ve
        zayıf yönlerini ortaya koymak, pazar ve çevreden gelebilecek fırsat ve
        tehdit alanlarını tahmin etmek için yürütülür. Paydaş Analizi tüm bu
        alanlar hakkında sizlere fikir sağlamakla birlikte İç Çevre Analizi
        genel olarak güçlü ve zayıf yönleriniz hakkında sizlere bilgi sağlar.{" "}
        <br /> <br />
        İç Çevre Analizi'ni yürütebilmek için Varlık ve Yetenek Analizi'nden
        faydalanmanızı önereceğiz. Varlık ve Yetenek Analizi, hem problem
        alanlarını ve etkilediği paydaşları ortaya koyabilmek hem de kurumsal
        varlık ve yeteneklerinizi çıkarabilmek ve bu varlık yeteneklerde
        rakiplerinize göre durumunuzu ortaya koymak için yapılır. Bunun için
        Michael Porter'ın Değer Zinciri olarak ifade ettiği 9 temel alanı
        dikkate alarak sektörünüzde değer yaratan faaliyetleri, bu değere
        yaratan faaliyetlerdeki rakiplerinize göre durumunuzu (güçlü/zayıf)
        ortaya koyabilirsiniz. Bu alanları sizler için özetledik.{" "}
      </div>
      <br />
      <div className="max-w-[243px] w-full text-[#797979] text-lg font-semibold font-['Outfit']">
        Haydi gelin şimdi başlayalım!
      </div>
      <br />
      <div className="w-full">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Pazarlama ve Satış:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Pazarlama ve satış alanında sektörünüzde değer yaratan faaliyetleri
          çıkarabilmek için şu soruları kendinize sorabilirsiniz: Sektördeki
          Konumumuz Nedir? Sektörü Ne Kadar İyi Tanıyoruz? Nasıl Bir Marka
          Algımız Var? Geleneksel ve Dijital Pazarlamada Ne Kadar Etkiliyiz?
          Alıcılarımızla İlişkilerimiz Ne Durumdadır? Alıcılarımızla
          İletişimimiz Nasıldır? Pazarlamada Farklılık Yarattığımız Şeyler
          Nelerdir? Satışta Farklılık Yarattığımız Şeyler Nelerdir? Fiyat, marka
          imajı, fuarlara katılım, tanıtım, reklam, çözüm ve danışmanlık
          hizmetleri pazarlama ve satış faaliyetlerine örneklerdendir. <br />{" "}
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Servis:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Servis alanında sektörünüzde değer yaratan faaliyetleri çıkarabilmek
          için şu soruları kendinize sorabilirsiniz: Satış Sonrası Desteklerimiz
          Neler? Satış Sonrası Desteklerimizle Alıcılarımızı Memnun Ediyor
          muyuz? Sürekli Ulaşılabilir Teknik Servisimiz Var mı? Rakiplerimize
          Kıyasla Teknik Destek Fiyatlarımız ve Maliyetlerimiz Nasıl? Teknik
          Destek Ağımız Ne Kadar Geniş? Yedek parça temini, teknik destek hızı,
          servis ve bayi personelinin eğitimi, çözüm odaklı geri dönüş servis
          faaliyetlerine örneklerdendir. <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Üretim:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Üretim alanında sektörünüzde değer yaratan faaliyetleri çıkarabilmek
          için şu soruları kendinize sorabilirsiniz: Üretim Hızımız Ne Durumda?
          Üretim Kapasitemiz Ne Durumda ve Artırılabilir mi? Üretim
          Maliyetlerimiz Ne Durumda? Üretimde Fark Yarattığımız /
          Yaratamadığımız Alanlar Ne? Kalite Kontrol Süreçlerimiz Nasıl?
          Süreçlerimiz Ne Kadar Etkin? Zamanında teslimat, standart ve esnek
          üretim, üretimin dijitalleşmesi, kaliteli ürün ve hız üretim
          faaliyetlerine örneklerdendir. <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Dışa Yönelik Lojistik:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Dışa yönelik lojistik alanında sektörünüzde değer yaratan faaliyetleri
          çıkarabilmek için şu soruları kendinize sorabilirsiniz: Kaç Adet
          Bayimiz, Distribütörümüz, Depomuz Var? Ürünlerimizi Hangi Yollarla
          Alıcılara Gönderiyoruz? Ürünlerimiz Alıcılara Ortalama Ne Kadar Sürede
          Ulaşıyor? Konum, alternatif lojistik, zamanında yükleme, hızlı
          sevkiyat planlama dışa yönelik lojistik faaliyetlerine örneklerdendir.{" "}
          <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Tedarik ve İçe Yönelik Lojistik:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Tedarik ve içe yönelik lojistik faaliyetlerini ayırabileceğiniz gibi
          birlikte ele alarak da ilerleyebilirsiniz. Tedarik ve içe yönelik
          lojistik alanında sektörünüzde değer yaratan faaliyetleri çıkarabilmek
          için şu soruları kendinize sorabilirsiniz: Satın Almada Karşımıza
          Çıkan Engellerde Zorluk Çekiyor muyuz? Tedarikçilerle İletişimimiz
          Nasıl? Malzeme Satın Alma Süreçlerimiz Ne Kadar Etkin? Tedarik İle
          İlgili Standartlarımız, Politikalarımız Var mı? Üretim Planlamamız var
          mı? Stoklarımız Ne Durumdadır? Gerçek Zamanlı Kontrol Var mı? Tedarik
          edilen ürünlerin kalitesi, alternatif tedarikçi sayısı, ikame ürün
          sayısı, tedarikçilerle iletişim, kurumsal kaynak planlama ve depolama
          tedarik ve içe yönelik lojistik faaliyetlerine örneklerdendir. <br />{" "}
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Yönetim:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Yönetim alanında sektörünüzde değer yaratan faaliyetleri çıkarabilmek
          için şu soruları kendinize sorabilirsiniz: Ne Gibi Yönetsel Teknikleri
          Kullanıyoruz? Planlama Faaliyetlerimiz Neleri Kapsıyor? İşletmemizin
          Yürütme ve Kontrol Mekanizmasında Kimler Yer Alıyor? Halkla
          İlişkilerde Ne Kadar İyiyiz? Neler Yapıyoruz? Maliyet Muhasebesini Ne
          Kadar Etkin Yaptık? Başarılı mıyız? Bir Stratejimiz Var mı? Bir Kurum
          Kültürümüz Var mı? Vizyon, kültür, değer odaklılık, ileri görüşlülük,
          liderlik becerileri, yenikliklere açıklık, koordinasyon yönetim
          faaliyetlerine örneklerdendir. <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Teknoloji:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Teknoloji alanında sektörünüzde değer yaratan faaliyetleri
          çıkarabilmek için şu soruları kendinize sorabilirsiniz: Teknoloji
          Geliştirirken Destek Aldığımız Kurumlar&Kişiler Var Mıdır? Katkıları
          Nelerdir? Ar-Ge Yetkinliklerimiz Rakiplerimize Nazaran Nasıl? Ar-Ge
          Departmanına Ayırılan Bütçe Karımızın %de Kaçı? Kullandığımız Bilgi
          Teknolojileri Sistemleri Nelerdir? Bize Kattığı Faydalar Nelerdir?
          Kurum içi Know-How Kütüphanesi Mevcut Mu? Arge yetkinliği, teknoloji
          istihbarat ve geliştirme düzeyi, inovasyon yetkinliği, üretimin
          dijitalleşmesi, Endüstri 4.0 çalışmaları teknoloji faaliyetlerine
          örneklerdendir. <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          İnsan Kaynakları:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          İK alanında sektörünüzde değer yaratan faaliyetleri çıkarabilmek için
          şu soruları kendinize sorabilirsiniz: Personel Seçimi Yapılırken
          Nelere Dikkat Ediliyor? Belirli Bir Prosedür Var Mı? Bir Teşvik veya
          Oyunlaştırma Kurgusu Mevcut mu? Personellere Yönelik Eğitim
          Programları Yapılıyor Mu? Performans Yönetim Sistemi Mevcut mu?
          Çalışma Saatleri Esnek mi? Maaş ve Ücret Politikası Nasıl? Çalışma
          Şartları Nasıl? Yetkin personel sayısı, çalışanların deneyimi,
          personel devir hızı, düzenli yapılan eğitim programları ve personel
          hizmetleri İK faaliyetlerine örneklerdendir. <br /> <br />
          Hangi Stratejik Plan dönemi ve hangi ürün/hizmet grubu için
          dolduruyorsanız her biri için ayrı ayrı tabloyu doldurmanızı öneririz.
          Ürün/Hizmet grubu ayrımı için Paydaş Analizi'ni tekrar gözden
          geçirmeniz yerinde olacaktır.{" "}
        </span>
      </div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
