import React, { useState, useEffect } from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { BLUE_TYPE, GREY_TYPE } from "../Button/constants";
import { toast } from "react-toastify";
import Chechbox from "../Chechbox/Chechbox";

export default function AddUserModal({
  isOpen,
  onClose,
  onSubmit,
  editUser = null,
}) {
  const [userRegister, setUserRegister] = useState({
    name: { value: "", error: false },
    surname: { value: "", error: false },
    email: { value: "", error: false },
    cellPhone: { value: "", error: false },
    department: { value: "", error: false },
    password: { value: "", error: false },
    passwordRequret: { value: "", error: false },
    permissions: {
      innovationCheckup: false,
      customerAnalysis: false,
      internalEntrepreneurship: false,
      strategicPlan: false,
      marketResearch: false,
    },
  });

  useEffect(() => {
    if (editUser && isOpen) {
      setUserRegister({
        name: { value: editUser.name || "", error: false },
        surname: { value: editUser.surname || "", error: false },
        email: { value: editUser.email || "", error: false },
        cellPhone: { value: editUser.cellPhone || "", error: false },
        department: { value: editUser.department || "", error: false },
        password: { value: "", error: false },
        passwordRequret: { value: "", error: false },
        permissions: {
          innovationCheckup: editUser.permissions?.innovationCheckup || false,
          customerAnalysis: editUser.permissions?.customerAnalysis || false,
          internalEntrepreneurship:
            editUser.permissions?.internalEntrepreneurship || false,
          strategicPlan: editUser.permissions?.strategicPlan || false,
          marketResearch: editUser.permissions?.marketResearch || false,
        },
      });
    } else if (!editUser && isOpen) {
      // Reset form when opening for new user
      setUserRegister({
        name: { value: "", error: false },
        surname: { value: "", error: false },
        email: { value: "", error: false },
        cellPhone: { value: "", error: false },
        department: { value: "", error: false },
        password: { value: "", error: false },
        passwordRequret: { value: "", error: false },
        permissions: {
          innovationCheckup: false,
          customerAnalysis: false,
          internalEntrepreneurship: false,
          strategicPlan: false,
          marketResearch: false,
        },
      });
    }
  }, [editUser, isOpen]);

  if (!isOpen) return null;

  const handleSubmit = async () => {
    let hasError = false;
    const newUserData = { ...userRegister };

    Object.keys(newUserData).forEach((key) => {
      if (
        key !== "permissions" &&
        !newUserData[key].value &&
        key !== "passwordRequret"
      ) {
        newUserData[key].error = true;
        hasError = true;
      }
    });

    if (hasError) {
      toast.error("Lütfen tüm alanları doldurunuz");
      setUserRegister(newUserData);
      return;
    }

    if (userRegister.password.value !== userRegister.passwordRequret.value) {
      toast.error("Şifre ve Şifre tekrar aynı olmalıdır.");
      setUserRegister((prev) => ({
        ...prev,
        password: { ...prev.password, error: true },
        passwordRequret: { ...prev.passwordRequret, error: true },
      }));
      return;
    }

    onSubmit(userRegister);
    onClose();
    setUserRegister({
      name: { value: "", error: false },
      surname: { value: "", error: false },
      email: { value: "", error: false },
      cellPhone: { value: "", error: false },
      department: { value: "", error: false },
      password: { value: "", error: false },
      passwordRequret: { value: "", error: false },
      permissions: {
        innovationCheckup: false,
        customerAnalysis: false,
        internalEntrepreneurship: false,
        strategicPlan: false,
        marketResearch: false,
      },
    });
  };

  const changeInput = (changeJson) => {
    setUserRegister(changeJson);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[800px] max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-semibold">
            {editUser ? "Kullanıcı Düzenle" : "Kullanıcı Ekle"}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="p-8 bg-gradient-to-br from-gray-50 to-white">
          <div className="flex mt-5">
            <div className="flex-1 pr-3 flex">
              <div className="pr-4">
                <Input
                  name="Ad"
                  placeholder="Adını girin"
                  type="text"
                  labelName="Ad"
                  regexType="text"
                  required={true}
                  error={userRegister.name.error}
                  value={userRegister.name.value}
                  maxLength={15}
                  onChange={(e) =>
                    changeInput({
                      ...userRegister,
                      name: {
                        ...userRegister.name,
                        value: e.target.value,
                        error: false,
                      },
                    })
                  }
                />
              </div>

              <Input
                name="Soyad"
                placeholder="Soyad bilgisini girin"
                labelName="Soyad"
                required={true}
                regexType="text"
                error={userRegister.surname.error}
                value={userRegister.surname.value}
                maxLength={15}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    surname: {
                      ...userRegister.surname,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>

            <div className="flex-1 pl-3">
              <Input
                name="Departman"
                placeholder="Departman adını girin"
                labelName="Departman"
                required={true}
                error={userRegister.department.error}
                value={userRegister.department.value}
                maxLength={30}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    department: {
                      ...userRegister.department,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="flex mt-3">
            <div className="flex-1 pr-3">
              <Input
                name="e-Posta"
                placeholder="E-posta adresini giriniz"
                labelName="e-Posta"
                type="email"
                autocomplete="email"
                required={true}
                error={userRegister.email.error}
                value={userRegister.email.value}
                maxLength={30}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    email: {
                      ...userRegister.email,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>
            <div className="flex-1 pl-3">
              <Input
                regexType="number"
                name="Telefon Numarası"
                placeholder="555 334 9028"
                labelName="Telefon Numarası"
                required={true}
                error={userRegister.cellPhone.error}
                value={userRegister.cellPhone.value}
                maxLength={10}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    cellPhone: {
                      ...userRegister.cellPhone,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="flex mt-3">
            <div className="flex-1 pr-3">
              <Input
                name="Şifre"
                placeholder="Şifre giriniz"
                labelName="Şifre"
                type="password"
                required={!editUser}
                error={userRegister.password.error}
                value={userRegister.password.value}
                maxLength={15}
                tooltipPosition="left"
                tooltipTitle={
                  <div className="text-neutral-400 text-sm font-medium font-['outfit'] p-2">
                    {editUser ? (
                      "Şifreyi değiştirmek için yeni şifre girin. Boş bırakırsanız şifre değişmeyecektir."
                    ) : (
                      <>
                        <div>Şifre en az 8 karakter olmalıdır.</div>
                        <div>Büyük ve küçük harf kullanılmalıdır.</div>
                        <div>Rakam kullanılmalıdır.</div>
                      </>
                    )}
                  </div>
                }
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    password: {
                      ...userRegister.password,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>
            <div className="flex-1 pl-3">
              <Input
                name="Şifre Tekrar"
                placeholder="Şifreyi tekrar giriniz"
                labelName="Şifre Tekrar"
                type="password"
                required={!editUser}
                error={userRegister.passwordRequret.error}
                value={userRegister.passwordRequret.value}
                maxLength={15}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    passwordRequret: {
                      ...userRegister.passwordRequret,
                      value: e.target.value,
                      error: false,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="mt-6">
            <h3 className="text-sm font-medium mb-3">Modül İzinleri</h3>
            <div className="grid grid-cols-2 gap-4">
              <Chechbox
                text="İnovasyon Check-Up"
                value={userRegister.permissions.innovationCheckup}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    permissions: {
                      ...userRegister.permissions,
                      innovationCheckup: e.target.checked,
                    },
                  })
                }
              />
              <Chechbox
                text="Müşteri ve Problem Analizi"
                value={userRegister.permissions.customerAnalysis}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    permissions: {
                      ...userRegister.permissions,
                      customerAnalysis: e.target.checked,
                    },
                  })
                }
              />
              <Chechbox
                text="İç Girişimcilik"
                value={userRegister.permissions.internalEntrepreneurship}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    permissions: {
                      ...userRegister.permissions,
                      internalEntrepreneurship: e.target.checked,
                    },
                  })
                }
              />
              <Chechbox
                text="Stratejik Plan"
                value={userRegister.permissions.strategicPlan}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    permissions: {
                      ...userRegister.permissions,
                      strategicPlan: e.target.checked,
                    },
                  })
                }
              />
              <Chechbox
                text="Pazar Araştırması ve İhracat"
                value={userRegister.permissions.marketResearch}
                onChange={(e) =>
                  changeInput({
                    ...userRegister,
                    permissions: {
                      ...userRegister.permissions,
                      marketResearch: e.target.checked,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="mt-8 flex justify-end space-x-3">
            <Button
              text="İptal"
              onClick={onClose}
              iconType={GREY_TYPE}
              className="w-32"
            />
            <Button
              text="Kaydet"
              onClick={handleSubmit}
              iconType={BLUE_TYPE}
              className="w-32"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
