import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const productService = {
  getProducts: async (periodId) => {
    try {
      const response = await axios.get(
        `${API_URL}/product-service-groups?type=1`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getProductsByPeriod: async () => {
    try {
      const response = await axios.get(`${API_URL}/product-service-groups`, {
        headers,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  createProduct: async (data) => {
    try {
      const response = await axios.post(
        `${API_URL}/product-service-groups`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateProduct: async (id, data) => {
    try {
      const response = await axios.put(
        `${API_URL}/product-service-groups/${id}`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  deleteProduct: async (id) => {
    try {
      const response = await axios.delete(
        `${API_URL}/product-service-groups/${id}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getRecommendedProducts: async () => {
    try {
      const response = await axios.get(
        `${API_URL}/product-service-groups/recommended`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

export default productService;
