import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { marketAnswerService } from "../../../../../services/marketAnswerService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function AnalysisMarket({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [analysisData, setAnalysisData] = useState([]);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    fetchAnalysisData();
  }, [projectId]);

  const fetchAnalysisData = async () => {
    try {
      const response = await marketAnswerService.getMarketAttractiveness(
        projectId
      );
      setAnalysisData(response);
    } catch (error) {
      console.error("Error fetching analysis data:", error);
    }
  };

  const handleFactorChange = async (rowIndex, value) => {
    try {
      const titleId = rowIndex + 1; // titleId'ler 1'den başlıyor
      await marketAnswerService.updateMarketFactor(
        projectId,
        titleId,
        parseFloat(value)
      );
      await fetchAnalysisData(); // Güncel verileri çek
    } catch (error) {
      console.error("Error updating factor:", error);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("market-analysis-container");

    // Store original styles
    const originalStyle = container.style.cssText;

    // Set temporary container styles for PDF
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Handle input elements
    const inputs = container.getElementsByTagName("input");
    const originalElements = [];

    Array.from(inputs).forEach((input) => {
      const div = document.createElement("div");
      div.textContent = input.value || "0";
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        text-align: center;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: input,
        parent: input.parentNode,
      });

      input.parentNode.replaceChild(div, input);
    });

    try {
      await downloadPageAsPDF(
        "market-analysis-container",
        "Pazar Çekiciliğinin Analizi",
        projectName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Restore original elements
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div id="market-analysis-container">
        <div className="w-full mt-20">
          <div className="flex justify-between items-center mb-8">
            <div className="text-[#439dc6] text-2xl font-semibold font-['Outfit']">
              Pazar Çekiciliğinin Analizi
            </div>
          </div>

          <div className="border border-[#e0e0e0] rounded-[10px] overflow-hidden">
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="w-[293px] h-[50px] bg-[#f6f6f6] border-b border-r border-[#e0e0e0]"></th>
                  <th className="w-[92px] h-[50px] bg-[#f6f6f6] border-b border-r border-[#e0e0e0]"></th>
                  {analysisData.length > 0 &&
                    analysisData[0].analysis.map((market, index) => (
                      <th
                        key={index}
                        className="w-[148px] h-[50px] bg-[#f6f6f6] border-b border-r border-[#e0e0e0] text-[#439dc6] text-base font-semibold"
                        colSpan="2"
                      >
                        {market.market}
                      </th>
                    ))}
                </tr>
                <tr>
                  <th className="w-[293px] h-[50px] border-b border-r border-[#e0e0e0]"></th>
                  <th className="w-[92px] h-[50px] border-b border-r border-[#e0e0e0]"></th>
                  {analysisData.length > 0 &&
                    analysisData[0].analysis.map((_, index) => (
                      <React.Fragment key={index}>
                        <th className="w-[74px] h-[50px] border-b border-r border-[#e0e0e0] text-[#439dc6] text-sm font-semibold">
                          Puan
                        </th>
                        <th className="w-[74px] h-[50px] border-b border-r border-[#e0e0e0] bg-[#f6f6f6] text-[#439dc6] text-sm font-semibold">
                          Score
                        </th>
                      </React.Fragment>
                    ))}
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    label: "Pazara Giriş Zorluk Derecesi",
                    value: analysisData[0]?.factor,
                  },
                  {
                    label: "Pazarın Büyüklüğü",
                    value: analysisData[1]?.factor,
                  },
                  {
                    label: "Pazarın Büyüme Hızı",
                    value: analysisData[2]?.factor,
                  },
                  {
                    label: "Pazardaki Rekabetin Şiddeti",
                    value: analysisData[3]?.factor,
                  },
                ].map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="h-[50px] border-b border-r border-[#e0e0e0] pl-4">
                      <span className="text-[#242731] text-sm font-semibold">
                        {item.label}
                      </span>
                    </td>
                    <td className="h-[50px] border-b border-r border-[#e0e0e0]">
                      <div className="flex justify-center">
                        <input
                          type="number"
                          value={item.value?.toFixed(2) || 0.25}
                          step="0.01"
                          min="0"
                          max="1"
                          onChange={(e) =>
                            handleFactorChange(rowIndex, e.target.value)
                          }
                          className="w-20 text-center text-[#242731] text-sm font-semibold border border-[#e0e0e0] rounded px-2 py-1 [&::-webkit-inner-spin-button]:opacity-100 [&::-webkit-outer-spin-button]:opacity-100"
                        />
                      </div>
                    </td>
                    {analysisData.length > 0 &&
                      analysisData[rowIndex].analysis.map(
                        (market, colIndex) => (
                          <React.Fragment key={colIndex}>
                            <td className="h-[50px] border-b border-r border-[#e0e0e0] text-center">
                              <span className="text-[#242731] text-sm font-semibold">
                                {market.rating.toFixed(1)}
                              </span>
                            </td>
                            <td className="h-[50px] border-b border-r border-[#e0e0e0] bg-[#f6f6f6] text-center">
                              <span className="text-[#242731] text-sm font-semibold">
                                {market.score.toFixed(2)}
                              </span>
                            </td>
                          </React.Fragment>
                        )
                      )}
                  </tr>
                ))}
                <tr>
                  <td className="h-[50px] bg-[#f6f6f6] border-r border-[#e0e0e0] pl-4">
                    <span className="text-[#439dc6] text-base font-semibold">
                      Toplam
                    </span>
                  </td>
                  <td className="h-[50px] bg-[#f6f6f6] border-r border-[#e0e0e0] text-center relative group">
                    <span
                      className={`text-[#242731] text-sm font-semibold ${
                        parseFloat(analysisData[4]?.factor) !== 1
                          ? "text-red-500"
                          : ""
                      }`}
                    >
                      {analysisData[4]?.factor.toFixed(2) || "1.00"}
                    </span>

                    {/* Tooltip */}
                    {parseFloat(analysisData[4]?.factor) !== 1 && (
                      <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-xs rounded p-2 -mt-2 left-1/2 transform -translate-x-1/2 -translate-y-full z-10 w-48">
                        Toplam değer 1'e eşit olmalıdır
                        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
                      </div>
                    )}
                  </td>
                  {analysisData.length > 0 &&
                    analysisData[4].analysis.map((market, index) => (
                      <React.Fragment key={index}>
                        <td className="h-[50px] bg-[#f6f6f6] border-r border-[#e0e0e0] text-center">
                          <span className="text-[#242731] text-sm font-semibold"></span>
                        </td>
                        <td className="h-[50px] bg-[#f6f6f6] border-r border-[#e0e0e0] text-center">
                          <span className="text-[#242731] text-sm font-semibold">
                            {market.score.toFixed(2)}
                          </span>
                        </td>
                      </React.Fragment>
                    ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="w-full flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              onPdfDownload={handlePdfDownload}
              backChange={backPage}
              nextChange={changePage}
              activeStep={5}
              stepLimit={5}
              nextDisable={analysisData[4]?.factor !== 1}
              nextTooltip={
                analysisData[4]?.factor !== 1
                  ? "Toplam değer 1'e eşit olmalıdır"
                  : "Sonraki"
              }
            />
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
