export const BLUE_TYPE =
  "disabled:bg-gray-400  bg-[#439DC6] p-[15px] hover:bg-[#67aecf] focus:ring-4 focus:ring-blue-300 text-white text-base font-medium font-['Outfit'] rounded-[10px]  focus:outline-none px-[30px]";

export const GREY_TYPE =
  "disabled:bg-gray-400 border-[1px]  bg-white p-[15px] hover:bg-gray-100 focus:ring-4 focus:ring-blue-300 text-[#BBBBBB] text-base font-medium font-['Outfit'] rounded-[10px]    focus:outline-none px-[30px]";

export const GREEN_TYPE = {
  background: "bg-green-500",
  hoverBackground: "hover:bg-green-600",
  focusRing: "focus:ring-green-500",
  textColor: "text-white",
  borderColor: "border-green-500",
  shadowColor: "shadow-green-500/20",
};
