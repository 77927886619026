import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import { accountService } from "../../../services/accountService";

export default function PersonalInformation() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    company: "",
    registrationDate: "",
  });

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    try {
      const response = await accountService.getAccountInfo();
      setFormData({
        firstName: response.name || "",
        lastName: response.surname || "",
        phone: response.cellPhone || "",
        company: response.corporation || "",
        registrationDate: response.registrationDate || "",
      });
      setHasChanges(false);
    } catch (error) {
      console.error("Error fetching account info:", error);
    }
  };

  useEffect(() => {
    // Check for changes whenever formData updates
    setHasChanges(true);
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await accountService.updateAccountInfo({
        name: formData.firstName,
        surname: formData.lastName,
        cellPhone: formData.phone,
        corporation: formData.company,
      });
      setHasChanges(false);
    } catch (error) {
      console.error("Error updating account info:", error);
    }
  };

  const handleInputChange = (key, value) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(date);
  };

  return (
    <div className="w-full h-full items-start flex flex-col justify-start">
      <div className="max-w-[800px] p-6 bg-white rounded-xl shadow-sm">
        <h2 className="text-xl font-semibold text-[#439DC6] mb-6 flex items-center gap-2">
          <div className="p-1.5 bg-[#439DC6]/10 rounded-lg">
            <svg
              className="w-5 h-5 text-[#439DC6]"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          Kişisel Bilgiler
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            {[
              { label: "Ad", key: "firstName", type: "text" },
              { label: "Soyad", key: "lastName", type: "text" },
              { label: "Telefon Numarası", key: "phone", type: "tel" },
              { label: "Kurum Adı", key: "company", type: "text" },
            ].map((field) => (
              <div key={field.key} className="space-y-1">
                <label className="text-sm text-gray-600 font-medium">
                  {field.label}
                </label>
                <input
                  type={field.type}
                  value={formData[field.key]}
                  onChange={(e) => handleInputChange(field.key, e.target.value)}
                  className="w-full p-2.5 border border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-[#439DC6]/20 focus:border-[#439DC6] transition-all duration-200"
                />
              </div>
            ))}
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-600 font-medium">
              Kayıt Tarihi
            </label>
            <div className="w-full p-2.5 border border-gray-200 rounded-lg bg-gray-50 text-gray-500">
              {formatDate(formData.registrationDate)}
            </div>
          </div>

          {/*  {hasChanges && (
            <div className="flex justify-end pt-2">
              <Button
                type="submit"
                text="Bilgilerimi Güncelle"
                className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-2.5 rounded-lg font-medium transition-all duration-200 hover:shadow-md hover:shadow-[#439DC6]/20 text-sm"
              />
            </div>
          )}
            */}
        </form>
      </div>
    </div>
  );
}
