import { toast } from "react-toastify";

export const handleApiError = (error, defaultMessage = "Bir hata oluştu") => {
  console.log("error: ", error);
  let errorMessage = defaultMessage;

  try {
    // Handle 401 Unauthorized error
    if (error?.response?.status === 401) {
      errorMessage = "Oturum süreniz dolmuştur. Lütfen tekrar giriş yapınız.";
      // Redirect to login page after a short delay
      setTimeout(() => {
        window.location.href = "/giris";
      }, 2000);
    }
    // Handle validation errors
    else if (error?.errorCode === "validation_error") {
      errorMessage = error.message;
    } else if (error?.response?.data?.errorCode === "validation_error") {
      errorMessage = error.response.data.message;
    }
  } catch (parseError) {
    console.error("Error parsing error response:", parseError);
  }

  toast.error(errorMessage);
  return errorMessage;
};

export const handleApiErrorLogin = (
  error,
  defaultMessage = "Bir hata oluştu"
) => {
  console.log("error: ", error);
  let errorMessage = defaultMessage;

  try {
    if (error?.response?.status === 401) {
      errorMessage = "Oturum süreniz dolmuştur. Lütfen tekrar giriş yapınız.";
      setTimeout(() => {
        window.location.href = "/giris";
      }, 2000);
    } else if (error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    } else if (error?.message) {
      errorMessage = error.message;
    }
  } catch (parseError) {
    console.error("Error parsing error response:", parseError);
  }

  toast.error(errorMessage);
  return errorMessage;
};
