import React, { useState, useEffect } from "react";
import { FormControl, RadioGroup, Radio } from "@mui/material";
import { marketAnalysisService } from "../../../../../services/marketAnalysisService";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function MarketQuestionsE({
  periodId,
  productId,
  changePage,
  backPage,
  projectName,
}) {
  const [analysisData, setAnalysisData] = useState([]);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (productId) {
      fetchAnswers();
    }
  }, [productId]);

  const fetchAnswers = async () => {
    setIsLoading(true);
    try {
      const response = await marketAnalysisService.getAnswers(productId);
      setAnalysisData(response);
    } catch (error) {
      toast.error("Veriler yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAnswerChange = async (titleId, value) => {
    try {
      const numValue = parseInt(value);
      let status;

      if (numValue <= 2) {
        status = "firsat";
      } else if (numValue === 3) {
        status = "notr";
      } else {
        status = "tehdit";
      }

      // Make API call first
      await marketAnalysisService.saveAnswers(productId, {
        titleId,
        value: numValue,
        status,
      });

      // Then fetch updated data from server
      await fetchAnswers();
    } catch (error) {
      toast.error("Cevap kaydedilirken bir hata oluştu");
    }
  };

  const handleStatusChange = async (titleId, status) => {
    try {
      await marketAnalysisService.saveAnswers(productId, {
        titleId,
        status,
      });
      await fetchAnswers();
    } catch (error) {
      toast.error("Durum kaydedilirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      await downloadPageAsPDF(
        "market-analysis-container",
        projectName,
        "Pazar Analizi"
      );
      toast.success("PDF başarıyla indirildi");
    } catch (error) {
      toast.error("PDF indirilirken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleSectionPdfDownload = async (sectionTitle, sectionIndex) => {
    setIsPdfLoading(true);
    const container = document.getElementById(`market-analysis-container`);
    const originalStyle = container.style.cssText;
    const originalElements = [];

    try {
      // Hide all sections except the current one
      const sections = container.getElementsByClassName("mb-8");
      Array.from(sections).forEach((section, index) => {
        if (index !== sectionIndex) {
          section.style.display = "none";
        }
      });

      // Set temporary container styles for PDF
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Handle radio inputs
      const radioInputs = container.getElementsByTagName("input");

      Array.from(radioInputs).forEach((input) => {
        if (input.type === "radio" && input.checked) {
          const div = document.createElement("div");
          div.textContent = "✓";
          div.style.cssText = `
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Outfit;
            font-size: 14px;
            color: #439dc6;
          `;

          originalElements.push({
            element: input,
            parent: input.parentNode,
          });

          input.parentNode.replaceChild(div, input);
        }
      });

      await downloadPageAsPDF(
        "market-analysis-container",
        `${projectName} - ${sectionTitle}`,
        "Pazar Analizi"
      );

      toast.success("PDF başarıyla indirildi");
    } catch (error) {
      toast.error("PDF indirilirken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Show all sections again
      const sections = container.getElementsByClassName("mb-8");
      Array.from(sections).forEach((section) => {
        section.style.display = "block";
      });

      // Restore original elements
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });

      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading || isLoading}>
      <div id="market-analysis-container" className="w-full">
        {analysisData.map((section, index) => (
          <div key={index} className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <div className="text-[#439dc6] text-xl font-semibold">
                {section.title}
              </div>
              <button
                onClick={() => handleSectionPdfDownload(section.title, index)}
                className="px-4 py-2 bg-[#439dc6] text-white rounded-lg hover:bg-[#3589b1] transition-colors flex items-center gap-2"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 12.5v3.75a1.25 1.25 0 01-1.25 1.25H3.75a1.25 1.25 0 01-1.25-1.25V12.5M10 13.75V2.5M10 13.75l-3.75-3.75M10 13.75l3.75-3.75"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                PDF İndir
              </button>
            </div>
            <div className="bg-white rounded-[10px] border border-[#e0e0e0]">
              {section.answers.map((answer) => (
                <div
                  key={answer.titleId}
                  className="border-b border-[#e0e0e0] last:border-b-0"
                >
                  <div className="flex ">
                    <div className="flex-1 min-w-[400px] border-r border-[#e0e0e0] pr-4 p-4">
                      <div className="text-[#242731] text-sm font-medium">
                        {answer.title}
                      </div>
                      <div className="text-[#797979] text-xs mt-1">
                        (1 = Çok Düşük, 5 = Çok Yüksek)
                      </div>
                    </div>
                    <div className="flex items-center gap-4 px-4 min-w-[300px] border-r border-[#e0e0e0]">
                      <FormControl>
                        <RadioGroup
                          row
                          value={answer.value.toString()}
                          onChange={(e) =>
                            handleAnswerChange(answer.titleId, e.target.value)
                          }
                        >
                          {[1, 2, 3, 4, 5].map((value) => (
                            <div
                              key={value}
                              className="flex flex-col items-center mx-2"
                            >
                              <Radio
                                value={value.toString()}
                                size="small"
                                sx={{
                                  color: "#439dc6",
                                  "&.Mui-checked": {
                                    color: "#439dc6",
                                  },
                                }}
                              />
                              <div className="text-xs text-[#242731] -mt-2">
                                ({value})
                              </div>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="flex items-center min-w-[150px] pl-4">
                      {answer.value > 0 && (
                        <>
                          {answer.value <= 2 && (
                            <div className="flex items-center text-[#439dc6]">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
                                  fill="#439dc6"
                                />
                                <path
                                  d="M10 5V15M5 10H15"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <span className="ml-2 text-sm">Fırsat</span>
                            </div>
                          )}
                          {answer.value === 3 && (
                            <div className="flex items-center text-[#6c757d]">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx="10" cy="10" r="10" fill="#6c757d" />
                                <path
                                  d="M6 10H14"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <span className="ml-2 text-sm">Nötr</span>
                            </div>
                          )}
                          {answer.value >= 4 && (
                            <div className="flex items-center text-[#dc3545]">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
                                  fill="#dc3545"
                                />
                                <path
                                  d="M15 10H5"
                                  stroke="white"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <span className="ml-2 text-sm">Tehdit</span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div className="p-4 bg-[#f6f6f6] rounded-b-[10px] flex justify-end items-center">
                <div className="w-[493px] h-[50px] bg-white rounded-[10px] border border-[#e0e0e0] flex">
                  <div className="flex-1 border-r border-[#e0e0e0] flex items-center">
                    <div className="text-[#242731] text-sm font-semibold font-['Outfit'] ml-4">
                      Ortalama
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-center gap-4">
                    <div className="text-[#242731] text-sm font-semibold">
                      {section.average.toFixed(2)}
                    </div>
                    <div className="flex items-center">
                      {section.average <= 2.5 && (
                        <div className="flex items-center text-[#439dc6]">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
                              fill="#439dc6"
                            />
                            <path
                              d="M10 5V15M5 10H15"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="ml-2 text-sm">Fırsat</span>
                        </div>
                      )}
                      {section.average > 2.5 && section.average <= 3.5 && (
                        <div className="flex items-center text-[#6c757d]">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="10" cy="10" r="10" fill="#6c757d" />
                            <path
                              d="M6 10H14"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="ml-2 text-sm">Nötr</span>
                        </div>
                      )}
                      {section.average > 3.5 && (
                        <div className="flex items-center text-[#dc3545]">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
                              fill="#dc3545"
                            />
                            <path
                              d="M15 10H5"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="ml-2 text-sm">Tehdit</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="mt-8">
          <Stepper
            nextChange={changePage}
            backChange={backPage}
            activeStep={1}
            stepLimit={2}
          />
        </div>
      </div>
    </LoadingFullPage>
  );
}
