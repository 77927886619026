import React, { useEffect, useState } from "react";
import ArrowRight from "../../icons/arrow-right-gray.svg";
import User from "../../icons/user.svg";
import Settings from "../../icons/settings.svg";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../services/LogoutService";
import { errorService } from "../../services/error";
import { Skeleton } from "@mui/material";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import HomeIcon from "../../icons/home.svg";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../../redux/slices/profileSlice";
import { accountInformationService } from "../../services/AccountInformation";

const UserProfileIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10C12.0711 10 13.75 8.32107 13.75 6.25C13.75 4.17893 12.0711 2.5 10 2.5C7.92893 2.5 6.25 4.17893 6.25 6.25C6.25 8.32107 7.92893 10 10 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.25 17.5C16.25 14.7721 13.4279 12.5 10 12.5C6.57207 12.5 3.75 14.7721 3.75 17.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UsersGroupIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 6.875C14.9747 8.42528 13.8859 9.72819 12.5 10C13.8859 10.2718 14.9747 11.5747 15 13.125M5 13.125C5.02532 11.5747 6.11407 10.2718 7.5 10C6.11407 9.72819 5.02532 8.42528 5 6.875M13.125 6.875C13.125 8.32475 11.9497 9.5 10.5 9.5C9.05025 9.5 7.875 8.32475 7.875 6.875C7.875 5.42525 9.05025 4.25 10.5 4.25C11.9497 4.25 13.125 5.42525 13.125 6.875ZM16.875 13.125C16.875 14.5747 15.6997 15.75 14.25 15.75H6.75C5.30025 15.75 4.125 14.5747 4.125 13.125C4.125 11.6753 5.30025 10.5 6.75 10.5H14.25C15.6997 10.5 16.875 11.6753 16.875 13.125Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const KeyIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 8.75L17.5 2.5M17.5 2.5H13.75M17.5 2.5V6.25M7.5 12.5C9.15685 12.5 10.5 11.1569 10.5 9.5C10.5 7.84315 9.15685 6.5 7.5 6.5C5.84315 6.5 4.5 7.84315 4.5 9.5C4.5 11.1569 5.84315 12.5 7.5 12.5ZM7.5 12.5V17.5M5 15H10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LogoutIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.75 6.25L17.5 10M17.5 10L13.75 13.75M17.5 10H7.5M7.5 2.5H5.5C4.39543 2.5 3.5 3.39543 3.5 4.5V15.5C3.5 16.6046 4.39543 17.5 5.5 17.5H7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// URL kontrolü için yeni bir fonksiyon ekleyelim
const isProfilePage = () => {
  return window.location.pathname === "/profil";
};

export default function Header({ title, subTexts }) {
  const dispatch = useDispatch();
  //initial
  const navigate = useNavigate();
  //services
  const [accountInfo, setAccountInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [logout] = useLogoutMutation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [value, setValue] = useState(0);

  const isOrganizationUser = accountInfo?.accountType === "OrganizationUser";

  useEffect(() => {
    const fetchAccountInfo = async () => {
      setIsLoading(true);
      try {
        const data = await accountInformationService.getAccountInformation();
        setAccountInfo(data);
      } catch (error) {
        errorService(error, navigate);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountInfo();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (isDropdownOpen && !event.target.closest(".dropdown-container")) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownOpen]);

  // Get projectName and productName from URL
  const searchParams = new URLSearchParams(window.location.search);
  const projectName = searchParams.get("projectName");
  const productName = searchParams.get("productName");

  async function logoutUser(params) {
    var data = await logout();
    navigate("/giris");
  }

  function StepHeader(title, subTexts) {
    return (
      <>
        <div className="flex flex-1 items-center ml-2 sm:ml-4">
          <div className="text-zinc-800 text-lg sm:text-2xl font-bold font-['Outfit'] whitespace-nowrap text-ellipsis">
            {title}
          </div>
          <div className="ml-3 sm:ml-7 flex items-center justify-center text-left overflow-x-auto">
            {subTexts?.map((title, index) =>
              subTexts.length - 1 === index ? (
                <>
                  <div className="text-slate-900 text-sm sm:text-base font-normal font-['Outfit'] whitespace-nowrap text-ellipsis">
                    {title}
                  </div>
                </>
              ) : (
                <>
                  <div className="text-stone-300 text-sm sm:text-base font-normal font-['Outfit'] whitespace-nowrap text-ellipsis">
                    {title}
                  </div>
                  <img
                    className="p-1 sm:p-2 mt-0.5"
                    src={ArrowRight}
                    alt="arrow"
                  />
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }

  function WelcomeHeader() {
    return (
      <>
        <div className="flex flex-1 items-center px-2 sm:px-0">
          <div>
            <span className="text-gray-500 text-xl sm:text-3xl font-light font-['Outfit']">
              Hoşgeldin
            </span>
            <span className="text-gray-500 text-xl sm:text-3xl font-medium font-['Outfit']">
              {" "}
            </span>
            <span className="text-zinc-900 text-xl sm:text-3xl font-extrabold font-['Outfit']">
              <> {accountInfo?.name}</>
            </span>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col sm:flex-row mt-4 sm:mt-[23px] mb-4 sm:mb-[38px] w-full gap-4 sm:gap-0">
        {isProfilePage() && (
          <div className="flex-1">
            <button
              onClick={() => navigate("/anasayfa")}
              className="flex mt-2 items-center px-6 h-[40px] sm:h-[50px] text-gray-600 hover:text-[#439dc6] rounded-lg hover:bg-[#439dc6]/10 transition-all duration-200 bg-white border border-gray-200"
            >
              <img src={HomeIcon} className="w-5 h-5 mr-2" alt="home" />
              <span className="font-medium">Anasayfaya Dön</span>
            </button>
          </div>
        )}
        {projectName && (
          <div className="flex mt-2 justify-center items-center w-[120px] sm:w-[156px] h-[40px] sm:h-[50px] rounded-lg border-2 border-[#439dc6] bg-white">
            <div className="w-full h-[10px] flex items-center justify-center px-2">
              <span className="text-[#439dc6] text-sm sm:text-l font-semibold font-['Outfit']">
                {projectName.length > 15
                  ? projectName.substring(0, 15) + "..."
                  : projectName}
              </span>
            </div>
          </div>
        )}
        {productName && (
          <div className="flex mt-2 ml-2 justify-center items-center w-[120px] sm:w-[156px] h-[40px] sm:h-[50px] rounded-lg border-2 border-[#439dc6] bg-white">
            <div className="w-full h-[10px] flex items-center justify-center px-2">
              <span className="text-[#439dc6]  text-sm sm:text-l font-semibold font-['Outfit']">
                {productName.length > 15
                  ? productName.substring(0, 15) + "..."
                  : productName}
              </span>
            </div>
          </div>
        )}
        {title
          ? StepHeader(title, subTexts)
          : !isProfilePage()
          ? WelcomeHeader()
          : null}
        <div className="w-full sm:w-auto">
          <div className="w-full sm:w-[313px] h-[60px] sm:h-[70px] relative">
            <div
              className="w-full sm:w-[313px] h-full bg-white absolute rounded-lg border border-neutral-200 cursor-pointer hover:bg-white transition-all duration-200"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="w-[40px] sm:w-[47px] h-[40px] sm:h-[47px] left-[9px] top-[10px] sm:top-[12px] absolute rounded-full border border-neutral-200" />
              <div className="w-5 sm:w-6 h-5 sm:h-6 left-[19px] sm:left-[21px] top-[18px] sm:top-[23px] absolute justify-center items-center inline-flex">
                <div className="w-full h-full relative">
                  <img src={User} alt="user" />
                </div>
              </div>
              <div className="w-[140px] sm:w-[169px] h-[42px] left-[60px] sm:left-[70px] top-[10px] sm:top-[15px] absolute">
                <div className="left-0 top-0 absolute text-gray-900 text-base sm:text-lg font-bold font-['Outfit'] truncate w-full">
                  {accountInfo?.name} {accountInfo?.surname}
                </div>
                <div className="left-0 top-[22px] absolute text-neutral-400 text-sm sm:text-base font-medium font-['Outfit'] truncate w-full">
                  {accountInfo?.corporation}
                </div>
              </div>
            </div>

            {isDropdownOpen && (
              <div className="dropdown-container absolute top-[70px] right-0 w-full sm:w-[313px] bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                <div className="p-4">
                  {/* Profil Başlığı */}

                  {/* Menü Öğeleri */}
                  <div className="space-y-1">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/anasayfa");
                        setIsDropdownOpen(false);
                      }}
                      className="flex w-full items-center px-4 py-2.5 text-gray-700 rounded-lg hover:bg-gray-50 group transition-colors"
                    >
                      <div className="w-9 h-9 bg-gray-100 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                        <img src={HomeIcon} className="w-5 h-5" alt="home" />
                      </div>
                      <span className="font-medium">Anasayfa</span>
                    </button>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/profil");
                        dispatch(setActiveTab(0));
                        setIsDropdownOpen(false);
                      }}
                      className="flex w-full items-center px-4 py-2.5 text-gray-700 rounded-lg hover:bg-gray-50 group transition-colors"
                    >
                      <div className="w-9 h-9 bg-gray-100 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                        <UserProfileIcon />
                      </div>
                      <span className="font-medium">Kişisel Bilgiler</span>
                    </button>

                    {!isOrganizationUser && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profil");
                          dispatch(setActiveTab(1));
                          setIsDropdownOpen(false);
                        }}
                        className="flex w-full items-center px-4 py-2.5 text-gray-700 rounded-lg hover:bg-gray-50 group transition-colors"
                      >
                        <div className="w-9 h-9 bg-gray-100 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                          <UsersGroupIcon />
                        </div>
                        <span className="font-medium">Kullanıcılar</span>
                      </button>
                    )}

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/profil");
                        dispatch(setActiveTab(2));
                        setIsDropdownOpen(false);
                      }}
                      className="flex w-full items-center px-4 py-2.5 text-gray-700 rounded-lg hover:bg-gray-50 group transition-colors"
                    >
                      <div className="w-9 h-9 bg-gray-100 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                        <svg
                          className="w-5 h-5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <span className="font-medium">Modüller</span>
                    </button>

                    {!isOrganizationUser && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/profil");
                          dispatch(setActiveTab(3));
                          setIsDropdownOpen(false);
                        }}
                        className="flex w-full items-center px-4 py-2.5 text-gray-700 rounded-lg hover:bg-gray-50 group transition-colors"
                      >
                        <div className="w-9 h-9 bg-gray-100 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                          <KeyIcon />
                        </div>
                        <span className="font-medium">Şifremi Güncelle</span>
                      </button>
                    )}

                    <div className="my-2 border-t border-gray-200"></div>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        logoutUser();
                        setIsDropdownOpen(false);
                      }}
                      className="flex w-full items-center px-4 py-2.5 text-red-600 rounded-lg hover:bg-red-50 group transition-colors"
                    >
                      <div className="w-9 h-9 bg-red-50 rounded-lg flex items-center justify-center group-hover:bg-white mr-3">
                        <LogoutIcon />
                      </div>
                      <span className="font-medium">Çıkış Yap</span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="h-[0px] border border-neutral-200 w-full"></div>
    </>
  );
}
