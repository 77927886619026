import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import StrategicDirectionImage from "../../../../../images/strategic-direction.svg";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-4 md:gap-8 mx-auto px-2 md:px-4">
      <div className="w-full">
        <div className="text-black text-base md:text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="w-full text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
          Stratejik amaçlarınızı BSC boyutları ile belirlediniz. Şimdi
          hedefleriniz belirleme ve bu hedefler doğrultusunda
          gerçekleştireceğiniz projelerinizi belirleme zamanıdır. Artık bu kısım
          stratejik planlama çalışmalarında sona geldiğiniz, önümüzdeki 3-4 sene
          içerisinde ulaşmak istediğiniz amaçları, hedefleri tanımladığınız ve
          bu amaç ve hedeflere ulaşmak için yürütmeniz gereken proje
          faaliyetlerini oluşturduğunuz kısımdır.
          <br />
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gap-2 mb-4">
          <div className="w-4 md:w-5 h-4 md:h-5"></div>
          <div className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi başlayalım!
          </div>
        </div>
      </div>

      <div className="w-full text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
        Bir önceki aşamada belirlediğiniz stratejik amaçlarınızı bu modülde yer
        alan tabloda görmektesiniz. Stratejik amaçlarınıza göre her bir amaca
        ulaşabilecek hedeflerinizi ilk aşamada tanımlamanız gerekmektedir. Bir
        amacın altında birkaç hedef tanımlayabilir; her hedefin altında da
        birkaç temel sonuç tanımlayabilirsiniz. Hedef, amaçların daha anlaşılır
        ve ölçülebilir ifade ediliş biçimidir. Örneğin "Pazar payını artırmak"
        bir amaç olabilir; ama "Ankara bölgesinde bir sene içerisinde pazar
        payını %2'den %3'e çıkarmak" bir hedef ifadesi olabilir. Bu yüzden
        hedeflerin SMART (Spesific, Measurable, Attainable, Relevant,Timely)
        yapıda olması gerekir. SMART faktörler şu bileşenlerden oluşur:{" "}
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            1
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Spesifiklik:
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            {" "}
            Hedefiniz spesifiklik mi? İyi tanımlanmış ve net mi? Herkes hedef
            ifadesinden aynı şeyi mi anlıyor?
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            2
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Ölçülebilirlik:
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            {" "}
            Hedefiniz ölçülebilir mi? İlerlemeyi izlemek için ölçülebilir
            göstergeleri dahil edildi mi? KPI tanımlamaları ile hedeflerinizi
            ölçülebilir yapıya getirebilirsiniz.
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            3
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Ulaşılabilirlik:
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            {" "}
            Hedefiniz ulaşılabilir mi? Sahip olduğunuz kaynaklar, para,
            deneyim bu hedefi gerçekleştirmeye yeterli mi?
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            4
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Alakalılık:
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            {" "}
            Hedefiniz amacınıza uygun mu ?
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            5
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Zamanındalık
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            : Hedefiniz başlangıç - bitiş tarihi var mı?
          </span>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            6
          </div>
        </div>
        <div className="flex-1">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            Sosyal Fayda:{" "}
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            Toplumsal değer sunacak veya çevre dostu çözümler sunulmasıdır.
            Ürüne karbon ayak izi hesaplayıcısı eklenmesi, ürün ömrünü
            tamamlayınca başka bir ürüne dönüştürülebilir olması, bağış ve
            sosyal sorumluluk kampanyalarının düzenlenmesi örnek olarak
            verilebilir.
          </span>
        </div>
      </div>

      <div className="w-full">
        <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
          Temel Sonuçlar:{" "}
        </span>
        <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
          Hedef ve Temel Sonuçlar (Objective and Key Results: OKRs) en temel
          ifadeyle bir hedef belirleme çerçevesidir. Bir amaca birden fazla
          hedefle ulaşmanız gerektiği gibi, bir hedef ulaşmak içinde birden
          fazla temel sonuca ihtiyaç duyarsınız. Temel sonuçlar (KRs), hedeflere
          ulaşmak için gerekli olan somut, spesifik ve ölçülebilir adımlardır.
          Hedefler gibi temel sonuçların da SMART yapısını desteklemesi gerekir.
          Yukarıdaki örnekten gidecek olursak "Ankara bölgesinde bir sene
          içerisinde pazar payını %2'den %3'e çıkarmak" hedefi için şu temel
          sonuçlar yazılabilir: 1. Ankara bölgesinde özel promosyon ve
          kampanyalar düzenleyerek mevcut müşteri satış hacmini %20 artırmak. 2.
          Müşteri hizmetleri ve destek süreçlerini iyileştirerek Ankara
          müşterilerinin memnuniyet puanını %15 artırmak 3. Pazarlama bütçesini
          %15 artırarak Ankara bölgesi için dijital reklam kampanyaları
          düzenlemek.
        </span>
      </div>

      <div className="w-full flex items-center">
        <div className="w-full lg:w-[600px] flex-1">
          <div className="w-full pr-2 md:pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
