import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const marketAnswerService = {
  getMarketAnswers: async (projectId, marketId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/markets/${marketId}/answers`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  submitAnswer: async (projectId, marketId, titleId, value) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/markets/${marketId}/answers`,
        {
          titleId,
          value,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getMarketAttractiveness: async (projectId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/market-attractiveness-analysis`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateMarketFactor: async (projectId, titleId, factor) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/determining-market-attractiveness/${projectId}/market-attractiveness-analysis/titles/${titleId}/factor`,
        { factor },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating market factor:", error);
      throw error;
    }
  },
};
