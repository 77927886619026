import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import determiningValueInnovationService from "../../../../../services/determiningValueInnovationService";
import DeleteIcon from "../../../../../icons/delete-item.svg";
import MarketFocusIcon from "../../../../../icons/market-focus.svg";
import SolutionFocusIcon from "../../../../../icons/solution-focus.svg";
import Modal from "../../../../../components/Modal/ModalSelect";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function DeterminingValueInnovation({
  changePage,
  backPage,
  projectId,
  nextPage,
  projectName,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [modalInput, setModalInput] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState("");
  const [gridContent, setGridContent] = useState(Array(36).fill(null));
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const data = await determiningValueInnovationService.getAnswers(
          projectId
        );
        const newGridContent = Array(36).fill(null);
        data.forEach((item) => {
          if (item.answer && item.focusPoint) {
            newGridContent[item.containerId - 1] = {
              type: item.focusPoint === 1 ? "current" : "solution",
              content: item.answer,
            };
          }
        });
        setGridContent(newGridContent);
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };

    if (projectId) {
      fetchAnswers();
    }
  }, [projectId]);

  const handleSubmit = async () => {
    if (!selectedOption || !modalInput.trim()) {
      setError("Lütfen bir seçenek ve değer önerisi giriniz");
      return;
    }

    try {
      await determiningValueInnovationService.addAnswer(
        projectId,
        selectedCell + 1,
        modalInput.trim(),
        selectedOption === "current" ? 1 : 2
      );

      const newGridContent = [...gridContent];
      newGridContent[selectedCell] = {
        type: selectedOption,
        content: modalInput.trim(),
      };
      setGridContent(newGridContent);
      setIsModalOpen(false);
      setModalInput("");
      setSelectedOption(null);
    } catch (error) {
      console.error("Error adding answer:", error);
      setError("Ekleme işlemi sırasında bir hata oluştu");
    }
  };

  const handleDelete = async (index, e) => {
    e.stopPropagation();
    try {
      await determiningValueInnovationService.deleteAnswer(
        projectId,
        index + 1
      );
      const newGridContent = [...gridContent];
      newGridContent[index] = null;
      setGridContent(newGridContent);
    } catch (error) {
      console.error("Error deleting answer:", error);
    }
  };

  const handleCellClick = (index) => {
    setSelectedCell(index);
    setIsModalOpen(true);
    if (gridContent[index]) {
      setModalInput(gridContent[index].content);
      setSelectedOption(gridContent[index].type);
    } else {
      setModalInput("");
      setSelectedOption(null);
    }
    setError("");
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setError("");
  };

  const renderGridCell = (index) => (
    <div
      key={index}
      onClick={() => handleCellClick(index)}
      className="bg-white w-full h-full hover:bg-gray-200 cursor-pointer border-[0.5px] relative p-2 group"
      title={gridContent[index]?.content || ""}
    >
      {gridContent[index] && (
        <>
          <div
            className={`text-sm font-medium ${
              gridContent[index].type === "current"
                ? "text-red-500"
                : "text-blue-500"
            }`}
          >
            {gridContent[index].type === "current" ? (
              <img
                src={MarketFocusIcon}
                alt="Current Focus"
                className="inline-block w-4 h-4 mr-1 opacity-100 transition-opacity duration-300 hover:opacity-80"
              />
            ) : (
              <img
                src={SolutionFocusIcon}
                alt="Solution Focus"
                className="inline-block w-4 h-4 mr-1 opacity-100 transition-opacity duration-300 hover:opacity-80"
              />
            )}
            {gridContent[index].content.length > 47
              ? `${gridContent[index].content.substring(0, 47)}...`
              : gridContent[index].content}
          </div>
          <button
            onClick={(e) => handleDelete(index, e)}
            className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <img src={DeleteIcon} alt="Sil" className="w-4 h-4" />
          </button>
        </>
      )}
    </div>
  );

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("page-content-container");

    // Geçici stil değişiklikleri
    const originalStyle = container.style.cssText;

    // Container'ı geçici olarak düzenleyelim
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Textarea'ları geçici olarak görünür div'lere çevirelim
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "page-content-container",
        "Alıcı Değer Haritası",
        projectName
      );
    } finally {
      // Orijinal duruma geri dönelim
      container.style.cssText = originalStyle;

      // Orijinal textarea'ları geri yükleyelim
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div>
        {" "}
        <div className=" text-[#797979] text-lg font-normal font-['Outfit']">
          Eğer mevcut pazara bu değer önerilerinden en az birini dahil
          edebiliyorsanız şimdi haritada gerekli işaretleri yapma zamanı!
          Tabloyu örneğin şöyle doldurmanız gerekecek: Mevcut pazar veya mevcut
          çözüm önerileri ürün teslimatı aşamasında kolaylık sunarak kolay
          teslimat sağlıyor olabilir. Buraya kırmızı butonu ekleyin ve mevcut
          pazarın/çözümün değer önerisini yazın. Siz buna ek olarak ürünün elden
          çıkarma aşamasında da bir kolaylık sağlıyor olabilirsiniz veya ürünün
          kullanım aşamasında bazı riskleri ortadan kaldırıyor olabilirsiniz. Bu
          kısımlara da mavi butonu ekleyerek kendi değer önerinizi yazınız.{" "}
          <br />{" "}
        </div>
        <br />
        <div id="page-content-container">
          <div className="text-center text-[#439dc6] text-xl font-semibold font-['Outfit']">
            Alıcı Değer Haritası
          </div>
          <br />
          <div className="flex ">
            <div className="flex flex-col  w-[189px]">
              <div className="flex-1"></div>
              <div className="flex-1 border-b-[1px] items-center flex ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Verimlilik
                </div>
              </div>
              <div className="flex-1 border-b-[1px] items-center flex  ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Sadelik
                </div>
              </div>
              <div className="flex-1 border-b-[1px] items-center flex  ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Kolaylık
                </div>
              </div>
              <div className="flex-1 border-b-[1px] items-center flex  ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Eğlence & Görüntü
                </div>
              </div>
              <div className="flex-1 border-b-[1px]  items-center flex  ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Verimlilik
                </div>
              </div>
              <div className="flex-1  items-center flex  ">
                <div className="  text-[#3ebbd0] text-base font-semibold font-['Outfit']">
                  Sosyal Fayda
                </div>
              </div>
            </div>
            <div className="w-full h-[656px] rounded-[10px] border border-[#e0e0e0] flex flex-col">
              <div className="h-[93px]  items-center flex   border-b-[1px]">
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Farkındalık ve Satın Alma{" "}
                  </div>
                </div>
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Teslimat
                  </div>
                </div>
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Kullanım
                  </div>
                </div>
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Takviyeler
                  </div>
                </div>
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Bakım
                  </div>
                </div>
                <div className="flex-1 border-r-[1px] h-full items-center justify-center flex ">
                  <div className=" text-center text-black text-base font-medium font-['Outfit']">
                    Elden Çıkartma
                  </div>
                </div>
              </div>

              <div className="flex-1 items-center flex">
                <div className="grid grid-rows-6 grid-cols-6 w-full h-full">
                  {Array.from({ length: 36 }, (_, index) => (
                    <div key={index} title={renderGridCell(index)}>
                      {renderGridCell(index)}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="w-full items-end justify-end flex">
            <div className="w-[246px] h-[74px] rounded-[10px] border border-[#e0e0e0] flex flex-col gap-2 items-center justify-center">
              <div className="text-center text-black text-base font-xs font-['Outfit'] flex items-center justify-center gap-2">
                <img src={MarketFocusIcon} alt="Market Focus" />
                <div>Şu anki pazarın odak noktası</div>
              </div>

              <div className="text-center text-black text-base font-xs font-['Outfit'] flex items-center justify-center gap-2">
                <img src={SolutionFocusIcon} alt="Solution Focus" />
                <div>Çözümün odak noktası</div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                nextChange={changePage}
                backChange={backPage}
                activeStep={2}
                stepLimit={2}
                nextPage={nextPage}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          selectedOption={selectedOption}
          handleOptionSelect={handleOptionSelect}
          modalInput={modalInput}
          setModalInput={setModalInput}
          error={error}
          handleSubmit={handleSubmit}
        />
      </div>
    </LoadingFullPage>
  );
}
