import React, { useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import Information from "./Informations/Information";
import HowCan from "./Modules/HowCan";
import InformationRootCause from "./Informations/InformationRootCause";
import RootCause from "./Modules/RootCause";
import InformationInterviewTemplate from "./Informations/InformationInterviewTemplate";
import InterviewTemplate from "./Modules/InterviewTemplate";
import InformationExploratoryInterview from "./Informations/InformationExploratoryInterview";
import ClueExploratoryInterview from "./Modules/ClueExploratoryInterview";
import ExploratoryInterview from "./Modules/ExploratoryInterview";
import Project from "../Project";

export default function Empathy() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Empati",
      subTexts: ["Bilgilendirme"],
    },
    {
      title: "Empati",
      subTexts: ["Bilgilendirme", "Nasıl Yapabiliriz"],
    },
    {
      title: "Empati",
      subTexts: ["Bilgilendirme", "Nasıl Yapabiliriz", "Kök Neden Analizi"],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
    {
      title: "Empati",
      subTexts: [
        "Bilgilendirme",
        "Nasıl Yapabiliriz",
        "Kök Neden Analizi",
        "Mülakat Şablonu",
        "Keşif Mülakatı",
      ],
    },
  ]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const changePage = (newPage) => {
    if (newPage === 0) {
      resetSearchParams();
    }
    setPageState(newPage);
  };

  function chageProject(projectId) {
    setProjectId(projectId);
  }

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="max-w-[1200px] w-full bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px] mx-4"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Project
              subId={searchParams.get("subId")}
              changePage={() => changePage(1)}
              pageState={pageState}
              chageProject={(projectId) => chageProject(projectId)}
              changeProjectName={(projectName) => setProjectName(projectName)}
            />
          )}

          {pageState === 1 && (
            <Information
              subId={searchParams.get("subId")}
              backPage={() => changePage(0)}
              changePage={() => changePage(2)}
            />
          )}

          {pageState === 2 && (
            <HowCan
              subId={searchParams.get("subId")}
              projectId={projectId}
              backPage={() => changePage(1)}
              changePage={() => changePage(3)}
              pageState={pageState}
              projectName={projectName}
            />
          )}

          {pageState === 3 && (
            <InformationRootCause
              subId={searchParams.get("subId")}
              backPage={() => changePage(2)}
              changePage={() => changePage(4)}
              pageState={pageState}
            />
          )}

          {pageState === 4 && (
            <RootCause
              subId={searchParams.get("subId")}
              projectId={projectId}
              backPage={() => changePage(3)}
              changePage={() => changePage(5)}
              pageState={pageState}
              projectName={projectName}
            />
          )}

          {pageState === 5 && (
            <InformationInterviewTemplate
              subId={searchParams.get("subId")}
              backPage={() => changePage(4)}
              changePage={() => changePage(6)}
              projectId={projectId}
              pageState={pageState}
            />
          )}

          {pageState === 6 && (
            <InterviewTemplate
              projectId={projectId}
              subId={searchParams.get("subId")}
              backPage={() => changePage(5)}
              changePage={() => changePage(7)}
              pageState={pageState}
              projectName={projectName}
            />
          )}

          {pageState === 7 && (
            <InformationExploratoryInterview
              projectId={projectId}
              subId={searchParams.get("subId")}
              backPage={() => changePage(6)}
              changePage={() => changePage(8)}
              pageState={pageState}
            />
          )}
          {pageState === 8 && (
            <ClueExploratoryInterview
              projectId={projectId}
              subId={searchParams.get("subId")}
              backPage={() => changePage(7)}
              changePage={() => changePage(9)}
              pageState={pageState}
              projectName={projectName}
            />
          )}
          {pageState === 9 && (
            <ExploratoryInterview
              projectId={projectId}
              subId={searchParams.get("subId")}
              backPage={() => changePage(8)}
              nextPage={() => changePage(0)}
              pageState={pageState}
              projectName={projectName}
            />
          )}
          {/*{pageState === 2 && (
            <CompetenceGraphic
              subId={searchParams.get("subId")}
              backPage={() => changePage(1)}
            />
          )} */}
        </div>
      </div>
    </>
  );
}
