import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { marketService } from "../services/marketService";

export default function MarketTabs({
  projectId,
  onMarketSelect,
  selectedMarketId,
}) {
  const [open, setOpen] = useState(false);
  const [newMarket, setNewMarket] = useState("");
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);

  useEffect(() => {
    if (projectId) {
      fetchMarkets();
    }
  }, [projectId]);

  useEffect(() => {
    if (selectedMarketId) {
      const index = markets.findIndex(
        (market) => market.id === selectedMarketId
      );
      setSelectedMarket(index);
    }
  }, [selectedMarketId, markets]);

  const fetchMarkets = async () => {
    try {
      const response = await marketService.getMarkets(projectId);
      setMarkets(response || []);
    } catch (error) {
      console.error("Error fetching markets:", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewMarket("");
    setSelectedToDelete(null);
  };

  const handleAdd = async () => {
    if (newMarket.trim()) {
      setLoading(true);
      try {
        await marketService.addMarket(projectId, newMarket);
        await fetchMarkets();
        handleClose();
      } catch (error) {
        console.error("Error adding market:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (marketId) => {
    setLoading(true);
    try {
      await marketService.deleteMarket(projectId, marketId);
      await fetchMarkets();
      setSelectedToDelete(null);
    } catch (error) {
      console.error("Error deleting market:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarketSelect = (index, marketId) => {
    setSelectedMarket(index);
    if (onMarketSelect) {
      onMarketSelect(marketId);
    }
  };

  return (
    <div className="flex gap-2 mb-4">
      <button
        onClick={handleClickOpen}
        className="flex items-center gap-2 px-4 py-2 rounded-lg border border-gray-300 hover:bg-gray-50"
      >
        <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 7V17M7 12H17"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
        Pazar Ekle / Kaldır
      </button>

      {markets.map((market, index) => (
        <div key={market.id} className="relative">
          <button
            onClick={() => handleMarketSelect(index, market.id)}
            className={`px-4 py-2 rounded-lg ${
              selectedMarket === index
                ? "bg-[#EF8024] text-white"
                : "border border-[#EF8024] text-[#EF8024]"
            }`}
          >
            {market.name}
          </button>
        </div>
      ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pazar Ekle / Kaldır</DialogTitle>
        <DialogContent>
          <div className="mb-4">
            <TextField
              autoFocus
              margin="dense"
              label="Yeni Pazar Adı"
              type="text"
              fullWidth
              variant="outlined"
              value={newMarket}
              onChange={(e) => setNewMarket(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleAdd()}
              disabled={loading}
            />
          </div>

          {markets.length > 0 && (
            <div className="mt-4">
              <div className="text-gray-600 mb-2">Mevcut Pazarlar:</div>
              <div className="space-y-2">
                {markets.map((market) => (
                  <div
                    key={market.id}
                    className="flex items-center justify-between p-2 border rounded hover:bg-gray-50"
                  >
                    <span>{market.name}</span>
                    <button
                      onClick={() => handleDelete(market.id)}
                      className="text-red-500 hover:text-red-700 p-1"
                      disabled={loading}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            disabled={loading}
          >
            Kapat
          </button>
          <button
            onClick={handleAdd}
            className="px-4 py-2 bg-[#EF8024] text-white rounded hover:bg-[#E67016] disabled:opacity-50"
            disabled={loading || !newMarket.trim()}
          >
            {loading ? "Ekleniyor..." : "Ekle"}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
