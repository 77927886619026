import React, { useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import Information from "./Information/Information";
import InformationNext from "./Information/InformationNext";
import MarketDeterminationScale from "./Modules/MarketDeterminationScale";
import Products from "../Products";

export default function DeterminingForeignTargetMarket() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);
  const [productsId, setProductsId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [headersText, setHeadersText] = useState([
    {
      title: "Yurtdışı Hedef Pazar Belirlenmesi",
      subTexts: ["Ürün/Hizmet Seçimi"],
    },
    {
      title: "Yurtdışı Hedef Pazar Belirlenmesi",
      subTexts: ["Ürün/Hizmet Seçimi", "Bilgilendirme"],
    },
    {
      title: "Yurtdışı Hedef Pazar Belirlenmesi",
      subTexts: ["Ürün/Hizmet Seçimi", "Bilgilendirme", "Bilgilendirme"],
    },
    {
      title: "Yurtdışı Hedef Pazar Belirlenmesi",
      subTexts: [
        "Ürün/Hizmet Seçimi",
        "Bilgilendirme",
        "Bilgilendirme",
        "Yurtdışı Hedef Pazar Belirleme Ölçeği",
      ],
    },
  ]);

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Products
              setProductsId={setProductsId}
              changePage={() => setPageState(1)}
            />
          )}
          {pageState === 1 && (
            <Information
              nextChange={() => setPageState(2)}
              backChange={() => setPageState(0)}
            />
          )}

          {pageState === 2 && (
            <InformationNext
              nextChange={() => setPageState(3)}
              backChange={() => setPageState(1)}
            />
          )}
          {pageState === 3 && (
            <MarketDeterminationScale
              projectId={productsId}
              changePage={() => setPageState(0)}
              backPage={() => setPageState(2)}
            />
          )}
        </div>
      </div>
    </>
  );
}
