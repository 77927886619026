import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Pencil from "../../../../../icons/pencil.svg";

export default function BussinessModelInformation({ changePage, backPage }) {
  return (
    <div>
      <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        Ürün-Pazar uyumunu da başarılı geçtiyseniz artık iş
        fikirleriniz/çözümleriniz için iş modelinizi oluşturabilirsiniz. Artık
        gerçekleştirmiş olduğunuz tüm çalışmaları birleştirme ve iş modelinizi
        oluşturmaya hazırsınız. Bu bölümde kurumsal firmalar için daha uygun
        olduğunu düşündüğümüz iş modeli kanvasını tercih ediyoruz.{" "}
      </div>
      <br />
      <div className="items-center  text-neutral-500 text-lg font-semibold font-['Outfit'] flex">
        <img className="p-1 pr-2" src={Pencil} />
        Haydi gelin şimdi başlayalım!
      </div>
      <br />
      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        İş Modeli Kanvası, işletmelerin veya girişimlerin iş modelini planlamak,
        analiz etmek ve iletmek için kullanılan bir araçtır. Alexander
        Osterwalder ve Yves Pigneur tarafından geliştirilen bu araç,
        işletmelerin temel bileşenlerini görsel bir şekilde tasvir etmelerine
        yardımcı olur. İş Modeli Kanvası, bir iş fikrini daha iyi anlamak,
        geliştirmek ve başkalarına iletmek için kullanışlı bir araçtır.{" "}
      </div>
      <br />
      <div className="w-[1136px] text-[#439dc6] text-lg font-semibold font-['Outfit']">
        İş Modeli Kanvası 9 ana bileşeni içerir:{" "}
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Müşteri Segmentleri (Customer Segments):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          İşletmenizin hangi tür müşterilere hizmet vermek veya ürünler sunmak
          istediğini tanımlar. Müşteri segmentleri, pazarı tanımlamak için
          kullanılır ve hedef kitlenizin kim olduğunu anlamanıza yardımcı olur.
          Yenilikçi ürünlerinizin potansiyel müşterileri, yeni pazarlardaki yeni
          müşterileriniz kimler olabilir ve ürününüzü en çok deneyimleyebilecek
          öncü grup kimler olabilir gibi konularda karar vermeniz gerekecektir.
          Problem ve Müşteri Analizi modülü bu konuda sizlere bilgi
          sağlayabilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Değer Teklifi (Value Proposition):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Müşterilere sunulan değerleri ve faydaları tanımlar. Bu, işletmenizin
          neden benzersiz olduğunu ve müşteriler için hangi sorunları çözdüğünü
          açıklar. Değer İnovasyonu Modülü bölümü bu konuda sizlere bilgi
          sağlayabilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Kanallar (Channels):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün veya hizmetlerinizin müşterilere nasıl ulaştırılacağını ve
          dağıtılacağını belirler. Kanallar, pazarlama, satış, dağıtım ve
          iletişim stratejilerini içerir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Müşteri İlişkileri (Customer Relationships):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Müşterilerle nasıl etkileşime geçileceğini, ilişkilerin nasıl
          sürdürüleceğini ve müşteri memnuniyetinin nasıl sağlanacağını
          tanımlar.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Temel Kaynaklar (Key Resources):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün veya hizmetlerin ortaya çıkması için gereken kritik
          kaynakları tanımlar. Bu kaynaklar insan kaynakları, teknoloji,
          tesisler, finansman vb. olabilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Temel Faaliyetler (Key Activities):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün veya hizmetlerin ortaya çıkması için gereken kritik
          faaliyetleri tanımlar. Hangi iş süreçleri, ürün geliştirme, üretim,
          pazarlama, satış vb. işlerin kritik olduğunu açıklar.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Temel Ortaklıklar (Key Partnerships):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün geliştirme sürecinde hangi organizasyonlar, şirketler veya
          kurumlarla iş birliği yapma gerekliliğini tanımlar. Bu, kaynakların ve
          yeteneklerin paylaşılması veya dış kaynakların kullanılması anlamına
          gelebilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Gelir Kaynakları (Revenue Streams):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün veya hizmetlerinizden nasıl para kazanacağını ve gelir elde
          edeceğinizi tanımlar. Bu bileşen, ürün satışları, abonelikler, reklam
          gelirleri veya diğer gelir kaynaklarını içerebilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Maliyet Yapısı (Cost Structure):{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Yeni ürün veya hizmetlerin geliştirilmesi aşamasında üstlenilecek
          maliyet kalemlerini tanımlar. Bu bileşen, personel maliyetleri,
          hammadde maliyetleri, pazarlama harcamaları, araştırma ve geliştirme
          maliyetleri vb. olabilir.{" "}
        </span>
      </div>
      <br />

      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
