import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function InformationEmpathyMap({
  changePage,
  backPage,
  nextPage,
}) {
  return (
    <div className="max-w-[1136px] mx-auto px-4">
      <div className="text-[#797979] text-base font-normal font-['Outfit']">
        Bir diğer kazanılan içgörüleri birleştirme yöntemi Empati Haritası'dır.
        Empati Haritası yaptığınız mülakatlar sonucu müşterilerinizin,
        kullanıcılarınızın ne söylediği, nasıl davrandığı, ne düşündüğü ve nasıl
        hissettiğiyle ilgilidir. JTBD gibi Empati Haritası da problemi doğrulama
        yöntemidir.{" "}
      </div>
      <br />
      <div className="text-black text-xl font-semibold font-['Outfit']">
        Empati Haritası'nın Bileşenleri{" "}
      </div>
      <br />
      <div className="text-[#797979] text-base font-normal font-['Outfit']">
        Bir empati haritası genellikle dört ana kadrandan oluşur. Her bir kadran
        kullanıcı deneyiminin farklı yönlerini; kullanıcının ne söylediğini, ne
        yaptığını, ne düşündüğünü ve ne hissettiğini temsil eder. Bazı
        haritalarda bu kadranlara ek olarak; görür, duyar, kazanç ve acı
        noktaları yer alabilir. Sürece uygun olacak şekilde kadran eklemesi
        yapılabilir. Kadranlar, kullanıcıyı temsil eden persona etrafında
        düzenlenir.{" "}
      </div>
      <br />
      <div className="flex flex-wrap">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          1
        </div>
        <div className="flex-1 ml-4">
          <div className="min-h-[23px]">
            <span className="text-[#797979] text-xl font-semibold font-['Outfit']">
              Söyledi:
            </span>
            <span className="text-[#797979] text-xl font-normal font-['Outfit']">
              {" "}
            </span>
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              Bu kadran, kullanıcıların görüşmeler, anketler veya geri bildirim
              oturumları sırasında söylediklerini içerir:{" "}
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcılar ihtiyaçları ve istekleri hakkında neler söylüyor?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Duygu veya beklentilerini gösteren belirli ifadeler var mı?
        </div>
      </div>
      <br />

      <div className="flex flex-wrap">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          2
        </div>
        <div className="flex-1 ml-4">
          <div className="min-h-[23px]">
            <span className="text-[#797979] text-xl font-semibold font-['Outfit']">
              Düşündü:
            </span>
            <span className="text-[#797979] text-xl font-normal font-['Outfit']">
              {" "}
            </span>
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              Bu kadranda, kullanıcıların muhtemelen düşündüğü ama
              seslendirmediği şeylere odaklanılır. Bu odaklanma, kullanıcının
              davranışlarını şekillendiren temel inançları ve varsayımları
              tanımlamaya yardımcı olur:
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcıların endişeleri neler?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Karar verme süreçlerini etkileyen faktörler neler?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kararlarını etkileyen çelişkili düşünceler neler?
        </div>
      </div>
      <br />

      <div className="flex flex-wrap">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          3
        </div>
        <div className="flex-1 ml-4">
          <div className="min-h-[23px]">
            <span className="text-[#797979] text-xl font-semibold font-['Outfit']">
              Yaptı / Davrandı:
            </span>
            <span className="text-[#797979] text-xl font-normal font-['Outfit']">
              {" "}
            </span>
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              Bu kadranda, kullanıcıların gerçekleştirdiği eylemler ve
              davranışlar belgelenir. Ürünlerle, sistemlerle veya hizmetlerle
              nasıl etkileşimde bulunduklarını ve hedeflerine ulaşmak için
              attıkları adımları içerir:
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcıların davranışlarında belirgin bir kalıp var mı?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcılar hangi eylemleri gerçekleştiriyor?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcılar genellikle zorlukları nasıl aşmaya çalışıyor?
        </div>
      </div>
      <br />

      <div className="flex flex-wrap">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          4
        </div>
        <div className="flex-1 ml-4">
          <div className="min-h-[23px]">
            <span className="text-[#797979] text-xl font-semibold font-['Outfit']">
              Hissetti:
            </span>
            <span className="text-[#797979] text-xl font-normal font-['Outfit']">
              {" "}
            </span>
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              Bu kadranda, kullanıcılar; ihtiyaçları ve etkileşimleri ile ilgili
              deneyimledikleri duyguları keşfeder. Duyguları anlamak, empati
              kurmak ve duygusal olarak anlamlı çözümler tasarlamak için bu
              kısım önemlidir:
            </span>
          </div>
        </div>
      </div>
      <br />

      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Kullanıcılar hangi duyguları ima veya ifade ediyor?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          İfade veya ima edilen duygular, eylemleri ve kararları nasıl
          etkiliyor?
        </div>
      </div>
      <div className="flex flex-wrap ml-10 items-start">
        <div className="w-[7px] h-[7px] bg-[#f39a44] rounded-full mt-2" />
        <div className="flex-1 ml-5 text-[#797979] text-base font-medium font-['Outfit']">
          Öne çıkan sevinç veya kafa karışıklığı anları neler?
        </div>
      </div>
      <br />

      <div className="w-full flex items-center">
        <div className="flex-1">
          <div className="w-full pr-10">
            <Stepper
              backChange={backPage}
              nextChange={changePage}
              nextPage={nextPage}
              activeStep={3}
              stepLimit={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
