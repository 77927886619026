import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-8 mx-auto px-4">
      <div className="w-full">
        <div className="text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="w-full text-[#797979] text-lg font-normal font-['Outfit']">
          SWOT (Güçlü-Zayıf-Fırsat-Tehdit) Analizi, firmanızın etkilediği ve
          firmanızı etkileyen koşulların sistematik olarak incelendiği bir
          yöntemdir. İnovasyon yetkinlik ve kültür ölçeği, müşteri ve problem
          analizi ve önceki Stratejik Plan Modülleri SWOT'unuz hakkında sizlere
          bilgi sağlar. Örneğin İç Çevre Analizi; güçlü ve zayıf yönleriniz,
          Pazar ve Çevre Analizleri ise fırsat ve tehditler hakkında sizlere
          bilgi sağlar.
          <br /> <br />
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gap-2 mb-4">
          <div className="w-5 h-5"></div>
          <div className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi başlayalım!
          </div>
        </div>
        <div className="w-full text-[#797979] text-lg font-normal font-['Outfit']">
          SWOT bileşenleri şunlardır:{" "}
        </div>
      </div>

      <div className="w-full">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Güçlü Yönler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Firma tarafından kontrol edilebilen, firmanın amaç ve hedeflerine
          ulaşırken yararlanabileceği, yüksek değer ürettiği ya da başarılı
          performans gösterdiği ve paydaşların firmanın olumlu içsel özellikleri
          olarak gördüğü hususlardır. Güçlü yönler sunulan benzersiz değer
          önerileri ve proje ekibinin kabiliyeti gibi somut ve soyut hususlar
          olabilir.
          <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Zayıf Yönler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Firmanın başarısını etkileyebilecek eksiklikleridir. Başka bir
          ifadeyle firmanın üstesinden gelmesi gereken ve rakiplerine göre
          kendisini geliştirmesi gereken olumsuz yönleridir. Zayıf yönlerin
          belirlenmesinde "neleri iyileştirmeliyiz?" sorusuna odaklanılması
          gerekir.
          <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Fırsatlar:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Firmanın kontrolü dışında ortaya çıkan ve firma için avantaj sağlaması
          muhtemel olan etken ya da durumlardır. Genellikle pazar ve çevre
          kaynaklıdır.
          <br /> <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Tehditler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Firma kontrolü dışında gerçekleşen, kurum için dezavantaj sağlaması
          muhtemel olan önlenmesi ya da sınırlandırılması gereken unsurlardır.
          Genellikle pazar ve çevre kaynaklıdır.
          <br />{" "}
        </span>
        <br />
        <div className="w-full text-[#797979] text-lg font-bold font-['Outfit']">
          Şimdi sizler için oluşturduğumuz SWOT matrisi ile ürün ve hizmet
          gruplarınızın her biri için 5 alanda nasıl bir fırsat veya tehdit
          alanı ile karşı karşıya olduğunuzu belirleyin!
        </div>
      </div>

      <div className="w-full flex items-center">
        <div className="w-full lg:w-[600px] flex-1">
          <div className="w-full pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
