import React from "react";
import Pencil from "../../../../../icons/pencil.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Threatening from "../../../../../icons/threatening.svg";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function InformationRootCause({ changePage, backPage }) {
  return (
    <div className="px-4">
      <div id="page-content-container">
        <div className="max-w-[1117px] text-[#797979] text-lg font-normal font-['Outfit']">
          Unutmayın tasarım odaklı düşünme süreci hep döngüseldir! Problemlerin
          tekrar tekrar tanımlanması, yeni problem ve fırsat alanlarının
          belirlenmesi gerekir. Bunun için sonraki aşamalarda da yeni problem
          alanlarının ortaya çıkabileceğini göreceksiniz.
          <br />
          <br />
          En öncelikli gördüğünüz problemi ve etkilediği kullanıcı kitlesini
          belirlediniz. Sebep-Sonuç İlişkisi kurarak ve Kök Neden Analizi
          yaparak probleminize sebep olan faktörleri ve bunun sonucunu ortaya
          koyabilirsiniz. Örnekte probleminiz şu şekildeydi: 3-10 yaş çocukların
          otel konaklamalarında özelleştirilmiş mimari tasarım projeleri nasıl
          yapabiliriz? Bunun sonucu veya etkisi çocuklu ailelerin otellerimizi
          daha az tercih etmesi olabilir. Peki bunlara sebep olan ana faktörler
          neler? Bunları belirleyerek kök sebeplere ininiz.
        </div>

        <br />
        <div className="max-w-[1117px] p-[5px] bg-[#fef7eb] rounded-[11px] mt-5 flex items-center">
          <img className="p-4" src={Threatening} alt="Threatening Icon" />
          <div className="flex-1">
            <div className="text-[#797979] text-lg font-normal font-['Outfit']">
              Örneğin çocuklu aileler neden bizim otelleri tercih etmiyor? Buna
              sebep ana faktörler “İnsan” veya “Yönetimsel” olabilir. Yeterince
              bu konuda eğitilmiş insan kaynağınız olmayabilir, çocuklarla
              ilgilenen çalışanlarınızın motivasyon eksiklikleri olabilir. Yine
              neden diye sorarak eğitilmiş insan kaynağı ve çalışanların
              motivasyon eksikliklerinin kök nedenlerine ininiz. Diğer taraftan
              probleminizin oluşmasına sebep veren “Malzeme & Ekipman”
              faktörleri de olabilir. Çocukların ilgisini çekecek yeterince oyun
              ekipmanlarına sahip olmayabilirsiniz. Yine bu sebebin de kök
              sebeplerine neden diye sorarak ininiz. En fazla 5 kez neden diye
              sorarak kök nedenlere inip tabloyu doldurabilirsiniz.
            </div>
          </div>
        </div>
        <br />
        <div className="max-w-[1136px]">
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Şimdi tabloyu probleminizi ve probleminizin etkisi/sonucunu yazarak
            başlayın. Daha sonra sizlere standart sunulan ana faktörlerden bir
            veya birkaçını seçerek probleminize sebep olan ana faktörleri ve o
            faktörlere neden olan kök nedenleri belirleyiniz
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            . Örnekte problemimiz veya ihtiyacımız şu şekildeydi: “3-10 yaş
            çocukların otel konaklamalarında özelleştirilmiş mimari tasarım
            proje çizimlerini ve uygulamalarını nasıl yapabiliriz?”
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            Bunun üst satıra yazacağınız görünürde etkisi/sonucu, çocuklu
            ailelerin otelinizi pek tercih etmemesi olabilir. Buna sebep ana
            faktörlerden biri olarak “İnsan”ı seçtiğinizi ve “Personelin
            çocukların ilgisini yeterince çekememe” diye bir alt neden
            oluşturduğunuzu varsayalım. Bunun bir alt nedeni “eğitim eksikliği”
            onun da bir alt nedeni “İK’nın bir eğitim politikasının olmaması”
            olabilir. Bu şekilde ana problemle ilişkili ilgili gördüğünüz tüm
            alt problemleri/ihtiyaç alanlarını listeleyin.{" "}
          </span>
        </div>
      </div>
      <div className="w-full flex items-center">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={3}
              stepLimit={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
