import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const passwordResetService = {
  async requestPasswordReset(email) {
    try {
      const response = await axios.post(
        `${API_URL}/identity/password-reset-requests`,
        { email },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async resetPassword(token, newPassword) {
    try {
      const response = await axios.put(
        `${API_URL}/account/password/reset`,
        { token, newPassword },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
