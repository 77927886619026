import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const howCanService = {
  async getHowCanAnswers(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/customer-problem-analysis/empathy/how-to-do-it/${projectId}/answers`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async saveHowCanAnswers(projectId, answers) {
    try {
      const data = {
        projectId: projectId,
        answers: answers,
      };

      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/empathy/how-to-do-it/answers`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
