import React, { useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import HowCan from "./Modules/HowCan";
import InformationWrittenBrainstorming from "./Informations/InformationWrittenBrainstorming";
import WrittenBrainstorming from "./Modules/WrittenBrainstorming";
import Project from "../Project";

export default function IdeaGenerationAndEvaluation() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Fikir Üretme ve Değerlendirme",
      subTexts: ["Projeler"],
    },
    {
      title: "Fikir Üretme ve Değerlendirme",
      subTexts: ["Projeler", "Nasıl Yapabiliriz?"],
    },
    {
      title: "Fikir Üretme ve Değerlendirme",
      subTexts: ["Projeler", "Nasıl Yapabiliriz?", "Bilgilendirme"],
    },
    {
      title: "Fikir Üretme ve Değerlendirme",
      subTexts: [
        "Projeler",
        "Nasıl Yapabiliriz?",
        "Bilgilendirme",
        "Yazılı Beyin Fırtınası",
      ],
    },
  ]);

  const changeProject = (id, name) => {
    setProjectId(id);
    setProjectName(name);
  };

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Project
              subId={searchParams.get("subId")}
              changePage={() => setPageState(1)}
              pageState={pageState}
              chageProject={changeProject}
              changeProjectName={(projectName) => setProjectName(projectName)} // Ensure this is correctly passed
            />
          )}
          {pageState === 1 && (
            <HowCan
              projectId={projectId}
              subId={searchParams.get("subId")}
              backPage={() => setPageState(0)}
              changePage={() => setPageState(2)}
              pageState={pageState}
            />
          )}

          {pageState === 2 && (
            <InformationWrittenBrainstorming
              subId={searchParams.get("subId")}
              backPage={() => setPageState(1)}
              projectId={projectId}
              changePage={() => setPageState(3)}
              pageState={pageState}
            />
          )}

          {pageState === 3 && (
            <WrittenBrainstorming
              subId={searchParams.get("subId")}
              projectId={projectId}
              backPage={() => setPageState(2)}
              changePage={() => setPageState(0)}
              pageState={pageState}
              projectName={projectName}
            />
          )}
        </div>
      </div>
    </>
  );
}
