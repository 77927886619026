import axios from "axios";
import { handleApiError } from "../utils/errorHandler";

const apiUrl = "https://panel.kurumsalgirisimpusulasi.com/api";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export async function subModuleQuestions(subModuleId) {
  try {
    const response = await axios.get(
      apiUrl + "/submodules/survey/" + subModuleId + "/questions",
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function subModuleSurveyAnswers(subModuleId, answers) {
  try {
    const response = await axios.post(
      apiUrl + "/submodules/survey/" + subModuleId + "/answers",
      answers,
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function subModuleCreateNewQuestions(subModuleId, question) {
  try {
    const response = await axios.post(
      apiUrl + "/submodules/survey/" + subModuleId + "/questions",
      question,
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function subModuleDeleteQuestion(subModuleId, questionId) {
  try {
    const response = await axios.delete(
      apiUrl + "/submodules/survey/" + subModuleId + "/questions/" + questionId,
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function subModuleResult(subModuleId) {
  try {
    const response = await axios.get(
      apiUrl + "/submodules/survey/" + subModuleId + "/results",
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function subModuleSingleAnswer(subModuleId, questionId, answer) {
  try {
    const response = await axios.post(
      apiUrl +
        "/submodules/survey/" +
        subModuleId +
        "/questions/" +
        questionId +
        "/answer",
      answer,
      {
        headers: headers,
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    throw handleApiError(error);
  }
}
