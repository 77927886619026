import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import StrategicDirectionImage from "../../../../../images/strategic-direction.svg";

export default function InformationBasic({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-4 md:gap-8 mx-auto px-2 md:px-4">
      <div className="w-[1136px]">
        <span class="text-[#797979] text-lg font-semibold font-['Outfit']">
          OKRs hakkında temel bilmeniz gerekenler:
        </span>
        <span class="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Her şeyden önce OKRs hakkında net bir bilginiz yoksa bir uzmandan
          destek almanızı öneririz. Bununla birlikte temel bilmeniz gerekenleri
          şu şekilde özetleyebiliriz:
          <br />{" "}
        </span>
      </div>
      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            1
          </div>
        </div>
        <div className="flex-1">
          <div className="w-[1015px] h-[69px] text-[#797979] text-lg font-normal font-['Outfit']">
            Bir OKR döngüsü 3 ayda tamamlanmalıdır. Hedef ve teme sonuçlarınız 3
            ay içerisinde başarılabilmelidir. Bununla birlikte daha stratejik
            hedefler için bu süre artırılabilir ve OKR’larınızı alt dallara
            bölerek daha alt seviye hedef ve temel sonuçlar belirleyebilirsiniz.
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            2
          </div>
        </div>
        <div className="flex-1">
          <div className="w-[1015px] h-[46px] text-[#797979] text-lg font-normal font-['Outfit']">
            Bir hedef (O) altında 3-5 arası temel sonuç (KRs) belirlemeye gayret
            gösteriniz. Bu rakamların altına inmeyin veya üstüne çıkmayın.
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            3
          </div>
        </div>
        <div className="flex-1">
          <div className="w-[1015px] h-[29px] text-[#797979] text-lg font-normal font-['Outfit']">
            Herhangi bir zaman dilimi içerisinde bir birey/grup veya departmanın
            ilgileneceği hedef sayısı 5’i geçmemeli.
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            4
          </div>
        </div>
        <div className="flex-1">
          <div className="w-[1015px] h-[46px] text-[#797979] text-lg font-normal font-['Outfit']">
            Her bir temel sonucun (KR) başarılabilmesi için bir ekip gerekir ama
            muhakkak bir kişiyi işin sorumlusu olarak atayın. Daha fazla kişi
            atamayın.
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-8">
        <div className="flex-none">
          <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            5
          </div>
        </div>
        <div className="flex-1">
          <div className="w-[1015px] h-[87px] text-[#797979] text-lg font-normal font-['Outfit']">
            Her bir temel sonucun (KR) hedeflerde (O) olduğu gibi, SMART yapıyı
            desteklemesi gerektiğini unutmayın. Bununla birlikte spesifik bir
            ölçüm metriğiniz muhakkak cümle içinde yer alsın. Teme sonucunuz
            niceliksel bir ölçüm değeri atanacak yapıda değilse en azından
            tamamlanma oranı, evet/hayır, yapıldı/yapılıyor/yapılmadı gibi bir
            metriğiniz olsun. Böylelikle 3 aylık döngülerde ilerlemeleri
            ölçebilirsiniz.
          </div>
        </div>
      </div>

      <div className="w-full flex items-center">
        <div className="w-full lg:w-[600px] flex-1">
          <div className="w-full pr-2 md:pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
