import React from "react";

export default function Select({
  value,
  onChange,
  options,
  className = "",
  placeholder = "Seçiniz",
  disabled = false,
  size = "medium" // small, medium, large
}) {
  const heights = {
    small: "32px",
    medium: "42px",
    large: "52px"
  };

  const fontSizes = {
    small: "12px", 
    medium: "14px",
    large: "16px"
  };

  return (
    <div className="w-full">
      <select
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`
          w-full
          px-3
          border border-[#E5E7EB]
          rounded-lg
          outline-none
          transition-all
          ${className}
          ${disabled ? 'bg-[#f9fafb] cursor-not-allowed' : 'bg-white cursor-pointer'}
          hover:border-[#B8BCCA]
          focus:border-[#439DC6]
        `}
        style={{
          height: heights[size],
          fontSize: fontSizes[size]
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="hover:bg-[#F3F4F6]"
            style={{
              fontSize: fontSizes[size],
              padding: "8px"
            }}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
