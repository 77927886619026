import React, { useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import Project from "../Project";
import Information from "./Informations/Information";
import DeterminingValueInnovation from "./Modules/DeterminingValueInnovation";

export default function Index() {
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Değer İnovasyonu Belirleme",
      subTexts: ["Projeler"],
    },
    {
      title: "Değer İnovasyonu Belirleme",
      subTexts: ["Projeler", "Bilgilendirme"],
    },
    {
      title: "Değer İnovasyonu Belirleme",
      subTexts: ["Projeler", "Bilgilendirme", "Değer İnovasyonu"],
    },
  ]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const changePage = (newPage) => {
    if (newPage === 0) {
      resetSearchParams();
    }
    setPageState(newPage);
  };

  const changeProject = (id) => {
    setProjectId(id);
  };

  const changeProjectName = (name) => {
    setProjectName(name);
  };

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div className="w-[1200px] bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]">
          {pageState === 0 && (
            <Project
              changePage={changePage}
              chageProject={changeProject}
              changeProjectName={changeProjectName}
            />
          )}
          {pageState === 1 && (
            <Information
              changePage={() => setPageState(2)}
              backPage={() => setPageState(0)}
              projectId={projectId}
              projectName={projectName}
            />
          )}
          {pageState === 2 && (
            <DeterminingValueInnovation
              changePage={() => changePage(0)}
              backPage={() => setPageState(1)}
              nextPage={() => changePage(0)}
              projectId={projectId}
              projectName={projectName}
            />
          )}
        </div>
      </div>
    </>
  );
}
