import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import productService from "../../../services/productService";
import Stepper from "../../../components/Stepper/MainStepper";
import LoadingFullPage from "../../../components/LoadingFullPage/LoadingFullPage";

export default function Products({
  periodId,
  changePage,
  backPage,
  changeProductId,
  changeProductName,
}) {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [newProduct, setNewProduct] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProducts();
  }, [periodId]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await productService.getProductsByPeriod(periodId);
      setProducts(response);
    } catch (error) {
      toast.error("Ürünler yüklenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewProduct("");
  };

  const handleEditOpen = (product) => {
    setSelectedProduct(product);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedProduct(null);
  };

  const handleAddProduct = async () => {
    setLoading(true);
    try {
      await productService.createProduct({
        name: newProduct,
        periodId: periodId,
      });
      await fetchProducts();
      toast.success("Yeni ürün başarıyla eklendi");
      handleClose();
    } catch (error) {
      toast.error("Ürün eklenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProduct = async () => {
    setLoading(true);
    try {
      await productService.updateProduct(selectedProduct.id, {
        name: selectedProduct.name,
        periodId: periodId,
      });
      await fetchProducts();
      toast.success("Ürün başarıyla güncellendi");
      handleEditClose();
    } catch (error) {
      toast.error("Ürün güncellenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    try {
      await productService.deleteProduct(productId);
      await fetchProducts();
      toast.success("Ürün başarıyla silindi");
    } catch (error) {
      toast.error("Ürün silinirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handleProductClick = (product) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("productId", product.id);
    searchParams.set("productName", product.name);
    changeProductId(product.id);
    changeProductName(product.name);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams}`
    );
    changePage(3);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <LoadingFullPage state={loading}>
      <div className="min-h-screen bg-gray-50/50 font-['Outfit']">
        {/* Header Container */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto p-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-4">
                <button
                  onClick={() => backPage()}
                  className="p-2 hover:bg-gray-100 rounded-xl transition-colors"
                  title="Geri Dön"
                >
                  <svg
                    className="w-6 h-6 text-gray-600"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 12H5M5 12L12 19M5 12L12 5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <div className="p-3 bg-gradient-to-br from-[#439DC6] to-[#3B89B6] rounded-2xl shadow-lg shadow-[#439DC6]/20">
                  <svg
                    className="w-8 h-8 text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div>
                  <h1 className="text-3xl font-bold text-gray-800">
                    Ürün/Hizmet Grubu
                  </h1>
                  <p className="text-gray-500 text-sm">
                    Tüm ürünlerinizi buradan yönetin
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="relative">
                  <input
                    type="search"
                    placeholder="Ürün ara..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-11 pr-4 py-3 rounded-xl border border-gray-200 w-64 focus:outline-none focus:ring-2 focus:ring-[#439DC6]/20 transition-all"
                  />
                  <svg
                    className="absolute left-3.5 top-3.5 text-gray-400 w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                <button
                  onClick={handleClickOpen}
                  className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 7V17M7 12H17"
                      stroke="currentColor"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  Yeni Ürün/Hizmet Grubu
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Products Grid */}
        <div className="max-w-7xl mx-auto p-8">
          {products.length === 0 ? (
            <div className="text-center py-12">
              <div className="mb-4">
                <svg
                  className="w-16 h-16 text-gray-300 mx-auto"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-gray-600">
                Henüz hiç ürün eklenmemiş
              </h3>
              <p className="text-gray-400 mt-1">
                Yeni ürün eklemek için yukarıdaki "Yeni Ürün" butonunu
                kullanabilirsiniz
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProducts.map((product) => (
                <div
                  key={product.id}
                  className="bg-white rounded-2xl border border-gray-200 p-6 group hover:border-[#439DC6] transition-all cursor-pointer"
                  onClick={() => handleProductClick(product)}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-3 justify-center text-center">
                        <div className="p-3 bg-[#439DC6]/10 rounded-xl">
                          <svg
                            className="w-6 h-6 text-[#439DC6]"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <svg
                              className="w-6 h-6 text-[#439DC6]"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
                                stroke="currentColor"
                                strokeWidth="2"
                              />
                            </svg>
                          </svg>
                        </div>

                        <div className="text-lg font-semibold text-gray-800 mb-4">
                          {product.name}
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditOpen(product);
                        }}
                        className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-[#439DC6] hover:bg-[#439DC6]/10 p-2.5 rounded-xl transition-all duration-200"
                        title="Ürünü Düzenle"
                      >
                        <svg
                          className="w-5 h-5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteProduct(product.id);
                        }}
                        className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-[#EF8024] hover:bg-[#EF8024]/10 p-2.5 rounded-xl transition-all duration-200"
                        title="Ürünü Sil"
                      >
                        <svg
                          className="w-5 h-5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H7V4Z"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M20 6H4V8H20V6Z"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M6 8L7.47405 19.4233C7.64151 20.9187 8.89851 22 10.4066 22H13.5934C15.1015 22 16.3585 20.9187 16.526 19.4233L18 8"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Add Product Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            className: "rounded-2xl font-['Outfit']",
          }}
        >
          <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-[#439DC6]/10 rounded-xl">
                <svg
                  className="w-6 h-6 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 7V17M7 12H17"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              Yeni Ürün Oluştur
            </div>
          </DialogTitle>
          <DialogContent className="px-6 pt-4">
            <input
              type="text"
              placeholder="Ürün adını giriniz"
              value={newProduct}
              onChange={(e) => setNewProduct(e.target.value)}
              className="w-full p-3 border rounded-xl mt-4 focus:outline-none focus:ring-2 focus:ring-[#439DC6]/20"
            />
          </DialogContent>
          <DialogActions className="p-6">
            <button
              onClick={handleClose}
              className="px-5 py-2.5 rounded-xl text-gray-500 hover:bg-gray-50 transition-colors font-medium"
            >
              İptal
            </button>
            <button
              onClick={handleAddProduct}
              className="px-5 py-2.5 rounded-xl bg-[#439DC6] text-white transition-all hover:shadow-lg hover:shadow-[#439DC6]/20 font-medium"
            >
              Oluştur
            </button>
          </DialogActions>
        </Dialog>

        {/* Edit Product Dialog */}
        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          PaperProps={{
            className: "rounded-2xl font-['Outfit']",
          }}
        >
          <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-[#439DC6]/10 rounded-xl">
                <svg
                  className="w-6 h-6 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              Ürün Düzenle
            </div>
          </DialogTitle>
          <DialogContent className="px-6 pt-4">
            <input
              type="text"
              placeholder="Ürün adını giriniz"
              value={selectedProduct?.name || ""}
              onChange={(e) =>
                setSelectedProduct({ ...selectedProduct, name: e.target.value })
              }
              className="w-full p-3 border rounded-xl mt-4 focus:outline-none focus:ring-2 focus:ring-[#439DC6]/20"
            />
          </DialogContent>
          <DialogActions className="p-6">
            <button
              onClick={handleEditClose}
              className="px-5 py-2.5 rounded-xl text-gray-500 hover:bg-gray-50 transition-colors font-medium"
            >
              İptal
            </button>
            <button
              onClick={handleUpdateProduct}
              className="px-5 py-2.5 rounded-xl bg-[#439DC6] text-white transition-all hover:shadow-lg hover:shadow-[#439DC6]/20 font-medium"
            >
              Güncelle
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </LoadingFullPage>
  );
}
