import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-8 mx-auto px-4">
      <div className="w-full">
        <div className="text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <div className="mt-[39px] text-[#797979] text-lg font-normal font-['Outfit']">
          Strateji ve İnovasyon iç içe kavramlardır. Başarılı inovasyonların
          önemli bir bölümü doğru bir stratejiden gelir. Bu yüzden her firma
          Stratejik Planını periyodik dönemler için oluşturmalı ve
          güncellemelidir. Örneğin 2025-2028 yıllarını kapsayan bir Stratejik
          Planınız olabilir. Strateji, bugün neredeyiz, yarın nerede olmak
          istiyoruz ve oraya nasıl varabiliriz sorularına cevap arar. Bu yüzden
          firmalar mevcut durumlarını analiz etmek, ihtiyaç ve gelişim
          alanlarını belirlemek, kendilerine bu doğrultuda amaç ve hedefler
          koymak ve kendilerini bu amaç ve hedeflere götürecek proje ve
          faaliyetleri belirlemeleri gerekir. <br /> <br />
          Tüm bu alanları koordine edebilmek için Stratejik Planlar dört aşamada
          yürütülür. Bunlar (Mirze, 2014): 1. Stratejik Analiz, 2. Stratejik
          Yönlendirme, 3. Projelendirme ve 4. Stratejik İzleme ve Kontrol.
          Kurumsal Girişim Pusulası ile bu aşamalarda sizlere yardımcı olarak
          Stratejik Planınızı oluşturmanıza yardımcı olacağız.{" "}
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gap-2 mb-4">
          <div className="w-5 h-5"></div>
          <div className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi başlayalım!
          </div>
        </div>

        <div className="text-[#797979] text-lg font-normal font-['Outfit']">
          İlk olarak Stratejik Analiz ile başlıyoruz. Bu aşamada, Paydaş Analiz,
          İç Çevre Analizi, Pazar Analizi, Dış Çevre Analizi ve SWOT Analizi
          çalışmalarını yürüteceksiniz. Bu modülde Paydaş Analizi'ni ele
          alıyoruz. <br /> <br />
          Bir işin, projenin gidişatını etkileyen veya ondan etkilenen tüm
          birey, grup ve organizasyonlar sizin paydaşınızdır. Bu anlamda
          çalışanlarınız, tedarikçileriniz, kullanıcılarınız, müşterileriniz,
          bayileriniz, resmi kurum ve kuruluşlar hepsi sizin bir paydaşınız
          olabilir. Bunları detaylı belirlemek ve bunlarla birlikte
          ihtiyaç/beklentilerin karşılanmamış gizli paydaşlarınızı tespit etmek
          oldukça önemlidir. İnovasyonlar, stratejiler ve projeler genellikle
          ihtiyacı tam olarak karşılanmamış bir paydaş grubu için yürütülür. Bu
          anlamda paydaşlarınızı iyi analiz etmek, yeni paydaşlar keşfetmek ve
          onların karşılanmamış ihtiyaçlarını tespit edebilmek oldukça
          önemlidir. Bunun için paydaş matrisini doldurabilirsiniz. <br />{" "}
          <br />
          Paydaş Analizini yürütürken, Paydaş Matrisinde doldurmanız gereken
          tavsiye alanlar sırayla şu şekildedir:
          <br /> <br />
          <span className="font-semibold">Ürün/Hizmet Grubu:</span> Ürün ve
          hizmet gruplarınızı ayrıştırarak paydaşlarınızı daha net
          belirleyebilirsiniz. Hangi ürünleri üretiyor ve pazarlıyorsunuz?
          Ayrıştırmada tereddüt yaşıyorsanız şu yolu kullanabilirsiniz: Eğer bu
          ürün/hizmet gruplarının rakipleri farklı ise farklı iş/faaliyet
          alanındadır. Eğer rakipler aynı ise bu sefer pazar paylarına bakılır.
          Eğer ürün/hizmet grupları için pazar payları ve dolayısı ile göreceli
          pazar payları rakiplerin birbirine yakınsa hizmet gruplarının aynı
          iş/faaliyet alanı içinde olduğu söylenebilir. Yani böylelikle iki
          ürün/hizmet bileşeninizi aynı ürün/hizmet grubuna dahil edebilirsiniz.{" "}
          <br /> <br />
          <span className="font-semibold">Ürün/Hizmet Bölgesi:</span>{" "}
          Ürün/Hizmet alanınızın bölgesi ne? Yurtiçi? Yurtdışı? Veya daha
          spesifik ayrıntılı bölge tanımlamaları gerekebilir. Çünkü her bölge ve
          yörenin farklı beklentileri dolayısıyla farklı beklentileri olabilir.{" "}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8 lg:gap-16">
        <div className="w-full lg:w-[516px] text-[#797979] text-lg font-['Outfit']">
          <div>
            <span className="font-semibold">Paydaş Adı:</span> Paydaş adı
            spesifik bir isim olabileceği gibi bir grup, kurum, dernek de
            olabilir. <br />
            <br />
          </div>
          <div>
            <span className="font-semibold">Önem Derecesi:</span> Firmanızın,
            paydaşın beklenti ve taleplerinin karşılanması konusuna verdiği
            değeri ifade eder. 1-5 arasında bir derece veya Düşük-Yüksek gibi
            değerlendirme skoru belirleyebilirsiniz. <br />
          </div>
          <div>
            <span className="font-semibold">Etki Derecesi:</span> Firmanızın
            faaliyet ve hizmetleriyle paydaşı etkilemesi ile paydaşın alacağı
            kararlarla işletmeyi etkileme gücünü ifade eder. 1-5 arasında bir
            derece veya Zayıf-Güçlü gibi bir değerlendirme skoru
            belirleyebilirsiniz. <br />
            <br />
          </div>
          <div>
            <span className="font-semibold">Önceliği:</span> Etki ve önem
            derecesine göre paydaşın sizin için önceliğini belirleyebilirsiniz.
            Düşük-Orta veya Yüksek olabilir. <br />
            <br />
          </div>
          <div>
            <span className="font-semibold">Değerlendirme:</span> Etki-Önem
            matrisine göre bir değerlendirme oluşturabilirsiniz. Örneğin önemi
            düşük etkisi güçlü paydaşlar sizin için düzenli bilgilendirmeniz
            gereken paydaş gruplarıdır.
          </div>
        </div>

        <div className="w-full lg:w-[485px]">
          <img
            src={StakeholderAnalysisImage}
            alt="Stakeholder Analysis"
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
