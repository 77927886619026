import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Information({ nextChange, backChange }) {
  return (
    <div className="max-w-[1274px] mx-auto px-4">
      <div className="text-black text-lg font-semibold font-['Outfit'] mb-4">
        Değerli Firma Yetkilimiz,
      </div>
      <div className="text-[#797979] text-lg font-normal font-['Outfit'] mb-4">
        İhracat niyetinizi, potansiyelinizi ve hedef pazarları belirlediniz.
        Şimdi sıra geldi o pazardaki potanisyel müşteriler ile iletişime
        geçmeye! Bunun için pek çok ücretsiz ve ücretli veri kaynakları
        mevcuttur:{" "}
      </div>
      <div className="text-[#797979] text-lg font-semibold font-['Outfit'] mb-6">
        Haydi gelin şimdi bu veri kaynaklarını inceleyelim!{" "}
      </div>

      <div className="flex flex-col space-y-6">
        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            1
          </div>
          <div className="flex-1 ml-4">
            <div className="flex flex-col">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                B2B Pazar Yerleri:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                B2B platformları, satıcıları ve alıcıları bir araya getiren
                çevrimiçi platformlardır. Bu platformlar, yurtdışındaki
                potansiyel müşterilere ulaşmanın etkili bir yoludur. Alibaba,
                Made-in-China, IndiaMART.com, Europages ve Tradewheel B2B
                platformlara örneklerdir.{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            2
          </div>
          <div className="flex-1 ml-4">
            <div className="flex flex-col">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Açık Veri Kaynakları:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Trademap, Apollo.io, Hunter.io, LinkedIn Sales Navigator ve
                Zoominfo hem potansiyel rakipleriniz hem potansiyel
                müşterileriniz için başvurabileceğiniz önemli platformlarıdır.{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            3
          </div>
          <div className="flex-1 ml-4">
            <div className="flex flex-col">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Sosyal Medya Platformları:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Sosyal medya hala günümüzde bu konuda en etkin veri
                kaynaklarından biridir. Başta Instagram, Linkedin ve Twitter
                olmak üzere potansiyel müşterilerinize sosyal medya kanalları
                üzerinden ulaşabilirsiniz.{" "}
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            4
          </div>
          <div className="flex-1 ml-4">
            <div className="flex flex-col">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Dijital Pazarlama Stratejileri:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Dijital pazarlama, yurtdışındaki potansiyel müşterilere
                ulaşmanın etkili bir yoludur. Arama Motoru Optimizasyonu (SEO),
                Arama Motoru Reklamcılığı (SEM), sosyal medya pazarlaması ve
                içerik pazarlaması gibi dijital pazarlama kanallarını potansiyel
                müşterilerinize ulaşmak için kullanabilirsiniz.
              </span>
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            5
          </div>
          <div className="flex-1 ml-4">
            <div className="flex flex-col">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                Serbest Çalışan (Freelance) Pazar Yerleri:{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                Bu pazar yerleri serbest çalışanlar için bir platform olup dünya
                genelinde potansiyel müşterilere erişim sağlamanın da bir yolu
                olabilir. Upwork, Freelancer ve Fiverr bu konuda bazı örnek
                platformlardır.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <Stepper
          nextChange={nextChange}
          backChange={backChange}
          activeStep={1}
          stepLimit={1}
        />
      </div>
    </div>
  );
}
