import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function InformationExploratoryInterview({
  changePage,
  backPage,
}) {
  return (
    <div className="p-4">
      <div id="page-content-container">
        <div className="text-[#797979] text-[2rem] font-bold font-['Outfit']">
          Keşifsel Mülakatın Yürütülmesi
        </div>
        <br />
        <br />
        {[...Array(6)].map((_, index) => (
          <div className="flex mb-4" key={index}>
            <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
              {index + 1}
            </div>
            <div className="ml-4 flex-1">
              <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
                {getStepTitle(index)}{" "}
              </span>
              <span className="text-[#797979] text-lg font-normal font-['Outfit']">
                {getStepContent(index)}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex items-center ">
        <div className="flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={7}
              stepLimit={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const getStepTitle = (index) => {
  const titles = [
    "Giriş - Isınma - Kendinizi ve Amacınızı Tanıtın:",
    "Güncel Uygulamaları ve Bağlamı Anlama – Genel Sorular:",
    "Duygusal Yolculuk ve Deneyim – Deneyim Soruları:",
    "Ağrı Noktalarını ve Zorlukları Belirleme – Spesifik Sorular:",
    "Kullanıcı Geri Bildirimi ve Önerileri – Meta Düzey Soruları:",
    "Gelecekteki Beklentiler – Temenni Soruları:",
  ];
  return titles[index];
};

const getStepContent = (index) => {
  const contents = [
    "Merhaba, ben [Adınız]. Bugün benimle konuşmak için zaman ayırdığınız için teşekkür ederim. Burada [Ürününüzle ilgili genel aktiviteyi tanımlayın] ve deneyimlerinizi, ihtiyaçlarınızı ve karşılaştığınız zorlukları anlamak ve öğrenmek için bulunuyorum. Doğru veya yanlış diye bir cevap yoktur ve aklınıza gelen her şeyi paylaşmaktan çekinmeyin.",
    "İlişki kurmak için sorunla ilgili hafif, tehdit edici olmayan sorularla başlayın. Örneğin: Bana kendinizden ve ne yaptıklarınızdan biraz bahsedebilir misiniz? Gününüzün çoğunu nasıl geçiriyorsunuz? [Ürün/hizmet] sizin için ne ifade ediyor? [Ürün/hizmet]'i ne sıklıkla kullanıyorsunuz? Ne kadar süreyle kullanıyorsunuz? [Ürün/hizmet]'i kullandığınız tipik bir günü bana anlatabilir misiniz? [Ürün/hizmet]'i kullandığınızda genellikle nerede oluyorsunuz? Ortam nasıl?",
    "[Ürün/hizmet] kullanmadan önce, kullanırken ve kullandıktan sonra nasıl hissediyorsunuz? Olumlu Deneyimler: [Ürün/hizmet] kullanırken kendinizi mutlu veya tatmin olmuş hissettiğiniz bir zamanı anlatabilir misiniz? Bunu özel kılan neydi? Olumsuz Deneyimler: [Ürün/hizmet] kullanırken yaşadığınız hayal kırıkları nelerdir? Hayal Kırıklıklarının Etkisi: Bahsettiğiniz hayal kırıklıkları [Ürün/hizmet] ile ilgili genel deneyiminizi nasıl etkiliyor?",
    "İhtiyaçlar ve Hedefler: [Ürün/hizmet] kullanırken neyi başarmaya çalışıyorsunuz? Karşılanmayan İhtiyaçlar: [Ürün/hizmet]in şu anda yapamadığı ancak yapmasını istediğiniz bir şey var mı? Zorluklar: [Ürünle ilgili etkinlik] sırasında karşılaştığınız en büyük zorluklardan bazıları nelerdir? Belirli Olaylar: [Ürün/hizmet] kullanırken hayal kırıklığına uğradığınız yakın bir zamanı hatırlayabilir misiniz? Ne oldu? Çözüm Yolları: Sorunlarla karşılaştığınızda kullandığınız herhangi bir çözüm yolunuz veya geçici çözümünüz var mı?",
    "[Ürün/hizmet] hakkında bir şeyi değiştirebilseydiniz, bu ne olurdu? [Ürün/hizmet] istediğiniz gibi olursa ne olur?",
    "Gelecekteki İhtiyaçlar: İhtiyaçlarınızın gelecekte nasıl değiştiğini görüyorsunuz? Uyarlama: Bu gelecekteki ihtiyaçları karşılamak için [ürün/hizmet]in nasıl uyarlanması gerektiğini düşünüyorsunuz?",
  ];
  return contents[index];
};
