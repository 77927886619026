import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../../../../components/Header/Header";
import Periods from "../Periods";
import Products from "../Products";
import Information from "./Informations/Information";
import EnvironmentalAnalysisTable from "./Modules/EnvironmentalAnalysisTable";

export default function EnvironmentalAnalysis() {
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [periodId, setPeriodId] = useState(null);
  const [periodName, setPeriodName] = useState(null);
  const [productId, setProductId] = useState(null);
  const [productName, setProductName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Dış Çevre Analizi",
      subTexts: ["Dönem Seçimi"],
    },
    {
      title: "Dış Çevre Analizi",
      subTexts: ["Dönem Seçimi", "Ürün Seçimi"],
    },
    {
      title: "Dış Çevre Analizi",
      subTexts: ["Dönem Seçimi", "Ürün Seçimi", "Bilgilendirme"],
    },
    {
      title: "Dış Çevre Analizi",
      subTexts: [
        "Dönem Seçimi",
        "Ürün Seçimi",
        "Bilgilendirme",
        "Çevre Analizi",
      ],
    },
  ]);

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div className="w-[1200px] bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]">
          {pageState === 0 && (
            <Periods
              changePage={() => setPageState(1)}
              pageState={pageState}
              changePeriod={(periodId) => setPeriodId(periodId)}
              changePeriodName={(periodName) => setPeriodName(periodName)}
            />
          )}
          {pageState === 1 && (
            <Products
              changePage={() => setPageState(2)}
              backPage={() => setPageState(0)}
              periodId={periodId}
              changeProductId={(productId) => setProductId(productId)}
              changeProductName={(productName) => setProductName(productName)}
            />
          )}
          {pageState === 2 && (
            <Information
              changePage={() => setPageState(3)}
              backPage={() => setPageState(1)}
              changePeriodName={(periodName) => setPeriodName(periodName)}
            />
          )}
          {pageState === 3 && (
            <EnvironmentalAnalysisTable
              changePage={() => setPageState(0)}
              backPage={() => setPageState(1)}
              projectId={productId}
              changePeriodName={(periodName) => setPeriodName(periodName)}
              periodId={periodId}
              productId={productId}
              productName={productName}
              periodName={periodName}
            />
          )}
        </div>
      </div>
    </>
  );
}
