import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { periodService } from "../../../services/periodService";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../components/LoadingFullPage/LoadingFullPage";

export default function Periods({
  subId,
  changePage,
  pageState,
  changePeriod,
  changePeriodName,
}) {
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [newPeriod, setNewPeriod] = useState({
    startYear: new Date().getFullYear(),
    startMonth: 1,
    endYear: new Date().getFullYear(),
    endMonth: 12,
  });

  const months = [
    { value: 1, label: "Ocak" },
    { value: 2, label: "Şubat" },
    { value: 3, label: "Mart" },
    { value: 4, label: "Nisan" },
    { value: 5, label: "Mayıs" },
    { value: 6, label: "Haziran" },
    { value: 7, label: "Temmuz" },
    { value: 8, label: "Ağustos" },
    { value: 9, label: "Eylül" },
    { value: 10, label: "Ekim" },
    { value: 11, label: "Kasım" },
    { value: 12, label: "Aralık" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = months[date.getMonth()].label;
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const createPeriodTitle = (startDate, endDate) => {
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
    return `${startYear}-${endYear} Dönemi`;
  };

  useEffect(() => {
    const fetchPeriods = async () => {
      try {
        setLoading(true);
        const response = await periodService.getPeriods();
        const formattedPeriods = response.map((period) => ({
          id: period.id,
          title: createPeriodTitle(period.startDate, period.endDate),
          startDate: formatDate(period.startDate),
          endDate: formatDate(period.endDate),
        }));
        setPeriods(formattedPeriods);
      } catch (error) {
        toast.error("Dönemler yüklenirken bir hata oluştu");
      } finally {
        setLoading(false);
      }
    };

    fetchPeriods();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewPeriod({
      startYear: new Date().getFullYear(),
      startMonth: 1,
      endYear: new Date().getFullYear(),
      endMonth: 12,
    });
  };

  const handleEditOpen = (period) => {
    setSelectedPeriod(period);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setSelectedPeriod(null);
  };

  const handleAddPeriod = async () => {
    try {
      setLoading(true);
      const formattedStartDate = `${newPeriod.startYear}-${String(
        newPeriod.startMonth
      ).padStart(2, "0")}-15`;
      const formattedEndDate = `${newPeriod.endYear}-${String(
        newPeriod.endMonth
      ).padStart(2, "0")}-01`;

      await periodService.createPeriod({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });

      const response = await periodService.getPeriods();
      const formattedPeriods = response.map((period) => ({
        id: period.id,
        title: createPeriodTitle(period.startDate, period.endDate),
        startDate: formatDate(period.startDate),
        endDate: formatDate(period.endDate),
      }));
      setPeriods(formattedPeriods);
      toast.success("Yeni dönem başarıyla eklendi");
      handleClose();
    } catch (error) {
      toast.error("Dönem eklenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handlePeriodClick = (period) => {
    changePeriod(period.id);
    changePeriodName(period.title);
    changePage();
  };

  const handleDeletePeriod = async (periodId) => {
    try {
      setLoading(true);
      await periodService.deletePeriod(periodId);
      const response = await periodService.getPeriods();
      const formattedPeriods = response.map((period) => ({
        id: period.id,
        title: createPeriodTitle(period.startDate, period.endDate),
        startDate: formatDate(period.startDate),
        endDate: formatDate(period.endDate),
      }));
      setPeriods(formattedPeriods);
      toast.success("Dönem başarıyla silindi");
    } catch (error) {
      toast.error("Dönem silinirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const handleEditPeriod = async () => {
    try {
      setLoading(true);
      const formattedStartDate = `${selectedPeriod.startYear}-${String(
        selectedPeriod.startMonth
      ).padStart(2, "0")}-15`;
      const formattedEndDate = `${selectedPeriod.endYear}-${String(
        selectedPeriod.endMonth
      ).padStart(2, "0")}-01`;

      await periodService.updatePeriod(selectedPeriod.id, {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });

      const response = await periodService.getPeriods();
      const formattedPeriods = response.map((period) => ({
        id: period.id,
        title: createPeriodTitle(period.startDate, period.endDate),
        startDate: formatDate(period.startDate),
        endDate: formatDate(period.endDate),
      }));
      setPeriods(formattedPeriods);
      toast.success("Dönem başarıyla güncellendi");
      handleEditClose();
    } catch (error) {
      toast.error("Dönem güncellenirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  };

  const filteredPeriods = periods.filter((period) =>
    period.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <LoadingFullPage state={loading}>
      <div className="min-h-screen bg-gray-50/50 font-['Outfit']">
        {/* Header Container */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto p-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-gradient-to-br from-[#439DC6] to-[#3B89B6] rounded-2xl shadow-lg shadow-[#439DC6]/20">
                  <svg
                    className="w-8 h-8 text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 5.5L5 3.5H19L20.5 5.5M3.5 5.5H20.5M3.5 5.5V19C3.5 19.5523 3.94772 20 4.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V5.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div>
                  <h1 className="text-3xl font-bold text-gray-800">Dönemler</h1>
                  <p className="text-gray-500 text-sm">
                    Tüm stratejik plan dönemlerini buradan yönetin
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="relative">
                  <input
                    type="search"
                    placeholder="Dönem ara..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-11 pr-4 py-3 rounded-xl border border-gray-200 w-64 focus:outline-none focus:ring-2 focus:ring-[#439DC6]/20 transition-all"
                  />
                  <svg
                    className="absolute left-3.5 top-3.5 text-gray-400 w-5 h-5"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>

                <button
                  onClick={handleClickOpen}
                  className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 7V17M7 12H17"
                      stroke="currentColor"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  Yeni Dönem
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Empty State */}
        {periods.length === 0 && (
          <div className="max-w-7xl mx-auto p-8">
            <div className="bg-white rounded-2xl border border-gray-200 p-16 text-center">
              <div className="w-20 h-20 bg-[#439DC6]/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
                <svg
                  className="w-10 h-10 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3.5 5.5L5 3.5H19L20.5 5.5M3.5 5.5H20.5M3.5 5.5V19C3.5 19.5523 3.94772 20 4.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V5.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Henüz Dönem Bulunmuyor
              </h3>
              <p className="text-gray-500 mb-8 max-w-md mx-auto">
                Stratejik plan dönemlerini yönetmek için yeni bir dönem
                oluşturun.
              </p>
              <button
                onClick={handleClickOpen}
                className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 mx-auto hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 7V17M7 12H17"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
                Yeni Dönem Oluştur
              </button>
            </div>
          </div>
        )}

        {/* Periods Grid */}
        {periods.length > 0 && (
          <div className="max-w-7xl mx-auto p-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredPeriods.map((period) => (
                <div
                  key={period.id}
                  className="bg-white rounded-2xl border border-gray-200 p-6 group hover:border-[#439DC6] transition-all cursor-pointer"
                  onClick={() => handlePeriodClick(period)}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800 mb-4">
                        {period.title}
                      </h3>
                      <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                          <svg
                            className="w-5 h-5 text-[#439DC6]"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="text-sm text-gray-600">
                            Başlangıç: {period.startDate}
                          </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <svg
                            className="w-5 h-5 text-[#439DC6]"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="text-sm text-gray-600">
                            Bitiş: {period.endDate}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeletePeriod(period.id);
                        }}
                        className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-[#EF8024] hover:bg-[#EF8024]/10 p-2.5 rounded-xl transition-all duration-200"
                        title="Dönemi Sil"
                      >
                        <svg
                          className="w-5 h-5"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H7V4Z"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M18 6H6V20C6 21.1046 6.89543 22 8 22H16C17.1046 22 18 21.1046 18 20V6Z"
                            stroke="currentColor"
                            strokeWidth="2"
                          />
                          <path
                            d="M10 11V17M14 11V17"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            className: "rounded-2xl font-['Outfit']",
          }}
        >
          <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-[#439DC6]/10 rounded-xl">
                <svg
                  className="w-6 h-6 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 7V17M7 12H17"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              Yeni Dönem Oluştur
            </div>
          </DialogTitle>
          <DialogContent className="px-6 pt-4">
            <div className="grid grid-cols-2 gap-8 mt-4">
              <div className="space-y-6">
                <TextField
                  select
                  fullWidth
                  label="Başlangıç Ayı"
                  value={newPeriod.startMonth}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, startMonth: e.target.value })
                  }
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="number"
                  fullWidth
                  label="Başlangıç Yılı"
                  value={newPeriod.startYear}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, startYear: e.target.value })
                  }
                />
              </div>
              <div className="space-y-6">
                <TextField
                  select
                  fullWidth
                  label="Bitiş Ayı"
                  value={newPeriod.endMonth}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, endMonth: e.target.value })
                  }
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  type="number"
                  fullWidth
                  label="Bitiş Yılı"
                  value={newPeriod.endYear}
                  onChange={(e) =>
                    setNewPeriod({ ...newPeriod, endYear: e.target.value })
                  }
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions className="p-6">
            <button
              onClick={handleClose}
              className="px-5 py-2.5 rounded-xl text-gray-500 hover:bg-gray-50 transition-colors font-medium"
            >
              İptal
            </button>
            <button
              onClick={() => {
                handleAddPeriod();
                handleClose();
              }}
              className="px-5 py-2.5 rounded-xl bg-[#439DC6] text-white transition-all hover:shadow-lg hover:shadow-[#439DC6]/20 font-medium"
            >
              Oluştur
            </button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          PaperProps={{
            className: "rounded-2xl font-['Outfit']",
          }}
        >
          <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-[#439DC6]/10 rounded-xl">
                <svg
                  className="w-6 h-6 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              Dönemi Düzenle
            </div>
          </DialogTitle>
          <DialogContent className="px-6 pt-4">
            {selectedPeriod && (
              <div className="grid grid-cols-2 gap-8 mt-4">
                <div className="space-y-6">
                  <TextField
                    select
                    fullWidth
                    label="Başlangıç Ayı"
                    value={selectedPeriod.startMonth}
                    onChange={(e) =>
                      setSelectedPeriod({
                        ...selectedPeriod,
                        startMonth: e.target.value,
                      })
                    }
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    type="number"
                    fullWidth
                    label="Başlangıç Yılı"
                    value={selectedPeriod.startYear}
                    onChange={(e) =>
                      setSelectedPeriod({
                        ...selectedPeriod,
                        startYear: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="space-y-6">
                  <TextField
                    select
                    fullWidth
                    label="Bitiş Ayı"
                    value={selectedPeriod.endMonth}
                    onChange={(e) =>
                      setSelectedPeriod({
                        ...selectedPeriod,
                        endMonth: e.target.value,
                      })
                    }
                  >
                    {months.map((month) => (
                      <MenuItem key={month.value} value={month.value}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    type="number"
                    fullWidth
                    label="Bitiş Yılı"
                    value={selectedPeriod.endYear}
                    onChange={(e) =>
                      setSelectedPeriod({
                        ...selectedPeriod,
                        endYear: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions className="p-6">
            <button
              onClick={handleEditClose}
              className="px-5 py-2.5 rounded-xl text-gray-500 hover:bg-gray-50 transition-colors font-medium"
            >
              İptal
            </button>
            <button
              onClick={handleEditPeriod}
              className="px-5 py-2.5 rounded-xl bg-[#439DC6] text-white transition-all hover:shadow-lg hover:shadow-[#439DC6]/20 font-medium"
            >
              Kaydet
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </LoadingFullPage>
  );
}
