import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { exploratoryInterviewService } from "../../../../../services/exploratoryInterviewService";
import { useNavigate } from "react-router-dom";
import InfoIcon from "../../../../../icons/info.svg";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import tooltipTexts from "../../../../../constants/tooltipTexts";
import InfoTooltip from "../../../../../components/Tooltip/InfoTooltip";

export default function ExploratoryInterview({
  changePage,
  nextPage,
  backPage,
  projectId,
  projectName,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const navigate = useNavigate();
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await exploratoryInterviewService.getAnswers(
          projectId
        );
        setFormData(response);
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) {
      fetchData();
    }
  }, [projectId]);

  const handleNext = async () => {
    try {
      const transformedData = {
        answers: formData.flatMap((section) =>
          section.answers.map((answer) => ({
            containerId: answer.containerId,
            value: answer.value,
          }))
        ),
      };

      await exploratoryInterviewService.saveAnswers(projectId, transformedData);
      nextPage();
    } catch (error) {
      toast.error("Veriler kaydedilirken bir hata oluştu");
    }
  };

  const updateFormValue = (sectionIndex, answerIndex, value) => {
    const newFormData = [...formData];
    if (newFormData[sectionIndex] && newFormData[sectionIndex].answers) {
      newFormData[sectionIndex].answers[answerIndex].value = value;
      console.log("newFormData: ", newFormData);
      setFormData(newFormData);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      const container = document.getElementById("exploratory-interview-title");
      const originalStyle = container.style.cssText;

      // Container'ın orijinal arka plan rengini saklayalım
      const sections = container.querySelectorAll(
        ".bg-\\[\\#5F6FA8\\], .bg-\\[\\#6EA750\\], .bg-\\[\\#985F68\\], .bg-\\[\\#D73155\\]"
      );
      const originalBackgrounds = Array.from(sections).map((section) => ({
        element: section,
        background: section.style.backgroundColor,
        className: section.className,
      }));

      // PDF için container stillerini ayarlayalım
      container.style.cssText = `
        width: 1200px !important;
        max-width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: transparent !important;
      `;

      // Textarea'ları div'lere çevirelim
      const textareas = container.getElementsByTagName("textarea");
      const originalElements = [];

      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder;
        div.style.cssText = `
          width: 100%;
          min-height: ${textarea.offsetHeight}px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
          color: white;
          background-color: rgba(255, 255, 255, 0.1);
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      // Başlık renklerini ve arka plan renklerini koruyalım
      sections.forEach((section) => {
        section.style.backgroundColor =
          window.getComputedStyle(section).backgroundColor;
      });

      try {
        await downloadPageAsPDF(
          "exploratory-interview-title",
          "Keşifsel Mülakat",
          projectName
        );
      } finally {
        // Orijinal stilleri geri yükleyelim
        container.style.cssText = originalStyle;

        // Orijinal textarea'ları geri yükleyelim
        originalElements.forEach(({ element, parent }) => {
          const currentElement = parent.firstChild;
          parent.replaceChild(element, currentElement);
        });

        // Orijinal arka plan renklerini geri yükleyelim
        originalBackgrounds.forEach(({ element, background, className }) => {
          element.style.backgroundColor = background;
          element.className = className;
        });
      }
    } catch (error) {
      toast.error("PDF oluşturulurken bir hata oluştu");
      console.error("PDF error:", error);
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage isLoading={isPdfLoading}>
      <div>
        <div className="text-[#797979] text-[20px] font-bold font-['Outfit'] mb-4">
          KEŞİFSEL MÜLAKAT ŞABLONU
        </div>
        <div
          id="exploratory-interview-title"
          className="w-full h-auto rounded-[20px] border border-[#c8c8c8]"
        >
          <div className="w-full h-[80px] bg-[#a95f97] rounded-tl-[20px] rounded-tr-[20px] flex">
            <div className="flex-1 flex items-center justify-center relative">
              <span className="text-white text-md font-semibold font-['Outfit']">
                Kişi Bilgileri
              </span>
              <InfoTooltip
                title={tooltipTexts.interviewAnalysisCanvas.personInfo}
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
                />
              </InfoTooltip>
            </div>

            <div className="flex-1 flex items-center justify-center relative border-l border-white/20">
              <span className="text-white text-md font-semibold font-['Outfit']">
                Temel İçgörüler
              </span>
              <InfoTooltip
                title={tooltipTexts.interviewAnalysisCanvas.basicInsights}
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
                />
              </InfoTooltip>
            </div>

            <div className="flex-1 flex items-center justify-center relative border-l border-white/20">
              <span className="text-white text-md font-semibold font-['Outfit'] p-2">
                Güncel Çözümler ve Eksiklikler
              </span>
              <InfoTooltip
                title={tooltipTexts.interviewAnalysisCanvas.currentSolutions}
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
                />
              </InfoTooltip>
            </div>

            <div className="flex-1 flex items-center justify-center relative border-l border-white/20">
              <span className="text-white text-md font-semibold font-['Outfit']">
                İyileştirme Fırsatları
              </span>
              <InfoTooltip
                title={
                  tooltipTexts.interviewAnalysisCanvas.improvementOpportunities
                }
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
                />
              </InfoTooltip>
            </div>

            <div className="flex-1 flex items-center justify-center relative border-l border-white/20">
              <span className="text-white text-md font-semibold font-['Outfit']">
                Sonraki Adımlar
              </span>
              <InfoTooltip
                title={tooltipTexts.interviewAnalysisCanvas.nextSteps}
              >
                <img
                  src={InfoIcon}
                  alt="info"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-5 h-5"
                />
              </InfoTooltip>
            </div>
          </div>
          <div className="w-full h-auto flex flex-wrap gap-4 mt-4 p-4">
            {/* Left column - Person Info */}
            <div className="flex-1 bg-[#5F6FA8] rounded-[10px] border border-[#E0E0E0]">
              {formData[0]?.answers.map((answer, index) => (
                <React.Fragment key={index}>
                  <div className="text-white text-[13px] font-['Outfit'] p-4">
                    {answer.label}
                  </div>
                  {answer.label === "Kişisel Veriler:" ? (
                    <textarea
                      className="w-full h-[120px] text-[13px] px-4 py-2 bg-white/10 text-white resize-none"
                      value={answer.value}
                      onChange={(e) =>
                        updateFormValue(0, index, e.target.value)
                      }
                      maxLength={150}
                    />
                  ) : (
                    <input
                      type={
                        answer.label === "Mülakat Tarihi:" ? "date" : "text"
                      }
                      className="w-full px-4 py-2 bg-white/10 text-white"
                      value={answer.value}
                      onChange={(e) =>
                        updateFormValue(0, index, e.target.value)
                      }
                      maxLength={150}
                    />
                  )}
                  <div className="w-full border-t border-[#E0E0E0]" />
                </React.Fragment>
              ))}
            </div>

            {/* Middle column - Core Insights */}
            <div className="flex-1 bg-[#6EA750] rounded-[10px] border border-[#E0E0E0]">
              {formData[1]?.answers.map((answer, index) => (
                <React.Fragment key={index}>
                  <div className="text-white text-[13px] font-['Outfit'] p-4">
                    {answer.label}
                  </div>
                  <textarea
                    className="w-full h-[140px] text-[13px] px-4 py-2 bg-white/10 text-white resize-none"
                    value={answer.value}
                    onChange={(e) => updateFormValue(1, index, e.target.value)}
                    maxLength={150}
                  />
                  <div className="w-full border-t border-[#E0E0E0]" />
                </React.Fragment>
              ))}
            </div>

            {/* Current Solutions column */}
            <div className="flex-1 bg-[#985F68] rounded-[10px] border border-[#E0E0E0]">
              {formData[2]?.answers.map((answer, index) => (
                <React.Fragment key={index}>
                  <div className="text-white text-[13px] font-['Outfit'] p-4">
                    {answer.label}
                  </div>
                  <textarea
                    className="w-full h-[180px] text-[13px] px-4 py-2 bg-white/10 text-white resize-none"
                    value={answer.value}
                    onChange={(e) => updateFormValue(2, index, e.target.value)}
                    maxLength={150}
                  />
                  <div className="w-full border-t border-[#E0E0E0]" />
                </React.Fragment>
              ))}
            </div>

            {/* Improvement Areas column */}
            <div className="flex-1 bg-[#D73155] rounded-[10px] border border-[#E0E0E0]">
              {formData[3]?.answers.map((answer, index) => (
                <React.Fragment key={index}>
                  <div className="text-white text-[13px] font-['Outfit'] p-4">
                    {answer.label}
                  </div>
                  <textarea
                    className="w-full h-[160px] text-[13px] px-4 py-2 bg-white/10 text-white resize-none"
                    value={answer.value}
                    onChange={(e) => updateFormValue(3, index, e.target.value)}
                    maxLength={150}
                  />
                  <div className="w-full border-t border-white" />
                </React.Fragment>
              ))}
            </div>

            {/* Next Steps column */}
            <div className="flex-1 bg-[#6EA750] rounded-[10px] border border-[#E0E0E0]">
              {formData[4]?.answers.map((answer, index) => (
                <React.Fragment key={index}>
                  <div className="text-white  text-[13px] font-['Outfit'] p-4">
                    {answer.label}
                  </div>
                  <textarea
                    className="w-full h-[140px] text-[13px] px-4 py-2 bg-white/10 text-white resize-none"
                    value={answer.value}
                    onChange={(e) => updateFormValue(4, index, e.target.value)}
                    maxLength={150}
                  />
                  <div className="w-full border-t border-[#E0E0E0]" />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full flex items-center">
          <div className="w-full w-[600px] flex-1">
            <div className="w-full pr-10">
              <Stepper
                nextChange={handleNext}
                backChange={backPage}
                activeStep={9}
                stepLimit={9}
                navigate={navigate}
                navigatePath={"/empati"}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
