import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const marketDeterminationService = {
  getMarkets: async (projectId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/markets`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  addMarket: async (projectId, marketName) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/markets`,
        {
          name: marketName,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateMarket: async (projectId, marketId, marketName) => {
    try {
      const response = await axios.put(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/markets/${marketId}`,
        {
          name: marketName,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  deleteMarket: async (projectId, marketId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/markets/${marketId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateFactor: async (projectId, titleId, factor) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/titles/${titleId}/factors`,
        {
          factor: factor,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
