import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const organizationAdminService = {
  async getUsers() {
    try {
      const response = await axios.get(`${API_URL}/organization-admin/users`, {
        headers,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async createUser(userData) {
    try {
      // Permissions'ı modül ID'lerine dönüştür
      const moduleIds = [];
      if (userData.permissions.innovationCheckup) moduleIds.push(8);
      if (userData.permissions.customerAnalysis) moduleIds.push(1);
      if (userData.permissions.internalEntrepreneurship) moduleIds.push(2);
      if (userData.permissions.strategicPlan) moduleIds.push(3);
      if (userData.permissions.marketResearch) moduleIds.push(4);

      const response = await axios.post(
        `${API_URL}/organization-admin/users`,
        {
          email: userData.email.value,
          corporation: userData.corporation?.value || "",
          name: userData.name.value,
          surname: userData.surname.value,
          department: userData.department.value,
          cellPhone: userData.cellPhone.value,
          password: userData.password.value,
          availableModules: moduleIds,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteUser(userId) {
    try {
      const response = await axios.delete(
        `${API_URL}/organization-admin/users/${userId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getUser(userId) {
    try {
      const response = await axios.get(
        `${API_URL}/organization-admin/users/${userId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async updateUser(userId, userData) {
    try {
      // Transform permissions to moduleIds array
      const moduleIds = [];
      if (userData.permissions.innovationCheckup) moduleIds.push(8);
      if (userData.permissions.customerAnalysis) moduleIds.push(1);
      if (userData.permissions.internalEntrepreneurship) moduleIds.push(2);
      if (userData.permissions.strategicPlan) moduleIds.push(3);
      if (userData.permissions.marketResearch) moduleIds.push(4);

      const response = await axios.put(
        `${API_URL}/organization-admin/users/${userId}`,
        {
          email: userData.email.value,
          corporation: userData.corporation?.value || "",
          name: userData.name.value,
          surname: userData.surname.value,
          department: userData.department.value,
          cellPhone: userData.cellPhone.value,
          password: userData.password.value || undefined, // Only send if password is provided
          availableModules: moduleIds,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
