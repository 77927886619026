import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import Logo from "../images/GP-Logo.png";

export async function downloadPageAsPDF(
  containerId,
  title,
  projectTitle,
  period = null,
  productService = null
) {
  const container = document.getElementById(containerId);
  if (!container) {
    console.error("Container not found");
    return;
  }

  const doc = new jsPDF("p", "mm", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margins = 10;

  // Add header with a more compact, professional layout
  doc.setFillColor(249, 250, 251); // Light gray background
  doc.rect(0, 0, pageWidth, 20, "F"); // Reduced from 25 to 20mm height

  // Add border line
  doc.setDrawColor(229, 231, 235);
  doc.line(0, 20, pageWidth, 20);

  // Add logo
  doc.addImage(Logo, "PNG", margins, 3, 20, 0); // Reduced logo size from 25 to 20

  // Add project title with adjusted font size
  doc.setFont("Roboto-Medium", "normal");
  doc.setFontSize(16); // Reduced from 20 to 16
  doc.setTextColor(31, 41, 55); // Dark gray text
  doc.text(projectTitle, pageWidth / 2, 10, { align: "center" });

  // Add period and product/service info in a more compact layout
  if (period || productService) {
    doc.setFontSize(9); // Reduced from 10 to 9
    doc.setTextColor(107, 114, 128); // Medium gray text

    let infoText = [];
    if (period) {
      infoText.push(`Dönem: ${period}`);
    }
    if (productService) {
      infoText.push(`Ürün/Hizmet Grubu: ${productService}`);
    }

    doc.text(infoText.join(" | "), pageWidth / 2, 15, { align: "center" }); // Adjusted Y position
  }

  // Add timestamp in a more subtle way
  doc.setFontSize(7); // Reduced from 8 to 7
  doc.setTextColor(156, 163, 175); // Light gray text
  doc.text(moment().format("DD.MM.YYYY HH:mm"), pageWidth - margins, 6, {
    align: "right",
  });

  try {
    // Save original scroll heights
    const scrollElements = container.querySelectorAll(
      '[style*="overflow: auto"], [style*="overflow-y: auto"]'
    );
    const originalHeights = Array.from(scrollElements).map((el) => ({
      element: el,
      height: el.style.height,
      maxHeight: el.style.maxHeight,
    }));

    // Temporarily remove scroll by setting full height
    scrollElements.forEach((el) => {
      el.style.height = "auto";
      el.style.maxHeight = "none";
    });

    const canvas = await html2canvas(container, {
      scale: 2,
      useCORS: true,
      logging: false,
      windowWidth: container.scrollWidth,
      windowHeight: container.scrollHeight,
      ignoreElements: (element) => {
        return element.classList.contains("stepper-container");
      },
    });

    // Restore original scroll heights
    originalHeights.forEach(({ element, height, maxHeight }) => {
      element.style.height = height;
      element.style.maxHeight = maxHeight;
    });

    const imgData = canvas.toDataURL("image/jpeg", 1.0);
    const imgWidth = pageWidth - margins * 2;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let heightLeft = imgHeight;
    let position = 20;

    // Add content first
    doc.addImage(imgData, "JPEG", margins, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - position;

    // Add watermark on top of content
    doc.saveGraphicsState();
    doc.setGState(new doc.GState({ opacity: 0.1 }));
    doc.addImage(
      Logo,
      "PNG",
      pageWidth / 4,
      pageHeight / 4,
      pageWidth / 2,
      pageWidth / 2
    );
    doc.restoreGraphicsState();

    // Add additional pages if needed
    while (heightLeft >= 0) {
      doc.addPage();
      position = heightLeft - imgHeight;
      doc.addImage(imgData, "JPEG", margins, position, imgWidth, imgHeight);

      // Add watermark after content on each page
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.1 }));
      doc.addImage(
        Logo,
        "PNG",
        pageWidth / 4,
        pageHeight / 4,
        pageWidth / 2,
        pageWidth / 2
      );
      doc.restoreGraphicsState();

      heightLeft -= pageHeight;
    }

    doc.save(`${title}.pdf`);
  } catch (error) {
    console.error("PDF generation failed:", error);
  }
}

const splitContentIntoPages = async (container, pageHeight) => {
  const pages = [];
  let currentPosition = 0;
  const containerHeight = container.scrollHeight;

  while (currentPosition < containerHeight) {
    const canvas = await html2canvas(container, {
      y: currentPosition,
      height: Math.min(pageHeight, containerHeight - currentPosition),
      windowHeight: pageHeight,
      scrollY: -currentPosition,
    });

    pages.push(canvas);
    currentPosition += pageHeight;
  }

  return pages;
};
