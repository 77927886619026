import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const testAndLearningCardsService = {
  getCards: async (projectId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/product-market-fit/${projectId}/test-and-learning-cards`,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  getCardAnswers: async (cardId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/product-market-fit/test-and-learning-cards/${cardId}/answers`,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  createCard: async (projectId, data) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/product-market-fit/${projectId}/test-and-learning-cards`,
        data,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  deleteCard: async (cardId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/internal-entrepreneurship/product-market-fit/test-and-learning-cards/${cardId}`,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  saveCardAnswers: async (cardId, answers) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/product-market-fit/test-and-learning-cards/${cardId}/answers`,
        answers,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
