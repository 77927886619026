import { BarChart } from "@mui/x-charts/BarChart";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/Button/Button";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { marketAnalysisResultService } from "../../../../../services/marketAnalysisResultService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";

const shortenLabel = (header) => {
  const labelMap = {
    "Pazardaki Rakipler Arası Rekabet Şiddetinin Ölçülmesi":
      "Rakipler Arası Rekabet",
    "Pazara Yeni Girebilecek Firmaların (Olası Rakipler) Yarattığı Tehditlerin Ölçülmesi":
      "Yeni Rakip Tehdidi",
    "Tedarikçilerin Pazarlık Gücünün Ölçülmesi": "Tedarikçi Gücü",
    "Müşterilerin Pazarlık Gücünün Ölçülmesi": "Müşteri Gücü",
    "İşletme Ürününe Alternatif Olabilecek İkame Ürünlerin Yarattığı Tehditlerin Ölçülmesi":
      "İkame Ürün Tehdidi",
  };
  return labelMap[header] || header;
};

export default function MarketGraphic({ backPage, productId, changePage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [grapData, setGrapData] = useState([]);

  async function fetchResult() {
    setIsLoading(true);
    try {
      const response = await marketAnalysisResultService.getResults(productId);
      const datas = response.scores.map((element) => ({
        data: [element.average],
        label: shortenLabel(element.header),
      }));
      setGrapData(datas);
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (productId) {
      fetchResult();
    }
  }, [productId]);

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      await downloadPageAsPDF(
        "market-analysis-chart",
        "Pazar Analizi Sonuçları",
        "Pazar Analizi"
      );
      toast.success("PDF başarıyla indirildi");
    } catch (error) {
      toast.error("PDF indirilirken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <div className="w-full flex items-center">
      <div className="w-[600px] flex-1">
        <LoadingFullPage state={isLoading || isPdfLoading}>
          <div id="market-analysis-chart" className="w-full pr-10">
            <div className="flex">
              <div className="text-zinc-800 text-lg font-bold font-['Outfit'] flex-1">
                Pazar Analizi Sonuçları
              </div>
            </div>

            <div className="bg-white rounded-[10px] border border-neutral-200 mt-6">
              <div className="flex items-center justify-center my-4">
                <BarChart
                  series={grapData}
                  width={800}
                  height={400}
                  colors={[
                    "#f8522e",
                    "#f8893a",
                    "#f7c045",
                    "#abc32f",
                    "#5ec618",
                  ]}
                  xAxis={[
                    {
                      scaleType: "band",
                      data: ["Pazar Analizi"],
                      categoryGapRatio: 0.1,
                      barGapRatio: 0.2,
                    },
                  ]}
                  margin={{ top: 40, right: 20, bottom: 40, left: 40 }}
                  slotProps={{
                    legend: {
                      direction: "row",
                      position: { vertical: "top", horizontal: "middle" },
                      padding: 0,
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                      markGap: 5,
                      itemGap: 15,
                      labelStyle: {
                        fontSize: 12,
                        fill: "#242731",
                      },
                    },
                  }}
                />
              </div>
            </div>

            <div className="bg-white rounded-[10px] border border-neutral-200 mt-6 flex p-2">
              {grapData.map((element) => (
                <div
                  key={element.label}
                  className="flex flex-col items-center justify-center m-1 flex-1 p-2"
                >
                  <div className="text-center text-zinc-800 text-sm font-medium font-['Outfit'] mb-1">
                    {element.label}
                  </div>
                  <div className="text-center text-zinc-800 text-base font-semibold font-['Outfit'] bg-gray-50 px-3 py-1 rounded">
                    {element.data[0].toFixed(2)}
                  </div>
                </div>
              ))}
            </div>

            <Stepper
              nextTitle={"PDF"}
              backChange={backPage}
              nextChange={changePage}
              onPdfDownload={handlePdfDownload}
              activeStep={4}
              stepLimit={4}
            />
          </div>
        </LoadingFullPage>
      </div>
    </div>
  );
}
