import axios from "axios";
import { API_URL } from "./config";
import { api } from "./api"; // Assuming you have a base RTK Query API setup
import { handleApiError, handleApiErrorLogin } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

// Axios-based service
export const loginService = {
  /**
   * Logs in a user with the provided email and password.
   * @param {string} email - The user's email.
   * @param {string} password - The user's password.
   * @returns {Promise<Object>} The response data from the server.
   */
  async login(email, password) {
    try {
      const response = await axios.post(
        `${API_URL}/identity/login`,
        { email, password },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiErrorLogin(error);
    }
  },

  /**
   * Resends the account confirmation email.
   * @param {string} email - The user's email.
   * @returns {Promise<Object>} The response data from the server.
   */
  async resendConfirmationEmail(email) {
    try {
      const response = await axios.post(
        `${API_URL}/identity/resend-confirmation`,
        { email },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

// RTK Query-based API (optional)
export const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "identity/login",
        method: "POST",
        body: data,
        credentials: "include",
      }),
    }),
  }),
});

export const { useLoginMutation } = loginApi;
