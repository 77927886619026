import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const stakeholderMatrixPeriodService = {
  async getPeriods() {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/stakeholder-analysis/stakeholder-matrix/periods`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async createPeriod(periodData) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/strategic-plan/stakeholder-analysis/stakeholder-matrix/periods`,
        periodData,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deletePeriod(periodId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/strategic-plan/stakeholder-analysis/stakeholder-matrix/periods/${periodId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
