import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import StrategicDirectionImage from "../../../../../images/strategic-direction.svg";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-8 mx-auto px-4">
      <div className="w-full">
        <div className="text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="w-full text-[#797979] text-lg font-normal font-['Outfit']">
          Stratejik Analiz ile mevcut durumunuzu ortaya koydunuz. Şimdi mevcut
          durumunuzdan yola çıkarak gelişim ve fırsat alanlarınızı
          belirlemenizde fayda vardır. Örneğin SWOT stratejilerini uygulayarak
          bunu gerçekleştirebilirsiniz. Güçlü yönler, zayıf yönler, fırsatlar ve
          tehditler bir araya getirilerek işletmenin stratejik konumunu
          anlamlandırılmaya çalışılır. Örneğin güçlü yönlerle fırsatları
          birleştirerek rekabet avantajları oluşturacak temel stratejiler
          belirlenir. Diğer taraftan, zayıf yönler ve tehditlerle başa çıkmak
          için stratejiler geliştirilir veya güçlü yönleriniz ile tehditleri
          nasıl bertaraf edebileceğiniz veya zayıf yönlerinizi pazar fırsatları
          ile nasıl güçlü hale getirebileceğiniz üzerinden durulur. Buna TOWS
          Analizi denir.  
          <br /> <br />
        </div>
      </div>

      <div className="w-full">
        <div className="flex items-center gap-2 mb-4">
          <div className="w-5 h-5"></div>
          <div className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi başlayalım!
          </div>
        </div>
      </div>

      <div className="w-[1136px]">
        <span class="text-[#797979] text-lg font-medium font-['Outfit']">
          TOWS Analizi
        </span>
        <span class="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          ile birlikte ne yapmanız gerektiği ortaya çıkmaya başlar. Yani
          Stratejik Amaçlarınızı tanımlamaya başlarsınız. Örneğin İç Çevre
          Analizi sonucunuz zamanında teslimat ve büyük partiler ile üretim
          konusunda iyi olduğunuzu söylüyor. Ürettiğiniz ürünler konusunda da
          yaptığınız Çevre Analizi sonucu ihracat fırsatlarının arttığını
          gözlemlemektesiniz. İhracat yapmaya başlama veya ihracatınız varsa
          hedef ülke belirleyerek o ülkeye ihracat kapasitenin artırılması gibi
          bir stratejik amaç belirleyebilirsiniz. Stratejik amaçlar
          oluşturulurken İnovasyon Check-Up ve Müşteri ve Problem Analizine
          ilişkin modüllerimiz de önemli girdi sağlar.
        </span>
      </div>

      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        Stratejik Amaçlarınızı oluştururken Balanced Scorecard (BSC) tekniğini
        kullanmanızı önermekteyiz. Robert S. Kaplan ve David P. Norton
        tarafından geliştirilmiş BSC, bir firmanın stratejik amaçlarını dengeli
        bir şekilde belirlenmesi gerektiği vurgular. Sadece finansal ölçütlere
        dayalı amaçların değil; finansal olmayan performans ölçütlerini de
        dikkate alarak stratejik amaçları dengeli bir şekilde oluşturmaya katkı
        sunar. BSC dört ana boyuttan oluşur ve her bir boyut, farklı açılardan
        amaçları ortaya koymaya yardımcı olur. Böylelikle dengeli bir şekilde
        farklı alanlarda stratejik amaçlar tanımlayabilir, bir alanın diğer
        alanın önüne geçmesini engelleyebilirsiniz. BSC boyutları şunlardır:
        <br />{" "}
      </div>

      <div className="text-[#797979] text-2xl font-semibold font-['Outfit'] w-full flex items-center justify-center">
        Balanced Scorecard
      </div>

      <img src={StrategicDirectionImage} />

      <div className="w-full flex items-center">
        <div className="w-full lg:w-[600px] flex-1">
          <div className="w-full pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
