import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function InformationWrittenBrainstorming({
  changePage,
  backPage,
  projectId,
  subId,
}) {
  return (
    <>
      <div className="w-full">
        <div className="max-w-[1136px] mx-auto px-4">
          <div className="text-[#797979] text-lg font-normal font-['Outfit']">
            Şimdi ekibinizle birlikte çerçevelediğiniz probleme ilişkin Yazılı
            Beyin Fırtınası (635 Yöntemi) şablonunu kullanarak probleminizi
            çözebilecek yenilikçi fikir alternatiflerini ortaya koyunuz. Bununla
            birlikte farklı yenilikçi fikir üretme tekniklerinden de
            faydalanabilirsiniz. <br />
            <br />
            Yazılı Beyin Fırtınası (635 Yöntemi) için problemi bilen,
            ilgilendiren birim çalışanlarını bir araya getirmeye çalışınız.
            Ar-Ge, Pazarlama, Müşteri Hizmetleri ve İş Geliştirme gibi departman
            çalışanlarını öncelikli tutun. Bunun için zorunlu olmamakla birlikte
            6 katılımcı olmanız önerilir. 6 katılımcı 5 dk. içerisinde 3 fikir
            üreterek süreç başlar. <br />
            <br />
            Süre ve fikir adedi de standart olmamakla birlikte bu rakamlara
            yakın sayılarda hareket etmenizi öneririz. Döner bir masa etrafında
            5 dk. içerisinde her katılımcı yazdığı fikirleri her seferinde aynı
            yönde bir yanındaki katılımcı ile paylaşır. Her bir alınan geri
            bildirime göre göre fikirler değişebilir, güncellenebilir,
            artırılabilir, azaltılabilir veya sabit kalabilir. Bu şekilde 5
            raunt yapılarak fikir toplama süreci sona erdirilir. Size sunduğumuz
            Yazılı Beyin Fırtınası (635 Yöntemi) şablonunu indirerek, her bir
            katılımcı ile paylaşınız. Fiziki olarak yöntemi bir atölye
            formatında uygulayınız. Atölye çalışmanız bittiğinde fikirleri
            gruplandırınız. Benzer olanları birleştiriniz. Kesin uygulanamayacak
            olarak gördüklerinizi, daha önce benzerlerinin yapıldığını
            düşündüğünüz fikirleri veya stratejik hedefleriniz kapsamında
            görmediklerinizi ön elemeden geçiriniz. <br />
            <br />
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <div className="w-full w-[600px] px-4">
            <div className="w-full">
              <Stepper
                nextChange={changePage}
                backChange={backPage}
                activeStep={2}
                stepLimit={3}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
