import React, { useState } from "react";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import { BLUE_TYPE } from "../Button/constants";

const reasonTypes = [
  "Yönetimsel",
  "Süreç",
  "Yöntem",
  "İnsan",
  "Çevresel",
  "Makine",
  "Malzeme & Ekipman",
];

export default function ReasonContainerModal({ isOpen, onClose, onSubmit }) {
  const [selectedReason, setSelectedReason] = useState("");

  const handleSubmit = () => {
    if (!selectedReason) {
      toast.error("Lütfen bir sebep seçiniz");
      return;
    }
    onSubmit(selectedReason);
    onClose();
    setSelectedReason("");
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Sebep Seçiniz" header={true}>
      <div className="p-8 bg-gradient-to-br from-gray-50 to-white">
        <div className="grid grid-cols-2 gap-4">
          {reasonTypes.map((reason) => (
            <div
              key={reason}
              onClick={() => setSelectedReason(reason)}
              className={`
                p-4 rounded-xl cursor-pointer transition-all duration-200
                ${
                  selectedReason === reason
                    ? "bg-[#439DC6] text-white shadow-lg scale-105"
                    : "bg-white hover:bg-gray-50 text-gray-700 border-2 border-gray-100"
                }
              `}
            >
              <div className="flex items-center space-x-3">
                <div
                  className={`
                  w-4 h-4 rounded-full border-2 flex items-center justify-center
                  ${
                    selectedReason === reason
                      ? "border-white"
                      : "border-gray-300"
                  }
                `}
                >
                  {selectedReason === reason && (
                    <div className="w-2 h-2 bg-white rounded-full" />
                  )}
                </div>
                <span className="font-['Outfit'] text-base">{reason}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 flex justify-end">
          <Button
            text="Ekle"
            onClick={handleSubmit}
            className="w-40"
            iconType={BLUE_TYPE}
          />
        </div>
      </div>
    </Modal>
  );
}
