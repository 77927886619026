import React, { useState, useEffect } from "react";
import { jtbdService } from "../../../../../services/jtbdService";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import InfoIcon from "../../../../../icons/info.svg";
import AddWhite from "../../../../../icons/add-white.svg";
import DeleteItem from "../../../../../icons/delete-item.svg";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import InfoTooltip from "../../../../../components/Tooltip/InfoTooltip";
import tooltipTexts from "../../../../../constants/tooltipTexts";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function JTBDCanvas({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [columns, setColumns] = useState({
    column1: {
      id: "column1",
      items: [],
    },
    column2: {
      id: "column2",
      items: [],
    },
    column3: {
      id: "column3",
      items: [],
    },
    column4: {
      id: "column4",
      items: [],
    },
    column5: {
      id: "column5",
      items: [],
    },
  });

  const [jobStatement, setJobStatement] = useState("");
  const [whenWhoSituation, setWhenWhoSituation] = useState("");
  const [desires, setDesires] = useState("");
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedColumnId, setSelectedColumnId] = useState(null);
  const [newItemContent, setNewItemContent] = useState("");
  const [showIdeaModal, setShowIdeaModal] = useState(false);
  const [selectedHatType, setSelectedHatType] = useState(null);
  const [newIdea, setNewIdea] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const handleClickOpen = (columnId) => {
    setSelectedColumnId(columnId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewItemContent("");
  };

  const handleAddItem = async () => {
    try {
      await jtbdService.addCanvasItem(projectId, {
        titleId: selectedColumnId,
        content: newItemContent,
      });

      // Update local state
      const newColumns = { ...columns };
      newColumns[`column${selectedColumnId}`].items.push({
        id: `${selectedColumnId}-${
          newColumns[`column${selectedColumnId}`].items.length
        }`,
        content: newItemContent,
      });
      setColumns(newColumns);
      handleClose();
    } catch (error) {
      toast.error("Öğe eklenirken bir hata oluştu");
    }
  };

  const handleNext = async () => {
    console.log("next");
    try {
      const formattedAnswers = {
        answers: [
          { id: 6, content: jobStatement },
          { id: 7, content: whenWhoSituation },
          { id: 8, content: desires },
          { id: 9, content: desiredOutcome },
        ],
      };

      await jtbdService.saveAnswers(projectId, formattedAnswers);
      changePage();
    } catch (error) {
      toast.error("Cevaplar kaydedilirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("jtbd-canvas-container");

    // Store original styles
    const originalStyle = container.style.cssText;

    // Set temporary container styles for PDF
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Handle textareas
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "jtbd-canvas-container",
        "JTBD Kanvası",
        projectName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Restore original elements
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jtbdService.getAllJTBD(projectId);
        console.log("API Response:", response);

        // Map canvas data to columns
        const newColumns = { ...columns };
        if (response.canvas && Array.isArray(response.canvas)) {
          response.canvas.forEach((section, index) => {
            if (section.answers && Array.isArray(section.answers)) {
              newColumns[`column${index + 1}`].items = section.answers.map(
                (answer) => ({
                  id: `${index + 1}-${answer.id}`,
                  answerId: answer.id,
                  content:
                    typeof answer === "string" ? answer : answer.content || "",
                })
              );
            }
          });
        }
        setColumns(newColumns);

        // Map other answers
        if (response.answers && Array.isArray(response.answers)) {
          const answersMap = {
            6: setJobStatement,
            7: setWhenWhoSituation,
            8: setDesires,
            9: setDesiredOutcome,
          };

          response.answers.forEach((answer) => {
            if (answer && answersMap[answer.id]) {
              answersMap[answer.id](answer.answer || "");
            }
          });
        }
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
        console.error("Error fetching JTBD data:", error);
      }
    };

    fetchData();
  }, [projectId]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      return;
    }

    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);

    const newColumns = {
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    };

    // Update state first
    setColumns(newColumns);

    try {
      const columnIndex = parseInt(source.droppableId.replace("column", ""));
      // Map items to just their content for the API
      const answers = copiedItems.map((item) => item.content);

      // Call the new service method
      await jtbdService.updateCanvasItemOrder(projectId, columnIndex, answers);
    } catch (error) {
      toast.error("Sıralama kaydedilirken bir hata oluştu");
      console.error("Error saving drag and drop order:", error);
    }
  };

  const handleShowIdeaModal = (hatType) => {
    setSelectedHatType(hatType);
    setShowIdeaModal(true);
    setNewIdea("");
  };

  const handleDeleteItem = async (columnId, itemId) => {
    try {
      // İlgili item'ı bul
      const item = columns[columnId].items.find((item) => item.id === itemId);
      if (!item) {
        throw new Error("Item not found");
      }

      // API'ye gerçek answerId'yi gönder
      await jtbdService.deleteCanvasItem(projectId, item.answerId);

      // Başarılı silme işleminden sonra local state'i güncelle
      const newColumns = { ...columns };
      newColumns[columnId].items = newColumns[columnId].items.filter(
        (item) => item.id !== itemId
      );
      setColumns(newColumns);

      toast.success("Öğe başarıyla silindi");
    } catch (error) {
      toast.error("Öğe silinirken bir hata oluştu");
      console.error("Error deleting item:", error);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div>
        <div id="jtbd-canvas-container">
          <div className="text-black text-lg font-bold font-['Outfit'] mb-4 text-center">
            Jobs to be Done (JTBD) kanvası
          </div>

          <div className="w-full h-full rounded-[20px] border border-[#c8c8c8]">
            <div className="w-full h-16 bg-[#169888] rounded-tl-[20px] rounded-tr-[20px] flex">
              <div className="flex-1 flex items-center  relative">
                <img className="ml-6" src={InfoIcon} />
                <div className="flex flex-col ml-1">
                  <div className="text-white text-xs font-semibold font-['Outfit']">
                    İhtiyaçlar
                  </div>
                  <div className="text-white text-[10px] font-semibold font-['Outfit']">
                    Kazançlar/Kayıplar
                  </div>
                </div>
                <InfoTooltip title={tooltipTexts.jtbdCanvas.needs}>
                  <img
                    className="absolute h-5 w-5 right-2 top-5"
                    src={InfoIcon}
                  />
                </InfoTooltip>
                <img
                  className="absolute h-6 w-6  right-8 top-[18px]"
                  src={AddWhite}
                  onClick={() => handleClickOpen(1)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="flex-1 flex items-center  relative border-l-[1px]">
                <img className="ml-6" src={InfoIcon} />
                <div className="flex flex-col ml-1">
                  <div className="text-white text-xs max-w-24 font-semibold font-['Outfit']">
                    İlgili Paydaş İçin Job(s) To Be Done
                  </div>
                  <div className="text-white text-[10px] font-semibold font-['Outfit']">
                    (Yapılacak İş)
                  </div>
                </div>
                <InfoTooltip title={tooltipTexts.jtbdCanvas.jobsToBeDone}>
                  <img
                    className="absolute h-5 w-5 right-2 top-5"
                    src={InfoIcon}
                  />
                </InfoTooltip>
                <img
                  className="absolute h-6 w-6  right-8 top-[18px]"
                  src={AddWhite}
                  onClick={() => handleClickOpen(2)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="flex-1 flex items-center  relative border-l-[1px]">
                <img className="ml-6" src={InfoIcon} />
                <div className="flex flex-col ml-1">
                  <div className="text-white text-xs font-semibold font-['Outfit']">
                    Fonksiyonel Bileşen
                  </div>
                  <div className="text-white text-[10px] font-semibold font-['Outfit']"></div>
                </div>
                <InfoTooltip
                  title={tooltipTexts.jtbdCanvas.functionalComponent}
                >
                  <img
                    className="absolute h-5 w-5 right-2 top-5"
                    src={InfoIcon}
                  />
                </InfoTooltip>
                <img
                  className="absolute h-6 w-6  right-8 top-[18px]"
                  src={AddWhite}
                  onClick={() => handleClickOpen(3)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className=" flex items-center  relative flex-1 border-l-[1px]">
                <img className="ml-6" src={InfoIcon} />
                <div className="flex flex-col ml-1">
                  <div className="text-white text-xs font-semibold font-['Outfit']">
                    Duygusal Bileşen
                  </div>
                  <div className="text-white text-[10px] font-semibold font-['Outfit']"></div>
                </div>
                <InfoTooltip title={tooltipTexts.jtbdCanvas.emotionalComponent}>
                  <img
                    className="absolute h-5 w-5 right-2 top-5"
                    src={InfoIcon}
                  />
                </InfoTooltip>
                <img
                  className="absolute h-6 w-6  right-8 top-[18px]"
                  src={AddWhite}
                  onClick={() => handleClickOpen(4)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className=" flex items-center  relative flex-1 border-l-[1px]">
                <img className="ml-6" src={InfoIcon} />
                <div className="flex flex-col ml-1">
                  <div className="text-white text-xs font-semibold font-['Outfit']">
                    Sosyal Bileşen
                  </div>
                  <div className="text-white text-[10px] font-semibold font-['Outfit']"></div>
                </div>
                <InfoTooltip title={tooltipTexts.jtbdCanvas.socialComponent}>
                  <img
                    className="absolute h-5 w-5 right-2 top-5"
                    src={InfoIcon}
                  />
                </InfoTooltip>
                <img
                  className="absolute h-6 w-6  right-8 top-[18px]"
                  src={AddWhite}
                  onClick={() => handleClickOpen(5)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="w-full min-h-[495px]  rounded-bl-[20px] rounded-br-[20px] flex">
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="flex flex-1">
                  {Object.entries(columns).map(([columnId, column]) => (
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="flex-1 flex flex-col items-center p-1 border-l-[1px]"
                          style={{
                            width: "20%",
                            flex: 1,
                            minWidth: "200px",
                            backgroundColor: snapshot.isDraggingOver
                              ? "#f0f0f0"
                              : "transparent",
                          }}
                        >
                          {column.items.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="w-full m-2 min-h-[85px] bg-[#a85f67] rounded-[10px] border flex-col border-[#e0e0e0] p-3 flex relative"
                                  style={{
                                    ...provided.draggableProps.style,
                                    opacity: snapshot.isDragging ? "0.5" : "1",
                                  }}
                                >
                                  <div className="text-white text-sm font-semibold font-['Outfit']">
                                    {item.content}
                                  </div>
                                  <img
                                    src={DeleteItem}
                                    className="absolute right-2 top-2"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent event bubbling
                                      handleDeleteItem(columnId, item.id);
                                    }}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </DragDropContext>
            </div>
          </div>

          <div className="w-full h-28 relative border rounded-2xl border-[#c8c8c8] mt-[25px]">
            <div className="w-[300px] h-28 left-0 top-0 absolute bg-[#a95f97] rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
              <img className="absolute  right-2 top-2" src={InfoIcon} />

              <div className=" ml-2 p-6">
                <span className="text-white text-lg  font-normal font-['Outfit'] ">
                  İş İfadesi En Önemli Yapılacak İşiniz Nedir? (Tek Cümle İle) :
                </span>
              </div>
            </div>
            <div className="ml-[300px] p-2  h-12">
              <textarea
                maxLength={350}
                id="message"
                rows="4"
                className="block w-full text-sm border-none overflow-auto outline-none h-22 font-['Outfit']"
                placeholder="Buraya yazınız"
                value={jobStatement}
                onChange={(e) => setJobStatement(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="w-full min-h-[153px] mt-5 rounded-[20px] border border-[#c8c8c8] overflow-y-auto">
            <div className="w-full h-[42px] bg-[#5F6FA8] rounded-tl-[20px] rounded-tr-[20px] flex sticky top-0">
              <div className="flex-1 flex items-center  relative justify-center">
                <div className=" text-white text-lg font-normal font-['Outfit']">
                  Ne Zaman - Kim (Paydaş) - Durum{" "}
                </div>
              </div>
              <div className="flex-1 flex items-center  relative border-l-[1px] justify-center">
                <div className=" text-white text-lg font-normal font-['Outfit']">
                  İstekler (Motivasyon)
                </div>
              </div>
              <div className="flex-1 flex items-center  relative border-l-[1px] justify-center">
                <div className=" text-white text-lg font-normal font-['Outfit']">
                  Böylelikle (İstenilen Sonuç)
                </div>
              </div>
            </div>
            <div className="w-full min-h-[111px] rounded-bl-[20px] rounded-br-[20px] flex">
              <div className="flex-1">
                <textarea
                  className="w-full min-h-[96px] resize-y border-none outline-none p-2 font-['Outfit']"
                  placeholder="Buraya yazınız"
                  value={whenWhoSituation}
                  maxLength={350}
                  onChange={(e) => setWhenWhoSituation(e.target.value)}
                  style={{ height: "auto", overflow: "hidden" }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                ></textarea>
              </div>
              <div className="flex-1 border-l-[1px]">
                <textarea
                  className="w-full min-h-[96px] resize-y border-none outline-none p-2 font-['Outfit']"
                  placeholder="Buraya yazınız"
                  value={desires}
                  maxLength={350}
                  onChange={(e) => setDesires(e.target.value)}
                  style={{ height: "auto", overflow: "hidden" }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                ></textarea>
              </div>
              <div className="flex-1 border-l-[1px]">
                <textarea
                  className="w-full min-h-[96px] resize-y border-none outline-none p-2 font-['Outfit']"
                  placeholder="Buraya yazınız"
                  value={desiredOutcome}
                  maxLength={350}
                  onChange={(e) => setDesiredOutcome(e.target.value)}
                  style={{ height: "auto", overflow: "hidden" }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                backChange={backPage}
                nextChange={handleNext}
                activeStep={2}
                stepLimit={4}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <div className="w-[500px] p-6 bg-white">
            <div className="text-xl font-semibold mb-4">Yeni Öğe Ekle</div>
            <textarea
              value={newItemContent}
              onChange={(e) => setNewItemContent(e.target.value)}
              className="w-full p-2 border rounded-lg mb-4 min-h-[100px]"
              placeholder="İçerik giriniz..."
              maxLength={110}
            />
            <div className="flex justify-end gap-2">
              <button
                onClick={handleClose}
                className="px-4 py-2 border rounded-lg"
              >
                İptal
              </button>
              <button
                onClick={handleAddItem}
                className="px-4 py-2 bg-[#169888] text-white rounded-lg"
              >
                Ekle
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </LoadingFullPage>
  );
}
