import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Information({ nextChange, backChange }) {
  return (
    <div>
      <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        İhracat niyetiniz ve potansiyeliniz iyi bir durumdaysa sıra geldi
        yurtdışı hedef pazar belirlemeye!{" "}
      </div>
      <div className="w-[243px] text-[#797979] text-lg font-semibold font-['Outfit']">
        Haydi gelin şimdi başlayalım!
      </div>
      <br />
      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        Yurtdışı hedef pazar belirlerken ilk konu başlıklardan biri ürünlerinizi
        pazarlayabileceğiniz ülke seçimlerini doğru yapabilmektedir. Bunun için
        öncellikle ilgili ürün/hizmet grubunuz ve/veya GTİP (Gümrük Tarife
        İstatistik Pozisyonu) numaranızı belirlemeniz gerekmektedir. Bunun için
        Ticaret Bakanlığı ve TİM gibi kurum ve kuruşların arama portallarını
        kullanabilirsiniz. Trademap de bu konuda size yardımcı olacaktır. Daha
        sonra aşağıdaki platform ve araçları kullanarak yurtdışı potansiyel
        hedef ülkeleri belirleyebilirsiniz: <br />{" "}
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Market Finder - Google:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Google'un bu aracı ilgili ürün/hizmet grubuna göre en iyi 3 hedef
          pazarı sizlere sunar.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Keyword Planner - Google Ads:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Google Ads içinde bulunan Keyword Planner (Anahtar Kelime Planyacı)'ı
          kullanarak anahtar kelimelerinizin ilgili yerel ve global dillerde
          arama potansiyellerine ulaşabilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Ülkelerin Periyodik İthalat
          Rakamlarına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde en çok ithalat gerçekleştiren ülkeleri tespit
          edebilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Ülkelerin Periyodik İthalat
          Artışına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde en çok ithalat artışını gerçekleştiren ülkeleri tespit
          edebilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Türkiye'den Periyodik
          İhracat Rakamlarına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde Türkiye'den en çok ithalat gerçekleştirmiş ülkeleri tespit
          edebilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Türkiye'den Periyodik
          İhracat Artışına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde Türkiye'den en çok ithalat artışını gerçekleştirmiş
          ülkeleri tespit edebilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Periyodik İthalat Azalışına
          ve Türkiye'den İhracat Artışına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde toplam ithalatını azaltmış ama Türkiye'den ithalatını
          artırmış ülkeleri tespit edebilirsiniz.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Periyodik İthalat Artışına
          ve Türkiye'den İhracat Artışına Göre Hedef Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde toplam ithalatını ve Türkiye'den ithalatını artırmış
          ülkeleri tespit edebilirsiniz.
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          TradeMap - ITC - İlgili Ürün Grubuna Göre Periyodik İthalatını Artıran
          fakat Türkiye'den İhracatını Azaltan Pazarlar:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Trademap'te GTİP koduna göre ürün grubunuza ilişkin yıllar/aylar
          içerisinde toplam ithalatını artırmış fakat Türkiye'den ithalatını
          azaltmış ülkeleri tespit edebilirsiniz. Bu diğer başlıkların aksine
          daha mesafeli yaklaşmanız gereken ülkelerdir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Kolay İhracat Platformu - Ticaret Bakanlığı:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Ticaret Bakanlığı'nın Kolay İhracat Platformu da benzer şekilde
          faydalanabileceğiniz bir platformdur.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          The Observatory of Economic Complexity - MIT:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          MIT destekli kurulan OEC platformunda ürün grubunuzun hedef ülkede
          veya bölgede ithalat rakamlarını inceleyebilirsiniz.{" "}
        </span>
      </div>
      <div className="mt-8">
        <Stepper
          backChange={backChange}
          nextChange={nextChange}
          activeStep={2}
          stepLimit={4}
        />
      </div>
    </div>
  );
}
