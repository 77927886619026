import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const marketAnalysisService = {
  async getAnswers(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/market-analysis/${projectId}/answers`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async saveAnswers(projectId, answers) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/strategic-plan/market-analysis/${projectId}/answers`,
        answers,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
