import React, { useEffect, useState } from "react";
import GridMenu from "../../icons/grid-4.svg";
import Logo from "../../images/GP-Logo.png";
import SmallLogo from "../../images/GP-Small-Logo.svg";

import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import ArrowDown from "../../icons/arrow-down.svg";
import CircleBlue from "../../icons/circle-blue.svg";
import Circle from "../../icons/circle.svg";
import Lock from "../../icons/lock.svg";
import { setMenu } from "../../redux/slices/menuSlice";
import { useAllModulesQuery } from "../../services/subModulesService";
import LoadingFullPage from "../LoadingFullPage/LoadingFullPage";
import { ClickAwayListener } from "@mui/material";
import { toast } from "react-toastify";
import { moduleService } from "../../services/moduleService";

export default function Sidebar({ children }) {
  const [open, setOpen] = useState(true);
  const [subOpen, setSubOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);

  const menuItem = useSelector((state) => state.menu.menuItem);

  const [Menus, setMenus] = useState(() => {
    // Clear localStorage on initial load
    localStorage.removeItem("menu");
    return JSON.parse(JSON.stringify(menuItem));
  });

  const {
    isSuccess,
    error,
    data,
    isLoading: queryLoading,
  } = useAllModulesQuery();

  useEffect(() => {
    // Update Menus state when menuItem changes
    setMenus(JSON.parse(JSON.stringify(menuItem)));
  }, [menuItem]);

  useEffect(() => {
    fetchModulePermissions();
  }, []);

  const fetchModulePermissions = async () => {
    try {
      setIsLoading(true);
      const data = await moduleService.getModulePermissions();

      let MenuArray = [...Menus];
      MenuArray.forEach((menu) => {
        data.forEach((element) => {
          if (element.title === menu.title) {
            menu.lock = element.isPurchased && element.canView;

            if (element?.subModules) {
              element.subMenu?.forEach((subMenu) => {
                const matchingSubModule = element.subModules.find(
                  (subModule) => subModule.title === subMenu.title
                );
                if (matchingSubModule) {
                  subMenu.subId = matchingSubModule.id;
                }
              });
            }
          }
        });
      });

      dispatch(setMenu(JSON.parse(JSON.stringify(MenuArray))));
      localStorage.setItem("menu", JSON.stringify(MenuArray));
    } catch (error) {
      console.error("Modül bilgileri yüklenirken bir hata oluştu", error);
    } finally {
      setIsLoading(false);
    }
  };

  function onClickMenuItem(id, path, lock) {
    if (id === 0) {
      let MenuArray = [...Menus];
      MenuArray.forEach((element) => {
        element.open = false;
        element.focus = element.id === 0;
      });
      setMenus(MenuArray);
      dispatch(setMenu(JSON.parse(JSON.stringify(MenuArray))));
      navigate(path);
      return;
    }

    // Remove error toast and just return if locked
    if (!lock) {
      return;
    }

    let MenuArray = [...Menus];
    MenuArray.forEach((element) => {
      if (element.id === id) {
        element.open = !element.open;
        element.focus = true;
      } else {
        element.open = false;
        element.focus = false;
      }
    });
    setMenus(MenuArray);
    dispatch(setMenu(JSON.parse(JSON.stringify(MenuArray))));
    path && navigate(path);
  }

  function onClickSub(id, menuId, path, subId, lock) {
    let MenuArray = [...Menus];

    MenuArray.forEach((element) => {
      element.focus = element.id === menuId;

      if (element.id === menuId) {
        element.subMenu?.forEach((subElement) => {
          subElement.focus = subElement.id === id;
        });
      } else {
        element.subMenu?.forEach((subElement) => {
          subElement.focus = false;
        });
      }
    });

    setMenus(MenuArray);
    // Update Redux store instead of localStorage
    dispatch(setMenu(JSON.parse(JSON.stringify(MenuArray))));
    subId ? navigate(path + "?subId=" + subId) : navigate(path);
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Sadece ilk yüklemede otomatik olarak kapat
      if (!localStorage.getItem("sidebarState") && window.innerWidth < 1024) {
        setOpen(false);
      }
    };

    // İlk yüklemede localStorage'dan durumu al
    const savedState = localStorage.getItem("sidebarState");
    if (savedState) {
      setOpen(JSON.parse(savedState));
    }

    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // open state değiştiğinde localStorage'a kaydet
  useEffect(() => {
    localStorage.setItem("sidebarState", JSON.stringify(open));
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      // Force re-render to update dropdown positions
      setMenus([...Menus]);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [Menus]);

  console.log("Menus: ", Menus);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <LoadingFullPage state={queryLoading}>
          <div
            className="flex bg-[#F6F7FA] h-full min-h-screen"
            style={{ zIndex: 99999 }}
          >
            <div
              onClick={(e) => {
                // Only open sidebar if clicking directly on the container, not its children
              }}
              className={`
                ${open ? "w-[364px]" : "w-20"}
                ${windowWidth < 1024 ? "lg:w-20" : ""} 
                bg-dark-purple h-screen scroll p-5 pt-8 pb-8 fixed duration-300 
                bg-white rounded-tr-[10px] rounded-br-[10px] mt-5 mb-5
              `}
            >
              <img
                src={GridMenu}
                className={`absolute cursor-pointer right-5 top-9   ${
                  !open && "w-0"
                }`}
                onClick={() => setOpen(!open)}
              />
              {!open && (
                <div
                  className="absolute -right-3 top-9 bg-white rounded-full p-1.5 shadow-md cursor-pointer hover:bg-gray-50"
                  onClick={() => setOpen(true)}
                >
                  <img src={GridMenu} className="w-4 h-4" />
                </div>
              )}
              <div className="flex gap-x-4 items-center">
                <img
                  src={open ? Logo : SmallLogo}
                  className={`cursor-pointer w-[130px] duration-500 ${
                    open && " transition-all"
                  }`}
                  onClick={() => navigate("/anasayfa")}
                />
              </div>
              <div className="py-11 my-[14px] h-full overflow-auto scrollbar-hide">
                {Menus.map((Menu, index) => (
                  <>
                    <div
                      onClick={(event) =>
                        onClickMenuItem(Menu.id, Menu.navigation, Menu.lock)
                      }
                      key={index}
                      className={`flex select-none  rounded-md p-2 cursor-pointer hover:bg-light-white my-[14px] py-[11px]  text-sm items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-2"} ${
                        index === 0 && "bg-light-white"
                      } ${
                        Menu.focus === true
                          ? "bg-[#D9EBF4] text-[#439DC6]"
                          : "text-[#929398]"
                      }  ${
                        Menu.disable === true
                          ? "bg-[#D9EBF4] text-[#439DC6]"
                          : "text-[#929398]"
                      }
                  
                   `}
                    >
                      <img src={Menu.src} />
                      <span
                        className={`${
                          !open && "hidden"
                        } origin-left duration-100   text-lg font-medium font-['Outfit'] flex-1`}
                      >
                        {Menu.title}
                      </span>
                      {!Menu.lock && (
                        <img
                          src={Lock}
                          alt="Locked"
                          className={`${!open && "hidden"} w-4 h-4 ml-auto`}
                        />
                      )}
                      {Menu.subMenu && Menu.open && (
                        <img
                          className="mr-2 h-4"
                          src={ArrowDown}
                          alt=""
                          className={`${!open && "hidden"} ${
                            Menu.open ? "rotate-180" : ""
                          } duration-200 ml-auto`}
                        />
                      )}
                    </div>
                    {Menu.subMenu && Menu.open && (
                      <ClickAwayListener
                        onClickAway={() => {
                          let MenuArray = [...Menus];
                          MenuArray.forEach((element) => {
                            if (element.id === Menu.id) {
                              element.open = false;
                            }
                          });
                          setMenus(MenuArray);
                          dispatch(
                            setMenu(JSON.parse(JSON.stringify(MenuArray)))
                          );
                        }}
                      >
                        <div
                          className={`
                            ${
                              !open
                                ? "fixed bg-white shadow-lg rounded-lg py-2 z-50 w-64 border border-gray-100 z-[9999]"
                                : "ml-4"
                            }
                          `}
                          style={
                            !open
                              ? {
                                  position: "fixed",
                                  left: "80px", // Sidebar genişliği + biraz boşluk
                                  transform: `translate(0, ${-50}%)`, // Dikey hizalama için
                                  top: `${Menu.dropdownTop}px`,
                                }
                              : {}
                          }
                        >
                          {Menu.subMenu?.map((subMenu, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                onClickSub(
                                  subMenu.id,
                                  Menu.id,
                                  subMenu.navigation,
                                  subMenu.subId,
                                  Menu.lock
                                )
                              }
                              className={`
                                select-none cursor-pointer text-sm font-medium font-['Outfit']
                                ${
                                  !open
                                    ? "px-4 py-3 hover:bg-gray-50"
                                    : "my-5 flex items-center"
                                }
                                ${
                                  subMenu.focus === true
                                    ? "text-[#439DC6]"
                                    : "text-[#929398]"
                                }
                              `}
                            >
                              <div className="flex items-center gap-2">
                                <div
                                  className={`w-4 h-4 rounded-full border-2 ${
                                    subMenu.focus
                                      ? "border-[#439DC6] bg-[#439DC6]/20"
                                      : "border-[#929398]"
                                  }`}
                                />
                                <span>{subMenu.title}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </ClickAwayListener>
                    )}
                  </>
                ))}
              </div>
            </div>
            <div></div>
            <div
              className={`mt-5 mb-5 duration-300 mr-8 
                ${
                  open
                    ? "ml-[400px] w-[calc(100%-400px)]"
                    : "ml-[120px] w-[calc(100%-120px)]"
                }
                ${
                  windowWidth < 1024
                    ? "lg:ml-[120px] lg:w-[calc(100%-120px)]"
                    : ""
                }
              `}
            >
              <Outlet />
            </div>
          </div>
        </LoadingFullPage>
      )}
    </>
  );
}
