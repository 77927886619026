import React, { useState, useEffect } from "react";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import okrTrackingService from "../../../../../services/okrTrackingService";
import { handleApiError } from "../../../../../utils/errorHandler";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { FiInfo, FiClock } from "react-icons/fi";
import {
  BsCheckCircle,
  BsExclamationTriangle,
  BsXCircle,
} from "react-icons/bs";

export default function OKRTrackingTable({
  projectName,
  projectId,
  changePage,
  backPage,
  targetId,
  targetName,
}) {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [fundamentalConsequences, setFundamentalConsequences] = useState([]);
  const [tableData, setTableData] = useState({
    temelSonuc: "",
    bagliHedef: "",
    okrTuru: "",
    sorumluKisi: "",
    sayisalHedef: "",
    durum: "",
    aciklama: "",
    haftalikIlerleme: Array(13).fill(""),
  });

  useEffect(() => {
    const fetchFundamentalConsequences = async () => {
      try {
        const response = await okrTrackingService.getFundamentalConsequences(
          projectId,
          targetId
        );
        setFundamentalConsequences(response);
      } catch (error) {
        handleApiError(error, "Temel sonuçlar yüklenirken bir hata oluştu");
      }
    };

    if (targetId) {
      fetchFundamentalConsequences();
    }
  }, [targetId]);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!tableData.temelSonuc) return;

      try {
        const response =
          await okrTrackingService.getFundamentalConsequenceAnswers(
            projectId,
            tableData.temelSonuc
          );

        setTableData((prev) => ({
          ...prev,
          okrTuru: response.okrType || "",
          sorumluKisi: response.responsiblePerson || "",
          sayisalHedef: response.numericalGoal || "",
          durum: response.situation || "",
          aciklama: response.description || "",
          haftalikIlerleme: response.processMonitoring.map(
            (item) => item.value || ""
          ),
        }));
      } catch (error) {
        handleApiError(error, "Yanıtlar yüklenirken bir hata oluştu");
      }
    };

    fetchAnswers();
  }, [tableData.temelSonuc]);

  const handleInputChange = (field, value) => {
    setTableData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    let originalStyle = "";
    const originalElements = [];

    try {
      const container = document.getElementById("okr-tracking-container");
      if (!container) {
        throw new Error("Container not found");
      }

      // Store original styles
      originalStyle = container.style.cssText;

      // Set temporary container styles for PDF
      container.style.cssText = `
        width: 1000px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Handle textareas and selects
      const textareas = container.getElementsByTagName("textarea");
      const selects = container.getElementsByTagName("select");

      // Convert textareas to divs
      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder || "";
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      // Convert selects to divs
      Array.from(selects).forEach((select) => {
        const div = document.createElement("div");
        const selectedOption = select.options[select.selectedIndex];
        div.textContent = selectedOption ? selectedOption.text : "";
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: select,
          parent: select.parentNode,
        });

        select.parentNode.replaceChild(div, select);
      });

      try {
        await downloadPageAsPDF(
          "okr-tracking-container",
          "OKR İzleme Tablosu",
          projectName || "Project"
        );
      } catch (error) {
        console.error("PDF generation error:", error);
        throw new Error("PDF oluşturulurken bir hata oluştu");
      }
    } catch (error) {
      console.error("PDF process error:", error);
      handleApiError(error, "PDF oluşturulurken bir hata oluştu");
    } finally {
      try {
        // Get container again in case it was removed from DOM
        const container = document.getElementById("okr-tracking-container");
        if (container) {
          // Restore original state
          container.style.cssText = originalStyle;

          // Restore original elements
          originalElements?.forEach(({ element, parent }) => {
            if (parent && parent.firstChild) {
              const currentElement = parent.firstChild;
              parent.replaceChild(element, currentElement);
            }
          });
        }
      } catch (cleanupError) {
        console.error("Cleanup error:", cleanupError);
      }
      setIsPdfLoading(false);
    }
  };

  const handleNext = async () => {
    const emptyWeeks = tableData.haftalikIlerleme.filter(
      (week) => !week.trim()
    );
    if (emptyWeeks.length > 0) {
      handleApiError(
        null,
        "Lütfen tüm haftalık ilerleme alanlarını doldurunuz"
      );
      return;
    }

    try {
      const data = {
        okrType: tableData.okrTuru,
        responsiblePerson: tableData.sorumluKisi,
        numericalGoal: tableData.sayisalHedef,
        situation: tableData.durum,
        description: tableData.aciklama,
        processMonitoring: tableData.haftalikIlerleme.map((value) => ({
          value,
        })),
      };

      await okrTrackingService.saveAnswers(
        projectId,
        tableData.temelSonuc,
        data
      );
      changePage();
    } catch (error) {
      handleApiError(error, "Kaydetme sırasında bir hata oluştu");
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div className="w-full max-w-[1136px] mx-auto">
        <div className="w-[1142px]  text-[#797979] text-lg font-normal font-['Outfit']">
          3 aylık dönemlerde döngüyü tamamlamanız gerektiği için 13 haftalık bir
          ilerleme takibi oluşturun. Size sunduğumuz OKR İzleme Şablonu'ndan
          bunu yönetebilirsiniz. Özellikle taahhütlü OKR'leri haftalık;
          diğerlerini en geç aylık gözden geçirin. 13 haftalık döngü sonucunda
          ayrıca bir değerlendirme gerçekleştirin. Gözden geçirme
          toplantılarınız 2-3 saati aşmasın. Daha stratejik OKR'ler için bu süre
          4 saatten 2 güne kadar çıkabilir. Aylık gözden geçirmelerde hedefe
          ulaşıp ulaşamayacağınızı değerlendirin, buna göre gerekirse yeni
          görevler tanımlayın veya bazı görevleri yapmaktan vazgeçin. Durumunuza
          göre (İyi Durumda – Takip Edilmesi Gerekiyor - Riskli) plan
          geliştirin. Özellikle Riskli KR'lar için yardım isteyin, ek kaynaklar
          edinin, acil olmayan diğer görevleri erteleyin.
          <br />{" "}
        </div>
        <br />
        <div className="w-[1142px]  text-[#797979] text-lg font-normal font-['Outfit']">
          3 Aylık dönemin sonunda %100'e yakın bir performans sergilediyseniz
          döngüyü başarılı kapatıp bir öğrenilmiş dersler şablonu
          doldurabilirsiniz. OKR'larınızda %70 ve üstü oranda bir başarı elde
          ettiyseniz bu da bir başarı göstergesi olabilir; ancak taahhütlü
          OKR'larınız için bu rakam kâfi olmayabilir. Hedefin (O) çok iddialı
          olup olmadığını veya yanlış Temel Sonuçları (KRs) seçip seçmediğinizi
          değerlendirmeniz gerekebilir. Yanlış öncelikleri belirlemiş
          olabilirsiniz veya bir şeyi tamamlamak için gereken görevleri yerine
          getirecek disiplinden yoksun olabilirsiniz. Bu değerlendirme ve
          öğrenme, OKR'lerde uzmanlaşmak ve deneyim elde etmek için çok
          önemlidir. Tamamlanmamış hedefler hala üzerinde çalışılmaya değerse
          bir sonraki OKR döngüsüne de aktarılabilir
        </div>
        <br />
        <div className="w-[1142px]  text-[#797979] text-lg font-normal font-['Outfit']">
          OKR İzleme Şablonu'nda KR'ler için belirlediğiniz sayısal hedefi
          muhakkak ayrıca yazınız. Örneğin "Ankara bölgesinde özel promosyon ve
          kampanyalar düzenleyerek mevcut müşteri satış hacmini %20 artırmak"
          için yüzde 20 ifadesini ayrıca belirtin ve her hafta satış hacminin
          yüzde kaç artığını ilerleme bölüme kaydedin.
        </div>
        <br />
        {/* Main OKR Table */}
        <div
          id="okr-tracking-container"
          className="border border-[#e0e0e0] rounded-lg p-4"
        >
          <div className="overflow-x-auto border rounded-lg border-[#e0e0e0] ">
            <table className="w-full min-w-[800px]">
              <thead>
                <tr className="bg-[#55917f] text-white">
                  <th className="p-3 text-left border-r">Temel Sonuçlar</th>
                  <th className="p-3 text-left border-r">Bağlı Olduğu Hedef</th>
                  <th className="p-3 text-left border-r">OKR Türü</th>
                  <th className="p-3 text-left border-r">Sorumlu Kişi</th>
                  <th className="p-3 text-left border-r">Sayısal Hedef</th>
                  <th className="p-3 text-left flex items-center">
                    <span>Durumu</span>
                    <FiInfo
                      className="ml-2"
                      size={16}
                      title="İyi Durumda, Takip Edilmesi Gerekiyor veya Riskli"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border-r">
                    <select
                      className="w-full p-2 min-h-[90px] border rounded focus:outline-none whitespace-normal overflow-hidden text-ellipsis"
                      value={tableData.temelSonuc}
                      onChange={(e) =>
                        handleInputChange("temelSonuc", e.target.value)
                      }
                      style={{
                        lineHeight: "1.5",
                        height: "auto",
                        WebkitLineClamp: 3,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      <option value="">Lütfen seçim yapınız</option>
                      {fundamentalConsequences.map((consequence) => (
                        <option
                          key={consequence.id}
                          value={consequence.id}
                          style={{
                            whiteSpace: "normal",
                            height: "auto",
                            lineHeight: "1.5",
                            padding: "4px 0",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {consequence.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="p-2 border-r">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={targetName}
                      disabled={true}
                    />
                  </td>
                  <td className="p-2 border-r">
                    <select
                      className="w-full p-2 border rounded"
                      value={tableData.okrTuru}
                      onChange={(e) =>
                        handleInputChange("okrTuru", e.target.value)
                      }
                    >
                      <option value="">Seçiniz</option>
                      <option value="taahhutlu">Taahhütlü</option>
                      <option value="istekli">İstekli</option>
                    </select>
                  </td>
                  <td className="p-2 border-r">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={tableData.sorumluKisi}
                      onChange={(e) =>
                        handleInputChange("sorumluKisi", e.target.value)
                      }
                    />
                  </td>
                  <td className="p-2 border-r">
                    <input
                      type="text"
                      className="w-full p-2 border rounded"
                      value={tableData.sayisalHedef}
                      onChange={(e) =>
                        handleInputChange("sayisalHedef", e.target.value)
                      }
                    />
                  </td>
                  <td className="p-2">
                    <select
                      className="w-full p-2 border rounded"
                      value={tableData.durum}
                      onChange={(e) =>
                        handleInputChange("durum", e.target.value)
                      }
                    >
                      <option value="">Seçiniz</option>
                      <option value="takip" className="flex items-center">
                        <BsExclamationTriangle className="text-yellow-500 mr-2" />
                        Takip Edilmesi Gerekiyor
                      </option>
                      <option value="iyi" className="flex items-center">
                        <BsCheckCircle className="text-green-500 mr-2" />
                        İyi Durumda
                      </option>
                      <option value="riskli" className="flex items-center">
                        <BsXCircle className="text-red-500 mr-2" />
                        Riskli
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {!tableData.temelSonuc ? (
            <div className="mt-8 text-center p-8 bg-gray-50 rounded-lg">
              <div className="text-lg text-gray-500">
                Lütfen bir temel sonuç seçiniz
              </div>
            </div>
          ) : (
            <>
              {/* Açıklama Section */}
              <div className="mt-8 bg-gray-50 p-4 rounded-lg">
                <div className="font-medium mb-2 flex items-center">
                  <FiInfo className="text-[#55917f] mr-2" size={20} />
                  Açıklama:
                </div>
                <textarea
                  className="w-full p-3 border rounded-lg min-h-[100px] resize-none"
                  value={tableData.aciklama}
                  onChange={(e) =>
                    handleInputChange("aciklama", e.target.value)
                  }
                  placeholder="Açıklama giriniz..."
                  maxLength={500}
                />
              </div>

              {/* İlerleme Takibi Section */}
              <div className="mt-8">
                <div className="font-medium mb-4 text-2xl text-[#2C3E50] border-b-2 border-[#55917f] pb-2 flex items-center">
                  <FiClock className="text-[#55917f] mr-2" size={24} />
                  Haftalık İlerleme Takibi ve Değerlendirme
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {tableData.haftalikIlerleme.map((ilerleme, index) => (
                    <div key={index} className="flex items-start gap-4">
                      <div className="w-24 flex-shrink-0 font-medium pt-2">
                        {index + 1}. Hafta
                      </div>
                      <textarea
                        className="flex-1 p-2 border rounded min-h-[60px] resize-none"
                        value={ilerleme}
                        onChange={(e) => {
                          const newIlerleme = [...tableData.haftalikIlerleme];
                          newIlerleme[index] = e.target.value;
                          handleInputChange("haftalikIlerleme", newIlerleme);
                        }}
                        placeholder="İlerleme notlarınızı giriniz..."
                        maxLength={200}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full flex items-center">
          <div className="w-full lg:w-[600px] flex-1">
            <div className="w-full pr-2 md:pr-4 lg:pr-10">
              <Stepper
                nextChange={handleNext}
                backChange={backPage}
                activeStep={1}
                stepLimit={2}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
