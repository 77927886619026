import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { pestleAnalysisService } from "../../../../../services/pestleAnalysisService";
import { toast } from "react-toastify";
import {
  FaBalanceScale,
  FaGlobe,
  FaUsers,
  FaLandmark,
  FaPlusCircle,
  FaTrash,
} from "react-icons/fa";
import { BiMoney } from "react-icons/bi";
import { BsLaptop } from "react-icons/bs";
import { FiPlusCircle } from "react-icons/fi";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

const factors = [
  {
    id: "politik",
    name: "Politik",
    icon: <FaLandmark className="w-5 h-5 text-[#439DC6]" />,
  },
  {
    id: "ekonomik",
    name: "Ekonomik",
    icon: <BiMoney className="w-5 h-5 text-[#439DC6]" />,
  },
  {
    id: "sosyoKulturel",
    name: "Sosyo-Kültürel",
    icon: <FaUsers className="w-5 h-5 text-[#439DC6]" />,
  },
  {
    id: "teknolojik",
    name: "Teknolojik",
    icon: <BsLaptop className="w-5 h-5 text-[#439DC6]" />,
  },
  {
    id: "cevresel",
    name: "Çevresel",
    icon: <FaGlobe className="w-5 h-5 text-[#439DC6]" />,
  },
  {
    id: "yasal",
    name: "Yasal",
    icon: <FaBalanceScale className="w-5 h-5 text-[#439DC6]" />,
  },
];

export default function EnvironmentalAnalysisTable({
  changePage,
  backPage,
  projectId,
  projectName,
  periodId,
  periodName,
  productName,
}) {
  const [factorRows, setFactorRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [editedContents, setEditedContents] = useState({});
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  useEffect(() => {
    fetchFactors();
  }, [projectId]);

  const fetchFactors = async () => {
    try {
      const response = await pestleAnalysisService.getFactors(projectId);
      const factorsMap = {};
      response.forEach((container) => {
        factorsMap[container.container.toLowerCase()] = container.factors || [];
      });
      setFactorRows(factorsMap);
      setLoading(false);
    } catch (error) {
      toast.error("Faktörler yüklenirken bir hata oluştu");
      setLoading(false);
    }
  };

  const handleAddFactor = async (factorId) => {
    try {
      const data = {
        containerId: getContainerIdFromFactorId(factorId),
        content: "Yeni Faktör",
        importanceDegree: 1,
        isOppurtunity: false,
        isThreat: false,
      };

      const response = await pestleAnalysisService.addFactor(projectId, data);
      setFactorRows((prev) => ({
        ...prev,
        [factorId]: [...(prev[factorId] || []), response],
      }));
    } catch (error) {
      toast.error("Faktör eklenirken bir hata oluştu");
    }
  };

  const handleDeleteFactor = async (factorId, factorItemId) => {
    try {
      await pestleAnalysisService.deleteFactor(projectId, factorItemId);
      setFactorRows((prev) => ({
        ...prev,
        [factorId]: prev[factorId].filter((item) => item.id !== factorItemId),
      }));
    } catch (error) {
      toast.error("Faktör silinirken bir hata oluştu");
    }
  };

  const handleContentChange = async (factorId, factorItemId, content) => {
    try {
      const currentFactor = factorRows[factorId].find(
        (item) => item.id === factorItemId
      );
      if (!currentFactor) {
        console.error("Factor not found:", { factorId, factorItemId });
        toast.error("Faktör bulunamadı");
        return;
      }

      const updatedFactor = {
        content: content.trim(),
        importanceDegree: currentFactor.importanceDegree || 1,
        isThreat: currentFactor.isThreat || false,
      };

      await pestleAnalysisService.updateFactor(
        projectId,
        factorItemId,
        updatedFactor
      );

      setFactorRows((prev) => ({
        ...prev,
        [factorId]: prev[factorId].map((item) =>
          item.id === factorItemId ? { ...item, content: content.trim() } : item
        ),
      }));

      // Clear the edited state for this row
      setEditedContents((prev) => {
        const newState = { ...prev };
        delete newState[factorItemId];
        return newState;
      });

      toast.success("Faktör içeriği güncellendi");
    } catch (error) {
      console.error("Error updating factor content:", error);
      if (error.response?.data?.message) {
        toast.error(`Hata: ${error.response.data.message}`);
      } else {
        toast.error("Faktör içeriği güncellenirken bir hata oluştu");
      }
    }
  };

  const handleImportanceChange = async (factorId, factorItemId, value) => {
    try {
      const currentFactor = factorRows[factorId].find(
        (item) => item.id === factorItemId
      );
      const updatedFactor = {
        content: currentFactor.content,
        importanceDegree: value,
        isThreat: currentFactor.isThreat,
      };

      await pestleAnalysisService.updateFactor(
        projectId,
        factorItemId,
        updatedFactor
      );
      setFactorRows((prev) => ({
        ...prev,
        [factorId]: prev[factorId].map((item) =>
          item.id === factorItemId ? { ...item, importanceDegree: value } : item
        ),
      }));
    } catch (error) {
      toast.error("Önem derecesi güncellenirken bir hata oluştu");
    }
  };

  const handleThreatChange = async (factorId, factorItemId, type) => {
    try {
      const currentFactor = factorRows[factorId].find(
        (item) => item.id === factorItemId
      );

      // Calculate new state based on current state and button clicked
      let newState;
      if (type === "oppurtunity") {
        newState = {
          isOppurtunity: !currentFactor.isOppurtunity,
          isThreat: currentFactor.isThreat, // Keep current threat state
        };
      } else {
        newState = {
          isOppurtunity: currentFactor.isOppurtunity, // Keep current opportunity state
          isThreat: !currentFactor.isThreat,
        };
      }

      const updatedFactor = {
        content: currentFactor.content,
        importanceDegree: currentFactor.importanceDegree,
        ...newState,
      };

      await pestleAnalysisService.updateFactor(
        projectId,
        factorItemId,
        updatedFactor
      );

      setFactorRows((prev) => ({
        ...prev,
        [factorId]: prev[factorId].map((item) =>
          item.id === factorItemId ? { ...item, ...newState } : item
        ),
      }));
    } catch (error) {
      toast.error("Fırsat/Tehdit durumu güncellenirken bir hata oluştu");
    }
  };

  const getContainerIdFromFactorId = (factorId) => {
    const mapping = {
      politik: 1,
      ekonomik: 2,
      sosyoKulturel: 3,
      teknolojik: 4,
      cevresel: 5,
      yasal: 6,
    };
    return mapping[factorId];
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    let container = null;
    const originalElements = [];

    try {
      container = document.getElementById("page-content-container");
      if (!container) {
        throw new Error("Container element not found");
      }

      // Store original styles
      const originalStyle = container.style.cssText;

      // Set temporary container styles for PDF
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Handle textareas and select elements
      const textareas = container.getElementsByTagName("textarea");
      const selects = container.getElementsByTagName("select");

      // Handle textareas
      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = (
          textarea.value ||
          textarea.placeholder ||
          ""
        ).toString();
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      // Handle select elements
      Array.from(selects).forEach((select) => {
        const div = document.createElement("div");
        const selectedOption = select.options[select.selectedIndex];
        div.textContent = selectedOption ? selectedOption.text : "";
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          text-align: center;
        `;

        originalElements.push({
          element: select,
          parent: select.parentNode,
        });

        select.parentNode.replaceChild(div, select);
      });

      await downloadPageAsPDF(
        "page-content-container",
        "Çevre Analizi",
        projectName || "",
        periodName,
        productName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error(
        error.message === "Container element not found"
          ? "PDF oluşturmak için gerekli alan bulunamadı"
          : "PDF oluşturulurken bir hata oluştu"
      );
    } finally {
      try {
        if (container) {
          // Get container again in case it was removed from DOM
          const currentContainer = document.getElementById(
            "page-content-container",
            periodName,
            productName
          );
          if (currentContainer) {
            // Restore original elements
            originalElements.forEach(({ element, parent }) => {
              if (parent && parent.firstChild) {
                const currentElement = parent.firstChild;
                parent.replaceChild(element, currentElement);
              }
            });
          }
        }
      } catch (cleanupError) {
        console.error("Cleanup error:", cleanupError);
      }
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={loading || isPdfLoading}>
      <div id="page-content-container" className="w-full">
        <div className="w-full mx-auto">
          <div className="text-[#242731] text-xl font-bold font-['Outfit'] text-center mb-10">
            Çevre Analizi
          </div>

          <div className="bg-white rounded-xl border border-[#e0e0e0]">
            {/* Header Row */}

            {/* Content Rows */}
            <div className="border-t border-[#e0e0e0]">
              {factors.map((factor) => (
                <div
                  key={factor.id}
                  className="flex flex-col border-b border-[#e0e0e0]"
                >
                  {/* Factor Type Header */}
                  <div className="flex items-center p-4 bg-gray-50">
                    <div className="flex items-center gap-2">
                      <span>{factor.icon}</span>
                      <span className="font-semibold">{factor.name}</span>
                    </div>
                    <button
                      onClick={() => handleAddFactor(factor.id)}
                      className="ml-4 px-3 py-1 bg-[#F49D37] text-white rounded-md hover:bg-[#e08b26] flex items-center gap-2"
                    >
                      <FiPlusCircle className="w-4 h-4" />
                      <span>Faktör Ekle</span>
                    </button>
                  </div>

                  {/* Content Header */}
                  <div className="flex items-center p-4 bg-[#F8F9FB] border-y border-[#e0e0e0]">
                    <div className="flex-1">
                      <span className="text-[#242731] text-sm font-semibold">
                        Faktörler
                      </span>
                    </div>
                    <div className="w-32 text-center">
                      <div className="text-[#242731] text-sm font-semibold">
                        Önem Derecesi
                      </div>
                      <div className="text-[#797979] text-xs">
                        (1 = Az, 5 = Çok)
                      </div>
                    </div>
                    <div className="w-44 text-center">
                      <span className="text-[#242731] text-sm font-semibold">
                        Fırsat mı Tehdit mi
                      </span>
                    </div>
                  </div>

                  {/* Content Rows */}
                  {factorRows[factor.id]?.length > 0 ? (
                    factorRows[factor.id].map((row) => (
                      <div
                        key={row.id}
                        className="flex items-center p-4 border-t border-[#e0e0e0]"
                      >
                        <div className="flex-1 flex flex-col gap-2">
                          <div className="relative">
                            <textarea
                              value={
                                editedContents[row.id] === undefined
                                  ? row.content
                                  : editedContents[row.id]
                              }
                              onChange={(e) => {
                                setEditedContents((prev) => ({
                                  ...prev,
                                  [row.id]: e.target.value,
                                }));
                              }}
                              className="flex-1 p-2 border rounded-md min-h-[80px] resize-none w-full pr-32"
                              placeholder="Faktör içeriği..."
                            />
                            {editedContents[row.id] !== undefined &&
                              editedContents[row.id] !== row.content && (
                                <button
                                  onClick={() =>
                                    handleContentChange(
                                      factor.id,
                                      row.id,
                                      editedContents[row.id]
                                    )
                                  }
                                  className="absolute top-2 right-2 px-3 py-1 bg-[#F49D37] text-white rounded-md hover:bg-[#e08b26] text-sm"
                                >
                                  Kaydet
                                </button>
                              )}
                          </div>
                        </div>

                        <div className="flex items-center ml-4 space-x-4">
                          {[1, 2, 3, 4, 5].map((value) => (
                            <label
                              key={value}
                              className="flex flex-col items-center"
                            >
                              <input
                                type="radio"
                                name={`importance-${row.id}`}
                                value={value}
                                checked={row.importanceDegree === value}
                                onChange={() =>
                                  handleImportanceChange(
                                    factor.id,
                                    row.id,
                                    value
                                  )
                                }
                                className="mb-1"
                              />
                              <span className="text-xs">{value}</span>
                            </label>
                          ))}
                        </div>

                        <div className="flex items-center ml-4 space-x-2">
                          <button
                            onClick={() =>
                              handleThreatChange(
                                factor.id,
                                row.id,
                                "oppurtunity"
                              )
                            }
                            className={`px-3 py-1 rounded-md ${
                              row.isOppurtunity
                                ? "bg-green-500 text-white"
                                : "bg-gray-200"
                            }`}
                          >
                            Fırsat
                          </button>
                          <button
                            onClick={() =>
                              handleThreatChange(factor.id, row.id, "threat")
                            }
                            className={`px-3 py-1 rounded-md ${
                              row.isThreat
                                ? "bg-red-500 text-white"
                                : "bg-gray-200"
                            }`}
                          >
                            Tehdit
                          </button>
                        </div>

                        <button
                          onClick={() => handleDeleteFactor(factor.id, row.id)}
                          className="ml-4 px-2 py-1 text-red-500 hover:text-red-700"
                        >
                          <span>
                            <FaTrash className="w-5 h-5 text-[#439DC6]" />
                          </span>
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-center justify-center p-8 text-gray-500 border-t border-[#e0e0e0]">
                      <div className="text-6xl mb-4">
                        <FaPlusCircle className="w-5 h-5 text-[#439DC6]" />
                      </div>
                      <p className="text-lg font-medium">
                        Henüz faktör eklemediniz
                      </p>
                      <p className="text-sm mt-2">
                        Yukarıdaki "Faktör Ekle" butonunu kullanarak yeni faktör
                        ekleyebilirsiniz
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <Stepper
            nextChange={changePage}
            backChange={backPage}
            activeStep={2}
            stepLimit={2}
            onPdfDownload={handlePdfDownload}
          />
        </div>
      </div>
    </LoadingFullPage>
  );
}
