import React, { useEffect, useState } from "react";
import Header from "../../../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import Project from "../Project";
import Information from "./Informations/Information";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DifficultyMarket from "./Modules/DifficultyMarket";
import RivalryMarket from "./Modules/RivalryMarket";
import SizeMarket from "./Modules/SizeMarket";
import AnalysisMarket from "./Modules/AnalysisMarket";

export default function Index() {
  //initial
  const location = useLocation();
  console.log(location.pathname);
  const menuItem = useSelector((state) => state.menu.menuItem);
  const [lock, setLock] = useState(false);

  useEffect(() => {
    menuItem.forEach((item) => {
      item.subMenu?.forEach((subMenu) => {
        if (subMenu.navigation === location.pathname) {
          console.log(item.lock);
          var lockState = item.lock;
          setLock(lockState);
        }
      });
    });
  }, []);

  //services
  //states
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [selectedMarketId, setSelectedMarketId] = useState(null);

  console.log("searchParams: ", searchParams);

  const [headersText, setHeadersText] = useState([
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: ["Projeler"],
    },
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: ["Projeler", "Bilgilendirme"],
    },
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: ["Projeler", "Bilgilendirme", "Pazar Zorluğu"],
    },
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: ["Projeler", "Bilgilendirme", "Pazar Zorluğu", "Rekabet"],
    },
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: [
        "Projeler",
        "Bilgilendirme",
        "Pazar Zorluğu",
        "Rekabet",
        "Pazar Büyüklüğü",
      ],
    },
    {
      title: "Pazar Çekiciliği Belirlenmesi",
      subTexts: [
        "Projeler",
        "Bilgilendirme",
        "Pazar Zorluğu",
        "Rekabet",
        "Pazar Büyüklüğü",
        "Analiz",
      ],
    },
  ]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const changePage = (newPage) => {
    if (newPage === 0) {
      resetSearchParams();
    }
    setPageState(newPage);
  };

  const changeProject = (id) => {
    setProjectId(id);
  };

  const changeProjectName = (name) => {
    setProjectName(name);
  };

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Project
              changePage={changePage}
              chageProject={changeProject}
              changeProjectName={changeProjectName}
            />
          )}
          {pageState === 1 && (
            <Information
              changePage={() => setPageState(2)}
              backPage={() => setPageState(0)}
              projectId={projectId}
              projectName={projectName}
            />
          )}
          {pageState === 2 && (
            <DifficultyMarket
              changePage={() => setPageState(3)}
              backPage={() => setPageState(1)}
              projectId={projectId}
              projectName={projectName}
              selectedMarketId={selectedMarketId}
              setSelectedMarketId={setSelectedMarketId}
            />
          )}
          {pageState === 3 && (
            <RivalryMarket
              changePage={() => setPageState(4)}
              backPage={() => setPageState(2)}
              projectId={projectId}
              projectName={projectName}
              selectedMarketId={selectedMarketId}
              setSelectedMarketId={setSelectedMarketId}
            />
          )}
          {pageState === 4 && (
            <SizeMarket
              changePage={() => setPageState(5)}
              backPage={() => setPageState(3)}
              projectId={projectId}
              projectName={projectName}
              selectedMarketId={selectedMarketId}
              setSelectedMarketId={setSelectedMarketId}
            />
          )}
          {pageState === 5 && (
            <AnalysisMarket
              subId={searchParams.get("subId")}
              backPage={() => setPageState(4)}
              changePage={() => setPageState(0)}
              projectId={projectId}
              projectName={projectName}
            />
          )}
        </div>
      </div>
    </>
  );
}
