import React from "react";
import Modal from "../Modal/Modal";

export default function DeleteModal({
  open,
  onClose,
  onDelete,
  title = "Silme Onayı",
  message = "Bu öğeyi silmek istediğinizden emin misiniz?",
}) {
  return (
    <Modal open={open} onClose={onClose} title={title} header={true}>
      <div className="p-6 max-w-sm mx-auto">
        <p className="text-sm text-gray-600 text-center mb-6 font-['Outfit']">{message}</p>
        <div className="flex justify-center gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-all duration-200 font-['Outfit']"
          >
            İptal
          </button>
          <button
            onClick={onDelete}
            className="px-4 py-2 text-sm bg-red-500 text-white rounded-lg hover:bg-red-600 transition-all duration-200 font-['Outfit']"
          >
            Sil
          </button>
        </div>
      </div>
    </Modal>
  );
}
