import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const accountInformationService = {
  async getAccountInformation() {
    try {
      const response = await axios.get(`${API_URL}/account`, {
        headers,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
