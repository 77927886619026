import React, { useState } from "react";
import Header from "../../../../components/Header/Header.js";
import { useSearchParams } from "react-router-dom";
import InformationJTBD from "./Informaitons/InformationJTBD.js";
import JTBDCanvas from "./Modules/JTBDCanvas.js";
import InformationEmpathyMap from "./Informaitons/InformationEmpathyMap.js";
import EmpathyMap from "./Modules/EmpathyMap.js";
import Project from "../Project";

export default function ProblemDefinition() {
  //initial
  //services
  //states
  const [pageState, setPageState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState(null);

  const [headersText, setHeadersText] = useState([
    {
      title: "Problem Tanımlama",
      subTexts: ["Projeler", "Bilgilendirme"],
    },
    {
      title: "Problem Tanımlama",
      subTexts: ["Projeler", "Bilgilendirme"],
    },
    {
      title: "Problem Tanımlama",
      subTexts: ["Projeler", "Bilgilendirme", "JBTD Tablosu"],
    },
    {
      title: "Problem Tanımlama",
      subTexts: ["Projeler", "Bilgilendirme", "JBTD Tablosu", "Bilgilendirme"],
    },
    {
      title: "Problem Tanımlama",
      subTexts: [
        "Projeler",
        "Bilgilendirme",
        "JBTD Tablosu",
        "Bilgilendirme",
        "Empati Haritası",
      ],
    },
  ]);

  const resetSearchParams = () => {
    setSearchParams({});
  };

  const changePage = (newPage) => {
    if (newPage === 0) {
      resetSearchParams();
    }
    setPageState(newPage);
  };

  function chageProject(projectId) {
    setProjectId(projectId);
  }

  return (
    <>
      <Header
        title={headersText[pageState].title}
        subTexts={headersText[pageState].subTexts}
      />
      <div className="w-full flex items-center justify-center mt-[40px]">
        <div
          className="w-[1200px]  bg-white rounded-[10px] border border-neutral-200 mt-2 p-[32px]"
          id="surver-inovation"
        >
          {pageState === 0 && (
            <Project
              subId={searchParams.get("subId")}
              changePage={() => setPageState(1)}
              pageState={pageState}
              chageProject={(projectId) => chageProject(projectId)}
              changeProjectName={(projectName) => setProjectName(projectName)}
            />
          )}

          {pageState === 1 && (
            <InformationJTBD
              backPage={() => changePage(0)}
              changePage={() => changePage(2)}
            />
          )}

          {pageState === 2 && (
            <JTBDCanvas
              subId={searchParams.get("subId")}
              backPage={() => changePage(1)}
              changePage={() => changePage(3)}
              projectId={projectId}
              projectName={projectName}
              pageState={pageState}
            />
          )}

          {pageState === 3 && (
            <InformationEmpathyMap
              subId={searchParams.get("subId")}
              backPage={() => changePage(2)}
              changePage={() => changePage(4)}
              pageState={pageState}
            />
          )}

          {pageState === 4 && (
            <EmpathyMap
              subId={searchParams.get("subId")}
              backPage={() => changePage(3)}
              changePage={() => changePage(0)}
              projectId={projectId}
              projectName={projectName}
              pageState={pageState}
            />
          )}
        </div>
      </div>
    </>
  );
}
