import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const marketIndexService = {
  async getAvailableIndexes(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/available-indexes`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async addIndex(projectId, titleId, title) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/indexes`,
        { titleId, title },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteIndex(projectId, indexId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/indexes/${indexId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async getSelectedIndexes(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/market-research-and-export/foreign-market-determination/${projectId}/indexes`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
