import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AddUserModal from "../../../components/Modals/AddUserModal";
import { organizationAdminService } from "../../../services/organizationAdminService";
import DeleteModal from "../../../components/Modals/DeleteModal";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const data = await organizationAdminService.getUsers();
      // Transform API data to match component's expected format
      const transformedUsers = data.map((user) => ({
        id: user.userId,
        name: user.name,
        surname: user.surname,
        department: user.department || "",
        permissions: {
          innovationCheckup: user.availableModules.some(
            (m) => m.moduleId === 8
          ),
          customerAnalysis: user.availableModules.some((m) => m.moduleId === 1),
          internalEntrepreneurship: user.availableModules.some(
            (m) => m.moduleId === 2
          ),
          strategicPlan: user.availableModules.some((m) => m.moduleId === 3),
          marketResearch: user.availableModules.some((m) => m.moduleId === 4),
        },
      }));
      setUsers(transformedUsers);
    } catch (error) {
      toast.error("Kullanıcılar yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async (userData) => {
    try {
      await organizationAdminService.createUser(userData);
      toast.success("Kullanıcı başarıyla eklendi");
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error("Kullanıcı eklenirken bir hata oluştu");
    }
  };

  const handleUpdateUser = async (userData) => {
    try {
      await organizationAdminService.updateUser(selectedUser.id, userData);
      toast.success("Kullanıcı başarıyla güncellendi");
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error("Kullanıcı güncellenirken bir hata oluştu");
    }
  };

  const handleEdit = async (user) => {
    try {
      const userData = await organizationAdminService.getUser(user.id);
      // Transform API data to match component's format
      const transformedUser = {
        id: userData.id,
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        department: userData.department,
        cellPhone: userData.cellPhone,
        permissions: {
          innovationCheckup: userData.availableModules.some(
            (m) => m.moduleId === 8
          ),
          customerAnalysis: userData.availableModules.some(
            (m) => m.moduleId === 1
          ),
          internalEntrepreneurship: userData.availableModules.some(
            (m) => m.moduleId === 2
          ),
          strategicPlan: userData.availableModules.some(
            (m) => m.moduleId === 3
          ),
          marketResearch: userData.availableModules.some(
            (m) => m.moduleId === 4
          ),
        },
      };
      setSelectedUser(transformedUser);
      setIsAddModalOpen(true);
    } catch (error) {
      toast.error("Kullanıcı bilgileri alınırken bir hata oluştu");
    }
  };

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteUser = async () => {
    try {
      await organizationAdminService.deleteUser(userToDelete);
      toast.success("Kullanıcı başarıyla silindi");
      fetchUsers(); // Refresh user list
    } catch (error) {
      toast.error("Kullanıcı silinirken bir hata oluştu");
    } finally {
      setIsDeleteModalOpen(false);
      setUserToDelete(null);
    }
  };

  const renderPermissionCircle = (isActive) => (
    <div
      className={`w-6 h-6 rounded-full border-2 flex items-center justify-center transition-all duration-200 ${
        isActive
          ? "border-[#439DC6] bg-[#439DC6]/10"
          : "border-gray-200 bg-gray-50"
      }`}
    >
      {isActive && (
        <svg className="w-3 h-3 text-[#439DC6]" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 10L11 13L16 8"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );

  return (
    <div className="p-6 max-w-[1136px] mx-auto">
      {users.length === 0 ? (
        <div className="bg-white rounded-2xl border border-gray-200 p-16 text-center">
          <div className="w-20 h-20 bg-[#439DC6]/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
            <svg
              className="w-10 h-10 text-[#439DC6]"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            Henüz Kullanıcı Bulunmuyor
          </h3>
          <p className="text-gray-500 mb-8 max-w-md mx-auto">
            Organizasyonunuza yeni kullanıcılar ekleyerek modül erişimlerini
            yönetebilirsiniz.
          </p>
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 mx-auto hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
          >
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 7V17M7 12H17"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
            Yeni Kullanıcı Ekle
          </button>
        </div>
      ) : (
        <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full min-w-[800px] table-fixed">
              <thead className="bg-gray-50 border-b border-gray-200">
                <tr>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Adı
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Soyadı
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Departman
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    İnovasyon Check-Up
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Müşteri ve Problem Analizi
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    İç Girişimcilik
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Stratejik Plan
                  </th>
                  <th className="w-[11%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    Pazar Araştırması ve İhracat
                  </th>
                  <th className="w-[12%] px-4 py-3 text-left text-xs font-medium text-gray-600">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {users.map((user) => (
                  <tr
                    key={user.id}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-4 py-3 text-sm truncate">{user.name}</td>
                    <td className="px-4 py-3 text-sm truncate">
                      {user.surname}
                    </td>
                    <td className="px-4 py-3 truncate">
                      <span className="px-2 py-1 rounded-full text-xs bg-[#439DC633] text-[#439DC6]">
                        {user.department}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      {renderPermissionCircle(
                        user.permissions.innovationCheckup
                      )}
                    </td>
                    <td className="px-4 py-3">
                      {renderPermissionCircle(
                        user.permissions.customerAnalysis
                      )}
                    </td>
                    <td className="px-4 py-3">
                      {renderPermissionCircle(
                        user.permissions.internalEntrepreneurship
                      )}
                    </td>
                    <td className="px-4 py-3">
                      {renderPermissionCircle(user.permissions.strategicPlan)}
                    </td>
                    <td className="px-4 py-3">
                      {renderPermissionCircle(user.permissions.marketResearch)}
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex gap-2">
                        <button
                          className="text-gray-400 hover:text-[#439DC6] hover:bg-[#439DC6]/10 p-2 rounded-xl transition-all duration-200"
                          title="Kullanıcıyı Düzenle"
                          onClick={() => handleEdit(user)}
                        >
                          <svg
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        <button
                          className="text-gray-400 hover:text-[#EF8024] hover:bg-[#EF8024]/10 p-2 rounded-xl transition-all duration-200"
                          title="Kullanıcıyı Sil"
                          onClick={() => handleDeleteClick(user.id)}
                        >
                          <svg
                            className="w-4 h-4"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H7V4Z"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              d="M20 6H4V8H20V6Z"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                            <path
                              d="M6 8L7.47405 19.4233C7.64151 20.9187 8.89851 22 10.4066 22H13.5934C15.1015 22 16.3585 20.9187 16.526 19.4233L18 8"
                              stroke="currentColor"
                              strokeWidth="2"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {users.length > 0 && (
        <div className="flex justify-end mb-4 mt-8">
          <button
            onClick={() => setIsAddModalOpen(true)}
            className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-4 py-2 text-sm rounded-xl font-medium transition-all duration-200 flex items-center gap-2 hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
          >
            <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
              <path
                d="M12 7V17M7 12H17"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
            Kullanıcı Ekle
          </button>
        </div>
      )}

      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setSelectedUser(null);
        }}
        onSubmit={(userData) => {
          if (selectedUser) {
            handleUpdateUser(userData);
          } else {
            handleAddUser(userData);
          }
          setIsAddModalOpen(false);
          setSelectedUser(null);
        }}
        editUser={selectedUser}
      />

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Kullanıcıyı Sil</h2>
            <p>Bu kullanıcıyı silmek istediğinizden emin misiniz?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleDeleteUser}
                className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-4 py-2 text-sm rounded-xl font-medium transition-all duration-200 hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
              >
                Sil
              </button>
              <button
                onClick={() => setIsDeleteModalOpen(false)}
                className="bg-gray-200 text-gray-500 px-4 py-2 text-sm rounded-xl font-medium transition-all duration-200 hover:bg-gray-300"
              >
                İptal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
