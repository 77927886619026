import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { marketAnswerService } from "../../../../../services/marketAnswerService";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { marketDeterminationService } from "../../../../../services/marketDeterminationService";
import { marketIndexService } from "../../../../../services/marketIndexService";
import { marketDeterminationAnswerService } from "../../../../../services/marketDeterminationAnswerService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function MarketDeterminationScale({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [analysisData, setAnalysisData] = useState([
    {
      factor: 0.25,
      analysis: [
        { market: "USA", rating: 4.5, score: 1.13 },
        { market: "UK", rating: 3.8, score: 0.95 },
        { market: "DE", rating: 4.2, score: 1.05 },
        { market: "FR", rating: 3.5, score: 0.88 },
        { market: "IT", rating: 3.2, score: 0.8 },
      ],
    },
    {
      factor: 0.25,
      analysis: [
        { market: "USA", rating: 4.2, score: 1.05 },
        { market: "UK", rating: 3.9, score: 0.98 },
        { market: "DE", rating: 4.0, score: 1.0 },
        { market: "FR", rating: 3.7, score: 0.93 },
        { market: "IT", rating: 3.4, score: 0.85 },
      ],
    },
    {
      factor: 0.25,
      analysis: [
        { market: "USA", rating: 4.8, score: 1.2 },
        { market: "UK", rating: 4.1, score: 1.03 },
        { market: "DE", rating: 4.4, score: 1.1 },
        { market: "FR", rating: 3.9, score: 0.98 },
        { market: "IT", rating: 3.6, score: 0.9 },
      ],
    },
    {
      factor: 0.25,
      analysis: [
        { market: "USA", rating: 4.3, score: 1.08 },
        { market: "UK", rating: 4.0, score: 1.0 },
        { market: "DE", rating: 4.1, score: 1.03 },
        { market: "FR", rating: 3.8, score: 0.95 },
        { market: "IT", rating: 3.5, score: 0.88 },
      ],
    },
    {
      factor: 1.0,
      analysis: [
        { market: "USA", rating: 4.5, score: 4.46 },
        { market: "UK", rating: 3.9, score: 3.96 },
        { market: "DE", rating: 4.2, score: 4.18 },
        { market: "FR", rating: 3.7, score: 3.74 },
        { market: "IT", rating: 3.4, score: 3.43 },
      ],
    },
    {
      factor: 1.0,
      analysis: [
        { market: "USA", rating: 4.5, score: 4.46 },
        { market: "UK", rating: 3.9, score: 3.96 },
        { market: "DE", rating: 4.2, score: 4.18 },
        { market: "FR", rating: 3.7, score: 3.74 },
        { market: "IT", rating: 3.4, score: 3.43 },
      ],
    },
    {
      factor: 1.0,
      analysis: [
        { market: "USA", rating: 4.5, score: 4.46 },
        { market: "UK", rating: 3.9, score: 3.96 },
        { market: "DE", rating: 4.2, score: 4.18 },
        { market: "FR", rating: 3.7, score: 3.74 },
        { market: "IT", rating: 3.4, score: 3.43 },
      ],
    },
  ]);

  const [markets, setMarkets] = useState([]);
  const [open, setOpen] = useState(false);
  const [newMarket, setNewMarket] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [indexDialogOpen, setIndexDialogOpen] = useState(false);
  const [availableIndexes, setAvailableIndexes] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [indexLoading, setIndexLoading] = useState(false);
  const [activeIndexButton, setActiveIndexButton] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [totalFactor, setTotalFactor] = useState(0);

  useEffect(() => {
    if (projectId) {
      fetchMarkets();
      fetchSelectedIndexes();
      fetchAnswers();
    }
  }, [projectId]);

  useEffect(() => {
    // Update total factor when answers change
    const newTotal = answers
      .filter((item) => item.titleId !== 0)
      .reduce((sum, item) => sum + (item.factor || 0), 0);
    console.log("newTotal", newTotal);
    setTotalFactor(newTotal);
  }, [answers]);

  const fetchMarkets = async () => {
    try {
      const response = await marketDeterminationService.getMarkets(projectId);
      setMarkets(response || []);
    } catch (error) {
      console.error("Error fetching markets:", error);
      setMarkets([]);
    }
  };

  const fetchAvailableIndexes = async () => {
    setIndexLoading(true);
    try {
      const indexes = await marketIndexService.getAvailableIndexes(projectId);
      setAvailableIndexes(indexes);
    } catch (error) {
      console.error("Error fetching available indexes:", error);
    } finally {
      setIndexLoading(false);
    }
  };

  const fetchSelectedIndexes = async () => {
    try {
      // const response = await marketIndexService.getSelectedIndexes(projectId);
      // setSelectedIndexes(response);
    } catch (error) {
      console.error("Error fetching selected indexes:", error);
    }
  };

  const fetchAnswers = async () => {
    try {
      const response = await marketDeterminationAnswerService.getAnswers(
        projectId
      );
      console.log("Fetched answers:", response);
      if (Array.isArray(response)) {
        setAnswers(response);
        setAnalysisData(response.filter((item) => item.titleId !== 0)); // Toplam hariç
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  };

  const handleAddMarket = async () => {
    if (!newMarket.trim()) return;
    setLoading(true);
    try {
      await marketDeterminationService.addMarket(projectId, newMarket);
      await fetchMarkets();
      await fetchAnswers();
      setNewMarket("");
      setOpen(false);
    } catch (error) {
      console.error("Error adding market:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteMarket = async (marketId) => {
    setLoading(true);
    try {
      await marketDeterminationService.deleteMarket(projectId, marketId);
      await fetchMarkets();
    } catch (error) {
      console.error("Error deleting market:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMarket = async (marketId, newName) => {
    setLoading(true);
    try {
      await marketDeterminationService.updateMarket(
        projectId,
        marketId,
        newName
      );
      await fetchMarkets();
    } catch (error) {
      console.error("Error updating market:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddIndex = async (titleId, title) => {
    setIndexLoading(true);
    try {
      await marketIndexService.addIndex(projectId, activeIndexButton, title);
      setIndexDialogOpen(false);
      await fetchSelectedIndexes();
      await fetchAnswers();
    } catch (error) {
      console.error("Error adding index:", error);
    } finally {
      setIndexLoading(false);
    }
  };

  const handleDeleteIndex = async (titleId) => {
    setIndexLoading(true);
    try {
      await marketIndexService.deleteIndex(projectId, titleId);
      await fetchSelectedIndexes();
      await fetchAnswers();
    } catch (error) {
      console.error("Error deleting index:", error);
    } finally {
      setIndexLoading(false);
    }
  };

  const handleOpenIndexDialog = async (buttonIndex) => {
    setActiveIndexButton(buttonIndex);
    setIndexDialogOpen(true);
    try {
      const indexes = await marketIndexService.getAvailableIndexes(projectId);
      setAvailableIndexes(indexes);
    } catch (error) {
      console.error("Error fetching available indexes:", error);
    }
  };

  const handleRatingChange = async (titleId, marketId, value) => {
    try {
      if (!titleId || !marketId) {
        console.error("titleId and marketId are required");
        return;
      }

      const numericValue = value === "" ? 0 : parseFloat(value);

      if (isNaN(numericValue) || numericValue < 0 || numericValue > 5) {
        return;
      }

      const payload = {
        titleId: parseInt(titleId),
        value: numericValue,
      };

      console.log("Sending payload:", payload, "to marketId:", marketId); // Debug için

      await marketDeterminationAnswerService.updateAnswer(
        projectId,
        marketId,
        payload
      );
      await fetchAnswers();
    } catch (error) {
      console.error("Error updating rating:", error);
    }
  };

  const handleFactorChange = async (titleId, value) => {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > 1) {
      return;
    }

    try {
      await marketDeterminationService.updateFactor(
        projectId,
        titleId,
        numericValue
      );
      await fetchAnswers();
    } catch (error) {
      console.error("Error updating factor:", error);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      await downloadPageAsPDF(
        "page-content-container",
        projectName,
        "Yutdışı Hedef Pazar Belirleme Ölçeği"
      );
      toast.success("PDF başarıyla indirildi");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("PDF indirilirken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleNext = async () => {
    if (Math.abs(totalFactor - 1) > 0.01) {
      toast.error("Faktörlerin toplamı 1'e eşit olmalıdır");
      return;
    }
    changePage();
  };

  const RatingDropdown = ({ value, onChange, disabled }) => (
    <select
      value={value === 0 || value === "0" || value === 0.0 ? "0" : value}
      onChange={(e) => {
        const selectedValue = e.target.value;
        onChange(selectedValue);
      }}
      disabled={disabled}
      className="w-16 h-8 text-center border-none bg-transparent text-[#242731] text-sm font-semibold focus:outline-none"
    >
      <option value="0">0</option>
      {[1, 2, 3, 4, 5].map((rating) => (
        <option key={rating} value={rating}>
          {rating}
        </option>
      ))}
    </select>
  );

  const IndexDialog = () => {
    const currentAnswer = answers.find((a) => a.titleId === activeIndexButton);

    return (
      <Dialog
        open={indexDialogOpen}
        onClose={() => setIndexDialogOpen(false)}
        PaperProps={{
          className: "rounded-2xl font-['Outfit']",
        }}
      >
        <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-[#EF8024]/10 rounded-xl">
              <svg
                className="w-6 h-6 text-[#EF8024]"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 7V17M7 12H17"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            {currentAnswer?.title || "Endeks Ekle"}
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="mt-4">
            {indexLoading ? (
              <div className="text-center py-4">Yükleniyor...</div>
            ) : (
              <>
                <div className="text-gray-600 mb-2">Mevcut Endeksler:</div>
                <div className="space-y-2">
                  {availableIndexes.map((index, i) => (
                    <div
                      key={i}
                      className="flex items-center justify-between p-2 border rounded hover:bg-gray-50"
                    >
                      <span>{index}</span>
                      <button
                        onClick={() => handleAddIndex(activeIndexButton, index)}
                        className="px-3 py-1 text-sm bg-[#EF8024] text-white rounded hover:bg-[#E67016]"
                        disabled={indexLoading}
                      >
                        Ekle
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="p-6">
          <button
            onClick={() => setIndexDialogOpen(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            disabled={indexLoading}
          >
            Kapat
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <LoadingFullPage loading={isPdfLoading}>
      <div className="w-full items-center justify-center text-center">
        <div className="w-full items-center justify-center text-center">
          <br />
          <div className="w-[605px] text-black text-4xl font-semibold font-['Outfit']">
            Yutdışı Hedef Pazar Belirleme Ölçeği
          </div>

          <div
            id="page-content-container"
            className="w-full h-[551px] mt-5 rounded-tl-[10px] rounded-bl-[10px] rounded-br-[10px] border border-[#e0e0e0] flex"
          >
            <div className="w-[385px] flex flex-col border-r-[1px]">
              <div className="h-[55px] border-b-[1px] bg-[#f6f6f6]">
                <div className="text-[#439dc6] text-lg font-semibold font-['Outfit'] items-center justify-center flex text-center h-full">
                  Yurtdışı Pazar Belirleme{" "}
                </div>
              </div>
              <div className="h-[55px] border-b-[1px]"></div>
              {answers
                .filter((item) => item.titleId !== 0)
                .map((item) => (
                  <div
                    key={item.titleId}
                    className="h-[55px] border-b-[1px] flex items-center"
                  >
                    <div className="w-[293px] flex items-center h-full border-r-[1px]">
                      <div className="text-[#242731] ml-4 text-sm  font-['Outfit'] flex ">
                        {item.title === "-" ? (
                          <div
                            className="h-10 px-3 py-2.5 bg-white rounded-lg shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] border border-[#e0e0e0] justify-end items-center gap-2.5 inline-flex cursor-pointer"
                            onClick={() => handleOpenIndexDialog(item.titleId)}
                          >
                            <div className="justify-center items-center gap-2 flex">
                              <div className="w-5 h-5 justify-center items-center flex">
                                <div className="w-5 h-5 relative"></div>
                              </div>
                              <div className="text-[#929398] text-sm font-medium font-['Outfit']">
                                Endeks Ekle
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2 text-left">
                            <span>{item.title}</span>
                            {item.titleId <= 2 && (
                              <button
                                onClick={() => handleDeleteIndex(item.titleId)}
                                className="text-red-500 hover:text-red-700 p-1"
                                disabled={indexLoading}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-[102px]">
                      <input
                        type="number"
                        value={item.factor.toFixed(2)}
                        onChange={(e) =>
                          handleFactorChange(item.titleId, e.target.value)
                        }
                        step="0.01"
                        min="0"
                        max="1"
                        className="w-20 text-center text-[#242731] text-sm font-semibold font-['Outfit'] border border-[#e0e0e0] rounded px-2 py-1"
                      />
                    </div>
                  </div>
                ))}

              {/* Add Total Factors Row */}
              <div className="h-[55px] border-b-[1px] flex items-center bg-[#f6f6f6]">
                <div className="w-[293px] flex items-center h-full border-r-[1px] pl-4">
                  <span className="text-[#439dc6] text-base font-semibold">
                    Toplam
                  </span>
                </div>
                <div className="flex items-center justify-center w-[102px]">
                  <div
                    className={`text-[#242731] text-sm font-semibold ${
                      Math.abs(
                        answers
                          .filter((item) => item.titleId !== 0)
                          .reduce((sum, item) => sum + (item.factor || 0), 0) -
                          1
                      ) > 0.01
                        ? "text-red-500"
                        : ""
                    }`}
                  >
                    {answers
                      .filter((item) => item.titleId !== 0)
                      .reduce((sum, item) => sum + (item.factor || 0), 0)
                      .toFixed(2)}
                  </div>
                </div>
              </div>
            </div>

            {/* dinamic*/}
            <div className="relative w-full bg-gray-50 flex">
              {[1, 2, 3, 4, 5].map((index) => {
                const market = markets[index - 1];
                // Calculate total score for this market
                const totalScore = analysisData.reduce((sum, item) => {
                  const marketAnalysis = item.analysis?.find(
                    (a) => a.marketId === market?.id
                  );
                  return sum + (marketAnalysis?.score || 0);
                }, 0);

                return (
                  <div
                    key={index}
                    className="h-full w-[148px] border-r-[1px] flex flex-col bg-white"
                  >
                    <div className="h-[55px] bg-[#f6f6f6] border-b-[1px] items-center flex justify-center p-2">
                      {market ? (
                        <div className="w-full flex items-center justify-between px-2">
                          <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                            {market.name}
                          </div>
                          <button
                            onClick={() => handleDeleteMarket(market.id)}
                            className="text-gray-400 hover:text-red-500 p-1 rounded-lg transition-all duration-200 hover:bg-red-50"
                            title="Pazarı Sil"
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16" />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <button
                          className="w-full h-8 bg-white rounded-lg border border-[#e0e0e0] flex items-center justify-center gap-2 hover:bg-gray-50 transition-colors text-xs px-2"
                          onClick={() => setOpen(true)}
                        >
                          <svg
                            className="w-3.5 h-3.5 text-[#929398]"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 7V17M7 12H17"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                          <span className="text-[#929398] font-medium font-['Outfit'] whitespace-nowrap">
                            Pazar Ekle / Kaldır
                          </span>
                        </button>
                      )}
                    </div>
                    <div className="h-[55px] border-b-[1px] flex">
                      <div className="flex-1 border-r-[1px] flex items-center justify-center">
                        <div className="w-[52px] text-center text-[#439dc6] text-sm font-semibold font-['Outfit']">
                          Puan
                        </div>
                      </div>
                      <div className="flex-1 bg-[#f6f6f6] flex items-center justify-center">
                        <div className="w-[52px] text-center text-[#439dc6] text-sm font-semibold font-['Outfit']">
                          Score
                        </div>
                      </div>
                    </div>
                    {analysisData.map((item, idx) => (
                      <div key={idx} className="h-[55px] border-b-[1px] flex">
                        <div className="flex-1 border-r-[1px] flex items-center justify-center">
                          <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                            <RatingDropdown
                              value={
                                market &&
                                item.analysis?.find(
                                  (a) => a.marketId === market?.id
                                )?.rating === 0.0
                                  ? 0
                                  : (market &&
                                      item.analysis?.find(
                                        (a) => a.marketId === market?.id
                                      )?.rating) ||
                                    0
                              }
                              onChange={(value) => {
                                console.log("Item:", item); // Debug için
                                console.log("Market:", market); // Debug için
                                if (item && market) {
                                  handleRatingChange(
                                    item.id || item.titleId, // Her iki olası property'i kontrol et
                                    market.id,
                                    value
                                  );
                                }
                              }}
                              disabled={indexLoading || !market}
                            />
                          </div>
                        </div>
                        <div className="flex-1 bg-[#f6f6f6] flex items-center justify-center">
                          <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                            {(market &&
                              item.analysis
                                ?.find((a) => a.marketId === market?.id)
                                ?.score?.toFixed(2)) ||
                              "-"}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="h-[55px] border-b-[1px] flex">
                      <div className="flex-1 border-r-[1px] flex items-center justify-center">
                        <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                          -
                        </div>
                      </div>
                      <div className="flex-1 bg-[#f6f6f6] flex items-center justify-center">
                        <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                          {totalScore.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="w-full  bg-[#fef7eb] rounded-[11px] text-left p-2">
            <div className="w-full text-[#797979] text-lg font-normal font-['Outfit'] p-4">
              *İlgili ülke pazarlarını 5 üzerinden oylayınız. Paremetrik
              verilerde normalizasyon veya kategorilendirme yaparak 1 en kötü 5
              en iyi skor olacak şekilde puanlarınızı oluşturunuz. Ticaret
              Bakanlığı tarafından yayınlanan "Gümrük Tarife Cetveli"
              kitapçığında gümrük vergi oranlarını bulabilirsiniz. Kolay İhracat
              Platformu portalından da sorgulatma yapabilirsiniz. <br />
              ** Tarife dışı önlemler (TDO), gümrük vergileri dışındaki ticaret
              engelleyici veya sınırlayıcı önlemleri ifade eder. Bunlar, ithalat
              ve ihracat işlemlerini etkileyen çeşitli politika ve düzenlemeleri
              içerebilir. Rekabet, satış sonrası hizmetler, teknik gereksinimler
              gibi 16 ana kategoriden oluşan tarife dışı önlemler (NTM) kodları
              ülkelerin dış ticarette gümrük tarifesi dışındaki politika
              önlemlerini kapsamaktadır. Bu önlemler ihracatçı, ithalatçı veya
              transit ülkenin kamu otoriteleri tarafından konulmuş, uyulması
              zorunlu olan gereksinimler, kurallar veya düzenlemelerdir. Kolay
              İhracat Platformu portalından sorgulatma yapabilirsiniz. Daha
              detaylı bilgi için UNCTAD (Birleşmiş Milletler Ticaret ve Kalkınma
              Konferansı) tarafından yayınlanan kitapçık incelenebilir:
              https://unctad.org/system/files/official-document/ditctab2019d5_en.pdf{" "}
              <br /> *** Bu kısımları ürünlerinizi ve giriş yapmak istediğiniz
              ülke pazarlarını değerlendirerek doldurunuz.{" "}
            </div>
          </div>
          <div className="w-full flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                backChange={backPage}
                nextChange={handleNext}
                activeStep={4}
                stepLimit={4}
                onPdfDownload={handlePdfDownload}
                nextDisable={totalFactor !== 1}
                nextTooltip={
                  totalFactor !== 1
                    ? "Toplam değer 1'e eşit olmalıdır"
                    : "Sonraki"
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Market Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Pazar Ekle / Kaldır</DialogTitle>
        <DialogContent>
          <div className="mb-4">
            <TextField
              autoFocus
              margin="dense"
              label="Yeni Pazar Adı"
              type="text"
              fullWidth
              variant="outlined"
              value={newMarket}
              onChange={(e) => setNewMarket(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleAddMarket()}
              disabled={loading}
            />
          </div>

          {markets.length > 0 && (
            <div className="mt-4">
              <div className="text-gray-600 mb-2">Mevcut Pazarlar:</div>
              <div className="space-y-2">
                {markets.map((market) => (
                  <div
                    key={market.id}
                    className="flex items-center justify-between p-2 border rounded hover:bg-gray-50"
                  >
                    <span>{market.name}</span>
                    <button
                      onClick={() => handleDeleteMarket(market.id)}
                      className="text-red-500 hover:text-red-700 p-1"
                      disabled={loading}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => setOpen(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            disabled={loading}
          >
            Kapat
          </button>
          <button
            onClick={handleAddMarket}
            className="px-4 py-2 bg-[#EF8024] text-white rounded hover:bg-[#E67016] disabled:opacity-50"
            disabled={loading || !newMarket.trim()}
          >
            {loading ? "Ekleniyor..." : "Ekle"}
          </button>
        </DialogActions>
      </Dialog>

      <IndexDialog />
    </LoadingFullPage>
  );
}
