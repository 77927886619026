import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Threatening from "../../../../../icons/threatening.svg";
import WhiteHat from "../../../../../icons/hats/white-hat.svg";
import RedHat from "../../../../../icons/hats/red-hat.svg";
import BlackHat from "../../../../../icons/hats/black-hat.svg";
import YellowHat from "../../../../../icons/hats/yellow-hat.svg";
import GreenHat from "../../../../../icons/hats/green-hat.svg";
import BlueHat from "../../../../../icons/hats/blue-hat.svg";
import DeleteIcon from "../../../../../icons/delete-item-red.svg";
import { sixHatsService } from "../../../../../services/sixHatsService";
import { toast } from "react-toastify";
import { writtenBrainstormingService } from "../../../../../services/writtenBrainstormingService";
import { effectEvaluationMatrixService } from "../../../../../services/effectEvaluationMatrixService";
import AddAnswerModal from "../../../../../components/Modal/AddAnswerModal";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function WrittenBrainstorming({
  changePage,
  backPage,
  projectId,
  subId,
  projectName,
}) {
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [matrixIdeas, setMatrixIdeas] = useState({
    highImpactLowEffort: [],
    highImpactHighEffort: [],
    lowImpactLowEffort: [],
    lowImpactHighEffort: [],
    whiteHat: [],
    redHat: [],
    blackHat: [],
    yellowHat: [],
    greenHat: [],
    blueHat: [],
  });
  const [newHatIdea, setNewHatIdea] = useState("");
  const [gridAnswers, setGridAnswers] = useState(Array(15).fill(""));
  const [unsavedChanges, setUnsavedChanges] = useState(Array(15).fill(false));
  const [isLoading, setIsLoading] = useState(false);
  const [showAvailableAnswersModal, setShowAvailableAnswersModal] =
    useState(false);
  const [availableAnswers, setAvailableAnswers] = useState([]);
  const [selectedQuadrant, setSelectedQuadrant] = useState(null);
  const [matrixAnswers, setMatrixAnswers] = useState({
    1: [], // highImpactLowEffort
    2: [], // highImpactHighEffort
    3: [], // lowImpactLowEffort
    4: [], // lowImpactHighEffort
  });
  const [showAddIdeaModal, setShowAddIdeaModal] = useState(false);
  const [selectedHatType, setSelectedHatType] = useState(null);
  const [newIdeaText, setNewIdeaText] = useState("");
  const [isPdfLoading1, setIsPdfLoading1] = useState(false);
  const [isPdfLoading2, setIsPdfLoading2] = useState(false);
  const [isPdfLoading3, setIsPdfLoading3] = useState(false);

  const handlePdfDownload1 = async () => {
    setIsPdfLoading1(true);
    const container = document.getElementById("brainstorming-container");
    await handlePdfDownloadSection(
      container,
      "Yazılı Beyin Fırtınası (6-3-5)",
      setIsPdfLoading1
    );
  };

  const handlePdfDownload2 = async () => {
    setIsPdfLoading2(true);
    const container = document.getElementById("matrix-container");

    if (!container) {
      console.error("Container not found");
      setIsPdfLoading2(false);
      return;
    }

    const originalStyle = container.style.cssText;
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    // Özel matris PDF stil ayarları
    container.style.cssText = `
      width: 1400px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
      display: block !important;
    `;

    // Textarea'ları div'lere dönüştür
    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "matrix-container",
        "Etki - Efor Değerlendirme Matrisi",
        projectName,
        {
          margin: [10, 10, 10, 10],
          scale: 0.7,
          printBackground: true,
        }
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Orijinal stil ve elementleri geri yükle
      container.style.cssText = originalStyle;
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading2(false);
    }
  };

  const handlePdfDownload3 = async () => {
    setIsPdfLoading3(true);
    const container = document.getElementById("six-hats-container");
    await handlePdfDownload();
  };

  const handlePdfDownloadSection = async (container, title, setLoading) => {
    if (!container) {
      console.error("Container not found");
      setLoading(false);
      return;
    }

    const originalStyle = container.style.cssText;
    container.style.cssText = `
      width: 1500px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
      gap: 20px !important;
    `;

    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(container.id, title, projectName);
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      container.style.cssText = originalStyle;
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setLoading(false);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading3(true);
    const container = document.getElementById("six-hats-container");
    const originalStyle = container.style.cssText;

    try {
      // Ana container'ı düzenle
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Şapka gruplarını içeren container'ları düzenle
      const hatGroups = container.getElementsByClassName(
        "flex flex-col sm:flex-row"
      );
      Array.from(hatGroups).forEach((group) => {
        group.style.cssText = `
          display: flex !important;
          flex-direction: row !important;
          margin-bottom: 40px !important;
          gap: 20px !important;
        `;
      });

      // Her bir şapka container'ını düzenle
      const hatContainers = container.querySelectorAll(
        '[class*="flex-1 max-w-[300px]"]'
      );
      Array.from(hatContainers).forEach((hatContainer) => {
        hatContainer.style.cssText = `
          flex: 1 !important;
          max-width: 300px !important;
          position: relative !important;
          padding: 12px !important;
          border: 1px solid #bababa !important;
          margin: 0 !important;
        `;
      });

      // İçerik elementlerini düzenle
      const contentElements = container.getElementsByClassName("space-y-3");
      Array.from(contentElements).forEach((element) => {
        element.style.cssText = `
          display: flex !important;
          flex-direction: column !important;
          gap: 12px !important;
        `;
      });

      // Butonları ve diğer etkileşimli elementleri gizle
      const interactiveElements = container.querySelectorAll("button");
      const originalElements = [];
      interactiveElements.forEach((element) => {
        if (element.classList.contains("group-hover:opacity-100")) {
          element.style.display = "none";
        }
      });

      await downloadPageAsPDF(
        "six-hats-container",
        "Altı Şapkalı Düşünme",
        projectName,
        {
          margin: [20, 20, 20, 20],
          scale: 0.8,
          printBackground: true,
        }
      );
    } catch (error) {
      console.error("PDF oluşturma hatası:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Orijinal stilleri geri yükle
      container.style.cssText = originalStyle;

      // Tüm elementlerin orijinal stillerini geri yükle
      const allElements = container.getElementsByTagName("*");
      Array.from(allElements).forEach((element) => {
        element.removeAttribute("style");
      });

      setIsPdfLoading3(false);
    }
  };

  useEffect(() => {
    const fetchHatIdeas = async () => {
      try {
        const response = await sixHatsService.getAnswers(projectId);
        if (response) {
          const updatedIdeas = {
            ...matrixIdeas,
          };

          response.forEach((container) => {
            switch (container.containerId) {
              case 1:
                updatedIdeas.whiteHat = container.answers.map((a) => a.answer);
                break;
              case 2:
                updatedIdeas.redHat = container.answers.map((a) => a.answer);
                break;
              case 3:
                updatedIdeas.blackHat = container.answers.map((a) => a.answer);
                break;
              case 4:
                updatedIdeas.yellowHat = container.answers.map((a) => a.answer);
                break;
              case 5:
                updatedIdeas.greenHat = container.answers.map((a) => a.answer);
                break;
              case 6:
                updatedIdeas.blueHat = container.answers.map((a) => a.answer);
                break;
            }
          });

          setMatrixIdeas(updatedIdeas);
        }
      } catch (error) {
        toast.error("Fikirler yüklenirken bir hata oluştu");
      }
    };

    if (projectId) {
      fetchHatIdeas();
    }
  }, [projectId]);

  const fetchAnswers = async () => {
    if (!projectId) return;

    setIsLoading(true);
    try {
      const response = await writtenBrainstormingService.getAnswers(projectId);
      const newAnswers = Array(15).fill("");

      response.forEach((container) => {
        if (container.answers && container.answers.length > 0) {
          // Her container için son 5 cevabı al
          const lastFiveAnswers = container.answers.slice(-5);

          // Container'a göre başlangıç indexini belirle
          const startIndex = (container.containerId - 1) * 5;

          // Son 5 cevabı yerleştir
          lastFiveAnswers.forEach((answer, index) => {
            newAnswers[startIndex + index] = answer.answer;
          });
        }
      });

      setGridAnswers(newAnswers);
    } catch (error) {
      toast.error("Fikirler yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, [projectId]);

  useEffect(() => {
    const fetchMatrixAnswers = async () => {
      if (!projectId) return;
      try {
        const response = await effectEvaluationMatrixService.getAnswers(
          projectId
        );
        const newMatrixAnswers = {
          1: [],
          2: [],
          3: [],
          4: [],
        };

        response.forEach((container) => {
          if (container.answers) {
            newMatrixAnswers[container.containerId] = container.answers;
          }
        });

        setMatrixAnswers(newMatrixAnswers);
      } catch (error) {
        toast.error("Matrix fikirleri yüklenirken bir hata oluştu");
      }
    };

    if (projectId) {
      fetchMatrixAnswers();
    }
  }, [projectId]);

  const handleAddHatIdea = async (hatType) => {
    if (!newIdeaText.trim()) {
      toast.error("Lütfen bir fikir giriniz");
      return;
    }

    try {
      const containerId = getContainerId(hatType);
      await sixHatsService.addAnswer(
        projectId,
        containerId,
        newIdeaText.trim()
      );

      setMatrixIdeas((prev) => ({
        ...prev,
        [hatType]: [...prev[hatType], newIdeaText.trim()],
      }));

      setNewIdeaText("");
      setShowAddIdeaModal(false);
    } catch (error) {
      toast.error("Fikir eklenirken bir hata oluştu");
    }
  };

  const handleDeleteHatIdea = async (hatType, answerId) => {
    try {
      await sixHatsService.deleteAnswer(projectId, answerId);
      setMatrixIdeas((prev) => ({
        ...prev,
        [hatType]: prev[hatType].filter((_, idx) => idx !== answerId),
      }));
    } catch (error) {
      toast.error("Fikir silinirken bir hata oluştu");
    }
  };

  const getContainerId = (hatType) => {
    const containerMap = {
      whiteHat: 1,
      redHat: 2,
      blackHat: 3,
      yellowHat: 4,
      greenHat: 5,
      blueHat: 6,
    };
    return containerMap[hatType];
  };

  const handleIdeaSelect = (quadrant, idea) => {
    setMatrixIdeas((prev) => ({
      ...prev,
      [quadrant]: [...prev[quadrant], idea],
    }));
    setShowPopup(false);
  };

  const handleDeleteIdea = (section, ideaIndex) => {
    setMatrixIdeas((prev) => ({
      ...prev,
      [section]: prev[section].filter((_, idx) => idx !== ideaIndex),
    }));
  };

  const handleAnswerChange = (index, value) => {
    setGridAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[index] = value;
      return newAnswers;
    });
    setUnsavedChanges((prev) => {
      const newUnsaved = [...prev];
      newUnsaved[index] = true;
      return newUnsaved;
    });
  };

  const handleSaveAnswer = async (index) => {
    if (!gridAnswers[index].trim()) {
      toast.error("Lütfen bir fikir giriniz");
      return;
    }

    try {
      let containerId;
      if (index <= 4) {
        containerId = 1;
      } else if (index <= 9) {
        containerId = 2;
      } else {
        containerId = 3;
      }

      await writtenBrainstormingService.addAnswer(
        projectId,
        containerId,
        gridAnswers[index].trim()
      );
      setUnsavedChanges((prev) => {
        const newUnsaved = [...prev];
        newUnsaved[index] = false;
        return newUnsaved;
      });
    } catch (error) {
      toast.error("Fikir kaydedilirken bir hata oluştu");
    }
  };

  const handleQuadrantClick = async (quadrantId) => {
    try {
      const response = await effectEvaluationMatrixService.getAvailableAnswers(
        projectId,
        quadrantId
      );
      setAvailableAnswers(response);
      setSelectedQuadrant(quadrantId);
      setShowAvailableAnswersModal(true);
    } catch (error) {
      toast.error("Fikirler yüklenirken bir hata oluştu");
    }
  };

  const handleSelectAnswer = async (answer) => {
    try {
      await effectEvaluationMatrixService.addAnswer(projectId, {
        containerId: selectedQuadrant,
        answerId: answer.id,
      });

      setMatrixAnswers((prev) => ({
        ...prev,
        [selectedQuadrant]: [...prev[selectedQuadrant], answer],
      }));

      setShowAvailableAnswersModal(false);
    } catch (error) {
      toast.error("Fikir eklenirken bir hata oluştu");
    }
  };

  const handleDeleteMatrixAnswer = async (answerId) => {
    try {
      await effectEvaluationMatrixService.deleteAnswer(projectId, answerId);

      setMatrixAnswers((prev) => {
        const newAnswers = { ...prev };
        Object.keys(newAnswers).forEach((key) => {
          newAnswers[key] = newAnswers[key].filter(
            (answer) => answer.id !== answerId
          );
        });
        return newAnswers;
      });
    } catch (error) {
      toast.error("Fikir silinirken bir hata oluştu");
    }
  };

  return (
    <>
      <LoadingFullPage
        state={isLoading || isPdfLoading1 || isPdfLoading2 || isPdfLoading3}
      >
        <div>
          <div className="w-full text-center text-black text-lg font-bold font-['Outfit'] flex items-center justify-between">
            <span>Yazılı Beyin Fırtınası (6-3-5)</span>
            <button
              onClick={handlePdfDownload1}
              className="px-4 py-2 bg-[#f08025] text-white rounded-lg hover:bg-[#e07015] transition-colors flex items-center gap-2"
            >
              {isPdfLoading1 ? "Yükleniyor..." : "PDF İndir"}
            </button>
          </div>
          <div className="flex flex-col space-y-4 sm:space-y-6">
            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
                1
              </div>
              <div className="mt-2 sm:mt-1 sm:ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                6 katılımcı daire şeklinde oturması önerilir, her katılımcı ile
                bu form paylaşılır.
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
                2
              </div>
              <div className="mt-2 sm:mt-1 sm:ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                Her katılımcı her 3 kutuya 3 fikir yazar ( 5 dk içerisinde ).
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
                3
              </div>
              <div className="mt-2 sm:mt-1 sm:ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                Her katılımcı formunu yanındaki ile paylaşır.
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
                4
              </div>
              <div className="mt-2 sm:mt-1 sm:ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                Her katılımcı bir önceki katılımcının fikirlerine dayanarak yine
                5 dk içerisinde 3 fikir üretir.
              </div>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center">
              <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
                5
              </div>
              <div className="mt-2 sm:mt-1 sm:ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                Bu şekilde 5 raunt yapılır. Herkesin birbirlerinin fikrini
                görmesi sağlanır.
              </div>
            </div>

            <br />
            <div className="text-black text-xl sm:text-xl font-normal font-['Outfit'] text-center">
              Fikirlerinizi aşağıdaki kutulara yazınız.
            </div>
            <div
              id="brainstorming-container"
              className="w-full min-h-[600px] rounded-[20px] border border-[#bababa] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-2 sm:p-4 gap-2 sm:gap-4"
            >
              {[...Array(15)].map((_, index) => (
                <div
                  key={index}
                  className="border border-[#bababa] rounded-lg p-2 sm:p-4 flex flex-col min-h-[200px]"
                >
                  <textarea
                    className="w-full flex-1 resize-none p-2 text-[#797979] text-base sm:text-lg font-normal font-['Outfit'] mb-2"
                    value={gridAnswers[index]}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 350) {
                        handleAnswerChange(index, value);
                      }
                    }}
                    maxLength={350}
                  />
                  {unsavedChanges[index] && (
                    <button
                      onClick={() => handleSaveAnswer(index)}
                      className="w-full py-2 bg-[#f08025] text-white rounded-lg hover:bg-[#e07015] transition-colors text-sm sm:text-base"
                    >
                      Kaydet
                    </button>
                  )}
                </div>
              ))}
            </div>
            <br />
          </div>

          <div className="w-full text-center text-black text-base sm:text-lg font-bold font-['Outfit'] flex flex-col items-center justify-between">
            <div className="w-full text-center text-black text-base sm:text-lg font-bold font-['Outfit'] flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0">
              <div className="flex-1"></div>
              <button
                onClick={handlePdfDownload2}
                className="px-4 py-2 bg-[#f08025] text-white rounded-lg hover:bg-[#e07015] transition-colors flex items-center gap-2 w-full sm:w-auto"
              >
                {isPdfLoading2 ? "Yükleniyor..." : "PDF İndir"}
              </button>
            </div>

            <div id="matrix-container" className="w-full">
              <span>Etki - Efor Değerlendirme Matrisi</span>
              <div className="grid grid-cols-1 sm:grid-cols-2 border border-gray-300 rounded-lg mx-4 sm:mx-12 my-6 sm:my-12">
                {/* Yüksek Etki - Düşük Efor */}
                <div
                  className="border-b sm:border-r p-4 sm:p-6 bg-blue-50 cursor-pointer"
                  onClick={() => handleQuadrantClick(1)}
                >
                  <div className="font-medium mb-3 text-base sm:text-lg">
                    Yüksek Etki - Düşük Efor
                  </div>
                  {matrixAnswers[1].map((answer) => (
                    <div
                      key={answer.id}
                      className="mb-3 p-3 sm:p-4 bg-white rounded-lg shadow-sm text-sm sm:text-base relative group"
                    >
                      {answer.answer}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMatrixAnswer(answer.id);
                        }}
                        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <img
                          src={DeleteIcon}
                          className="w-3 sm:w-4 h-3 sm:h-4"
                          alt="Sil"
                        />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Yüksek Etki - Yüksek Efor */}
                <div
                  className="border-b p-4 sm:p-6 bg-green-50 cursor-pointer w-full"
                  onClick={() => handleQuadrantClick(2)}
                >
                  <div className="font-medium mb-3 text-base sm:text-lg">
                    Yüksek Etki - Yüksek Efor
                  </div>
                  {matrixAnswers[2].map((answer) => (
                    <div
                      key={answer.id}
                      className="mb-3 p-3 sm:p-4 bg-white rounded-lg shadow-sm text-sm sm:text-base relative group"
                    >
                      {answer.answer}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMatrixAnswer(answer.id);
                        }}
                        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <img
                          src={DeleteIcon}
                          className="w-3 sm:w-4 h-3 sm:h-4"
                          alt="Sil"
                        />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Düşük Etki - Düşük Efor */}
                <div
                  className="sm:border-r p-4 sm:p-6 bg-yellow-50 cursor-pointer"
                  onClick={() => handleQuadrantClick(3)}
                >
                  <div className="font-medium mb-3 text-base sm:text-lg">
                    Düşük Etki - Düşük Efor
                  </div>
                  {matrixAnswers[3].map((answer) => (
                    <div
                      key={answer.id}
                      className="mb-3 p-3 sm:p-4 bg-white rounded-lg shadow-sm text-sm sm:text-base relative group"
                    >
                      {answer.answer}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMatrixAnswer(answer.id);
                        }}
                        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <img
                          src={DeleteIcon}
                          className="w-3 sm:w-4 h-3 sm:h-4"
                          alt="Sil"
                        />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Düşük Etki - Yüksek Efor */}
                <div
                  className="p-4 sm:p-6 bg-red-50 cursor-pointer"
                  onClick={() => handleQuadrantClick(4)}
                >
                  <div className="font-medium mb-3 text-base sm:text-lg">
                    Düşük Etki - Yüksek Efor
                  </div>
                  {matrixAnswers[4].map((answer) => (
                    <div
                      key={answer.id}
                      className="mb-3 p-3 sm:p-4 bg-white rounded-lg shadow-sm text-sm sm:text-base relative group"
                    >
                      {answer.answer}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMatrixAnswer(answer.id);
                        }}
                        className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <img
                          src={DeleteIcon}
                          className="w-3 sm:w-4 h-3 sm:h-4"
                          alt="Sil"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div id="matrix-container">
            <div className="max-w-[1136px] mx-auto px-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Şimdi ürettiğiniz fikirleri değerlendirme ve seçme zamanı!
              Etki-Efor matrisine göre olabildiğince yaratacağı etki/değeri
              yüksek uygulama/gerçekleştirme eforu düşük proje fikirlerini
              uygulamaya almaya karar veriniz. Bir sonraki aşama artık seçilen
              fikir(ler) için doğrulama ve test çalışmalarının yürütülmesidir.
              Bunun için iç girişimcilik modüllerimize bakmanızı ve oradaki
              şablonlarla yola devam etmenizi öneriyoruz.{" "}
            </div>
            <br />

            <div className="max-w-[1136px] mx-auto px-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Diğer bir kullanabileceğiniz fikir üretme ve analiz tekniği Altı
              Şapka Düşünme Tekniğidir. Altı Şapka Düşünme Tekniğinin farklı
              uygulanış biçimleri vardır. Bizim önerimiz öncelikli etki/değeri
              yüksek, eforu düşük olarak sınıflandırdığınız fikirler için Altı
              Şapka Düşünme Tekniğini kullanarak bu fikirleri farklı açılardan
              değerlendiriniz.{" "}
            </div>
            <br />
            <div className="max-w-[1136px] mx-auto px-4 text-black text-base sm:text-lg font-semibold font-['Outfit']">
              Sürece başlamadan önce şapkaları tanıyalım!{" "}
            </div>
            <br />
            <div className="max-w-[1136px] mx-auto px-4">
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={WhiteHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Beyaz Şapka (Bilgi): Mevcut verilere ve bilgilere odaklanarak
                  problemi çözmeye ve alternatif fikirler üretmeye çalışır.
                </div>
              </div>
              <br />
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={RedHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Kırmızı Şapka (Duygular): Duygular ve sezgilerine göre
                  problemi çözmeye ve alternatif fikirler üretmeye çalışır.
                </div>
              </div>
              <br />
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={BlackHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Siyah Şapka (Eleştiri): Olumsuzlukları ve riskleri
                  değerlendirerek problemi çözmeye ve alternatif fikirler
                  üretmeye çalışır.
                </div>
              </div>
              <br />
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={YellowHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Sarı Şapka (Olumlu): Olumlu yönleri ve fırsatları düşünerek
                  problemi çözmeye ve alternatif fikirler üretmeye çalışır.
                </div>
              </div>
              <br />
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={GreenHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Yeşil Şapka (Yaratıcılık): Yaratıcı ve farklı bakış açısıyla
                  problemi çözmeye ve alternatif fikirler üretmeye çalışır.
                </div>
              </div>
              <br />
              <div className="flex items-center">
                <div className="w-8 h-8 sm:w-10 sm:h-10 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white mt-1">
                  <img src={BlueHat} className="w-full h-full" />
                </div>
                <div className="mt-1 ml-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                  Mavi Şapka (Süreç): Düşünme sürecini yönetme ve organize etme
                  becerisiyle daha kontrolcü problemi çözmeye ve alternatif
                  fikirler üretmeye çalışır.
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="max-w-[1136px] mx-auto px-4 text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Altı Şapka Düşünme Tekniği fikir üretmeden ziyade fikri
              olgunlaştırma veya fikri öldürme tekniğidir. Bu yüzden Yazılı
              Beyin Fırtınası ve Etki-Efor değerlendirmeleri sonucunda kalan
              fikirler üzerinden ilerlemenizi öneririz. Yine ilgili birim
              çalışanlarını bir araya getirmeye çalışın. Hangi sırayla şapkaları
              kullanacağınızı planlayın. Genellikle beyaz şapka ile başlayıp
              mavi şapka ile bitirmek yaygın bir yaklaşımdır. Her şapkayı
              sırayla kullanarak belirli sürelerde (10-15 dk.) düşünme sürecini
              yönetin. Herkesin o şapka kapsamında düşünmesini sağlayın. Her
              şapka için belirli bir zaman dilimi ayırın (örneğin, her şapka
              için 10-15 dakika).{" "}
            </div>

            <div className="max-w-[1136px] mx-auto px-4 mt-5">
              <div className="p-3 sm:p-[5px] bg-[#fef7eb] rounded-[11px] flex flex-col sm:flex-row items-start sm:items-center">
                <img className="p-2 sm:p-4 w-12 sm:w-auto" src={Threatening} />
                <div className="flex-1">
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    Örnek Oturum Akışı: Beyaz Şapka ile problemle ilgili mevcut
                    tüm veri ve bilgileri toplayın. Önceki modüller zaten bu
                    konuda sizi olabildiğince desteklemektedir. Katılımcılardan
                    sorunla ilgili bilinen herhangi bir veriyi, olguyu veya
                    bilgiyi paylaşmalarını isteyin. Yorumlardan veya görüşlerden
                    kaçının; tamamen gerçek bilgilere odaklanın. Verileri sunmak
                    için çizelgeler, grafikler ve raporlar gibi araçları
                    kullanın. Bir sonraki oturumda{" "}
                  </span>
                  <span className="text-[#ff0000] text-base sm:text-lg font-semibold font-['Outfit']">
                    Kırmızı Şapka
                  </span>
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    {" "}
                    ile başlangıçtaki duygusal tepkileri ve sezgileri paylaşın.
                    Katılımcıların hiçbir gerekçeye ihtiyaç duymadan içgüdüsel
                    duygularını ve sezgilerini paylaşmalarına izin verin. Anonim
                    bir yöntem izleyebilirsiniz. Dürüstlüğü teşvik edin ve
                    yargılayıcı olmayan bir ortam sağlayın.{" "}
                  </span>
                  <span className="text-black text-base sm:text-lg font-semibold font-['Outfit']">
                    Siyah Şapka
                  </span>
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    {" "}
                    ile potansiyel zorlukları ve riskleri belirleyin.
                    Katılımcıları neyin yanlış gidebileceğini ve nedenini
                    düşünmeye teşvik edin. Farklı fikirler veya çözümlerle
                    ilişkili riskleri ve sınırlamaları tartışın. Risk analizine
                    yönelik ve SWOT gibi araçlar kullanabilirsiniz.{" "}
                  </span>
                  <span className="text-[#ffd800] text-base sm:text-lg font-semibold font-['Outfit']">
                    Sarı Şapka
                  </span>
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    {" "}
                    ile olası çözümlerin faydalarını ve olumlu yönlerini
                    keşfedin. Fikirlerin potansiyel faydalarını ve olumlu
                    sonuçlarını belirleyin ve tartışın. İyimser düşünmeyi teşvik
                    edin ve neyin işe yarayabileceğini vurgulayın. Önerilen
                    çözümlerin getirebileceği değeri ve fırsatları göz önünde
                    bulundurun.{" "}
                  </span>
                  <span className="text-[#0be500] text-base sm:text-lg font-semibold font-['Outfit']">
                    Yeşil Şapka
                  </span>
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    {" "}
                    ile yaratıcı fikirler ve alternatif çözümler için beyin
                    fırtınası yapın. Alışılmışın dışında (yanal) düşünmeyi
                    teşvik edin ve fikirlerin serbestçe akmasına izin verin.
                    Benzersiz çözümler geliştirmek için birbirinizin
                    fikirlerinden yararlanın. Farklı fikir üretme ve yaratıcı
                    düşünme teknikleri ile hibrit gidebilirsiniz.{" "}
                  </span>
                  <span className="text-[#0055ff] text-base sm:text-lg font-semibold font-['Outfit']">
                    Mavi Şapka
                  </span>
                  <span className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
                    {" "}
                    ile tartışmayı özetleyin, düşünceleri organize edin ve
                    sonraki adımları planlayın. Her şapkanın tartışmasındaki
                    önemli noktaları gözden geçirin ve özetleyin. Uygulanabilir
                    öğeleri belirleyin ve fizibilite ve etkiye göre bunları
                    önceliklendirin. Belirli adımları, atanmış sorumlulukları ve
                    zaman çizelgelerini içeren net bir proje eylem planı
                    geliştirin.{" "}
                  </span>
                </div>
              </div>
            </div>
            <br />
          </div>

          <div className="w-full text-center text-black text-base sm:text-lg font-bold font-['Outfit'] flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0">
            <span>Altı Şapka Düşünme</span>
            <button
              onClick={handlePdfDownload3}
              className="px-3 sm:px-4 py-2 bg-[#f08025] text-white rounded-lg hover:bg-[#e07015] transition-colors flex items-center gap-2 text-sm sm:text-base"
            >
              {isPdfLoading3 ? "Yükleniyor..." : "PDF İndir"}
            </button>
          </div>
          <div className="text-black text-xl sm:text-xl font-normal font-['Outfit'] text-center">
            Fikirleriniz eklemek için ilgili şapka'ya tıklayınız.
          </div>
          <div className="flex flex-col sm:flex-col" id="six-hats-container">
            <div>
              <div className="w-full flex flex-col sm:flex-row mt-10 h-auto rounded-[20px] border border-[#bababa]">
                {/* Beyaz Şapka */}
                <div className="flex-1 max-w-[380px]  relative p-3 sm:p-4 border-b sm:border-b-0 sm:border-r border-[#bababa]">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      src={WhiteHat}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out"
                      onClick={() => {
                        setSelectedHatType("whiteHat");
                        setShowAddIdeaModal(true);
                      }}
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    Bilgi Tabanli
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.whiteHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-gray-50 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("whiteHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Kırmızı Şapka */}
                <div className="flex-1  max-w-[380px] relative p-3 sm:p-4 border-b sm:border-b-0 sm:border-r border-[#bababa]">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      onClick={() => {
                        setSelectedHatType("redHat");
                        setShowAddIdeaModal(true);
                      }}
                      src={RedHat}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out"
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    Duygusal / Sezgisel
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.redHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-red-50 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("redHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Siyah Şapka */}
                <div className="flex-1 relative p-3 max-w-[380px] sm:p-4 border-b sm:border-b-0  border-[#bababa]">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      onClick={() => {
                        setSelectedHatType("blackHat");
                        setShowAddIdeaModal(true);
                      }}
                      src={BlackHat}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out"
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    Muhakeme Yapan
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.blackHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-gray-100 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("blackHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="w-full flex flex-col sm:flex-row mt-10 h-auto rounded-[20px] border border-[#bababa]">
                {/* Sarı Şapka */}
                <div className="flex-1 relative p-3 max-w-[380px] sm:p-4 border-b sm:border-b-0 sm:border-r border-[#bababa]">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      src={YellowHat}
                      onClick={() => {
                        setSelectedHatType("yellowHat");
                        setShowAddIdeaModal(true);
                      }}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out "
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    İyimser
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.yellowHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-yellow-50 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("yellowHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Yeşil Şapka */}
                <div className="flex-1 relative p-3 max-w-[380px] sm:p-4 border-b sm:border-b-0 sm:border-r border-[#bababa]">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      onClick={() => {
                        setSelectedHatType("greenHat");
                        setShowAddIdeaModal(true);
                      }}
                      src={GreenHat}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out "
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    Yaratıcı
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.greenHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-green-50 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("greenHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Mavi Şapka */}
                <div className="flex-1 relative p-3 max-w-[380px] sm:p-4">
                  <div className="w-[50px] h-[50px] sm:w-[70px] sm:h-[70px] bg-white rounded-[41px] absolute left-1/2 -translate-x-1/2 sm:left-[60px] sm:translate-x-0 -top-6 sm:-top-8 border border-[#bababa] flex items-center justify-center">
                    <img
                      onClick={() => {
                        setSelectedHatType("blueHat");
                        setShowAddIdeaModal(true);
                      }}
                      src={BlueHat}
                      className="w-8 h-8 sm:w-auto sm:h-auto cursor-pointer hover:scale-125 transition-all duration-300 ease-in-out "
                    />
                  </div>
                  <div className="mt-8 text-center font-medium mb-3 text-sm sm:text-base">
                    Kontrolcü
                  </div>
                  <div className="space-y-3">
                    {matrixIdeas.blueHat?.map((idea, idx) => (
                      <div
                        key={idx}
                        className="p-2 sm:p-3 bg-blue-50 rounded-lg text-xs sm:text-sm text-[#797979] relative group overflow-hidden"
                      >
                        {idea}
                        <button
                          onClick={() => handleDeleteHatIdea("blueHat", idx)}
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <img
                            src={DeleteIcon}
                            className="w-3 h-3 sm:w-4 sm:h-4"
                            alt="Sil"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center ">
            <div className="w-[600px] flex-1">
              <div className="w-full pr-10 ">
                <Stepper
                  nextChange={changePage}
                  backChange={backPage}
                  activeStep={3}
                  stepLimit={3}
                />
              </div>
            </div>
          </div>
        </div>

        {showAvailableAnswersModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg w-[600px]">
              <h3 className="text-xl font-bold mb-4">Fikir Seçin</h3>
              <div className="max-h-[400px] overflow-y-auto">
                {availableAnswers.map((answer, index) => (
                  <div
                    key={index}
                    onClick={() => handleSelectAnswer(answer)}
                    className="p-4 border rounded-lg mb-2 cursor-pointer hover:bg-gray-50"
                  >
                    {answer.answer}
                  </div>
                ))}
              </div>
              <div className="flex justify-end gap-4 mt-4">
                <button
                  className="px-6 py-3 bg-gray-200 rounded-lg text-base font-medium hover:bg-gray-300"
                  onClick={() => setShowAvailableAnswersModal(false)}
                >
                  İptal
                </button>
              </div>
            </div>
          </div>
        )}

        {showAddIdeaModal && (
          <AddAnswerModal
            isOpen={showAddIdeaModal}
            onClose={() => {
              setShowAddIdeaModal(false);
              setNewIdeaText("");
            }}
            value={newIdeaText}
            onChange={setNewIdeaText}
            onAdd={() => handleAddHatIdea(selectedHatType)}
            title="Yeni Fikir Ekle"
          />
        )}
      </LoadingFullPage>
    </>
  );
}
