import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="max-w-[1136px] w-full mx-auto px-4">
      <div className="text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="max-w-[1136px] w-full text-[#797979] text-lg font-normal font-['Outfit']">
        Fırsat ve tehdit alanlarını tahminlerken diğer dikkate almanız gereken
        bir faktör Çevre Analizidir. Rekabet ortamında bulunduğunuz pazarı ve
        ülke koşullarını etkileyen pek çok faktör sizin iş yapış şeklinizi de
        etkileyebilir. Bu faktörler politik, ekonomik, sosyo-kültürel ve
        teknolojik gelişmeler olabilir. Bunları da hesaba katarak iş modelinizi
        şekillendirmeniz; sizi bekleyen fırsat ve tehditleri ortaya koymanız
        gerekmektedir. Bu konuda PESTLE (Politik - Ekonomik - Sosyolojik -
        Teknolojik - Yasal - Çevresel) Analizi size yardımcı olacaktır. PESTLE
        Analizi sizlere hem problem alanları hem de fırsat/tehdit alanlarını
        ortaya çıkarmaya yardımcı olarak strateji ve inovasyon faaliyetlerinizi
        doğru kurgulamanıza yardımcı olacaktır.{" "}
      </div>
      <br />
      <div className="max-w-[243px] w-full text-[#797979] text-lg font-semibold font-['Outfit']">
        Haydi gelin şimdi başlayalım!
      </div>
      <div className="max-w-[1136px] w-full">
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          PESTLE analizi, bir firmanın dış çevresini değerlendirmek için
          kullanılan bir çerçevedir. Bu analiz, politik, ekonomik,
          sosyo-kültürel, teknolojik, yasal ve çevresel faktörleri (PESTLE)
          içerir. İşletmeler, bu faktörleri analiz ederek stratejik kararlarını
          şekillendirebilirler. PESTLE analizi bileşenleri şu şekildedir: <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Politik Faktörler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Politik faktörler, bir firmanın faaliyet gösterdiği ülkelerdeki
          politik ortamı içerir. Bu, hükümetin politikaları, düzenlemeleri,
          vergi politikaları, istikrar seviyesi gibi unsurları kapsar. Politik
          faktörler, işletmenin faaliyetlerini etkileyebilir ve stratejik
          planlamada önemli bir rol oynar.
          <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Ekonomik Faktörler
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          : Ekonomik faktörler, firmaların faaliyet gösterdiği ülkelerdeki
          ekonomik koşulları içerir. Bu, büyüme oranları, enflasyon, işsizlik
          oranları, faiz oranları gibi unsurları kapsar. Ekonomik faktörler,
          talep seviyelerini, maliyetleri ve fiyatları etkileyebilir ve
          firmaların kararlarını etkileyebilir. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Sosyo-Kültürel Faktörler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Sosyo-kültürel faktörler, toplumun kültürel, demografik ve sosyal
          yapılarını içerir. Bu, tüketici davranışları, yaşam tarzları,
          eğilimler, demografik değişimler gibi unsurları kapsar. Sosyal
          faktörler, ürün talebini, pazarlama stratejilerini ve marka imajını
          etkileyebilir. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Teknolojik Faktörler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Teknolojik faktörler, teknolojik gelişmelerin firma etkisini içerir.
          Bu, Ar-Ge yatırımları, inovasyon, dijitalleşme, otomasyon gibi
          unsurları kapsar. Teknolojik faktörler, işletmelerin verimliliğini
          artırabilir, yeni pazar fırsatları yaratabilir veya mevcut iş
          modellerini dönüştürebilir. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Yasal Faktörler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Yasal faktörler, firmaların faaliyet gösterdiği ülkelerdeki yasal
          düzenlemeleri içerir. Bu, iş kanunları, tüketici koruma yasaları,
          rekabet yasaları, vergi yasaları gibi unsurları kapsar. Yasal
          faktörler firmaların faaliyetlerini düzenler ve uyum gereksinimlerini
          belirler. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Çevresel Faktörler:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Çevresel faktörler, firmaların faaliyet gösterdiği çevresel koşulları
          içerir. Bu, iklim değişikliği, doğal kaynak kullanımı, atık yönetimi
          gibi unsurları kapsar. Çevresel faktörler, firmaların
          sürdürülebilirlik stratejilerini, riskleri ve fırsatları etkiler.{" "}
          <br /> <br />
          Şimdi sizler için oluşturduğumuz PESTLE formu ile ürün ve hizmet
          gruplarınızın her biri için 5 alanda nasıl bir fırsat veya tehdit
          alanı ile karşı karşıya olduğunuzu belirleyin!{" "}
        </span>
      </div>
      <div className="w-full flex items-center">
        <div className="w-full w-[600px] flex-1">
          <div className="w-full pr-4 sm:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
