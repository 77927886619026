import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { testAndLearningCardsService } from "../../../../../services/testAndLearningCards";

import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import DeleteModal from "../../../../../components/Modals/DeleteModal";
import TickCircle from "../../../../../icons/tick-circle.svg";
import Coin from "../../../../../icons/coin.svg";
import TimeManagement from "../../../../../icons/time-management.svg";
import Danger from "../../../../../icons/danger.svg";
import ClipboardTick from "../../../../../icons/clipboard-tick.svg";

export default function TestCard({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);
  const [newCardName, setNewCardName] = useState("");
  const [isPdfLoadingTest, setIsPdfLoadingTest] = useState(false);
  const [isPdfLoadingLearning, setIsPdfLoadingLearning] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(2);
  const [selectedLearningLevel, setSelectedLearningLevel] = useState(2);
  const [selectedCost, setSelectedCost] = useState(2);
  const [selectedTime, setSelectedTime] = useState(2);
  const [selectedRisk, setSelectedRisk] = useState(2);
  const [selectedAction, setSelectedAction] = useState(2);
  const [testCardAnswers, setTestCardAnswers] = useState({
    testName: "Yeni Test " + new Date().toLocaleDateString(),
    endDate: new Date().toISOString().split("T")[0],
    assignedPerson: "Atanmadı",
    hypothesis: "Varsayılan hipotez metni",
    testMethod: "",
    kpi: "",
    successThreshold: "",
    riskLevel: "2",
    dataReliability: "2",
    testCost: "2",
    timeRequired: "2",
  });

  const [learningCardAnswers, setLearningCardAnswers] = useState({
    analysisName: "Analiz " + new Date().toLocaleDateString(),
    analysisDate: new Date().toISOString().split("T")[0],
    responsiblePerson: "Atanmadı",
    hypothesis: "",
    observation: "",
    learnings: "",
    decision: "",
    dataReliability: "2",
    requiredAction: "2",
  });

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    const fetchCardAnswers = async () => {
      if (selectedCard?.id) {
        try {
          const response = await testAndLearningCardsService.getCardAnswers(
            selectedCard.id
          );

          if (response) {
            // Test Card Answers
            const testAnswers = response.testCardAnswers.reduce((acc, item) => {
              const value = Math.max(1, Math.min(3, Number(item.answer) || 2));
              switch (item.containerId) {
                case 1:
                  acc.testName = item.answer;
                  break;
                case 2:
                  acc.endDate = item.answer;
                  break;
                case 3:
                  acc.assignedPerson = item.answer;
                  break;
                case 4:
                  acc.hypothesis = item.answer;
                  break;
                case 5:
                  acc.riskLevel = value.toString();
                  break;
                case 6:
                  acc.testMethod = item.answer;
                  break;
                case 7:
                  acc.testCost = value.toString();
                  break;
                case 8:
                  acc.dataReliability = value.toString();
                  break;
                case 9:
                  acc.kpi = item.answer;
                  break;
                case 10:
                  acc.timeRequired = value.toString();
                  break;
                case 11:
                  acc.successThreshold = item.answer;
                  break;
              }
              return acc;
            }, {});

            // Learning Card Answers
            const learningAnswers = response.learningCardAnswers.reduce(
              (acc, item) => {
                const value = Math.max(
                  1,
                  Math.min(3, Number(item.answer) || 2)
                );
                switch (item.containerId) {
                  case 1:
                    acc.analysisName = item.answer;
                    break;
                  case 2:
                    acc.analysisDate = item.answer;
                    break;
                  case 3:
                    acc.responsiblePerson = item.answer;
                    break;
                  case 4:
                    acc.hypothesis = item.answer;
                    break;
                  case 5:
                    acc.observation = value.toString();
                    break;
                  case 6:
                    acc.dataReliability = value.toString();
                    break;
                  case 7:
                    acc.learnings = item.answer;
                    break;
                  case 8:
                    acc.requiredAction = item.answer;
                    break;
                  case 9:
                    acc.decision = item.answer;
                    break;
                }
                return acc;
              },
              {}
            );

            // Set Test Card State
            setTestCardAnswers((prev) => ({
              ...prev,
              ...testAnswers,
            }));

            // Set Learning Card State
            setLearningCardAnswers((prev) => ({
              ...prev,
              ...learningAnswers,
            }));

            // Set radio button states
            setSelectedRisk(
              { 1: "low", 2: "medium", 3: "high" }[testAnswers.riskLevel] ||
                "medium"
            );
            setSelectedLevel(Number(testAnswers.dataReliability) || 2);
            setSelectedCost(Number(testAnswers.testCost) || 2);
            setSelectedTime(Number(testAnswers.timeRequired) || 2);
            setSelectedLearningLevel(
              Number(learningAnswers.dataReliability) || 2
            );
            setSelectedAction(Number(learningAnswers.requiredAction) || 2);
          }
        } catch (error) {
          console.error("Error fetching card answers:", error);
          toast.error("Kart cevapları yüklenirken bir hata oluştu");
        }
      }
    };

    fetchCardAnswers();
  }, [selectedCard]);

  const fetchCards = async () => {
    try {
      const response = await testAndLearningCardsService.getCards(projectId);
      console.log("API Response:", response);
      setCards(response || []);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  const handleCardSelection = (card) => {
    setSelectedCard(card);
  };

  const handleAddCard = () => {
    if (cards.length < 5) {
      setShowModal(true);
    } else {
      alert("Maksimum 5 tane kart ekleyebilirsiniz.");
    }
  };

  const handleCreateCard = async (cardName) => {
    try {
      const data = {
        testCardName: cardName,
        learningCardName: cardName,
      };

      await testAndLearningCardsService.createCard(projectId, data);
      await fetchCards();
      setShowModal(false);
      setNewCardName("");
      await fetchCards();
    } catch (error) {
      console.error("Error creating card:", error);
    }
  };

  const openDeleteModal = (cardId) => {
    setCardToDelete(cardId);
    setDeleteModalOpen(true);
  };

  const handleDeleteCard = async () => {
    if (cardToDelete) {
      try {
        await testAndLearningCardsService.deleteCard(cardToDelete);
        await fetchCards();
        if (selectedCard?.id === cardToDelete) {
          setSelectedCard(null);
        }
        toast.success("Kart başarıyla silindi");
      } catch (error) {
        console.error("Error deleting card:", error);
        toast.error("Kart silinirken bir hata oluştu");
      } finally {
        setDeleteModalOpen(false);
        setCardToDelete(null);
      }
    }
  };

  const addNewCard = () => {
    handleCreateCard(newCardName);
  };

  const handlePdfDownloadTest = async () => {
    setIsPdfLoadingTest(true);
    const container = document.getElementById("test-card-container");

    try {
      const originalStyle = container.style.cssText;
      container.style.cssText = `
        width: 504px !important;
        position: relative !important;
        padding: 20px !important;
        background: #439dc6 !important;
      `;

      // Handle all text elements
      const textElements = container.querySelectorAll("div, span, textarea");
      const originalElements = [];

      textElements.forEach((element) => {
        if (element.tagName.toLowerCase() === "textarea") {
          const div = document.createElement("div");
          div.textContent = element.value || element.placeholder;
          div.style.cssText = `
            width: 100%;
            min-height: 32px;
            padding: 8px;
            font-family: Outfit;
            font-size: 14px;
            white-space: pre-wrap;
            word-wrap: break-word;
            color: white;
            background: #6cb3d3;
            border-radius: 8px;
            border: 1px solid #6cb3d3;
          `;

          originalElements.push({
            element: element,
            parent: element.parentNode,
            replacement: div,
          });

          element.parentNode.replaceChild(div, element);
        } else {
          // Store original text color
          const originalColor = element.style.color;
          element.style.color = "white";

          originalElements.push({
            element: element,
            originalColor: originalColor,
          });
        }
      });

      try {
        await downloadPageAsPDF(
          "test-card-container",
          "Test Kartı",
          projectName
        );
      } finally {
        // Restore original state
        container.style.cssText = originalStyle;

        // Restore original elements
        originalElements.forEach((item) => {
          if (item.replacement) {
            // Restore textareas
            item.parent.replaceChild(item.element, item.replacement);
          } else {
            // Restore text colors
            item.element.style.color = item.originalColor;
          }
        });
      }
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      setIsPdfLoadingTest(false);
    }
  };

  const handlePdfDownloadLearning = async () => {
    setIsPdfLoadingLearning(true);
    const container = document.getElementById("learning-card-container");

    try {
      const originalStyle = container.style.cssText;
      container.style.cssText = `
        width: 504px !important;
        position: relative !important;
        padding: 20px !important;
        background: #f08025 !important;
      `;

      // Handle all text elements
      const textElements = container.querySelectorAll("div, span, textarea");
      const originalElements = [];

      textElements.forEach((element) => {
        if (element.tagName.toLowerCase() === "textarea") {
          const div = document.createElement("div");
          div.textContent = element.value || element.placeholder;
          div.style.cssText = `
            width: 100%;
            min-height: 32px;
            padding: 8px;
            font-family: Outfit;
            font-size: 14px;
            white-space: pre-wrap;
            word-wrap: break-word;
            color: white;
            background: #f7bf7a;
            border-radius: 8px;
            border: 1px solid #f7bf7a;
          `;

          originalElements.push({
            element: element,
            parent: element.parentNode,
            replacement: div,
          });

          element.parentNode.replaceChild(div, element);
        } else {
          // Store original text color
          const originalColor = element.style.color;
          element.style.color = "white";

          originalElements.push({
            element: element,
            originalColor: originalColor,
          });
        }
      });

      try {
        await downloadPageAsPDF(
          "learning-card-container",
          "Öğrenme Kartı",
          projectName
        );
      } finally {
        // Restore original state
        container.style.cssText = originalStyle;

        // Restore original elements
        originalElements.forEach((item) => {
          if (item.replacement) {
            // Restore textareas
            item.parent.replaceChild(item.element, item.replacement);
          } else {
            // Restore text colors
            item.element.style.color = item.originalColor;
          }
        });
      }
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      setIsPdfLoadingLearning(false);
    }
  };

  const handleSaveAnswers = async () => {
    try {
      // Null check'i daha güvenli hale getirelim
      const currentCardId = selectedCard?.id;
      if (!currentCardId) {
        toast.error("Lütfen geçerli bir kart seçin");
        return;
      }

      // Zorunlu alan kontrolleri
      const requiredFields = [
        { value: testCardAnswers.testName, field: "Test Adı" },
        { value: testCardAnswers.assignedPerson, field: "Atanan Kişi" },
      ];

      const emptyFields = requiredFields.filter((f) => !f.value?.trim());
      if (emptyFields.length > 0) {
        toast.error(
          `Zorunlu alanlar: ${emptyFields.map((f) => f.field).join(", ")}`
        );
        return;
      }

      // Veri formatlama
      const payload = {
        testCardAnswers: [
          { containerId: 1, answer: testCardAnswers.testName.trim() },
          { containerId: 2, answer: testCardAnswers.endDate },
          { containerId: 3, answer: testCardAnswers.assignedPerson },
          { containerId: 4, answer: testCardAnswers.hypothesis },
          {
            containerId: 5,
            answer: String({ low: 1, medium: 2, high: 3 }[selectedRisk] || 2),
          },
          { containerId: 6, answer: testCardAnswers.testMethod },
          { containerId: 7, answer: String(selectedLevel) },
          { containerId: 8, answer: String(selectedCost) },
          { containerId: 9, answer: testCardAnswers.kpi },
          { containerId: 10, answer: String(selectedTime) },
          { containerId: 11, answer: testCardAnswers.successThreshold },
        ],
        learningCardAnswers: [
          { containerId: 1, answer: learningCardAnswers.analysisName },
          { containerId: 2, answer: learningCardAnswers.analysisDate },
          { containerId: 3, answer: learningCardAnswers.responsiblePerson },
          { containerId: 4, answer: learningCardAnswers.hypothesis },
          {
            containerId: 5,
            answer: String(learningCardAnswers.observation || 2),
          },
          { containerId: 6, answer: String(selectedLearningLevel) },
          { containerId: 7, answer: learningCardAnswers.learnings },
          { containerId: 8, answer: String(selectedAction) },
          { containerId: 9, answer: learningCardAnswers.decision },
        ],
      };

      // API çağrısında captured ID kullanalım
      await testAndLearningCardsService.saveCardAnswers(currentCardId, payload);
      toast.success("Kayıt başarılı!");
    } catch (error) {
      console.error("Kayıt hatası:", error);
      toast.error("Kayıt sırasında bir hata oluştu");
    }
  };

  const handleTestInputChange = (field, value) => {
    setTestCardAnswers((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleLearningInputChange = (field, value) => {
    setLearningCardAnswers((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getFieldNameByContainerId = (containerId) => {
    const testCardFields = {
      1: "testName",
      2: "endDate",
      3: "assignedPerson",
      4: "hypothesis",
      5: "riskLevel",
      6: "testMethod",
      7: "dataReliability",
      8: "testCost",
      9: "kpi",
      10: "timeRequired",
      11: "successThreshold",
    };

    const learningCardFields = {
      1: "analysisName",
      2: "analysisDate",
      3: "responsiblePerson",
      4: "hypothesis",
      5: "observation",
      6: "dataReliability",
      7: "learnings",
      8: "requiredAction",
      9: "decision",
    };

    // Check if the field exists in testCardFields
    if (containerId.startsWith("test_")) {
      const testId = parseInt(containerId.split("_")[1]);
      return testCardFields[testId];
    }

    // Check if the field exists in learningCardFields
    if (containerId.startsWith("learning_")) {
      const learningId = parseInt(containerId.split("_")[1]);
      return learningCardFields[learningId];
    }

    return null;
  };

  const handleRadioChange = (setter, value) => {
    setter(parseInt(value, 10));
  };

  return (
    <LoadingFullPage isLoading={isPdfLoadingTest || isPdfLoadingLearning}>
      <div>
        {console.log("Current cards state:", cards)}
        <div className="w-[1136px]">
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Test etmek için belirlediğiniz her bir varsayım için{" "}
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Minimum Geçerli Ürün (MVP)
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            oluşturun. Daha sonra her bir varsayım için{" "}
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Kritik başarı faktörlerinizi (KPIs)
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            belirleyiniz.{" "}
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            MVP, KPI{" "}
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            kavramları ve{" "}
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            MVP
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            test süreci hakkında yeterince bilgi sahibi değilseniz{" "}
          </span>
          <span className="text-[#439dc6] text-lg font-semibold font-['Outfit'] underline">
            bizimle iletişime geçebilirsiniz.{" "}
          </span>
        </div>
        <br />
        <div className="w-[1136px]">
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Son olarak MVP'yi ilgili pazar ve müşteri grubunda test ederek her
            bir varsayım için Strategyzer tarafından geliştirilmiş test ve
            öğrenme kart(lar)ını doldurun. Sistem üzerinden 5 taneye kadar test
            ve öğrenme kartı oluşturabilirsiniz. <br />
            <br />
            Her kritik öneme sahip varsayımınız için ayrı bir test kartı
            doldurmanız gerekmektedir. <br />
            <br />
          </span>
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            1. Adım varsayımınızı yazdığınız, 2. Adım bu varsayımı hangi MVP ile
            nasıl test edeceğinizi belirlediğiniz, 3. Adım KPI'ınızı
            tanımladığınız, 4. ve son adım ise tanımladığınız KPI doğrultusunda
            kritik başarı eşiğini belirlediğiniz aşamadır. <br />
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            <br />
            Test aşamasından sonra öğrenme kart(lar)ını da doldurup ürününüzün
            pazardaki olası başarısını değerlendirin. Eğer bir şeyler yanlış
            gittiyse problem, varsayımlar, müşteri, değer önerisi ve pazar gibi
            temel bileşenlerin üzerinden tekrar geçerek nerede hata yaptığınızı
            bulmaya çalışın. Gerekiyorsa pivot edin.{" "}
          </span>
        </div>
        <br />
        <br />
        <div className=" mr-4 ml-4 h-[0px] border border-[#e0e0e0]"></div>
        <br />
        <br />

        <div className="w-full flex ">
          <div className="flex-1 flex items-start flex-col">
            <div className="flex flex-wrap gap-2 mb-6 w-full justify-start">
              {cards && cards.length > 0 ? (
                cards.map((card) => (
                  <div key={card.id} className="flex items-center gap-1">
                    <button
                      onClick={() => handleCardSelection(card)}
                      className={`px-2 py-2 rounded-lg text-white text-sm font-normal font-['Outfit'] flex items-center gap-2 ${
                        selectedCard?.id === card.id
                          ? "bg-[#439dc6]"
                          : "bg-[#439dc6] opacity-80 hover:opacity-100"
                      }`}
                    >
                      {card.testCardName}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openDeleteModal(card.id);
                        }}
                        className="hover:text-red-500 transition-colors"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </button>
                  </div>
                ))
              ) : (
                <div className="text-gray-500">Henüz kart bulunmamaktadır.</div>
              )}
              <button
                onClick={handleAddCard}
                className="px-4 py-2 rounded-lg border border-[#439dc6] text-[#439dc6] hover:bg-[#6cb3d3] hover:text-[#ffffff] transition-colors"
              >
                + Kart Ekle
              </button>
            </div>
            <div
              id="test-card-container"
              className="w-[504px] bg-[#439dc6] rounded-[10px] p-[24px]"
            >
              <div className="w-[231px]">
                <span className="text-white text-xl font-semibold font-['Outfit']">
                  Test Kartı:{" "}
                </span>
                <span className="text-white text-xl font-normal font-['Outfit']">
                  {selectedCard?.testCardName || ""}
                </span>
              </div>
              <div className="flex gap-2 w-full mt-4">
                <input
                  placeholder="Test Adı"
                  value={testCardAnswers.testName}
                  onChange={(e) =>
                    handleTestInputChange("testName", e.target.value)
                  }
                  className="p-2 flex-1 h-10 ring-0 text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />

                <input
                  placeholder="Bitiş Zamanı"
                  value={testCardAnswers.endDate}
                  onChange={(e) =>
                    handleTestInputChange("endDate", e.target.value)
                  }
                  className="p-2 flex-[2 2 2%] h-10 ring-0 text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>
              <div className="flex gap-2 w-full mt-4">
                <input
                  placeholder="Atanan Kişi"
                  value={testCardAnswers.assignedPerson}
                  onChange={(e) =>
                    handleTestInputChange("assignedPerson", e.target.value)
                  }
                  className="p-2 flex-1 h-10 ring-0 text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />

                <input
                  placeholder="Bitiş Zamanı"
                  className="p-2 flex-[2 2 2%] opacity-0 h-10 ring-0 text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#a2d6ee] text-xs font-normal font-['Outfit']">
                    1.Adım :
                    <span className="text-[#a2d6ee] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Hipotez
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Buna inanıyoruz:
                    </div>
                    <div>
                      <div className="flex items-center justify-end whitespace-nowrap">
                        <div className="text-[#a2d6ee] text-xs font-normal font-['Outfit'] mr-2">
                          Kritik:
                        </div>
                        <div className="flex items-center space-x-2">
                          {["low", "medium", "high"].map((level, index) => {
                            const isSelected = selectedRisk === level;
                            const iconSize =
                              level === "low"
                                ? "w-3 h-3"
                                : level === "medium"
                                ? "w-4 h-4"
                                : "w-5 h-5";

                            return (
                              <label
                                key={index}
                                className="flex items-center cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="risk"
                                  value={level}
                                  className="hidden"
                                  onChange={() =>
                                    handleRadioChange(setSelectedRisk, level)
                                  }
                                />
                                <div
                                  className={`flex items-center justify-center ${
                                    isSelected ? "opacity-100" : "opacity-50"
                                  }`}
                                >
                                  <img
                                    src={Danger}
                                    className={iconSize}
                                    alt="Risk"
                                  />
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  value={testCardAnswers.hypothesis}
                  onChange={(e) =>
                    handleTestInputChange("hypothesis", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#a2d6ee] text-xs font-normal font-['Outfit']">
                    2.Adım :
                    <span className="text-[#a2d6ee] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Test (MVP)
                    </span>
                  </span>

                  <div className="flex items-center justify-between">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Doğrulamak için şunları yapacağız:
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-end whitespace-nowrap">
                        <div className="text-[#a2d6ee] text-xs font-normal font-['Outfit'] mr-2">
                          Veri Güvenilirliği:
                        </div>
                        <div className="flex items-center space-x-2">
                          {[1, 2, 3].map((level, index) => {
                            const isSelected = selectedLevel === level;
                            const iconSize =
                              level === 1
                                ? "w-3 h-3"
                                : level === 2
                                ? "w-4 h-4"
                                : "w-5 h-5";

                            return (
                              <label
                                key={index}
                                className="flex items-center cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="dataReliability"
                                  value={level}
                                  className="hidden"
                                  onChange={() =>
                                    handleRadioChange(setSelectedLevel, level)
                                  }
                                />
                                <div
                                  className={`flex items-center justify-center ${
                                    isSelected ? "opacity-100" : "opacity-50"
                                  }`}
                                >
                                  <img
                                    src={TickCircle}
                                    className={iconSize}
                                    alt="Tick"
                                  />
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </div>

                      <div className="flex items-center justify-end whitespace-nowrap">
                        <div className="text-[#a2d6ee] text-xs font-normal font-['Outfit'] mr-2">
                          Test Maliyeti:
                        </div>
                        <div className="flex items-center space-x-2">
                          {[1, 2, 3].map((level, index) => {
                            const isSelected = selectedCost === level;
                            const iconSize =
                              level === 1
                                ? "w-3 h-3"
                                : level === 2
                                ? "w-4 h-4"
                                : "w-5 h-5";

                            return (
                              <label
                                key={index}
                                className="flex items-center cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="testCost"
                                  value={level}
                                  className="hidden"
                                  onChange={() =>
                                    handleRadioChange(setSelectedCost, level)
                                  }
                                />
                                <div
                                  className={`flex items-center justify-center ${
                                    isSelected ? "opacity-100" : "opacity-50"
                                  }`}
                                >
                                  <img
                                    src={Coin}
                                    className={iconSize}
                                    alt="Coin"
                                  />
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  value={testCardAnswers.testMethod}
                  onChange={(e) =>
                    handleTestInputChange("testMethod", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#a2d6ee] text-xs font-normal font-['Outfit']">
                    3.Adım :
                    <span className="text-[#a2d6ee] text-xs font-semibold font-['Outfit']">
                      {" "}
                      KPI
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Şunları ölçümleyeceğiz:
                    </div>
                    <div>
                      <div className="flex items-center justify-end whitespace-nowrap">
                        <div className="text-[#a2d6ee] text-xs font-normal font-['Outfit'] mr-2">
                          Gerekli Zaman:
                        </div>
                        <div className="flex items-center space-x-2">
                          {[1, 2, 3].map((level, index) => {
                            const isSelected = Number(selectedTime) === level;
                            const iconSize =
                              level === 1
                                ? "w-3 h-3"
                                : level === 2
                                ? "w-4 h-4"
                                : "w-5 h-5";

                            return (
                              <label
                                key={index}
                                className="flex items-center cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="requiredTime"
                                  value={level}
                                  className="hidden"
                                  onChange={() =>
                                    handleRadioChange(setSelectedTime, level)
                                  }
                                />
                                <div
                                  className={`flex items-center justify-center ${
                                    isSelected ? "opacity-100" : "opacity-50"
                                  }`}
                                >
                                  <img
                                    src={TimeManagement}
                                    className={iconSize}
                                    alt="Time"
                                  />
                                </div>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  value={testCardAnswers.kpi}
                  onChange={(e) => handleTestInputChange("kpi", e.target.value)}
                  className="p-2 h-[100px] text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                  placeholder="Buraya yazınız"
                  maxLength={256}
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#a2d6ee] text-xs font-normal font-['Outfit']">
                    4.Adım :
                    <span className="text-[#a2d6ee] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Başarı Eşiği
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      KPI başarı değeri:
                    </div>
                    <div></div>
                  </div>
                </div>
                <textarea
                  value={testCardAnswers.successThreshold}
                  onChange={(e) =>
                    handleTestInputChange("successThreshold", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#6cb3d3]/50 rounded-lg border border-[#6cb3d3] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>
            </div>

            <div className="flex gap-2 mt-4">
              <button
                onClick={handleSaveAnswers}
                className="px-4 py-2 rounded-lg bg-[#439dc6] text-white hover:bg-[#3589af] transition-colors"
              >
                Değişiklikleri Kaydet
              </button>
              <button
                onClick={handlePdfDownloadTest}
                className="px-4 py-2 rounded-lg bg-[#439dc6] text-white hover:bg-[#3589af] transition-colors"
              >
                Test Kartını İndir
              </button>
            </div>
          </div>
          <div className="flex-1 flex items-start flex-col">
            <div className="flex flex-wrap gap-2 mb-6 w-full justify-start">
              {cards.map((card) => (
                <div key={card.id} className="flex items-center gap-1">
                  <button
                    onClick={() => handleCardSelection(card)}
                    className={`px-2 py-2 rounded-lg text-white text-sm font-normal font-['Outfit'] flex items-center gap-2 ${
                      selectedCard?.id === card.id
                        ? "bg-[#f08025]"
                        : "bg-[#f08025] opacity-80 hover:opacity-100"
                    }`}
                  >
                    {card.learningCardName}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteModal(card.id);
                      }}
                      className="hover:text-red-500 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </button>
                </div>
              ))}
              <button
                onClick={handleAddCard}
                className="px-4 py-2 rounded-lg border border-[#f08025] text-[#f08025] hover:bg-[#f7bf7a] hover:text-white transition-colors"
              >
                + Kart Ekle
              </button>
            </div>
            <div
              id="learning-card-container"
              className="w-[504px] bg-[#f08025] rounded-[10px] p-[24px]"
            >
              <div className="">
                <span className="text-white text-xl font-semibold font-['Outfit']">
                  Öğrenme Kartı:
                </span>
                <span className="text-white text-xl font-normal font-['Outfit']">
                  {selectedCard?.learningCardName || ""}
                </span>
              </div>
              <div className="flex gap-2 w-full mt-4">
                <input
                  placeholder="Analiz Adı"
                  value={learningCardAnswers.analysisName}
                  onChange={(e) =>
                    handleLearningInputChange("analysisName", e.target.value)
                  }
                  className="p-2 flex-1 h-10 ring-0 text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#e16315] text-sm font-normal font-['Outfit']"
                />

                <input
                  placeholder="Analiz Tarihi"
                  value={learningCardAnswers.analysisDate}
                  onChange={(e) =>
                    handleLearningInputChange("analysisDate", e.target.value)
                  }
                  className="p-2 flex-[2 2 2%] h-10 ring-0 text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#e16315] text-sm font-normal font-['Outfit']"
                />
              </div>
              <div className="flex gap-2 w-full mt-4">
                <input
                  placeholder="Sorumlu Kişi"
                  value={learningCardAnswers.responsiblePerson}
                  onChange={(e) =>
                    handleLearningInputChange(
                      "responsiblePerson",
                      e.target.value
                    )
                  }
                  className="p-2 flex-1 h-10 ring-0 text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#e16315] text-sm font-normal font-['Outfit']"
                />

                <input
                  placeholder="Bitiş Zamanı"
                  className="p-2 flex-[2 2 2%] opacity-0 h-10 ring-0 text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#e16315] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#ba4a14] text-xs font-normal font-['Outfit']">
                    1.Adım :
                    <span className="text-[#ba4a14] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Hipotez
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Buna inanıyoruz:
                    </div>
                  </div>
                </div>
                <textarea
                  value={learningCardAnswers.hypothesis}
                  onChange={(e) =>
                    handleLearningInputChange("hypothesis", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#ba4a14] text-xs font-normal font-['Outfit']">
                    2.Adım :
                    <span className="text-[#ba4a14] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Gözlem
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Şunları gözlemledik:
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="text-[#ba4a14] text-xs font-normal font-['Outfit'] mr-2">
                        Veri Güvenilirliği:
                      </div>
                      <div className="flex items-center space-x-2">
                        {[1, 2, 3].map((level, index) => {
                          const isSelected = selectedLearningLevel === level;
                          const iconSize =
                            level === 1
                              ? "w-3 h-3"
                              : level === 2
                              ? "w-4 h-4"
                              : "w-5 h-5";

                          return (
                            <label
                              key={index}
                              className="flex items-center cursor-pointer"
                            >
                              <input
                                type="radio"
                                name="dataReliability"
                                value={level}
                                className="hidden"
                                onChange={() =>
                                  handleRadioChange(
                                    setSelectedLearningLevel,
                                    level
                                  )
                                }
                              />
                              <div
                                className={`flex items-center justify-center ${
                                  isSelected ? "opacity-100" : "opacity-50"
                                }`}
                              >
                                <img
                                  src={TickCircle}
                                  className={iconSize}
                                  alt="Tick"
                                />
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  value={learningCardAnswers.observation}
                  onChange={(e) =>
                    handleLearningInputChange("observation", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#ba4a14] text-xs font-normal font-['Outfit']">
                    3.Adım :
                    <span className="text-[#ba4a14] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Öğrenme ve içgörüler
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Gözlemlerden öğrendik ki:
                    </div>
                    <div className="flex items-center justify-center ">
                      <div className="text-[#ba4a14] text-xs font-normal font-['Outfit'] mr-2">
                        Gereken Eylem:
                      </div>

                      <div className="flex items-center space-x-2">
                        {[1, 2, 3].map((level, index) => {
                          const isSelected = selectedAction === level;
                          const iconSize =
                            level === 1
                              ? "w-3 h-3"
                              : level === 2
                              ? "w-4 h-4"
                              : "w-5 h-5";

                          return (
                            <label
                              key={index}
                              className="flex items-center cursor-pointer"
                            >
                              <input
                                type="radio"
                                name="requiredAction"
                                value={level}
                                className="hidden"
                                onChange={() =>
                                  handleRadioChange(setSelectedAction, level)
                                }
                              />
                              <div
                                className={`flex items-center justify-center ${
                                  isSelected ? "opacity-100" : "opacity-50"
                                }`}
                              >
                                <img
                                  src={ClipboardTick}
                                  className={iconSize}
                                  alt="Action"
                                />
                              </div>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <textarea
                  value={learningCardAnswers.learnings}
                  onChange={(e) =>
                    handleLearningInputChange("learnings", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>

              <div className="flex gap-2 flex-col mt-4">
                <div className="flex flex-col">
                  <span className="text-[#ba4a14] text-xs font-normal font-['Outfit']">
                    4.Adım :
                    <span className="text-[#ba4a14] text-xs font-semibold font-['Outfit']">
                      {" "}
                      Karar ve eylem
                    </span>
                  </span>

                  <div className="flex items-center justify-center">
                    <div className="text-white text-xl font-bold font-['Outfit'] flex-1">
                      Bu yüzden şunları yapacağız:
                    </div>
                    <div></div>
                  </div>
                </div>
                <textarea
                  value={learningCardAnswers.decision}
                  onChange={(e) =>
                    handleLearningInputChange("decision", e.target.value)
                  }
                  className="p-2 h-[100px] text-white bg-[#f7bf7a]/50 rounded-lg border border-[#f7bf7a] placeholder:text-[#a2d6ee] text-sm font-normal font-['Outfit']"
                />
              </div>
            </div>

            <div className="flex gap-2 mt-4">
              <button
                onClick={handleSaveAnswers}
                className="px-4 py-2 rounded-lg bg-[#f08025] text-white hover:bg-[#d66d1f] transition-colors"
              >
                Değişiklikleri Kaydet
              </button>
              <button
                onClick={handlePdfDownloadLearning}
                className="px-4 py-2 rounded-lg bg-[#f08025] text-white hover:bg-[#d66d1f] transition-colors"
              >
                Öğrenme Kartını İndir
              </button>
            </div>
          </div>
        </div>

        <div className="w-full flex items-center ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                nextChange={changePage}
                backChange={backPage}
                activeStep={2}
                stepLimit={2}
              />
            </div>
          </div>
        </div>

        {showModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-[400px]">
              <h3 className="text-lg font-semibold mb-4">Yeni Kart Ekle</h3>
              <input
                type="text"
                placeholder="Kart adını giriniz"
                className="w-full p-2 border rounded-lg mb-4"
                value={newCardName}
                maxLength={15}
                onChange={(e) => setNewCardName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addNewCard();
                  }
                }}
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  İptal
                </button>
                <button
                  onClick={addNewCard}
                  className="px-4 py-2 bg-[#f08025] text-white rounded-lg hover:bg-[#e67016]"
                >
                  Ekle
                </button>
              </div>
            </div>
          </div>
        )}

        <DeleteModal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteCard}
        />
      </div>
    </LoadingFullPage>
  );
}
