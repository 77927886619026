import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import GraphicImg from "../../../../../images/graphic.svg";

export default function TestCardInformation({ changePage }) {
  return (
    <div>
      <div className="w-[1136px] h-[233px] relative">
        <div className="w-[1136px]  text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="w-[1136px]  text-[#797979] text-lg font-normal font-['Outfit']">
          Çözümünüzü/fikrinizi ve pazarınızı netleştirmeye başladıktan sonra
          çözümünüzün belirlediğiniz pazarda belirli bir pay alıp alamayacağını
          doğrulama zamanı geldi. <br /> <br />
          Bu adımda öncelikle varsayımlarınızı belirlemeniz gerekmektedir.
          <br /> <br />
          Haydi gelin şimdi başlayalım!{" "}
        </div>
      </div>
      <br />
      <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        Yeni bir iş fikrine dayalı bir ürün ortaya koymaya çalıştığınız için pek
        çok varsayım ile karşı karşıyasınız. Varsayımları dört aşamaya
        ayırabiliriz. Siz de her bir aşamada sahip olabileceğiniz varsayımları
        belirlemeye çalışın:{" "}
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Kullanıcı Davranışı Varsayımları:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Ürününüzü veya hizmetinizi kullanacak olan kişilerin nasıl
          davranacaklarına dair bazı varsayımlarınız olabilir. Örneğin, bir
          e-ticaret uygulaması geliştiriyorsanız, kullanıcıların belirli
          ürünleri satın alacaklarını veya belirli özellikleri kullanacaklarını
          varsayabilirsiniz.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Problemin Çözümü Varsayımları:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Üründe karmaşıklığın veya belirsizliğin azaltılması ile ilgili
          iyileştirmelerdir. Üründen bazı şeyler çıkarılarak veya azaltılarak
          gerçekleştirilebilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Değer Önerisi Varsayımları:{" "}
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Ürün veya hizmetinizin, müşterilere ne tür bir değer sunacağına dair
          varsayımlarınız olabilir. Bu, müşterilerin ürününüzü neden kullanmak
          isteyecekleri ve nasıl fayda sağlayacaklarına dair bir anlayışı
          içerir. Örneğin, bir fitness uygulaması geliştiriyorsanız,
          kullanıcıların sağlık ve formda kalmalarına yardımcı olacağınızı
          varsayabilirsiniz. bilir.{" "}
        </span>
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          İş Modeli Varsayımları:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Ürün veya hizmetinizin nasıl para kazanacağına dair varsayımlarınız
          olabilir. İş modeli varsayımları, gelir kaynaklarınızı,
          fiyatlandırmayı ve karlılık stratejilerinizi içerir.{" "}
        </span>
      </div>
      <br />
      <div className="w-full flex relative flex-col ">
        <div className="w-[566px] text-[#797979] text-lg font-normal font-['Outfit']">
          Onlarca varsayımınız olabilir. Bu varsayımlarınızın önemli bir
          bölümünün doğru olma koşuluyla ürününüzün pazarda başarılı olacağını
          unutmayın! İşte bu yüzden özellikle kritik varsayımlarınızı test
          ederek doğrulamanız gerekir. Bunların hepsini test etmeye zamanınız ve
          bütçeniz yetmeyebilir. Olabildiğince riski yüksek zorluğu düşük
          varsayımları test etmeye çalışın. Risk, varsayımın doğru olmaması
          durumunda ürünü başarısız olma olasılığını temsil eder; zorluk ise
          varsayımı test etmenin zorluğu yani eforunuzu temsil eder.{" "}
        </div>
        <br />
        <div className="w-[566px] text-[#797979] text-lg font-normal font-['Outfit']">
          Bunların hepsini test etmeye zamanınız ve bütçeniz yetmeyebilir.
          Olabildiğince riski yüksek zorluğu düşük varsayımları test etmeye
          çalışın. Risk, varsayımın doğru olmaması durumunda ürünü başarısız
          olma olasılığını temsil eder; zorluk ise varsayımı test etmenin
          zorluğu yani eforunuzu temsil eder.{" "}
        </div>
        <img
          className="absolute -bottom-4 right-0"
          src={GraphicImg}
          alt="Graphic"
        />
      </div>

      <br />
      <div className="w-full flex items-center relative">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10">
            <Stepper nextChange={changePage} activeStep={1} stepLimit={2} />
          </div>
        </div>
      </div>
    </div>
  );
}
