import React, { useState, useEffect } from "react";
import { moduleService } from "../../../services/moduleService";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../components/LoadingFullPage/LoadingFullPage";

const getModuleIcon = (moduleId) => {
  switch (moduleId) {
    case 5: // İnovasyon Check-Up
      return (
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M8 10L11 13L16 8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 1: // Müşteri ve Problem Analizi
      return (
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 2: // İç Girişimcilik
      return (
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 7V17M7 12H17"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
          />
        </svg>
      );
    case 3: // Stratejik Plan
      return (
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M3.5 5.5L5 3.5H19L20.5 5.5M3.5 5.5H20.5M3.5 5.5V19C3.5 19.5523 3.94772 20 4.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V5.5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 4: // Pazar Araştırması ve İhracat
      return (
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M20 7L12 3L4 7M20 7L12 11M20 7V17L12 21M12 11L4 7M12 11V21M4 7V17L12 21"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return null;
  }
};

export default function Modules() {
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchModules = async () => {
    try {
      const data = await moduleService.getModulePermissions();
      console.log("Modules data:", data);
      setModules(data);
    } catch (error) {
      console.error("Error fetching modules:", error);
      toast.error("Modüller yüklenirken bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isLoading}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {modules.map((module) => (
          <div
            key={module.moduleId}
            className={`bg-gradient-to-br rounded-2xl p-6 shadow-lg transition-all duration-200 ${
              module.isPurchased && module.canView
                ? "from-[#439DC6] to-[#3B89B6] shadow-[#439DC6]/20 hover:shadow-xl hover:shadow-[#439DC6]/40 cursor-pointer"
                : "from-gray-300 to-gray-400 shadow-gray-200/20 cursor-not-allowed opacity-60"
            }`}
          >
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 rounded-full bg-white/20 flex items-center justify-center">
                <div className="text-white">
                  {getModuleIcon(module.moduleId)}
                </div>
              </div>
              <h3 className="text-white font-semibold font-['Outfit']">
                {module.title}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </LoadingFullPage>
  );
}
