import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function Information({ changePage, backPage, handleNext }) {
  return (
    <div className="max-w-[1136px] w-full mx-auto px-4">
      <div className="text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-full max-w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
        Pazar veya Sektörel Rekabet Analizi, hem problem alanlarını ve bu
        problemlerin etkilediği paydaşları ortaya koyabilmek hem de rekabet
        ettiğiniz pazara dair sizlere bilgi sağlar. Bunun için Michael Porter'ın
        Beş Güç Analizi Yöntemi ile pazardaki rekabetin şiddeti, pazara yeni
        girebilecek firmaların yarattığı tehdit, tedarikçilerin ve müşterilerin
        pazarlık gücü ve ikame ürünlerin yaratabileceği tehditi
        ölçümleyebilirsiniz. Buna göre pazarınızdan gelecek fırsat ve tehdit
        alanlarını belirleyip ona göre strateji ve inovasyon alanları
        oluşturabilirsiniz.{" "}
      </div>
      <br />
      <div className="max-w-[243px] w-full text-[#797979] text-lg font-semibold font-['Outfit']">
        Haydi gelin şimdi başlayalım!
      </div>
      <div className="w-full max-w-[1136px]">
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Michael Porter'ın beş güç analizi, bir endüstrinin rekabet ortamını
          anlamak için kullanılan bir çerçevedir. Bu analiz, bir işletmenin
          stratejik konumunu ve rekabet avantajlarını belirlemesine yardımcı
          olabilir. Porter'ın Beş Güç Analizi'ni özetleyen unsurlar şu
          şekildedir: <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Pazardaki Rakipler Arasındaki Şiddet:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Pazardaki rakipler, o ürün/hizmet grubu için doğrudan rekabet eden
          firmalardır. Pazar payını artırmak ve rekabet avantajı elde etmek için
          bu firmalarla rekabet etmek gerekir. Porter'a göre, rekabetin yoğun
          olduğu endüstrilerde, firmalar genellikle kar marjlarını düşük
          tutarlar. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Pazara Yeni Girecek Firmaların Yaratacağı Tehdit:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Yeni girişimler, mevcut oyuncuların pazar payını tehdit edebilir. Bu
          genellikle düşük giriş engellerin olduğu endüstrilerde ortaya çıkar.
          Yeni girişimlerin pazardaki varlığı, mevcut firmaların kar marjlarını
          düşürebilir ve rekabeti artırabilir. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Tedarikçilerin Pazarlık Gücü:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Tedarikçiler, bir işletmenin ihtiyaç duyduğu mal ve hizmetleri
          sağlayan firmalardır. Tedarikçilerin gücü, endüstrideki az sayıda
          tedarikçiye veya özel kaynaklara sahip olmalarından kaynaklanabilir.
          Güçlü tedarikçiler, fiyatları artırabilir veya kaliteyi düşürebilir,
          bu da işletmelerin kar marjlarını azaltabilir. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          Alıcıların (Müşterilerin) Pazarlık Gücü:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Alıcılar, bir işletmenin ürünlerini veya hizmetlerini satın alan
          müşterilerdir. Alıcı gücü, bir endüstrideki müşterilerin az sayıda
          olması veya taleplerinin güçlü olması durumunda artar. Güçlü alıcılar,
          fiyatları aşağı çekebilir veya daha yüksek kalite ve hizmet talep
          edebilir, bu da işletmelerin kar marjlarını azaltabilir. <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          <br />
          İkame Ürünlerin veya Hizmetlerin Tehdidi:
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          Bu, bir endüstrideki alternatif ürünlerin veya hizmetlerin varlığını
          ifade eder. Eğer alternatif ürünler veya hizmetler, müşterilere aynı
          veya daha iyi değeri daha uygun fiyata sunuyorsa, bu mevcut
          oyuncuların rekabet avantajını azaltabilir. <br /> <br />
          Şimdi sizler için oluşturduğumuz ölçek ile her bir ürün veya hizmet
          grubunuzda bu 5 alanda nasıl bir fırsat veya tehdit alanı ile karşı
          karşıya olduğunuzu belirleyin!{" "}
        </span>
      </div>
      <div className="w-full flex items-center">
        <div className="w-full w-[600px] flex-1">
          <div className="w-full pr-4 sm:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
