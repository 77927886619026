import React from "react";

import Stepper from "../../../../../components/Stepper/MainStepper";
import Threatening from "../../../../../icons/threatening.svg";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function InformationInterviewTemplate({ changePage, backPage }) {
  return (
    <div className="px-4">
      <div id="page-content-container">
        <div className="max-w-[1136px] mx-auto text-[#797979] text-lg font-normal font-['Outfit']">
          Probleminizin ne kadar gerçekçi ve üzerinde durulması gereken bir
          problem olduğunu Sebep-Sonuç ve Kök Neden Analizi yaparak bir miktar
          ortaya koymaya çalıştınız. Eğer yaptığınız analiz sonucu problemin çok
          üzerinde çalışılması gereken bir problem olmadığını
          değerlendirdiyseniz farklı bir problem alanı seçin. Yoksa şimdi
          probleminize ilişkin veri toplama zamanı geldi. Veri toplama
          kullanıcılarınızın, müşterilerinizin gerçekten böyle bir problemi
          olduğunu doğrulama için yürütülür. Bu aşamada tasarım odaklı düşünme
          sürecinin olmazsa olmazı gözlem veya mülakat yöntemini önereceğiz.
        </div>

        <br />
        <div className="max-w-[1117px] mx-auto p-[5px] bg-[#fef7eb] rounded-[11px] mt-5 flex items-center">
          <img className="p-4" src={Threatening} alt="Threatening Icon" />
          <div className="flex-1">
            <div className="text-[#797979] text-lg font-normal font-['Outfit']">
              Örneğimizde yer alan 3-10 yaş arası çocuklar esas
              kullanıcılarınızdır; ama mülakatları müşterileriniz olacak
              ebeveynler ile gerçekleştirebilirsiniz. Her mülakatta tek kişi ile
              görüşmenizi ve toplamda 8-12 kişi ile mülakat yapmanızı öneririz.
            </div>
          </div>
        </div>
        <br />
        <div className="max-w-[1136px] mx-auto text-[#797979] text-lg font-normal font-['Outfit']">
          Tasarım odaklı düşünme yaklaşımında en önemli konu başlıklarından biri
          mevcut veya potansiyel müşterileriniz ile yapacağınız görüşmelerde
          doğru soru yapılarını kullanabilmek ve müşterilerinize doğru soruları
          yöneltebilmektir. Bunun için aşağıda yer alan formatı önermekteyiz.
          Soru havuzunun büyük bir bölümünü genel, deneyim ve spesifik soru
          yapıları oluştursun. Belki birkaç tane de meta-seviyesi veya temenni
          soruları yöneltebilirsiniz. Her alan için en az 1 en fazla 5 soru
          belirlemenizi tavsiye ederiz. Her bir mülakatı 40-45 dk içerisinde
          bitirmeye gayret gösteriniz. Sorularınızı bu şablona kaydedebilir,
          mülakatlarda bu şablondan faydalanabilirsiniz. Aşağıda örnek bir
          mülakat yürütme sürecinden bahsedeceğiz. Daha sonrasında mülakat
          sonuçlarını kaydedeceğiniz bir şablon daha sunacağız.
        </div>
      </div>
      <div className="w-full flex items-center">
        <div className="w-[600px] mx-auto flex-1">
          <div className="w-full pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={5}
              stepLimit={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
