import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function InformationJTBD({ changePage }) {
  return (
    <div className="max-w-[1136px] mx-auto">
      <div className="text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="text-[#797979] text-lg font-normal font-['Outfit']">
        Bu modül ilk belirlediğiniz problemi doğrulama veya yeni problem
        alanları keşfederek artık probleminizi çerçeveleme için yürütülür. Eğer
        kullanıcılar ile yaptığınız mülakatlar veya ekipçe yürüttüğünüz Kök
        Neden Analizleri sonucu problem alanından emin değilseniz ilk aşama için
        farklı problem alanları ile süreci tekrarlayınız. Problemin varlığına
        ilişkin yeterince ikna olduysanız Empati aşamasında yürüttüğünüz
        çalışmalar sonucu yavaş yavaş problem alanları hakkında bir içgörü
        sahibi olmaya başlayacaksınız. Jobs To Be Done Kanvası bu aşamada
        sizlere yol gösterecektir. Özellikle mülakatlar sonucu elde ettiğiniz
        verileri Jobs to be Done (JTBD) kanvasında birleştiriniz. <br />{" "}
      </div>
      <br />
      <div className="text-[#797979] text-lg font-normal font-['Outfit']">
        Jobs-to-be-Done (JTBD) kanvası, müşterilerin gerçek ihtiyaçlarını
        anlamak ve yenilikçi çözümler geliştirmek için kullanılan bir araçtır.
        Ürün veya hizmetlerin, kullanıcıların “yapmak istedikleri işler” üzerine
        odaklanmasını sağlar. Bu çerçeveyi doğru bir şekilde uygulamak, ürün
        geliştirme süreçlerini daha etkili hale getirir ve pazar ihtiyaçlarına
        uygun olacak çözümlerin sunulmasını sağlar. Ürün ekibi üyeleri,
        aşağıdaki konularda anlaşmaya varmak için çalışırlar: <br />{" "}
      </div>
      <br />
      <div className="text-black text-lg font-semibold font-['Outfit']">
        Kanvasın temelde şu alanları vardır:
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          1
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            İhtiyaçlar (Kazançlar/Kayıplar):
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            Bu alan, kullanıcının veya paydaşın yapmaya çalıştığı işin sonucunda
            elde etmeyi beklediği kazançları ve kaçınmaya çalıştığı kayıpları
            tanımlar. Kullanıcının beklentilerini ve karşılaşabileceği olumsuz
            durumları listeler. Öncelik sırasına göre, kazançlar ve kayıplar
            yüksekten düşüğe sıralanır.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          2
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            İlgili Paydaş için Job(s) to be Done (Yapılacak İş):
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            {" "}
            Bu alan, kullanıcının veya paydaşın gerçekleştirmeye çalıştığı temel
            iş(ler)i tanımlar. Bu iş, genellikle müşterinin hayatındaki bir
            sorunu çözmeye veya önemli bir ihtiyacı karşılamaya yönelik olur.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          3
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Fonksiyonel Bileşen (İhtiyaçlar):{" "}
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Kullanıcıların temel olarak bir üründen veya hizmetten bekledikleri
            işlevsel özelliklerdir. Örneğin, bir telefon alırken kullanıcının
            işlevsel ihtiyaçları arasında hızlı işlemci, yüksek çözünürlüklü
            kamera gibi özellikler bulunabilir.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          4
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Duygusal Bileşen (İhtiyaçlar):{" "}
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Kullanıcıların ürün veya hizmetle ilişkilendirdikleri duygusal
            tatmin veya deneyimlerdir. Örneğin, bir araba alırken kullanıcının
            duygusal ihtiyaçları arasında prestij, güvenlik hissi veya kendini
            ifade etme bulunabilir.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          5
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            Sosyal Bileşen (İhtiyaçlar):{" "}
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Kullanıcının başkaları tarafından nasıl algılanmak istediğidir.
            Kullanıcıların sosyal çevreleriyle ilişkilendirdikleri
            ihtiyaçlardır. Örneğin, bir kıyafet alırken kullanıcının sosyal
            ihtiyaçları arasında kabul görme, statü göstergesi olma veya
            toplumsal gruplarla uyum sağlama isteği bulunabilir.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="flex">
        <div className="w-6 h-6 relative items-center justify-center flex font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
          6
        </div>
        <div className="flex-1 ml-4">
          <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
            İş İfadesi:{" "}
          </span>
          <span className="text-[#797979] text-lg font-normal font-['Outfit']">
            Bu bileşenlerin bir araya getirilmesiyle oluşturulan tek cümlelik
            bir özet olarak kullanılır. Örneğin, "Ne zaman, kim, hangi durumda,
            neyi başarmak istiyor ve motivasyonu nedir?" sorularına cevap verir
            ve istenen sonucu belirtir.{" "}
          </span>
        </div>
      </div>
      <br />
      <div className="text-[#797979] text-lg font-normal font-['Outfit']">
        Bir ürünü veya hizmeti geliştirirken, yalnızca fonksiyonel ihtiyaçları
        karşılamak yeterli olmamaktadır. Kullanıcıların ve paydaşların duygusal
        deneyimlerini ve motivasyonlarını da dikkate almak gerekir. Bu noktada
        kullanıcı veya paydaşın ihtiyaçlarını, deneyimlerini ve duygularını daha
        iyi anlamak için çeşitli araç setleri bizlere yardımcı olur.
        <br /> <br />
        Tasarım odaklı düşünme ve JTBD temelde kullanıcıların fonksiyonel
        ihtiyaçları yanı sıra duygusal ve sosyal ihtiyaçlarını da anlamlandırmak
        için yürütülür. Bu yüzden mülakatlar sonucu elde ettiğiniz verileri
        mülakat sonuç şablonu ile JTBD kanvasında birleştirmenizi öneririz.{" "}
      </div>
      <div className="w-full flex items-center ">
        <div className="flex-1">
          <div className="w-full pr-10 ">
            <Stepper nextChange={changePage} activeStep={1} stepLimit={4} />
          </div>
        </div>
      </div>
    </div>
  );
}
