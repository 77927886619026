import React from "react";
import MarketFocusIcon from "../../icons/market-focus.svg";
import SolutionFocusIcon from "../../icons/solution-focus.svg";

export default function Modal({
  isOpen,
  onClose,
  selectedOption,
  handleOptionSelect,
  modalInput,
  setModalInput,
  error,
  handleSubmit,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-[500px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Gerekli İşareti Seçiniz</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex gap-4 mb-4">
          <button
            onClick={() => handleOptionSelect("current")}
            className={`flex items-center gap-2 p-3 border border-2 rounded-lg ${
              selectedOption === "current"
                ? "border-red-500 bg-red-50"
                : "border-red-400"
            }`}
          >
            <img src={MarketFocusIcon} alt="Market Focus" />
            <span>Şu anki Pazarın Odak Noktası</span>
          </button>

          <button
            onClick={() => handleOptionSelect("solution")}
            className={`flex items-center gap-2 p-3 border border-2 rounded-lg ${
              selectedOption === "solution"
                ? "border-blue-500 bg-blue-50"
                : "border-blue-400"
            }`}
          >
            <img src={SolutionFocusIcon} alt="Solution Focus" />
            <span>Çözümün Odak Noktası</span>
          </button>
        </div>
        {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
        <textarea
          id="pop-up-text-area"
          value={modalInput}
          onChange={(e) => setModalInput(e.target.value)}
          placeholder="Değer önerinizi 3-4 kelimeyle özetleyiniz."
          className="w-full h-32 p-3 border z-10 rounded-lg resize-none mb-4"
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-lg hover:bg-gray-50"
          >
            İptal
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
