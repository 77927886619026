import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Close from "../../../../../icons/close.svg";
import UserIcon from "../../../../../icons/hc-user.svg";
import InfoIcon from "../../../../../icons/info.svg";
import AddWhite from "../../../../../icons/add-white.svg";
import { businessModelCanvasService } from "../../../../../services/businessModelCanvasService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import Download from "../../../../../icons/download.svg";
import PartnerIcon from "../../../../../icons/core-partners-1.svg";
import ValuePropIcon from "../../../../../icons/value-proposition-2.svg";
import CustomerIcon from "../../../../../icons/customer-segment-3.svg";
import ActivityIcon from "../../../../../icons/core-activities-4.svg";
import RelationshipIcon from "../../../../../icons/customer-relationships-5.svg";
import ChannelIcon from "../../../../../icons/channels-6.svg";
import ResourceIcon from "../../../../../icons/core-resources-7.svg";
import CostIcon from "../../../../../icons/cost-structure-8.svg";
import RevenueIcon from "../../../../../icons/revenue-stream-9.svg";

export default function BusinessModelCanvas({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [answers, setAnswers] = useState({
    1: "", // Temel Ortaklar
    2: "", // Değer Önerisi
    3: "", // Müşteri Segmentleri
    4: "", // Temel Faaliyetler
    5: "", // Müşteri İlişkileri
    6: "", // Kanallar
    7: "", // Temel Kaynaklar
    8: "", // Maliyet Yapısı
    9: "", // Gelir Akışı
  });

  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await businessModelCanvasService.getAnswers(projectId);
        const newAnswers = { ...answers };
        response.forEach((item) => {
          if (item.titleId && item.answer) {
            newAnswers[item.titleId] = item.answer;
          }
        });
        setAnswers(newAnswers);
      } catch (error) {
        toast.error("Cevaplar yüklenirken bir hata oluştu");
      }
    };

    if (projectId) {
      fetchAnswers();
    }
  }, [projectId]);

  const handleInputChange = (titleId, value) => {
    setAnswers((prev) => ({
      ...prev,
      [titleId]: value,
    }));
  };

  const handleNext = async () => {
    try {
      const formattedAnswers = Object.entries(answers)
        .filter(([_, answer]) => answer.trim() !== "") // Only send non-empty answers
        .map(([titleId, answer]) => ({
          titleId: parseInt(titleId),
          answer: answer.trim(),
        }));

      await businessModelCanvasService.saveAnswers(projectId, formattedAnswers);
      toast.success("Cevaplar başarıyla kaydedildi");
      changePage();
    } catch (error) {
      toast.error("Cevaplar kaydedilirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("business-model-canvas");

    // Temporary style changes
    const originalStyle = container.style.cssText;

    // Temporarily adjust container
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    // Convert textareas to visible divs temporarily
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    Array.from(textareas).forEach((textarea) => {
      const div = document.createElement("div");
      div.textContent = textarea.value || textarea.placeholder;
      div.style.cssText = `
        width: 100%;
        min-height: 32px;
        padding: 8px;
        font-family: Outfit;
        font-size: 14px;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        line-height: 1.5;
      `;

      originalElements.push({
        element: textarea,
        parent: textarea.parentNode,
      });

      textarea.parentNode.replaceChild(div, textarea);
    });

    try {
      await downloadPageAsPDF(
        "business-model-canvas",
        "İş Modeli Kanvası",
        projectName
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;

      // Restore original textareas
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div id="business-model-canvas">
        <div className="w-full text-center h-[30px] text-black text-[32px] font-semibold font-['Outfit']">
          İş Modeli Kanvası
        </div>

        <div className="grid p-4 grid-cols-3 gap-4 mt-8">
          {/* Temel Ortaklar */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={PartnerIcon} alt="partners" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Temel Ortaklar
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[1]}
                onChange={(e) => handleInputChange(1, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Değer Önerisi */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={ValuePropIcon} alt="value-proposition" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Değer Önerisi
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[2]}
                onChange={(e) => handleInputChange(2, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Müşteri Segmentleri */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={CustomerIcon} alt="customer-segments" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Müşteri Segmentleri
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[3]}
                onChange={(e) => handleInputChange(3, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Temel Faaliyetler */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={ActivityIcon} alt="key-activities" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Temel Faaliyetler
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[4]}
                onChange={(e) => handleInputChange(4, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Müşteri İlişkileri */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={RelationshipIcon} alt="customer-relationships" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Müşteri İlişkileri
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[5]}
                onChange={(e) => handleInputChange(5, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Kanallar */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={ChannelIcon} alt="channels" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Kanallar
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[6]}
                onChange={(e) => handleInputChange(6, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Temel Kaynaklar */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={ResourceIcon} alt="key-resources" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Temel Kaynaklar
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[7]}
                onChange={(e) => handleInputChange(7, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Maliyet Yapısı */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={CostIcon} alt="cost-structure" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Maliyet Yapısı
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[8]}
                onChange={(e) => handleInputChange(8, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>

          {/* Gelir Akışı */}
          <div className="rounded-[20px] bg-white min-h-[400px] border-2">
            <div className="w-full h-[81px] bg-[#5F6FA8] rounded-[20px] relative">
              <img
                className="absolute right-[20px] top-7"
                src={InfoIcon}
                alt="info"
              />
              <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                <img src={RevenueIcon} alt="revenue-streams" />
                <div className="w-40 ml-2">
                  <span className="text-white text-lg font-semibold font-['Outfit']">
                    Gelir Akışı
                  </span>
                </div>
              </div>
            </div>
            <div className="p-4">
              <textarea
                value={answers[9]}
                onChange={(e) => handleInputChange(9, e.target.value)}
                className="w-full h-[300px] p-2 border rounded-lg resize-none focus:outline-none"
                placeholder="Cevabınızı buraya yazın..."
                maxLength={400}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10">
              <Stepper
                nextChange={handleNext}
                backChange={backPage}
                activeStep={2}
                stepLimit={2}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
