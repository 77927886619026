import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Lock from "../../../../../icons/lock-white.svg";

export default function Information({ lock, changePage }) {
  console.log("lock: ", lock);
  return (
    <>
      <div className=" text-black text-lg font-semibold font-['Outfit']">
        Değerli Firma Yetkilimiz,
      </div>
      <br />
      <div className="w-[1136px]">
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          Sunduğunuz çözüm/iş fikri yeni pazarlara hitap ediyor olabilir. İş
          fikrinizin yeni pazarlarda başarılı olup olamayacağını belirlemeden
          önce üzerinde çalışacağınız pazarı doğru belirlemeniz oldukça
          önemlidir. Bir ürün veya hizmetin yeni bir pazarda başarı şansı
          potansiyel pazarın çekiciliği ile orantılıdır. Bir pazarın çekiciliği
          genellikle dört faktöre bağlıdır. Bu faktörleri 1-5 arasında
          ölçeklendirerek ve belirlediğiniz kriter ağırlıklarına göre
          puanlandırarak bir pazarın çekiciliğini hesaplayabilirsiniz. Yurtdışı
          hedef pazarlar belirlediyseniz ihracat faaliyetleri farklı dinamiklere
          sahip olduğu için ihracat adımında sunduğumuz modüllere bakmanızı
          tavsiye ederiz. <br />
          <br />
        </span>
        <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
          {" "}
          Haydi gelin şimdi başlayalım! <br />
        </span>
        <span className="text-[#797979] text-lg font-normal font-['Outfit']">
          {" "}
          <br />
          Bir pazarın çekiciliğini ortaya koymak için şu dört faktöre göz
          gezdirebilirsiniz: 1.Pazara Giriş Zorluk Derecesi, 2. Pazardaki
          Rekabetin Şiddeti, 3. Pazarın Büyüklüğü ve 4. Pazarın Büyüme Hızı.
          Bunlardan ilk iki parametre için sizlere nicel değerlendirme
          yapabileceğiniz bir ölçek sunuyoruz. Diğer ikisi içinse sizlere
          yönlendirme yaparak o pazar için puanlama yapmanıza destek oluyoruz.
          Şimdi Pazara Giriş Zorluk Derecesi ve Pazardaki Rekabetin Şiddeti için
          aşağıdaki ölçümleme yöntemini kullanarak ilgili alanlara 1-5 arasında
          puanınızı girerek ortalama puanınızı hesaplayınız. Pazar Büyüklüğü ve
          Pazar Büyüme Hızı için ise tek bir puan girerek ilgili pazarın
          çekiciliğini hesaplayınız.{" "}
        </span>
      </div>

      <div className="w-full flex items-center ">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              // nextTitle={!lock ? "Kilitli" : "Devam"}
              // nextDisable={!lock}
              nextChange={changePage}
              activeStep={1}
              stepLimit={5}
              // rightIcon={Lock}
            />
          </div>
        </div>
      </div>
    </>
  );
}
