import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const jtbdService = {
  getAllJTBD: async (projectId) => {
    try {
      const response = await axios.get(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/all`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  saveJTBD: async (projectId, data) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  addCanvasItem: async (projectId, data) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/canvas`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  saveAnswers: async (projectId, data) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/answers`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  saveCanvasOrder: async (projectId, data) => {
    try {
      const response = await axios.post(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/canvas/order`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  updateCanvasItemOrder: async (projectId, titleId, answers) => {
    try {
      const response = await axios.put(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/canvas/answers/${titleId}/order`,
        { answers },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response?.data || error;
    }
  },

  deleteCanvasItem: async (projectId, answerId) => {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/customer-problem-analysis/problem-definition/jobs-to-be-done/${projectId}/canvas/answers/${answerId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
