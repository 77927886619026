import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import StakeholderAnalysisImage from "../../../../../images/stakeholder-analysis.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import StrategicDirectionImage from "../../../../../images/strategic-direction.svg";

export default function InformationOKR({ changePage, backPage, handleNext }) {
  return (
    <div className="w-full max-w-[1136px] flex flex-col gap-4 md:gap-8 mx-auto px-2 md:px-4">
      <div className="w-full flex flex-col items-center">
        <div className="w-full">
          <span className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit']">
            OKR'lerin İzlenmesi:{" "}
          </span>
          <span className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
            OKR'ler yalnızca düzenli takip edilirse başarılabilir. Özellikle
            Taahhütlü OKR'ler için doğru yolda olup olmadığınızı
            belirlemelisiniz. Bu tür OKR'ler, mutlaka ulaşılması gereken
            hedeflerdir. Gerçekleştirilmesi taahhüt edilir ve genellikle
            performans değerlendirmelerinde önemli bir rol oynar. Bir de İstekli
            OKR'ler vardır. Bu tür OKR'ler, ekipleri veya bireyleri zorlayan ve
            sınırlarını zorlamalarını teşvik eden iddialı hedeflerdir.
            Gerçekleştirilmesi ideal olarak görülür, ancak ulaşılması zor
            olabilir. Daha inovatif, yeniliği teşvik eden ve motivasyon
            artırmaya yöneliktir. Aşağıda bu OKR türleri için birer örnek
            görebilirsiniz.
            <br />{" "}
          </span>
        </div>
        <div className="w-full mt-4 md:mt-8">
          <div className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit'] mb-2 md:mb-4">
            Taahhütlü OKR:
          </div>
          <div className="flex flex-col gap-1 md:gap-2 pl-2 md:pl-4">
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Amaç: Mevcut müşteri kitlesini genişletmek.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 1: Mevcut müşteri sayısını yıl sonunda %15 artırmak.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 2: Müşteri memnuniyet puanını %90'a çıkarmak.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 3: Yeni müşteri edinme maliyetini %20 azaltmak.
            </div>
          </div>

          <div className="text-[#797979] text-base md:text-lg font-semibold font-['Outfit'] mt-4 md:mt-6 mb-2 md:mb-4">
            İstekli OKR:
          </div>
          <div className="flex flex-col gap-1 md:gap-2 pl-2 md:pl-4">
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Amaç: Pazar yeni ürünle liderliğe ilerlemek.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 1: Yıl sonuna kadar piyasada devrim niteliğinde 2
              yeni ürün lansmanı yapmak.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 2: İnovasyonun yaygınlaştırılması 3 katı katlamak.
            </div>
            <div className="text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
              • Temel Sonuç 3: Yeni ürünlerden elde edilen geliri kıyasla geliri
              %25'ine ulaşmak.
            </div>
          </div>
        </div>
        <br />
        <div className="w-full text-[#797979] text-base md:text-lg font-normal font-['Outfit']">
          Her iki durumda da önünüzdeki 1-2 haftaya bakın ve bu OKR'lere yönelik
          çalışmak için yeterli zaman/kaynak ayırıp ayırmadığınıza karar verin.
          Hedefe ulaşacağınıza inanıyorsanız sorun yoktur ve "İyi Durumda"
          olarak "KR"nızın durumunu belirleyebilirsiniz.Aksi takdirde bir tehdit
          varsa kaybedilen bu haftaları nasıl telafi edeceğiniz konusunda bir
          plan geliştirin.Bu hedefin (O) ve/veya Temel Sonucun (KR) durumunu
          "Takip Edilmesi Gerekiyor" olarak ayarlayın. Planlarınızı yeniden
          değerlendirmeniz, bunu yöneticinizle tartışmanız veya başkalarından
          size yardım etmelerini istemeniz gereken durumlarda durumuzu "Riskli"
          olarak raporlayın.
          <br />{" "}
        </div>

        <div className="w-full w-[600px] flex-1">
          <div className="w-full pr-2 md:pr-4 lg:pr-10">
            <Stepper
              nextChange={changePage}
              backChange={backPage}
              activeStep={1}
              stepLimit={2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
