import React, { useState, useEffect } from "react";
import RadioSurvey from "../Modules/RadioSurvey";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { exportIntentionService } from "../../../../../services/exportIntentionService";
import { toast } from "react-toastify";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function Information({
  backChange,
  nextChange,
  productsId,
  projectName,
}) {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!productsId) return;

      setIsLoading(true);
      try {
        const response = await exportIntentionService.getAnswers(productsId);
        setQuestions(
          response.map((q) => ({
            id: q.containerId,
            question: q.title,
            userAnswer: q.answer,
            isDefaultQuestion: true,
          }))
        );
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnswers();
  }, [productsId]);

  const handleAnswerChange = async (questionId, value) => {
    try {
      await exportIntentionService.submitAnswer(
        productsId,
        questionId,
        parseInt(value)
      );
      setQuestions((prev) =>
        prev.map((q) =>
          q.id === questionId ? { ...q, userAnswer: parseInt(value) } : q
        )
      );
    } catch (error) {
      toast.error("Cevap kaydedilirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("page-content-container");

    // Store original styles
    const originalStyle = container.style.cssText;

    // Set temporary container styles for PDF
    container.style.cssText = `
      width: 1200px !important;
      position: relative !important;
      padding: 20px !important;
      background: white !important;
    `;

    try {
      await downloadPageAsPDF(
        "page-content-container",
        "İhracat Niyeti Anketi",
        projectName || "Project"
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original state
      container.style.cssText = originalStyle;
      setIsPdfLoading(false);
    }
  };

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div className="w-full max-w-[1136px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
          Bu bölüm, pazarlama ağını genişletmek isteyen ve yurtdışına açılmak
          isteyen firmalarımız için doldurulacak alanlardır. Önümüzdeki dört
          modülde sizlere bu alanlarda destek olmaya çalışacağız.
        </div>
        <br />
        <div className="text-[#797979] text-base sm:text-lg font-semibold font-['Outfit']">
          Haydi gelin şimdi başlayalım!
        </div>
        <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
          İlk olarak kısa ihracat niyeti anketini doldurarak ihracat niyetinizi
          değerlendiriniz. Stratejik Plan modüllerinde olduğu gibi her bir
          ürün/hizmet grubunuz için ayrı ayrı doldurunuz. Eğer ihracat yapmaya
          yönelik bir hedefiniz, arzunuz varsa ikinci kısımda ihracat
          potansiyelinizi değerlendiriniz.
        </div>
        <br />
        <div className="text-[#242731] text-2xl sm:text-3xl md:text-[32px] font-bold font-['Outfit']">
          İhracat Niyeti Anketi
        </div>
        <br />
        <div id="page-content-container" className="space-y-4">
          {questions.map((question, index) => (
            <RadioSurvey
              key={question.id}
              question={question.question}
              value={question.userAnswer}
              id={question.id}
              index={index}
              defaultQuestion={question.isDefaultQuestion}
              subId={1}
              onChange={(value) => handleAnswerChange(question.id, value)}
            />
          ))}
        </div>

        <div className="mt-8">
          <Stepper
            nextChange={nextChange}
            backChange={backChange}
            activeStep={2}
            stepLimit={2}
            onPdfDownload={handlePdfDownload}
          />
        </div>
      </div>
    </LoadingFullPage>
  );
}
