import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const periodService = {
  async getPeriods() {
    try {
      const response = await axios.get(`${API_URL}/periods`, {
        headers,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async createPeriod(periodData) {
    try {
      const response = await axios.post(
        `${API_URL}/periods`,
        {
          startDate: periodData.startDate,
          endDate: periodData.endDate,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deletePeriod(periodId) {
    try {
      const response = await axios.delete(`${API_URL}/periods/${periodId}`, {
        headers,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
