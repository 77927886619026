import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { projectService } from "../../../services/projectService";
import LoadingFullPage from "../../../components/LoadingFullPage/LoadingFullPage";

export default function Project({
  changePage,
  chageProject,
  changeProjectName,
}) {
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewProjectName("");
  };

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const data = await projectService.getProjects();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleAddProject = async (projectData) => {
    setLoading(true);
    try {
      await projectService.addProject(projectData);
      fetchProjects(); // Refresh the list after adding
    } catch (error) {
      console.error("Error adding project:", error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProject = async (projectId, e) => {
    e.stopPropagation(); // Prevent triggering parent onClick
    setLoading(true);
    try {
      await projectService.deleteProject(projectId);
      fetchProjects(); // Refresh the list after deleting
    } catch (error) {
      console.error("Error deleting project:", error);
      // Handle error appropriately
    } finally {
      setLoading(false);
    }
  };

  const handleProjectClick = (projectId, projectName) => {
    const searchParams = new URLSearchParams(window.location.search);
    chageProject(projectId);
    changeProjectName(projectName);
    searchParams.set("projectId", projectId);
    searchParams.set("projectName", projectName); // Added project name to search params
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams}`
    );
    changePage(1);
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <LoadingFullPage state={loading}>
      <div className="min-h-screen bg-gray-50/50 font-['Outfit']">
        {/* Header Container */}
        <div className="bg-white border-b">
          <div className="max-w-7xl mx-auto p-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-4">
                <div className="p-3 bg-gradient-to-br from-[#439DC6] to-[#3B89B6] rounded-2xl shadow-lg shadow-[#439DC6]/20">
                  <svg
                    className="w-8 h-8 text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 5.5L5 3.5H19L20.5 5.5M3.5 5.5H20.5M3.5 5.5V19C3.5 19.5523 3.94772 20 4.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V5.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 10L11 13L16 8"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div>
                  <h1 className="text-3xl font-bold text-gray-800">Projeler</h1>
                  <p className="text-gray-500 text-sm">
                    Tüm projelerinizi buradan yönetin
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                {projects.length > 0 && (
                  <div className="relative">
                    <input
                      type="search"
                      placeholder="Proje ara..."
                      className="pl-11 pr-4 py-3 rounded-xl border border-gray-200 w-64 focus:outline-none focus:ring-2 focus:ring-[#439DC6]/20 transition-all"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <svg
                      className="absolute left-3.5 top-3.5 text-gray-400 w-5 h-5"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}

                <button
                  onClick={handleClickOpen}
                  className="bg-gradient-to-r from-[#EF8024] to-[#E67016] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 hover:shadow-lg hover:shadow-[#EF8024]/20 hover:scale-[1.02]"
                >
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12 7V17M7 12H17"
                      stroke="currentColor"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                    />
                  </svg>
                  Yeni Proje
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto p-8">
          {projects.length === 0 ? (
            <div className="bg-white rounded-2xl border border-gray-200 p-16 text-center">
              <div className="w-20 h-20 bg-[#439DC6]/10 rounded-2xl flex items-center justify-center mx-auto mb-6">
                <svg
                  className="w-10 h-10 text-[#439DC6]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3.5 5.5L5 3.5H19L20.5 5.5M3.5 5.5H20.5M3.5 5.5V19C3.5 19.5523 3.94772 20 4.5 20H19.5C20.0523 20 20.5 19.5523 20.5 19V5.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                Henüz Proje Bulunmuyor
              </h3>
              <p className="text-gray-500 mb-8 max-w-md mx-auto">
                Müşteri ve problem analizini yönetmek için yeni bir proje
                oluşturun.
              </p>
              <button
                onClick={handleClickOpen}
                className="bg-gradient-to-r from-[#439DC6] to-[#3B89B6] text-white px-6 py-3 rounded-xl font-medium transition-all duration-200 flex items-center gap-2 mx-auto hover:shadow-lg hover:shadow-[#439DC6]/20 hover:scale-[1.02]"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12 7V17M7 12H17"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
                Yeni Proje Oluştur
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredProjects.map((project) => (
                <div
                  key={project.id}
                  onClick={() => handleProjectClick(project.id, project.name)}
                  className="group bg-white rounded-2xl border border-gray-100 p-6 hover:shadow-xl transition-all duration-200 cursor-pointer relative hover:border-[#439DC6]/30"
                >
                  <div className="flex items-center gap-4">
                    <div className="p-3 bg-[#439DC6]/10 rounded-xl">
                      <svg
                        className="w-6 h-6 text-[#439DC6]"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M8 17h8M8 12h8M8 7h8"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <h3
                        className="font-medium text-lg text-gray-800 group-hover:text-[#439DC6] transition-colors truncate"
                        title={project.name}
                      >
                        {project.name.length > 15
                          ? project.name.substring(0, 15) + "..."
                          : project.name}
                      </h3>
                    </div>
                    <button
                      onClick={(e) => handleDeleteProject(project.id, e)}
                      className="opacity-0 group-hover:opacity-100 text-gray-400 hover:text-[#EF8024] hover:bg-[#EF8024]/10 p-2.5 rounded-xl transition-all duration-200"
                      title="Projeyi Sil"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H7V4Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M20 6H4V8H20V6Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                        <path
                          d="M6 8L7.47405 19.4233C7.64151 20.9187 8.89851 22 10.4066 22H13.5934C15.1015 22 16.3585 20.9187 16.526 19.4233L18 8"
                          stroke="currentColor"
                          strokeWidth="2"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Add Project Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            className: "rounded-2xl font-['Outfit']",
          }}
        >
          <DialogTitle className="text-xl font-semibold px-6 pt-6 pb-2">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-[#EF8024]/10 rounded-xl">
                <svg
                  className="w-6 h-6 text-[#EF8024]"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 7V17M7 12H17"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              Yeni Proje Oluştur
            </div>
          </DialogTitle>
          <DialogContent className="px-6 pt-4">
            <TextField
              autoFocus
              margin="dense"
              label="Proje Adı"
              type="text"
              fullWidth
              variant="outlined"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              className="mt-2"
            />
          </DialogContent>
          <DialogActions className="p-6">
            <button
              onClick={handleClose}
              className="px-5 py-2.5 rounded-xl text-gray-500 hover:bg-gray-50 transition-colors font-medium"
            >
              İptal
            </button>
            <button
              onClick={() => {
                handleAddProject(newProjectName);
                handleClose();
              }}
              className="px-5 py-2.5 rounded-xl bg-[#EF8024] text-white transition-all hover:shadow-lg hover:shadow-[#EF8024]/20 font-medium"
            >
              Oluştur
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </LoadingFullPage>
  );
}
