import React from "react";
import Tooltip from "@mui/material/Tooltip";

export default function InfoTooltip({
  children,
  title,
  placement = "right",
  maxWidth = 300,
}) {
  return (
    <Tooltip
      title={<div className="text-xs p-3 font-poppins">{title}</div>}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            color: "black",
            bgcolor: "#E2E8F0",
            border: "1px solid #E2E8F0",
            borderRadius: "8px",
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            maxWidth: maxWidth,
            "& .MuiTooltip-arrow": {
              color: "#E2E8F0",
              before: {
                border: "1px solid #E2E8F0",
              },
            },
            fontFamily: "'Poppins', sans-serif",
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          },
        },
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
}
