import React from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";

export default function InformationNext({ nextChange, backChange }) {
  return (
    <div className="w-full max-w-[1136px] mx-auto px-4">
      <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
        Bu araç ve platformları kullanarak beş alternatif hedef pazar belirleyip
        daha sonra sunacağımız "Yurtdışı Hedef Pazar Belirleme Ölçeği" ile bu
        alternatiflerden en iyilerini seçebilirsiniz.{" "}
      </div>
      <br />
      <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
        Belirleyeceğiniz pazar ve müşteri sayıları ürün grubu ve kapasitenize
        göre az veya çok olabilir. İhracat potansiyeliniz de bu sayıları
        belirleyebilir. Ayrıca bu ülkeler arasında öncelikli tercih yaparken pek
        çok kriter önem kazanabilir. Bu konuda farklı kurumların belirlemiş
        olduğu çeşitli endeksler altında ülkelerin iş yapma kolaylığı, inovasyon
        derecesi, rekabetçilik indeksi veya pazar çekicilikleri
        belirlenebilmektedir. Her bir kurumun farklı endeks ve hesaplamaları ile
        bu skorlar ortaya çıkabilmekte ve bu hesaplamalara bu kurumların ilgili
        web sitelerinden ulaşabilmektedir. Siz de sizler için önemli endeks ve
        parametreleri belirleyerek size en uygun ülke pazarlarını
        belirleyebilirsiniz. Bunun için kullanabileceğiniz bazı endeksler
        şunlardır:{" "}
      </div>

      <br />
      <div className="flex flex-col space-y-4">
        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            1
          </div>
          <div className="flex-1 ml-4">
            <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Ease of Doing Business Index - Dünya Bankası{" "}
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            2
          </div>
          <div className="flex-1 ml-4">
            <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Global Innovation Index - WIPO{" "}
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            3
          </div>
          <div className="flex-1 ml-4">
            <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Global Competitiveness Index - Dünya Ekonomik Formu
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            4
          </div>
          <div className="flex-1 ml-4">
            <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Market Potential Index - Michigan State University
            </div>
          </div>
        </div>

        <div className="flex items-start">
          <div className="w-6 h-6 flex-shrink-0 flex items-center justify-center font-bold font-['Outfit'] rounded-[10px] text-white bg-[#f08025] mt-1">
            5
          </div>
          <div className="flex-1 ml-4">
            <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
              Global Attractiveness Index - The European House - Ambrosetti{" "}
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
        Eğer raporları ve kurumların web sitelerini incelediyseniz sizin için en
        önemli iki endeksi belirlemenizi tavsiye ederiz. Şimdi sizler için uygun
        olduğunu düşündüğünüz iki endeks belirleyerek, diğer oluşturulmuş
        kriterler ve yine sizlerin belirleyeceği kriter ağırlıkları altında
        sizler için en uygun ülke pazarlarını belirleyebilirsiniz. Bunun için
        öncelikle Yurtdışı Hedef Pazar Belirleme Ölçeği'ne beş taneye kadar
        yukarıda sunduğumuz araç ve platformlara göre belirlediğiniz alternatif
        pazarları sütun kısmına ekleyebilirsiniz. Daha sonra en önemli
        gördüğünüz endekslerden iki tanesini belirleyerek satır kısmında ilgili
        alana ekleyiniz.{" "}
      </div>
      <br />
      <div className="text-[#797979] text-base sm:text-lg font-normal font-['Outfit']">
        Kriter ağırlıklarının toplamı 1 ve puanlamanızın 1-5 arasında olduğuna
        emin olarak puanlamanızı yapınız. En yüksek skora sahip pazar
        alternatifleri sizler için en iyi pazar alternatifleridir.{" "}
      </div>
      <div className="mt-8">
        <Stepper
          nextChange={nextChange}
          backChange={backChange}
          activeStep={3}
          stepLimit={4}
        />
      </div>
    </div>
  );
}
