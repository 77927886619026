import React, { useState, useEffect } from "react";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { toast } from "react-toastify";
import { internalEnvironmentAnalysisService } from "../../../../../services/internalEnvironmentAnalysisService";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function InternalEnvironmentAnalysisMatrix({
  subId,
  backPage,
  changePage,
  pageState,
  projectName,
}) {
  const [containerRows, setContainerRows] = useState({
    container1: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container2: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container3: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container4: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container5: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container6: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container7: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
    container8: {
      activities: ["", "", ""],
      status: ["", "", ""],
      thoughts: ["", "", ""],
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  const containers = [
    { id: "container1", title: "Pazarlama, Satış" },
    { id: "container2", title: "Servis" },
    { id: "container3", title: "Üretim" },
    { id: "container4", title: "Dışa Yönelik Lojistik " },
    { id: "container5", title: "İçe Yönelik Lojistik ve Tedarik " },
    { id: "container6", title: "Yönetim Alt Yapısı " },
    { id: "container7", title: "Teknoloji " },
    { id: "container8", title: "İnsan Kaynakları  " },
  ];

  const handleAddRow = (containerId) => {
    setContainerRows((prev) => ({
      ...prev,
      [containerId]: {
        activities: [...prev[containerId].activities, ""],
        status: [...prev[containerId].status, ""],
        thoughts: [...prev[containerId].thoughts, ""],
      },
    }));
  };

  const handleChange = (containerId, rowIndex, column, value) => {
    setContainerRows((prev) => ({
      ...prev,
      [containerId]: {
        ...prev[containerId],
        [column]: prev[containerId][column].map((item, idx) =>
          idx === rowIndex ? value : item
        ),
      },
    }));
  };

  const handleDeleteRow = (containerId, rowIndex) => {
    setContainerRows((prev) => ({
      ...prev,
      [containerId]: {
        activities: prev[containerId].activities.filter(
          (_, idx) => idx !== rowIndex
        ),
        status: prev[containerId].status.filter((_, idx) => idx !== rowIndex),
        thoughts: prev[containerId].thoughts.filter(
          (_, idx) => idx !== rowIndex
        ),
      },
    }));
  };

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!subId) return;

      setIsLoading(true);
      try {
        const response = await internalEnvironmentAnalysisService.getAnswers(
          subId
        );
        if (response && response.length > 0) {
          const newContainerRows = { ...containerRows };

          response.forEach((container) => {
            if (container.rows && container.rows.length > 0) {
              newContainerRows[`container${container.containerId}`] = {
                activities: container.rows.map((row) => row.answers[0].answer),
                status: container.rows.map((row) => row.answers[1].answer),
                thoughts: container.rows.map((row) => row.answers[2].answer),
              };
            }
          });

          setContainerRows(newContainerRows);
        }
      } catch (error) {
        toast.error("Veriler yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnswers();
  }, [subId]);

  const handleNext = async () => {
    setIsLoading(true);
    try {
      const formattedData = Object.entries(containerRows).map(
        ([key, container]) => {
          const containerId = parseInt(key.replace("container", ""));
          return {
            containerId,
            title: containers.find((c) => c.id === key)?.title || "",
            rows: container.activities.map((activity, index) => ({
              rowId: index + 1,
              answers: [
                {
                  labelId: 1,
                  label: "Sektörde Değer Yaratan Faaliyetler",
                  answer: activity,
                },
                {
                  labelId: 2,
                  label: "Rakiplere Göre Durumunuz Güçlü? Zayıf?",
                  answer: container.status[index],
                },
                {
                  labelId: 3,
                  label: "Düşünceler",
                  answer: container.thoughts[index],
                },
              ],
            })),
          };
        }
      );

      await internalEnvironmentAnalysisService.saveAnswers(
        subId,
        formattedData
      );
      changePage();
    } catch (error) {
      toast.error("Kaydetme sırasında bir hata oluştu");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("page-content-container");

    // Store original styles
    const originalStyle = container.style.cssText;
    const textareas = container.getElementsByTagName("textarea");
    const originalElements = [];

    try {
      // Set temporary styles for PDF
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Convert textareas to divs for PDF
      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder;
        div.style.cssText = `
          width: 100%;
          min-height: 64px; /* Adjusted to show two lines */
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      await downloadPageAsPDF(
        "page-content-container",
        "İç Çevre Analizi",
        projectName
      );
    } catch (error) {
      console.error("PDF oluşturulurken bir hata oluştu:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      container.style.cssText = originalStyle;

      // Orijinal textarea'ları geri yükleyelim
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage isLoading={isPdfLoading}>
      <div>
        <div>
          <div className="w-[1136px] text-black text-lg font-semibold font-['Outfit']">
            Haydi gelin şimdi Paydaş Matrisini doldurmaya!
          </div>

          <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
            Paydaş,Matrisi ve Stratejik Planda yer alan diğer modüller için
            kritik departmanlarınızın temsilcileri ve yöneticilerinden oluşan
            5-7 kişilik bir stratejik plan ekibi oluşturmanızı ve düzenli
            aralıklarla ekiple beyin fırtınası yaparak matrise son halini
            vermenizi tavsiye ederiz.
          </div>

          <div className="w-[1136px] text-[#797979] text-lg font-normal font-['Outfit']">
            Unutmayın özellikle paydaş matrisinde yer alan buradan sonraki
            doldurulacak sütunlar paydaşlarla iletişim ve veri toplama sürecini
            ifade etmektedir. Buradan alınan görüşler size yeni inovasyon ve
            fırsat alanları açacaktır.
          </div>
          <br />
          <div id="page-content-container">
            <div className="w-full h-[130px] flex flex-col">
              <div className="w-full h-[50px] bg-white rounded-t-[10px] border border-[#e0e0e0] flex items-center justify-center">
                <div className="text-[#242731] text-lg font-semibold font-['Outfit']">
                  Varlık ve Yetenek Analizi
                </div>
              </div>

              <div className="w-full h-[81px] bg-[#f6f6f6]/50 border border-[#e0e0e0] rounded-b-[10px] grid grid-cols-[1fr_2fr_1fr_2fr]">
                <div className="flex items-center px-6 border-r border-[#e0e0e0]">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit']">
                    İÇ ÇEVRE ANALİZİ
                  </div>
                </div>

                <div className="flex items-center justify-center border-r border-[#e0e0e0] p-2">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit'] text-center">
                    Sektörde Değer Yaratan
                    <br />
                    Faaliyetler
                  </div>
                </div>

                <div className="flex items-center justify-center border-r border-[#e0e0e0] p-2">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit'] text-center">
                    Rakiplere Göre
                    <br />
                    Durumunuz
                    <br />
                    Güçlü? Zayıf?
                  </div>
                </div>

                <div className="flex items-center justify-center p-2">
                  <div className="text-[#242731] text-sm font-semibold font-['Outfit'] text-center">
                    Düşünceler
                  </div>
                </div>
              </div>
            </div>
            {containers.map((container) => (
              <div key={container.id} id={container.id} className="group">
                <div className="w-full h-auto rounded-[10px] border border-[#e0e0e0] grid grid-cols-[1fr_2fr_1fr_2fr] mt-10">
                  <div className="flex-1 border-r border-[#e0e0e0] text-center flex items-center justify-center">
                    <div className="text-[#439dc6] text-[13px] font-semibold font-['Outfit']">
                      {container.title}
                    </div>
                  </div>
                  <div className="flex-1 border-r border-[#e0e0e0] text-center flex flex-col">
                    {containerRows[container.id].activities.map(
                      (activity, index) => (
                        <div
                          key={index}
                          className={`flex-1 min-h-[38px] ${
                            index !==
                            containerRows[container.id].activities.length - 1
                              ? "border-b"
                              : ""
                          } border-[#e0e0e0] ${
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <div className="flex-1 min-h-[38px] relative group">
                            <textarea
                              value={
                                containerRows[container.id].activities[index]
                              }
                              onChange={(e) =>
                                handleChange(
                                  container.id,
                                  index,
                                  "activities",
                                  e.target.value
                                )
                              }
                              className="w-full h-full resize-none border-none bg-transparent focus:outline-none px-2 py-2 text-xs leading-4 text-center"
                              placeholder="Buraya yazın..."
                              maxLength={300}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex-2 border-r border-[#e0e0e0] text-center flex flex-col">
                    {containerRows[container.id].status.map((status, index) => (
                      <div
                        key={index}
                        className={`flex-1 min-h-[38px] ${
                          index !==
                          containerRows[container.id].status.length - 1
                            ? "border-b"
                            : ""
                        } border-[#e0e0e0] ${
                          index % 2 === 0 ? "bg-white" : "bg-gray-50"
                        }`}
                      >
                        <div className="flex-1 min-h-[38px]">
                          <textarea
                            value={containerRows[container.id].status[index]}
                            onChange={(e) =>
                              handleChange(
                                container.id,
                                index,
                                "status",
                                e.target.value
                              )
                            }
                            className="w-full h-full resize-none border-none bg-transparent focus:outline-none px-2 py-2 text-xs leading-4 text-center"
                            placeholder="Buraya yazın..."
                            maxLength={300}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex-1 text-center flex flex-col">
                    {containerRows[container.id].thoughts.map(
                      (thought, index) => (
                        <div
                          key={index}
                          className={`flex-1 min-h-[38px] ${
                            index !==
                            containerRows[container.id].thoughts.length - 1
                              ? "border-b"
                              : ""
                          } border-[#e0e0e0] ${
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <div className="flex-1 min-h-[38px]">
                            <div className="flex items-center w-full">
                              <textarea
                                value={
                                  containerRows[container.id].thoughts[index]
                                }
                                onChange={(e) =>
                                  handleChange(
                                    container.id,
                                    index,
                                    "thoughts",
                                    e.target.value
                                  )
                                }
                                className="w-full h-full resize-none border-none bg-transparent focus:outline-none px-2 py-2 text-xs leading-4 text-center"
                                placeholder="Buraya yazın..."
                                maxLength={300}
                              />
                              <button
                                onClick={() =>
                                  handleDeleteRow(container.id, index)
                                }
                                className="ml-2 p-1 text-gray-400 hover:text-red-500 transition-colors duration-200"
                                title="Satırı Sil"
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M3 6h18" />
                                  <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6" />
                                  <path d="M8 6V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    className="bg-blue-500 text-white rounded px-4 py-2"
                    onClick={() => handleAddRow(container.id)}
                  >
                    Satır Ekle
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full flex items-center ">
            <div className="w-[600px] flex-1">
              <div className="w-full pr-10 ">
                <Stepper
                  nextChange={handleNext}
                  backChange={backPage}
                  activeStep={2}
                  stepLimit={2}
                  onPdfDownload={handlePdfDownload}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingFullPage>
  );
}
