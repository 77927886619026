import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { interviewTemplateService } from "../../../../../services/interviewTemplateService";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import AddWhite from "../../../../../icons/add-white.svg";
import Close from "../../../../../icons/close.svg";
import UserIcon from "../../../../../icons/hc-user.svg";
import InfoIcon from "../../../../../icons/info.svg";
import AddAnswerModal from "../../../../../components/Modal/AddAnswerModal";
import DeleteModal from "../../../../../components/Modals/DeleteModal";
import { handleApiError } from "../../../../../utils/errorHandler";

import StartArrowIcon from "../../../../../icons/star-arrow.svg";
import PencilNoteIcon from "../../../../../icons/pencil-note.svg";
import SpeQuestionsIcon from "../../../../../icons/spe-questions.svg";
import MetaLevelIcon from "../../../../../icons/meta-level.svg";
import WishQuestionsIcon from "../../../../../icons/wish-questions.svg";
import NoteIcon from "../../../../../icons/note.svg";
import InfoTooltip from "../../../../../components/Tooltip/InfoTooltip";
import tooltipTexts from "../../../../../constants/tooltipTexts";

export default function InterviewTemplate({
  changePage,
  backPage,
  projectId,
  projectName,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [answers, setAnswers] = useState({
    containers: [
      {
        id: 1,
        name: "Genel Sorular",
        answers: [],
        icon: StartArrowIcon,
        tooltipKey: "generalQuestions",
      },
      {
        id: 2,
        name: "Deneyim Soruları",
        answers: [],
        icon: PencilNoteIcon,
        tooltipKey: "experienceQuestions",
      },
      {
        id: 3,
        name: "Spesifik Sorular",
        answers: [],
        icon: SpeQuestionsIcon,
        tooltipKey: "specificQuestions",
      },
      {
        id: 4,
        name: "Meta-Seviyesi",
        answers: [],
        icon: MetaLevelIcon,
        tooltipKey: "metaLevelQuestions",
      },
      {
        id: 5,
        name: "Temenni Soruları",
        answers: [],
        icon: WishQuestionsIcon,
        tooltipKey: "wishQuestions",
      },
      {
        id: 6,
        name: "Not",
        answers: [],
        icon: NoteIcon,
        tooltipKey: "note",
      },
    ],
  });
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [newAnswer, setNewAnswer] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);

  useEffect(() => {
    const fetchAnswers = async () => {
      setIsLoading(true);
      try {
        const data = await interviewTemplateService.getAnswers(projectId);

        // Ensure data is valid before setting answers
        if (data && data.length) {
          // Retain icons from the initial state
          const updatedContainers = answers.containers.map((container) => {
            const foundContainer = data.find(
              (item) => item.id === container.id
            );
            return foundContainer
              ? {
                  ...foundContainer,
                  icon: container.icon,
                  tooltipKey: container.tooltipKey,
                }
              : container;
          });
          setAnswers({ containers: updatedContainers });
        } else {
          handleApiError(null, "Cevaplar yüklenirken bir hata oluştu");
        }
      } catch (error) {
        handleApiError(error, "Cevaplar yüklenirken bir hata oluştu");
      } finally {
        setIsLoading(false);
      }
    };

    if (projectId) {
      fetchAnswers();
    }
  }, [projectId]);

  const handleNext = async () => {
    //TODO Check if all containers are empty

    changePage();
  };

  const handleAddQuestion = (containerName, question) => {
    setAnswers((prev) => ({
      ...prev,
      containers: prev.containers.map((container) =>
        container.name === containerName
          ? { ...container, answers: [...container.answers, question] }
          : container
      ),
    }));
  };

  const handleRemoveQuestion = async (containerId, answerIndex) => {
    setDeleteInfo({ containerId, answerIndex });
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await interviewTemplateService.deleteAnswer(
        projectId,
        deleteInfo.containerId,
        deleteInfo.answerIndex
      );
      setAnswers((prev) => ({
        ...prev,
        containers: prev.containers.map((container) =>
          container.id === deleteInfo.containerId
            ? {
                ...container,
                answers: container.answers.filter(
                  (_, i) => i !== deleteInfo.answerIndex
                ),
              }
            : container
        ),
      }));
      setShowDeleteModal(false);
    } catch (error) {
      handleApiError(error, "Cevap silinirken bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      const container = document.getElementById("interview-template-content");
      const originalStyle = container.style.cssText;

      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
        overflow: hidden !important; /* Prevent overflow issues in PDF */
      `;

      try {
        await downloadPageAsPDF(
          "interview-template-content",
          "Mülakat Şablonu",
          projectName
        );
      } finally {
        container.style.cssText = originalStyle;
      }
    } catch (error) {
      handleApiError(error, "PDF oluşturulurken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  const handleAddClick = (container) => {
    console.log("container: ", container);
    setSelectedContainer(container);
    setShowAddModal(true);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setSelectedContainer(null);
    setNewAnswer("");
  };

  const handleAddAnswer = async () => {
    if (!newAnswer.trim()) {
      handleApiError(null, "Lütfen bir cevap giriniz");
      return;
    }

    console.log("selectedContainer.id:", selectedContainer.id);

    try {
      await interviewTemplateService.addAnswer(
        projectId,
        selectedContainer.id,
        newAnswer.trim()
      );

      setAnswers((prev) => ({
        ...prev,
        containers: prev.containers.map((container) =>
          container.id === selectedContainer.id
            ? {
                ...container,
                answers: [...container.answers, newAnswer.trim()],
              }
            : container
        ),
      }));
      handleCloseModal();
    } catch (error) {
      handleApiError(error, "Cevap eklenirken bir hata oluştu");
    }
  };

  return (
    <LoadingFullPage state={isLoading || isPdfLoading}>
      <div className="w-full flex items-center flex-col">
        <div
          id="interview-template-content"
          className="w-full flex-1 items-center flex flex-col justify-center"
        >
          <div className="text-black text-lg font-bold font-['Outfit']">
            Mülakat Şablonu
          </div>

          <div className="grid p-4 grid-cols-2 gap-4 w-full">
            {answers.containers &&
              answers.containers.map((container, index) => (
                <div
                  key={index}
                  className="rounded-[20px] w-full bg-white min-h-[400px] border-2"
                >
                  <div
                    className={`w-full h-[81px] ${getBackgroundColor(
                      container.name
                    )} rounded-[20px] relative`}
                  >
                    <InfoTooltip
                      title={
                        tooltipTexts.interviewTemplate[container.tooltipKey]
                      }
                    >
                      <div className="absolute right-[70px] top-7">
                        <img src={InfoIcon} />
                      </div>
                    </InfoTooltip>
                    <img
                      className="absolute right-7 top-6 cursor-pointer"
                      src={AddWhite}
                      onClick={() => handleAddClick(container)}
                    />
                    <div className="w-64 h-[82px] left-0 top-0 absolute rounded-tl-2xl rounded-bl-2xl items-center justify-center flex">
                      <img src={container.icon} />{" "}
                      {/* Use the fixed icon from the container */}
                      <div className="w-40 ml-2">
                        <span className="text-white text-lg font-semibold font-['Outfit']">
                          {container.name}
                          <br />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-2 flex flex-col  justify-center">
                    {container.answers && container.answers.length > 0 ? (
                      container.answers.map((answer, answerIndex) => (
                        <div
                          key={answerIndex}
                          className={`rounded-lg p-2  mt-2 flex items-center  justify-start ${getBackgroundColorLight(
                            container.name
                          )}`}
                        >
                          <img
                            src={Close}
                            className="w-[18px] hover:cursor-pointer hover:opacity-50 h-[18px]"
                            onClick={() =>
                              handleRemoveQuestion(container.id, answerIndex)
                            }
                          />
                          <div>
                            <div className="mt-0 ml-1 text-[#797979] text-xs font-normal  font-['Outfit']  ">
                              {answer && typeof answer === "object"
                                ? answer.content
                                : answer}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full flex items-center ">
          <div className="w-[600px] flex-1">
            <div className="w-full pr-10 ">
              <Stepper
                nextChange={handleNext}
                backChange={backPage}
                activeStep={6}
                stepLimit={9}
                onPdfDownload={handlePdfDownload}
              />
            </div>
          </div>
        </div>
      </div>
      <AddAnswerModal
        isOpen={showAddModal}
        onClose={handleCloseModal}
        onAdd={handleAddAnswer}
        value={newAnswer}
        onChange={setNewAnswer}
        title={`${
          (selectedContainer && selectedContainer.name) || ""
        } - Yeni Soru Ekle`}
        placeholder="Mülakat sorunuzu yazın..."
      />
      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleConfirmDelete}
        title="Cevap Silme"
        message="Bu cevabı silmek istediğinizden emin misiniz?"
      />
    </LoadingFullPage>
  );
}

const getBackgroundColor = (containerName) => {
  const colors = {
    "Genel Sorular": "bg-[#169888]",
    "Deneyim Soruları": "bg-[#A85F68]",
    "Spesifik Sorular": "bg-[#A95F97]",
    "Meta-Seviyesi": "bg-[#7EA760]",
    "Temenni Soruları": "bg-[#5F6FA8]",
    Not: "bg-[#5F6FA8]", // Added Not color
  };
  return colors[containerName] || "bg-[#5F6FA8]";
};

const getBackgroundColorLight = (containerName) => {
  const colors = {
    "Genel Sorular": "bg-[#16988833]",
    "Deneyim Soruları": "bg-[#A85F6833]",
    "Spesifik Sorular": "bg-[#A95F9733]",
    "Meta-Seviyesi": "bg-[#7EA76033]",
    "Temenni Soruları": "bg-[#5F6FA833]",
    Not: "bg-[#5F6FA833]", // Added Not color
  };
  return colors[containerName] || "bg-[#5F6FA833]";
};
