import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import { passwordResetService } from "../../../services/passwordResetService";

export default function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async () => {
    try {
      setIsLoading(true);
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        toast.error("Kullanıcı e-posta adresi bulunamadı");
        return;
      }

      await passwordResetService.requestPasswordReset(userEmail);
      toast.success("Şifre sıfırlama bağlantısı e-posta adresinize gönderildi");
    } catch (error) {
      toast.error("Şifre sıfırlama isteği gönderilemedi");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full items-start flex flex-col justify-start">
      <div className="max-w-[800px] w-full p-6 bg-white rounded-xl shadow-sm">
        <h2 className="text-xl font-semibold text-[#439DC6] mb-6 flex items-center gap-2">
          <div className="p-1.5 bg-[#439DC6]/10 rounded-lg">
            <svg
              className="w-5 h-5 text-[#439DC6]"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15V17M6 9V17H18V9C18 7.89543 17.1046 7 16 7H8C6.89543 7 6 7.89543 6 9Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 7V5C15 3.89543 14.1046 3 13 3H11C9.89543 3 9 3.89543 9 5V7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          Şifre Güncelleme
        </h2>

        <Button
          text="Şifremi Sıfırla"
          onClick={handleResetPassword}
          disabled={isLoading}
          className="text-[#439DC6] border border-[#439DC6] hover:bg-[#439DC6] hover:text-white"
        />
      </div>
    </div>
  );
}
