import React from "react";

import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Lock from "../../icons/lock.svg";
import { useSelector } from "react-redux";

export default function Home() {
  const navigate = useNavigate();
  const menuItem = useSelector((state) => state.menu.menuItem);
  function MainButtons({ item }) {
    return (
      <div
        onClick={() =>
          navigate(
            item.subId
              ? item.navigation + "?subId=" + item.subId
              : item.navigation
          )
        }
        className="hover:border-[#439DC6] hover:bg-[#d8eaf4] cursor-pointer 
          hover:text-[#439DC6] min-h-[58px] bg-white rounded-[10px] border-2 
          border-neutral-200 flex px-2 text-neutral-400 w-full -z-10 "
      >
        <div
          className="text-center text-[11px] sm:text-xs lg:text-sm font-semibold 
          font-['Outfit'] flex items-center justify-center w-full py-2"
        >
          <img
            className="h-5 sm:h-6 m-2 flex-shrink-0"
            src={item.srcHome}
            alt=""
          />
          <div className="flex-1 flex items-center justify-center break-words">
            {item.title}
          </div>
        </div>
      </div>
    );
  }

  function MainCards({ menuItem }) {
    return (
      <div className="h-full bg-white rounded-[10px] border border-neutral-200">
        <div className="flex p-4 sm:p-6 relative">
          <div className="flex flex-1 items-center justify-start relative">
            <div
              className="h-10 sm:h-12 lg:h-14 absolute -right-2 -top-2 rounded-full 
              border-2 border-t-[#439DC6]"
            >
              <img
                className="h-5 sm:h-6 m-2 sm:m-3 lg:m-4"
                src={menuItem.srcHome}
                alt=""
              />
            </div>
            <div
              className="text-[#439DC6] text-base sm:text-lg lg:text-xl font-medium 
              font-['Outfit'] underline underline-offset-4 pr-12"
            >
              {menuItem.title}
            </div>
          </div>
        </div>

        <div
          className="w-full text-neutral-400 text-xs sm:text-sm font-normal 
          font-['Outfit'] px-4 sm:px-6 pb-4"
        >
          {menuItem.desc}
        </div>

        <div className="w-full h-[0px] border border-neutral-200"></div>

        <div className="relative">
          {!menuItem.lock && (
            <img
              onClick={() =>
                window.open(
                  "https://kurumsalgirisimpusulasi.com/satin-al/",
                  "_blank"
                )
              }
              className="h-10 sm:h-12 lg:h-16 hover:opacity-75 absolute 
                cursor-pointer right-0 left-[42%] top-8 sm:top-10 lg:top-14 
                bottom-0 items-center justify-center flex"
              src={Lock}
              alt=""
            />
          )}
          <div
            className={`w-full text-neutral-400 text-sm font-normal 
              font-['Outfit'] p-4 sm:p-6 ${
                !menuItem.lock && "blur-sm pointer-events-none"
              }`}
          >
            <Grid container spacing={2}>
              {menuItem?.subMenu?.map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  <MainButtons item={item} />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#F6F7FA] min-h-screen">
      <Header />
      <div className="flex items-center justify-center p-4 sm:p-6 lg:p-8">
        <div className="w-full max-w-[1300px]">
          <Grid container spacing={2}>
            {JSON.parse(JSON.stringify(menuItem)).map(
              (item) =>
                item.id !== 0 && (
                  <Grid item xs={12} sm={6} lg={4} key={item.id}>
                    <MainCards menuItem={item} />
                  </Grid>
                )
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}
