import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { strategyMapService } from "../../../../../services/strategyMapService";
import { toast } from "react-toastify";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";

export default function StrategyMap({
  changePage,
  projectId,
  changeTargetId,
  changeTargetName,
  projectName,
}) {
  const navigate = useNavigate();
  const [selectedBSC, setSelectedBSC] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("");
  const [targets, setTargets] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newTargetText, setNewTargetText] = useState("");
  const [processLabels, setProcessLabels] = useState([]);
  const [strategicGoals, setStrategicGoals] = useState([]);
  const [selectedGoalId, setSelectedGoalId] = useState(null);
  const [goalsAndConsequences, setGoalsAndConsequences] = useState([]);
  const [showKeyResultModal, setShowKeyResultModal] = useState(false);
  const [newKeyResultText, setNewKeyResultText] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(false);

  useEffect(() => {
    const fetchProcessLabels = async () => {
      try {
        const response = await strategyMapService.getProcessLabels();
        setProcessLabels(response);
      } catch (error) {
        toast.error("Süreç etiketleri yüklenirken bir hata oluştu");
      }
    };

    fetchProcessLabels();
  }, []);

  useEffect(() => {
    const fetchStrategicGoals = async () => {
      if (!selectedBSC) {
        setStrategicGoals([]);
        setSelectedStrategy("");
        return;
      }

      try {
        const response = await strategyMapService.getStrategicGoals(
          projectId,
          selectedBSC
        );
        setStrategicGoals(response);
        setSelectedStrategy("");
      } catch (error) {
        toast.error("Stratejik amaçlar yüklenirken bir hata oluştu");
        setStrategicGoals([]);
      }
    };

    fetchStrategicGoals();
  }, [selectedBSC, projectId]);

  useEffect(() => {
    const fetchGoalsAndConsequences = async () => {
      if (!selectedStrategy) {
        setTargets([]);
        return;
      }

      try {
        const response = await strategyMapService.getGoalsAndConsequences(
          projectId,
          selectedStrategy
        );

        // Transform the response to match our targets structure
        const formattedTargets = response.map((goal) => ({
          id: goal.id,
          title: goal.name,
          keyResults: goal.fundamentalConsequences.map((consequence) => ({
            id: consequence.id,
            text: consequence.name,
          })),
        }));

        setTargets(formattedTargets);
      } catch (error) {
        toast.error("Hedefler ve sonuçlar yüklenirken bir hata oluştu");
        setTargets([]);
      }
    };

    fetchGoalsAndConsequences();
  }, [selectedStrategy, projectId]);

  const handleAddTarget = async () => {
    if (targets.length >= 3) {
      toast.warning("En fazla 3 hedef ekleyebilirsiniz.");
      return;
    }
    setShowModal(true);
  };

  const handleSaveTarget = async () => {
    if (!newTargetText.trim()) {
      toast.warning("Lütfen hedef metnini giriniz");
      return;
    }

    try {
      const response = await strategyMapService.addGoal(
        projectId,
        selectedStrategy,
        newTargetText
      );
      setTargets([
        ...targets,
        { id: response.id, title: newTargetText, keyResults: [] },
      ]);
      setNewTargetText("");
      setShowModal(false);
      toast.success("Hedef başarıyla eklendi");
    } catch (error) {
      toast.error("Hedef eklenirken bir hata oluştu");
    }
  };

  const handleDeleteTarget = async (targetId) => {
    try {
      await strategyMapService.deleteGoal(projectId, targetId);
      setTargets(targets.filter((target) => target.id !== targetId));
      toast.success("Hedef başarıyla silindi");
    } catch (error) {
      toast.error("Hedef silinirken bir hata oluştu");
    }
  };

  const handleAddKeyResult = async (targetId) => {
    const target = targets.find((t) => t.id === targetId);
    if (target && target.keyResults.length >= 5) {
      toast.warning("Bir hedef için en fazla 5 temel sonuç ekleyebilirsiniz.");
      return;
    }

    setSelectedGoalId(targetId);
    setShowKeyResultModal(true);
  };

  const handleSaveKeyResult = async () => {
    if (!newKeyResultText.trim()) {
      toast.warning("Lütfen temel sonuç metnini giriniz");
      return;
    }

    try {
      const response = await strategyMapService.addFundamentalConsequence(
        projectId,
        selectedGoalId,
        newKeyResultText
      );

      const updatedTargets = targets.map((target) => {
        if (target.id === selectedGoalId) {
          return {
            ...target,
            keyResults: [
              ...target.keyResults,
              { id: response.id, text: newKeyResultText },
            ],
          };
        }
        return target;
      });

      setTargets(updatedTargets);
      setNewKeyResultText("");
      setShowKeyResultModal(false);
      setSelectedGoalId(null);
      toast.success("Temel sonuç başarıyla eklendi");
    } catch (error) {
      toast.error("Temel sonuç eklenirken bir hata oluştu");
    }
  };

  const handleDeleteKeyResult = async (targetId, keyResultId) => {
    try {
      await strategyMapService.deleteFundamentalConsequence(
        projectId,
        keyResultId
      );

      const updatedTargets = targets.map((target) => {
        if (target.id === targetId) {
          return {
            ...target,
            keyResults: target.keyResults.filter((kr) => kr.id !== keyResultId),
          };
        }
        return target;
      });

      setTargets(updatedTargets);
      toast.success("Temel sonuç başarıyla silindi");
    } catch (error) {
      toast.error("Temel sonuç silinirken bir hata oluştu");
    }
  };

  const handleTargetClick = (targetId) => {
    changeTargetName(targetId.title);
    changeTargetId(targetId.id);
    changePage();
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    try {
      const container = document.getElementById("strategy-map-container");
      if (!container) {
        throw new Error("Container not found");
      }

      // Store original styles
      const originalStyle = container.style.cssText;

      // Set temporary container styles for PDF
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Handle textareas and selects
      const textareas = container.getElementsByTagName("textarea");
      const selects = container.getElementsByTagName("select");
      const originalElements = [];

      // Handle textareas
      Array.from(textareas).forEach((textarea) => {
        const div = document.createElement("div");
        div.textContent = textarea.value || textarea.placeholder || "";
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: textarea,
          parent: textarea.parentNode,
        });

        textarea.parentNode.replaceChild(div, textarea);
      });

      // Handle selects
      Array.from(selects).forEach((select) => {
        const div = document.createElement("div");
        const selectedOption = select.options[select.selectedIndex];
        div.textContent = selectedOption ? selectedOption.text : "";
        div.style.cssText = `
          width: 100%;
          min-height: 32px;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: select,
          parent: select.parentNode,
        });

        select.parentNode.replaceChild(div, select);
      });

      try {
        await downloadPageAsPDF(
          "strategy-map-container",
          "Strateji Haritası",
          projectName || "Project"
        );
      } finally {
        // Restore original state
        container.style.cssText = originalStyle;

        // Restore original elements
        originalElements.forEach(({ element, parent }) => {
          const currentElement = parent.firstChild;
          parent.replaceChild(element, currentElement);
        });
      }
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      setIsPdfLoading(false);
    }
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div id="strategy-map-container" className="max-w-7xl mx-auto p-4">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold">
            Strateji Haritası ve OKR'lar
          </h1>
          <button
            onClick={handlePdfDownload}
            className="px-4 py-2 bg-[#A06B78] text-white rounded-lg hover:bg-[#8d5d68] transition-colors"
          >
            PDF İndir
          </button>
        </div>

        <div className="border rounded-lg p-6">
          <div className="grid grid-cols-2 gap-6">
            {/* BSC Dimension */}
            <div>
              <div className="bg-[#A06B78] p-4 rounded-t-lg">
                <h2 className="text-white text-lg font-semibold">BSC Boyutu</h2>
              </div>
              <div className="bg-white border-x border-b rounded-b-lg p-4">
                <select
                  className="w-full p-2 rounded-lg border"
                  value={selectedBSC}
                  onChange={(e) => setSelectedBSC(e.target.value)}
                >
                  <option value="">Lütfen seçim yapınız</option>
                  {processLabels.map((label) => (
                    <option key={label.id} value={label.id}>
                      {label.label}
                    </option>
                  ))}
                </select>

                {selectedBSC && (
                  <div className="mt-4 bg-[#9B6B96] p-4 rounded-lg">
                    <span className="text-white">
                      {
                        processLabels.find(
                          (label) => label.id === parseInt(selectedBSC)
                        )?.label
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>

            {/* Strategic Goal */}
            <div>
              <div className="bg-[#A06B78] p-4 rounded-t-lg">
                <h2 className="text-white text-lg font-semibold">
                  Stratejik Amaç
                </h2>
              </div>
              <div className="bg-white border-x border-b rounded-b-lg p-4">
                <select
                  className="w-full p-2 rounded-lg border"
                  value={selectedStrategy}
                  onChange={(e) => setSelectedStrategy(e.target.value)}
                  disabled={!selectedBSC}
                >
                  <option value="">Lütfen seçim yapınız</option>
                  {strategicGoals.map((goal) => (
                    <option key={goal.containerId} value={goal.containerId}>
                      {goal.answer}
                    </option>
                  ))}
                </select>

                {selectedStrategy && (
                  <div className="mt-4 bg-[#9B6B96] p-4 rounded-lg">
                    <span className="text-white">
                      {
                        strategicGoals.find(
                          (goal) =>
                            goal.containerId === parseInt(selectedStrategy)
                        )?.answer
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Hedefler Section */}
          {selectedStrategy && (
            <div className="mt-8">
              <div className="text-black text-xl sm:text-xl font-normal font-['Outfit'] text-center mb-8">
                Sonraki adıma geçmek için ilgili hedef başlığına
                tıklayabilirsiniz
              </div>
              <div className="bg-[#A06B78] p-4 rounded-lg flex justify-between items-center">
                <h2 className="text-white text-lg font-semibold">Hedefler</h2>
                <button
                  onClick={handleAddTarget}
                  className="bg-white text-[#A06B78] px-3 py-1 rounded-lg flex items-center"
                  disabled={targets.length >= 3}
                >
                  <span className="mr-1">+</span>
                </button>
              </div>

              <div className="mt-4">
                <div className="grid grid-cols-3 gap-6">
                  {targets.map((target) => (
                    <div key={target.id} className="flex flex-col">
                      <div
                        className="bg-[#9B6B96] p-4 rounded-lg text-white text-center mb-4 relative cursor-pointer hover:bg-[#8a5d85] transition-colors"
                        onClick={() => handleTargetClick(target)}
                      >
                        {target.title}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTarget(target.id);
                          }}
                          className="absolute right-2 top-2 text-white hover:text-red-300"
                        >
                          ×
                        </button>
                      </div>

                      <button
                        onClick={() => handleAddKeyResult(target.id)}
                        className="bg-white border-2 border-[#9B6B96] text-[#9B6B96] px-4 py-2 rounded-lg w-full text-center mb-4 flex items-center justify-center gap-2"
                        disabled={target.keyResults.length >= 5}
                      >
                        <span>+</span>
                        <span>Temel Sonuç Ekle</span>
                      </button>

                      {target.keyResults.map((keyResult) => (
                        <div
                          key={keyResult.id}
                          className="bg-[#3E8C83] p-4 rounded-lg mb-3 text-white text-center relative"
                        >
                          {keyResult.text}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteKeyResult(target.id, keyResult.id);
                            }}
                            className="absolute right-2 top-2 text-white hover:text-red-300"
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Add Target Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-[400px]">
              <h3 className="text-lg font-semibold mb-4">Yeni Hedef Ekle</h3>
              <textarea
                placeholder="Hedef için buraya metin eklenecek"
                className="w-full p-2 border rounded-lg mb-4 min-h-[100px] resize-none"
                value={newTargetText}
                onChange={(e) => setNewTargetText(e.target.value)}
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  İptal
                </button>
                <button
                  onClick={handleSaveTarget}
                  className="px-4 py-2 bg-[#A06B78] text-white rounded-lg hover:bg-[#8d5d68]"
                >
                  Ekle
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Key Result Modal */}
        {showKeyResultModal && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-[400px]">
              <h3 className="text-lg font-semibold mb-4">
                Yeni Temel Sonuç Ekle
              </h3>
              <textarea
                placeholder="Temel sonuç için buraya metin eklenecek"
                className="w-full p-2 border rounded-lg mb-4 min-h-[100px] resize-none"
                value={newKeyResultText}
                onChange={(e) => setNewKeyResultText(e.target.value)}
                maxLength={400}
              />
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => {
                    setShowKeyResultModal(false);
                    setNewKeyResultText("");
                    setSelectedGoalId(null);
                  }}
                  className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                >
                  İptal
                </button>
                <button
                  onClick={handleSaveKeyResult}
                  className="px-4 py-2 bg-[#A06B78] text-white rounded-lg hover:bg-[#8d5d68]"
                >
                  Ekle
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </LoadingFullPage>
  );
}
