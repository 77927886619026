import React from "react";
import Pencil from "../../../../../icons/pencil.svg";
import Stepper from "../../../../../components/Stepper/MainStepper";
import Threatening from "../../../../../icons/threatening.svg";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";

export default function Information({ changePage, backPage }) {
  return (
    <div>
      <div id="page-content-container" className="max-w-[1136px] mx-auto">
        <div className="text-black text-lg font-semibold font-['Outfit']">
          Değerli Firma Yetkilimiz,
        </div>
        <br />
        <div className="text-neutral-500 text-lg font-normal font-['Outfit']">
          İnovasyon fırsatlarını keşfetmek için başvurmanız gereken en önemli
          kaynaklardan biri kullanıcılarınız ve müşterilerinizdir.
          <br />
          İnovasyonlar genellikle onlar için yapılır ve paydaşlarınız içerisinde
          ayrı bir öneme sahiptirler. Onların gerçekte neye ihtiyacı olduğunu
          tespit edebilmek oldukça önemlidir. Bu yüzden kullanıcı ve
          müşterilerinizin gerçek problemini, ihtiyaçlarını analiz etmeniz
          gerekir. Sizlere sunduğumuz tasarım odaklı düşünme şablonları ve
          yönlendirici içeriklerimiz ile bu modüllerde müşterilerinizi ve
          onların problemini doğru analiz etmenizi sağlıyor, bu konuda yenilikçi
          fikirler üretmenizi ve bu fikirleri doğru değerlendirmenize destek
          sunuyoruz. Tasarım Odaklı Düşünme ve şablonlarını kullanma konusunda
          şayet yeterince deneyime sahip değilseniz Girişim Pusulası olarak
          bizlerle iletişime geçmenizi öneririz.
        </div>
        <br />
        <div className="items-center text-neutral-500 text-lg font-semibold font-['Outfit'] flex">
          <img className="p-1 pr-2" src={Pencil} />
          Haydi gelin şimdi başlayalım!
        </div>

        <div className="text-[#797979] text-lg font-normal font-['Outfit'] mt-[27px]">
          En önemli gördüğünüz problemler ve bu problemlerin ilgili
          olduğu/etkilediği müşteri veya kullanıcı kitleleriniz kimler? Birden
          çok önemli gördüğünüz problem ve bu problemlerin etkilediği farklı
          müşteri veya kullanıcılarınız olabilir; ama en öncelikli çözülmesi
          gerektiğini gördüğünüz bir problem ve etkilediği bir kullanıcı
          kitlesini belirleyerek probleminizi aşağıdaki formatta tanımlamaya
          başlayabilirsiniz.
        </div>

        <br />
        <div className="p-[5px] bg-[#fef7eb] rounded-[11px] mt-5 flex items-center">
          <img className="p-4" src={Threatening} />
          <div className="flex-1">
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              Örneğin inşaat ve mimarlık sektöründe iseniz önemli gördüğünüz bir
              probleminiz “
            </span>
            <span className="text-[#797979] text-lg font-semibold font-['Outfit']">
              3-10 yaş arası çocuklar için özelleştirilmiş mimari tasarım proje
              çizimlerini ve uygulamalarını nasıl yapabiliriz ki böylelikle
              çocukların otel konaklama deneyimlerini daha eğlenceli hale
              getirebiliriz?
            </span>
            <span className="text-[#797979] text-lg font-normal font-['Outfit']">
              ” olabilir.{" "}
            </span>
          </div>
        </div>
        <div
          id="last-text"
          className="text-[#797979] text-lg font-normal font-['Outfit'] mt-[42px]"
        >
          Şimdi gelin bu örneğe benzer probleminizi orta satıra, problemin
          etkilediği kullanıcı kitlesini ilk satıra, probleminizi çözdüğünüzde
          yaratacağı etkiyi son satıra yazarak başlayınız.{" "}
        </div>
      </div>
      <div className="w-full flex items-center mt-10">
        <div className="w-[600px] flex-1">
          <div className="w-full pr-10 ">
            <Stepper
              backChange={backPage}
              nextChange={changePage}
              activeStep={1}
              stepLimit={9}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
