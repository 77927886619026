import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

const determiningValueInnovationService = {
  async getAnswers(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/internal-entrepreneurship/determining-value-innovation/buyer-value-map/${projectId}/answers`,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async addAnswer(projectId, containerId, answer, focusPoint) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/internal-entrepreneurship/determining-value-innovation/buyer-value-map/${projectId}/answers`,
        {
          containerId,
          answer,
          focusPoint,
        },
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteAnswer(projectId, containerId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/internal-entrepreneurship/determining-value-innovation/buyer-value-map/${projectId}/answers/${containerId}`,
        { headers, withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

export default determiningValueInnovationService;
