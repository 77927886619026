const tooltipTexts = {
  jtbdCanvas: {
    needs:
      "Kullanıcının yaşadığı temel sorunlar, elde etmek istediği faydalar ve mevcut süreçte karşılaştığı zorluklar bu alanda belirtilir. Ör: Sürücüler boş park yeri bulmakta zorlanıyor. Trafikte park yeri aramak zaman ve yakıt kaybına neden oluyor.",
    jobsToBeDone:
      "Paydaşın hangi işi başarmak istediği veya hangi problemi çözmek için çaba sarf ettiği burada belirtilir. Ör: Sürücüler, varış noktalarına en yakın uygun park yerini hızlıca bulmak istiyor.",
    functionalComponent:
      "Kullanıcının fiziksel veya mantıksal olarak tamamlaması gereken işin işlevsel yönünü ifade eder. Ör: Gerçek zamanlı park yeri bilgisi sunan bir mobil uygulama ile park yeri bulma süreci hızlandırılabilir.",
    emotionalComponent:
      "Kullanıcının işini tamamlarken yaşadığı duygusal durumları ve psikolojik etkileri içerir. Ör: Park yeri bulma stresi azalır ve sürücüler daha rahat bir sürüş deneyimi yaşar.",
    socialComponent:
      "Kullanıcının toplumsal veya sosyal çevresinde nasıl bir etki yaratmak istediği belirtilir. Ör: Trafik sıkışıklığı ve çevre kirliliği azaltılarak daha sürdürülebilir bir şehir yaşamına katkı sağlanır.",
    mainJobStatement:
      "JTBD çerçevesinde en kritik işin kısa ve net bir şekilde tanımlandığı bölümdür. Ör: Sürücüler için en uygun park yerini en kısa sürede bulmalarını sağlayan bir sistem geliştirmek. ",
    whenWhoSituation:
      "Bu bileşen, işin hangi durumda, kim tarafından ve ne zaman yapılacağını açıklar. Ör: Yoğun saatlerde şehir merkezine gelen sürücüler park yeri ararken.",
    desires:
      "Kullanıcının bu işi neden yapmak istediğini açıklar. Ör: Hızlı ve kolay bir şekilde park yeri bulmak, zaman kaybını önlemek.",
    desiredOutcome:
      "Bu işin başarıyla tamamlanması durumunda elde edilecek fayda veya sonuç açıklanır. Ör: Park yeri bulma süresi kısalır, trafik azalır ve kullanıcı deneyimi iyileşir.",
  },
  howCan: {
    userBase:
      "Hedef kullanıcıları ve onların temel ihtiyaçlarını belirlediğimiz bölümdür. Kullanıcı kitlesinin kim olduğu ve onların yaşadığı problem netleştirilir. Ör: Şehirde yaşayan ve günlük hayatta park yeri bulmakta zorlanan sürücüler için.",
    aim: "Kullanıcıların sorunlarını çözmek için oluşturduğumuz 'HMW (How Might We)' sorusunun yer aldığı kısımdır. Burada çözüm yollarını araştırmak için açık uçlu bir soru belirlenir. Ör: Sürücülere en yakın ve müsait park yerlerini anlık olarak gösteren bir mobil uygulama sunarak nasıl yardımcı olabiliriz?",
    solution:
      "Hedeflenen çözümün yaratacağı etkiyi ve beklenen faydaları tanımladığımız bölümdür. Kullanıcıların problemlerine nasıl bir iyileştirme sağlanacağı açıklanır. Örnek: Sürücüler zaman kaybetmeden park yeri bulabilir ve trafikte gereksiz dolaşmayı azaltarak yakıt tasarrufu sağlayabilirler.",
  },
  rootCauseAnalysis: {
    impacts:
      "Problemin kullanıcılar, işletmeler veya toplum üzerindeki etkilerini ve doğurduğu sonuçları ifade eder. Ör: Sürücüler uzun süre park yeri arayarak vakit kaybediyor. Trafik sıkışıklığı artıyor ve hava kirliliği yükseliyor. Şehirde yaşanan stres seviyeleri yükseliyor. Yerel işletmeler müşteri kaybediyor.",
    problem:
      "Tanımlanan temel sorunun açık bir şekilde ifade edildiği alandır. Ör: Şehir merkezinde sürücüler için yeterli park yeri bulunamıyor.",
    causes:
      "Probleme yol açan temel nedenlerin kategorize edilerek listelendiği alandır. Genellikle İnsan, Süreç/Metot, Araçlar, Çevre, Malzemeler, Yönetim gibi ana başlıklara ayrılır. Ör: İnsan: Sürücüler park yerlerini uzun süre işgal ediyor. Süreç/Metod: Otopark yönetimi düzensiz ve yetersiz. Çevre: Şehir planlaması otopark ihtiyacını göz önünde bulundurmamış. Malzemeler: Akıllı park sistemleri yaygın değil. Yönetim: Belediyeler otopark alanları için yeterli yatırım yapmıyor.",
    note: "Bu sebeplerin kök sebeplerine arka arkaya 'neden?' soruları sorarak inebilir, problemi daha iyi analiz edebilirsiniz.",
  },
  interviewTemplate: {
    generalQuestions:
      "Detaylı açıklama için bir sonraki sayfaya bakınız. Bu kısım, kişinin mevcut durumu, alışkanlıkları ve genel perspektifini anlamak için sorulan açık uçlu sorulardır. Ör: Günlük hayatınızda ne sıklıkla park yeri bulma sorunu yaşıyorsunuz? Park yeri bulmak sizin için ne kadar zaman alıyor?",
    experienceQuestions:
      "Detaylı açıklama için bir sonraki sayfaya bakınız. Bu kısım, kullanıcının yaşadığı somut deneyimleri ve geçmişteki durumlarını anlamaya yönelik sorulardır. Ör: En son ne zaman ciddi bir park yeri sorunu yaşadınız ve nasıl çözdünüz? Daha önce bir park yeri bulma uygulaması kullandınız mı? Deneyiminiz nasıldı?",
    specificQuestions:
      "Detaylı açıklama için bir sonraki sayfaya bakınız. Belirli bir ürün, hizmet veya süreç hakkında detaylı bilgi almak için sorulan daha dar kapsamlı sorulardır. Ör: Park yerlerini anlık olarak gösteren bir uygulama olsa kullanır mıydınız? Mevcut park çözümlerinden en çok hangi özelliği beğeniyor veya eksik buluyorsunuz?",
    metaLevelQuestions:
      "Detaylı açıklama için bir sonraki sayfaya bakınız. Kullanıcının genel düşünce yapısını, problem algısını ve farkındalık seviyesini anlamaya yönelik sorulardır.  Ör: Şehirdeki otopark sorunlarının ana nedenleri sizce nelerdir? Teknolojinin bu sorunu çözebileceğini düşünüyor musunuz? Neden?",
    wishQuestions:
      "Detaylı açıklama için bir sonraki sayfaya bakınız. Kullanıcının beklentilerini, önerilerini ve ideal çözümlerini anlamak için sorulan sorulardır. Ör: Park yeri bulma deneyiminizi daha iyi hale getirmek için nasıl bir çözüm görmek isterdiniz? Sizin için ideal park yeri bulma süreci nasıl olmalı?",
    note: "Mülakat öncesi ve sırasında gözlemler, ek bilgiler, ek sorular ve önemli detayların kaydedildiği alandır. ",
  },
  interviewAnalysisCanvas: {
    personInfo:
      "Mülakat yapılan kişinin kimlik bilgileri, yaş, kişisel veriler ve mülakatın yapıldığı yer/tarih gibi temel bilgileri içerir. Farklı segmentlerde kişilerle görüşmeler yapabilirsiniz; fakat bu kısmı bir persona yapısında düşünerek öncelikli hedefleyeceğiniz kitle için doldurmanızı öneririz.",
    basicInsights:
      "Kullanıcının yaşadığı sorunları, beklentilerini ve duygusal faktörleri anlamaya yönelik bilgileri içerir. Ağrı Noktaları: Kullanıcının yaşadığı en büyük zorluklar ve sıkıntılar belirtilir. Ör: Park yeri bulmak çok zaman alıyor ve işe geç kalıyorum. Kazançlar: Kullanıcının mevcut çözümlerle veya yeni çözümlerle elde edebileceği faydalar belirtilir. Ör: Uygun bir park yeri bulduğumda zamandan tasarruf ediyorum. Duygusal Etkenler: Kullanıcının yaşadığı sorunlar karşısındaki hisleri ve psikolojik etkileri açıklanır. Ör: Park yeri bulamamak beni stresli ve sinirli yapıyor.",
    currentSolutions:
      "Kullanıcının şu anda uyguladığı veya piyasada var olan çözümleri ve bunların eksikliklerini kapsar. Kullanılan Mevcut / Geçici Çözümler: Kullanıcının şu anda yaşadığı sorunu çözmek için başvurduğu yöntemler belirtilir. Ör: Her gün aynı saatlerde aynı bölgedeki park yerlerini kontrol ediyorum. Mevcut Çözümlerdeki Boşluklar/Eksiklikler: Kullanıcının mevcut çözümlerle yaşayabileceği eksiklikler veya dezavantajlar açıklanır. Ör: Park uygulamaları genellikle yanlış veya güncellenmemiş bilgiler gösteriyor.",
    improvementOpportunities:
      "Mevcut sistemin nasıl geliştirilebileceği hakkında hem kullanıcının hem de sizin görüşlerinizi kapsar. Potansiyel İyileşme Alanları (Mülakat Yapılan Kişi Açısından): Kullanıcının kendi bakış açısından, sorununu çözebilecek önerileri yer alır. Ör: 'Gerçek zamanlı güncellenen bir park yeri haritası olmalı.  Potansiyel İyileşme Alanları (Sizin Açınızdan): Araştırma ve analizler sonucunda sizin fark ettiğiniz geliştirme alanları belirtilir. Ör: Kullanıcıların park yerlerini işaretleyerek diğer sürücülere bilgi verebildiği bir topluluk tabanlı çözüm geliştirilebilir.",
    nextSteps:
      "Mülakatın ardından yapılacaklar ve alınan notları kapsar. Yapılacaklar: Mülakat bulgularına dayanarak atılacak adımlar ve aksiyon planı belirtilir. Ör: Farklı sürücülerle görüşerek topluluk tabanlı park çözümleri hakkında geri bildirim toplanacak. Ek Notlar: Mülakat sırasında dikkat çeken, ancak belirli bir kategoriye uymayan ek bilgiler kaydedilir. Ör: Kullanıcılar mobil uygulamaların fazla reklam göstermesinden şikayetçi. ",
  },
  empathyMap: {
    felt: "Kullanıcının hissettiği duygular. Ör: Park yeri bulamayınca sinirleniyorum ve stres oluyorum.",
    thought:
      "Kullanıcının zihinsel süreçleri. Ör: Acaba bu saatlerde park yeri bulabilir miyim?",
    said: "Kullanıcının doğrudan ifade ettiği düşünceler. Ör: Bu şehirde park yeri bulmak tam bir işkence!",
    acted:
      "Kullanıcının davranışsal tepkileri. Ör: Trafikte yavaşlayarak sokak aralarında boş yer arıyor.",
    gains:
      "Potansiyel kazanımlar. Ör: Trafikte stres yaşamadan aracını park edebilecek.",
    losses: "Karşılaşılan zorluklar. Ör: Gereksiz zaman ve yakıt harcıyor.",
  },
  ideaGeneration: {
    targetAudience:
      "Problemi çerçevelendikten sonra hedeflenen nihai kullanıcı kitlesi.",
    howCanWe: "Temel amacı ve çözüm yaklaşımını özetleyen ifade.",
    result: "Beklenen sonuç ve elde edilmesi hedeflenen temel fayda.",
  },
  businessModelCanvas: {
    keyPartners:
      "İşbirliği yapan dış ortaklar. Ör: Park yeri veritabanı sağlayıcıları.",
    valueProposition:
      "Müşterilere sunulan değer. Ör: Anlık boş park yerleri göstererek zaman tasarrufu.",
    customerSegments:
      "Hedef müşteri grupları. Ör: Şehir merkezinde park sorunu yaşayan sürücüler.",
    channels:
      "İletişim ve dağıtım kanalları. Ör: Mobil uygulama, sosyal medya.",
    customerRelationships:
      "Müşteri ilişki yönetimi. Ör: Otomatik bildirimler ve geri bildirim toplama.",
    keyActivities:
      "Ana faaliyetler. Ör: Park veritabanı güncellemeleri ve uygulama geliştirme.",
    keyResources:
      "Gerekli kaynaklar. Ör: Harita teknolojileri ve yazılım altyapısı.",
    costStructure:
      "Temel maliyetler. Ör: Sunucu ve yazılım geliştirme maliyetleri.",
    revenueStreams:
      "Gelir kaynakları. Ör: Reklam gelirleri ve abonelik hizmetleri.",
  },
  // Add other sections as needed
};

export default tooltipTexts;
