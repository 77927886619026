import axios from "axios";
import { API_URL } from "./config";
import { handleApiError } from "../utils/errorHandler";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Accept-Language": "tr",
};

export const pestleAnalysisService = {
  async getFactors(projectId) {
    try {
      const response = await axios.get(
        `${API_URL}/modules/strategic-plan/pestle-analysis/${projectId}/factors`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async addFactor(projectId, data) {
    try {
      const response = await axios.post(
        `${API_URL}/modules/strategic-plan/pestle-analysis/${projectId}/factors`,
        data,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async deleteFactor(projectId, factorId) {
    try {
      const response = await axios.delete(
        `${API_URL}/modules/strategic-plan/pestle-analysis/${projectId}/factors/${factorId}`,
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  async updateFactor(projectId, factorId, data) {
    try {
      const response = await axios.put(
        `${API_URL}/modules/strategic-plan/pestle-analysis/${projectId}/factors/${factorId}`,
        {
          content: data.content,
          importanceDegree: data.importanceDegree,
          isOppurtunity: data.isOppurtunity,
          isThreat: data.isThreat,
        },
        {
          headers,
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};
