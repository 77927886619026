import React, { useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import { BLUE_TYPE, GREY_TYPE } from "../Button/constants";

export default function AddReasonModal({
  isOpen,
  onClose,
  onSubmit,
  containerTitle,
  initialContent = "",
  maxLength = 350,
}) {
  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    if (isOpen) {
      setContent(initialContent);
    }
  }, [isOpen, initialContent]);

  const handleSubmit = () => {
    if (!content.trim()) {
      toast.error("Lütfen bir içerik giriniz");
      return;
    }
    onSubmit(content);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose} title={containerTitle} header={true}>
      <div className="p-8 bg-gradient-to-br from-gray-50 to-white">
        <div className="relative">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full p-4 border-2 border-gray-200 rounded-xl h-40 resize-none bg-white shadow-sm 
             focus:border-transparent transition-all duration-200 ease-in-out
            text-gray-700 placeholder-gray-400"
            placeholder="İçerik giriniz..."
            maxLength={maxLength}
          />
          <span className="absolute bottom-2 right-2 text-xs text-gray-400">
            {content.length}/{maxLength}
          </span>
        </div>

        <div className="mt-8 flex justify-end space-x-3">
          <Button
            className="w-40"
            text={"İptal"}
            onClick={onClose}
            iconType={GREY_TYPE}
          />
          <Button
            className="w-40"
            text={"Kaydet"}
            onClick={handleSubmit}
            iconType={BLUE_TYPE}
          />
        </div>
      </div>
    </Modal>
  );
}
