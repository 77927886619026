import { configureStore } from "@reduxjs/toolkit";
import counterSlice from "./slices/counterSlice";
import stepperSlice from "./slices/spinnerSlice";

import { userApi } from "../services/UserService";
import { accountConfirmApi } from "../services/AccountConfirmService";
import { accountConfirmResetApi } from "../services/AccountConfirmReset";
import { accountConfirmResetTokenApi } from "../services/AccountConfirmResetToken";
import { logoutApi } from "../services/LogoutService";
import { resetPasswordApi } from "../services/ResetPasswordService";
import { subModuleApi } from "../services/subModulesService";
import menuSlice from "./slices/menuSlice";
import spinnerSlice from "./slices/spinnerSlice";
import profileSlice from "./slices/profileSlice";

export const store = configureStore({
  reducer: {
    profile: profileSlice,
    [logoutApi.reducerPath]: logoutApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [accountConfirmApi.reducerPath]: accountConfirmApi.reducer,
    [accountConfirmResetApi.reducerPath]: accountConfirmResetApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [subModuleApi.reducerPath]: subModuleApi.reducer,
    [accountConfirmResetTokenApi.reducerPath]:
      accountConfirmResetTokenApi.reducer,

    counter: counterSlice,
    spinner: spinnerSlice,
    menu: menuSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});
