import React, { useState, useEffect } from "react";
import LoadingFullPage from "../../../../../components/LoadingFullPage/LoadingFullPage";
import Stepper from "../../../../../components/Stepper/MainStepper";
import { downloadPageAsPDF } from "../../../../../utils/pdfUtils";
import { toast } from "react-toastify";
import { strategicGuidanceService } from "../../../../../services/strategicGuidanceService";
import AddReasonModal from "../../../../../components/Modals/AddReasonModal";

export default function StrategicObjectivesTable({
  changePage,
  backPage,
  projectId,
  nextPage,
  projectName,
}) {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [gridContent, setGridContent] = useState(
    Array(20)
      .fill()
      .map(() => ({
        section1: "",
        section2: "",
        section3: "",
      }))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [error, setError] = useState("");
  const [initialContent, setInitialContent] = useState("");

  const fetchAnswers = async () => {
    if (!projectId) return;

    try {
      const response = await strategicGuidanceService.getAnswers(projectId);
      if (response && response.titles) {
        const newGridContent = Array(20)
          .fill()
          .map(() => ({
            section1: "",
            section2: "",
            section3: "",
          }));

        response.titles.forEach((column, columnIndex) => {
          column.containers.forEach((container) => {
            const rowIndex = Math.floor((container.containerId - 1) / 3) % 5; // 0-4 arası
            const columnIndex = Math.floor((container.containerId - 1) / 15); // 0-3 arası
            const sectionIndex = ((container.containerId - 1) % 3) + 1; // 1-3 arası

            const cellIndex = rowIndex * 4 + columnIndex;

            if (newGridContent[cellIndex]) {
              newGridContent[cellIndex][`section${sectionIndex}`] =
                container.answer || "";
            }
          });
        });

        setGridContent(newGridContent);
      }
    } catch (error) {
      toast.error("Veriler yüklenirken bir hata oluştu");
    }
  };

  useEffect(() => {
    fetchAnswers();
  }, [projectId]);

  const handleSectionClick = (cellIndex, section, event) => {
    event.stopPropagation();
    console.log("Section clicked:", {
      cellIndex,
      section,
    });
    setSelectedCell(cellIndex);
    setSelectedSection(section);
    setIsModalOpen(true);
    const existingContent = gridContent[cellIndex][section] || "";
    setInitialContent(existingContent);
  };

  const handleSubmit = async (content) => {
    try {
      const rowIndex = Math.floor(selectedCell / 4); // Satır indeksi (0-4)
      const columnIndex = selectedCell % 4; // Sütun indeksi (0-3)
      const sectionNumber = parseInt(selectedSection.replace("section", "")); // Bölüm numarası (1-3)

      // Yeni container ID hesaplama mantığı
      const containerId = columnIndex * 15 + rowIndex * 3 + sectionNumber;

      await strategicGuidanceService.addAnswer(
        projectId,
        containerId,
        content.trim()
      );
      await fetchAnswers();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error adding answer:", error);
      toast.error("Ekleme işlemi sırasında bir hata oluştu");
    }
  };

  const handleDelete = async (cellIndex, section) => {
    try {
      const rowIndex = Math.floor(cellIndex / 4); // Satır indeksi (0-4)
      const columnIndex = cellIndex % 4; // Sütun indeksi (0-3)
      const sectionNumber = parseInt(section.replace("section", "")); // Bölüm numarası (1-3)

      // Yeni container ID hesaplama mantığı
      const containerId = columnIndex * 15 + rowIndex * 3 + sectionNumber;

      await strategicGuidanceService.deleteAnswer(projectId, containerId);
      await fetchAnswers();
    } catch (error) {
      console.error("Error deleting answer:", error);
      toast.error("Silme işlemi sırasında bir hata oluştu");
    }
  };

  const handlePdfDownload = async () => {
    setIsPdfLoading(true);
    const container = document.getElementById("strategic-objectives-container");

    // Store original styles
    const originalStyle = container.style.cssText;

    // Find all elements with overflow/scroll
    const scrollElements = container.querySelectorAll(
      '[style*="overflow"], [style*="overflow-y"], [class*="overflow-y"]'
    );
    const originalStyles = Array.from(scrollElements).map((el) => ({
      element: el,
      style: el.style.cssText,
      className: el.className,
    }));

    // Initialize array to store original elements
    const originalElements = [];

    try {
      // Set temporary container styles
      container.style.cssText = `
        width: 1200px !important;
        position: relative !important;
        padding: 20px !important;
        background: white !important;
      `;

      // Remove scroll/overflow limitations temporarily
      scrollElements.forEach((element) => {
        element.style.overflow = "visible";
        element.style.overflowY = "visible";
        element.style.maxHeight = "none";
        element.style.height = "auto";
        // Remove overflow classes if using Tailwind
        element.className = element.className
          .split(" ")
          .filter((cls) => !cls.includes("overflow"))
          .join(" ");
      });

      // Convert cells to simple divs for PDF
      const cells = container.getElementsByClassName("cell-content");

      Array.from(cells).forEach((cell) => {
        const div = document.createElement("div");
        const sections = cell.getElementsByClassName("section");

        const content = Array.from(sections)
          .map((section) => section.textContent || "")
          .filter((text) => text.trim())
          .join("\n\n");

        div.textContent = content;
        div.style.cssText = `
          width: 100%;
          padding: 8px;
          font-family: Outfit;
          font-size: 14px;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-word;
          line-height: 1.5;
        `;

        originalElements.push({
          element: cell,
          parent: cell.parentNode,
        });

        cell.parentNode.replaceChild(div, cell);
      });

      await downloadPageAsPDF(
        "strategic-objectives-container",
        "Stratejik Amaç Tablosu",
        projectName || "Proje"
      );
    } catch (error) {
      console.error("PDF generation error:", error);
      toast.error("PDF oluşturulurken bir hata oluştu");
    } finally {
      // Restore original container style
      container.style.cssText = originalStyle;

      // Restore original scroll element styles
      originalStyles.forEach(({ element, style, className }) => {
        element.style.cssText = style;
        element.className = className;
      });

      // Restore original cell elements
      originalElements.forEach(({ element, parent }) => {
        const currentElement = parent.firstChild;
        parent.replaceChild(element, currentElement);
      });

      setIsPdfLoading(false);
    }
  };

  const renderCell = (cellIndex) => {
    const cell = gridContent[cellIndex];

    // Container ID hesaplama fonksiyonu
    const getContainerId = (sectionNumber) => {
      const rowIndex = Math.floor(cellIndex / 4);
      const columnIndex = cellIndex % 4;
      return columnIndex * 15 + rowIndex * 3 + sectionNumber;
    };

    const formatText = (text) => {
      if (!text) return "";
      const lines = text.split("\n");
      if (lines.length > 3) {
        return lines.slice(0, 3).join("\n") + "...";
      }
      return text;
    };

    return (
      <div
        key={cellIndex}
        className="border-r bg-[#FDF8F8] border-t min-h-[207px] max-h-[207px] flex flex-col"
      >
        <div
          onClick={(e) => handleSectionClick(cellIndex, "section1", e)}
          className="flex-1 p-2 cursor-pointer hover:bg-gray-50 border-b min-h-[69px] max-h-[69px] text-[13px] whitespace-pre-line group relative overflow-y-auto"
        >
          {formatText(cell?.section1)}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(cellIndex, "section1");
            }}
            className="opacity-0 group-hover:opacity-100 absolute top-1 right-1 p-1 text-gray-400 hover:text-red-600 transition-all "
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
            </svg>
          </button>
        </div>
        <div
          onClick={(e) => handleSectionClick(cellIndex, "section2", e)}
          className="flex-1 p-2 cursor-pointer hover:bg-gray-50 border-b min-h-[69px] max-h-[69px] text-[13px] whitespace-pre-line group relative overflow-y-auto"
        >
          {formatText(cell?.section2)}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(cellIndex, "section2");
            }}
            className="opacity-0 group-hover:opacity-100 absolute top-1 right-1 p-1 text-gray-400 hover:text-red-600 transition-all"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
            </svg>
          </button>
        </div>
        <div
          onClick={(e) => handleSectionClick(cellIndex, "section3", e)}
          className="flex-1 p-2 cursor-pointer hover:bg-gray-50 min-h-[69px] max-h-[69px] text-[13px] whitespace-pre-line group relative overflow-y-auto"
        >
          {formatText(cell?.section3)}
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(cellIndex, "section3");
            }}
            className="opacity-0 group-hover:opacity-100 absolute top-1 right-1 p-1 text-gray-400 hover:text-red-600 transition-all"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
            </svg>
          </button>
        </div>
      </div>
    );
  };

  return (
    <LoadingFullPage state={isPdfLoading}>
      <div id="strategic-objectives-container" className="w-full">
        <div className="bg-[#5B6B9C] text-white p-4 text-center text-xl font-semibold rounded-t-lg">
          Stratejik Amaç Tablosu
        </div>

        <div className="grid grid-cols-5 border border-gray-300">
          {/* Header Row */}
          <div className="col-span-1 border-r p-4 font-semibold bg-gray-50">
            Stratejik Analiz
          </div>
          <div className="p-4 border-r font-semibold bg-gray-50">
            Stratejik Amaç - Finansal Perspektif
          </div>
          <div className="p-4 border-r font-semibold bg-gray-50">
            Stratejik Amaç - Müşteri Perspektifi
          </div>
          <div className="p-4 border-r font-semibold bg-gray-50">
            Stratejik Amaç - Süreç Perspektifi
          </div>
          <div className="p-4 font-semibold bg-gray-50">
            Stratejik Amaç Öğrenme - Büyüme Perspektifi
          </div>

          {/* Content Rows */}
          {[
            "Güçlü yönleriniz ile fırsatlardan nasıl yararlanabilirsiniz?",
            "Güçlü yönleriniz ile tehditleri nasıl bertaraf edebilirsiniz?",
            "Zayıf yönlerinizi gelecek fırsatlar ile nasıl güçlendirebilirsiniz?",
            "Zayıf yönler ile tehditleri nasıl azaltabilirsiniz?",
            "Diğer Modül İçgörüleri",
          ].map((label, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className="col-span-1 border-r border-t p-4 min-h-[150px] overflow-y-auto ">
                {label}
              </div>
              {[0, 1, 2, 3].map((colIndex) => {
                const cellIndex = rowIndex * 4 + colIndex;
                return renderCell(cellIndex);
              })}
            </React.Fragment>
          ))}
        </div>

        <AddReasonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
          containerTitle="İçerik"
          maxLength={100}
          initialContent={gridContent[selectedCell]?.[selectedSection] || ""}
        />
      </div>
      <Stepper
        onPdfDownload={handlePdfDownload}
        backChange={backPage}
        nextChange={changePage}
        activeStep={2}
        stepLimit={4}
      />
    </LoadingFullPage>
  );
}
